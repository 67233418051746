import $ from "jquery";

export default (window) => {

    /*var logoutURL = $("#SignoutURLContainer").attr('logouturl');

    document.querySelectorAll(".signout-link").onclick = function () {
        setTimeout(fetch(logoutURL), 200);
    };*/

    if ($("#customer_logout").length >= 1) {
        $("#customer_logout").on('click', function (e) {
            console.log("GA SEND: ", {
                'event': 'VirtualPageview',
                "virtualPageURL": '/customer-dashboard/logout ',
                "virtualPageTitle": 'Customer Dashboard - Logout'
            })

            window.dataLayer.push
            ({
                'event': 'VirtualPageview',
                "virtualPageURL": '/customer-dashboard/logout ',
                "virtualPageTitle": 'Customer Dashboard - Logout'
            });
        });

    }
    if ($("#application_start").length >= 1) {
        $("#application_start").on('click', function (e) {
            var date = new Date();
            var dateString = date.toLocaleDateString() + " " + date.toLocaleTimeString()
            console.log("GA SEND: ", {
                'event': 'event',
                'eventCategory': 'Loan Application Form',
                'eventAction': 'Start Application',
                'eventLabel': dateString
            })
            window.dataLayer.push({
                'event': 'event',
                'eventCategory': 'Loan Application Form',
                'eventAction': 'Start Application',
                'eventLabel': dateString
            });

            ;
        });

    }
    if ($("#application_continue").length >= 1) {
        $("#application_continue").on('click', function (e) {
            var date = new Date();
            var dateString = date.toLocaleDateString() + " " + date.toLocaleTimeString()
            console.log("GA SEND: ", {
                'event': 'event',
                'eventCategory': 'Loan Application Form',
                'eventAction': 'Continue Application',
                'eventLabel': dateString
            })
            dataLayer.push
            ({
                'event': 'event',
                'eventCategory': 'Loan Application Form',
                'eventAction': 'Continue Application',
                'eventLabel': dateString
            });
        });

    }

    // Remove Kentico's auto wrap on widgets
    if ($('.customer-dashboard > div:not(.column)').length === 1) {
        $('.customer-dashboard > div:not(.column)').children().unwrap();        

        const cards = $('.customer-dashboard .dashboard-card');

        let maxHeight = 0;

        cards.each(function() {
            if ($(this).outerHeight() > maxHeight) {
                maxHeight = $(this).outerHeight();
            }            
        });

        cards.each(function() {
            $(this).outerHeight(maxHeight); 
        });     
        
        $('.customer-dashboard').css('visibility', 'visible');
    }
}