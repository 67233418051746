import $ from 'jquery';
import {
    calculatorReset,
    calculateButtonReset,
    generateVirtualPageView, mobileCalculatorAccordionReset
}        from "./calculator-utils";

export default function () {

    $("#calculator-tabs").on('change.zf.tabs', function (e) {
        calculatorReset();
        let activeTabHref = $('#calculator-tabs .is-active a ').attr('href');

        generateVirtualPageView('');

    });
    $('.results-tabs .tabs').on('change.zf.tabs', function (e) {
        generateVirtualPageView('');
    })
    $(".button.calculate").on('click', function () {
        mobileCalculatorAccordionReset();
    })

};
