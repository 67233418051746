import $ from 'jquery'

export default () => {
  // Simple IE detection
  // Avoid using but it can be helpful sometimes
  let msie = parseInt((/msie (\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1])

  if (isNaN(msie)) {
    msie = parseInt((/trident\/.*; rv:(\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1])
  }

  if (isNaN(msie)) {
    msie = parseInt((/edge\/(\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1])
  }

  if (msie) {
    $('html').addClass('ie ie-'+msie)
  }
  // Polyfil for includes
  String.prototype.includes = function (str) {
    var returnValue = false;

    if (this.indexOf(str) !== -1) {
      returnValue = true;
    }

    return returnValue;
  }
}