import $ from 'jquery';

export default () => {
  $('[data-rel^=lightcase]').lightcase({
    maxWidth: 1200,
    maxHeight: 1200,
    shrinkFactor: 0.8,
    video: {
      width: 1200,
      height: 680
    },
    fullScreenModeForMobile: true,
    iframe: {
        allowfullscreen: true
    },
    onStart : {
      onStartEvent: function() {
        $(this).trigger('start.lightcase')
      }
    },
    // Would be called right before showing the content
    onFinish : {
      onFinishEvent: function() {
        $(this).trigger('finish.lightcase')
      }
    },
    // Would be called when aborting lightcase
    onClose : {
      onCloseEvent: function() {
        $(this).trigger('close.lightcase')
      }
    }
  })
}