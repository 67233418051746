import $ from "jquery";

export default (window) => {

    $("#ucd-mailing-address-input").on({
        keyup: function () {
            if ($("#ucd-mailing-address").val().length == 0) {
                $("#ucd-mailing-address-input").addClass("tiny-12");
                $("#ucd-mailing-address-input").removeClass("tiny-10");
                $("#ucd-mailing-address-button").css("display", "none");
            }
            else {
                $("#ucd-mailing-address-input").addClass("tiny-10");
                $("#ucd-mailing-address-input").removeClass("tiny-12");
                $("#ucd-mailing-address-button").css("display", "block");
            }
        }
    });


    $("#ucd-mailing-address").autocomplete({
        source: function (request, response) {

            $("#ucd-address-loading").css("display", "block");

            $.ajax({
                url: "/api/searchaddress",
                dataType: "json",
                data: { address: $("#ucd-mailing-address").val() },
                success: function (data) {

                    console.log(data);
                    $('.autocompleteSuggestion').remove();

                    (data).forEach(element => {
                        var addressString = element.replace(/\s+/g, ' ').replace(/^\s+|\s+$/, '');;
                        //console.log("addressString =  " + addressString);
                        var suggestion = document.createElement("p");
                        suggestion.innerHTML = addressString;
                        suggestion.className = "autocompleteSuggestion";
                        suggestion.style.color = "black";

                        suggestion.onclick = function () {
                            console.log("clicked suggestion: " + addressString);
                            $("#ucd-mailing-address").val(addressString);
                            $('.autocompleteSuggestion').remove();
                        };

                        $("#ucd-mailing-address").after(suggestion);
                        $("#ucd-address-loading").css("display", "none");
                    });
                }
            });
        }
    });

    /*$("#ucd-mailing-address").on({
        blur: function () { $('.autocompleteSuggestion').remove();}
    });*/

    $('#UCD-Form-Button').on('click', function () {

        lockBackground();
        document.getElementById('Update-Contact-Details').style.display = 'block';
        console.log("Getting customer details");
        $('#UCD-Submit-Button').html("<img src='/Content/img/oval.svg' width='18px'>");
        $('#UCD-Submit-Button').attr("disabled", true);

        $.ajax({
            url: "/api/calmsdashboard/getcustomer",
            success: function (result) {

                console.log(result);

                var address = "";
                var mobile = "";
                if (result.addresses.length > 0) { address = addressToString(result.addresses[0]); }
                if (result.phoneNumbers.length > 0) {
                    var phone = result.phoneNumbers.filter(function (item) { return item.phoneType == "Mobile" });
                    if (phone.length > 0) { mobile = phone[0].number; }
                }

                var $container = $('#Update-Contact-Details-Form');
                $container.find("#ucd-mailing-address").val(address);
                $container.find("#ucd-mailing-address").attr("original", address);
                $container.find("#ucd-mobile-number").val(mobile);
                $container.find("#ucd-mobile-number").attr("original", mobile);
                $container.find("#ucd-email-address").val(result.emailAddress);
                $container.find("#ucd-email-address").attr("original", result.emailAddress);

                document.getElementById("ucd-marketing-emails").checked = result.subscribeToMarketing;
                document.getElementById("ucd-marketing-emails").setAttribute("original", result.subscribeToMarketing);
                document.getElementById("ucd-electronic-communication").checked = !result.paperCommunications;
                document.getElementById("ucd-electronic-communication-label").style.display = result.paperCommunications ? "block" : "none";

                $('#UCD-Submit-Button').html("Update");
                $('#UCD-Submit-Button').attr("disabled", false);
            },
            error: function (error) {
                if (error.responseText.includes("User cannot perform action on this api")) {
                    document.location = "/api/Account/Login";
                }
            }
        });
    });

    $('#Update-Contact-Details-Form').on('submit', function (e) {

        console.log("UCD form submit");
        $('#UCD-Submit-Button').html("<img src='/Content/img/oval.svg' width='18px'>");
        $('#UCD-Submit-Button').attr("disabled", true);
        e.preventDefault();

        var $container = $('#Update-Contact-Details-Form');
        var Address = $container.find("#ucd-mailing-address").val();
        var OriginalAddress = $container.find("#ucd-mailing-address").attr("original");
        var Mobile = $container.find("#ucd-mobile-number").val();
        var OriginalMobile = $container.find("#ucd-mobile-number").attr("original");
        var Email = $container.find("#ucd-email-address").val();
        var OriginalEmail = $container.find("#ucd-email-address").attr("original");
        var MarketingEmail = document.getElementById("ucd-marketing-emails").checked;
        var OriginalMarketingEmail = (document.getElementById("ucd-marketing-emails").getAttribute("original") == "true");
        console.log("MarketingEmail: " + MarketingEmail + ", OriginalMarketingEmail: " + OriginalMarketingEmail);
        var ElectronicCommunication = document.getElementById("ucd-electronic-communication").checked;

        $('.error').remove();

        $.ajax({
            url: '/api/calmsdashboard/validateUCD?Address=' + Address + '&Phone=' + Mobile + '&Email=' + Email,
            success: function (result) {
                console.log(result);
                $('#UCD-Submit-Button').html("Update");
                $('#UCD-Submit-Button').attr("disabled", false);

                if (!result.addressValid) {
                    $container.find("#ucd-mailing-address").parent().addClass('is-invalid-label');
                    $container.find("#ucd-mailing-address").addClass('is-invalid-input');
                }
                if (!result.phoneValid) {
                    $container.find("#ucd-mobile-number").parent().addClass('is-invalid-label');
                    $container.find("#ucd-mobile-number").addClass('is-invalid-input');
                }
                if (!result.emailValid) {
                    $container.find("#ucd-email-address").parent().addClass('is-invalid-label');
                    $container.find("#ucd-email-address").addClass('is-invalid-input');
                }

                var invalid = $container.find('.is-invalid-label');
                console.log(invalid.length, 'invalids');

                if (invalid.length != 0) {
                    for (var i = 0; i < invalid.length; i++) {
                        var error = document.createElement("span");
                        error.innerHTML = invalid[i].getAttribute("errorText");
                        error.className = "error";
                        error.style.display = "flex";
                        invalid[i].after(error);
                    }
                }
                else {
                    document.getElementById('UCD-Confirm-Paragraph').innerHTML = "";

                    if (Address != OriginalAddress) { document.getElementById('UCD-Confirm-Paragraph').innerHTML += "Your mailing address will be updated to:<br>" + Address + "<br>"; }
                    if (Mobile != OriginalMobile) { document.getElementById('UCD-Confirm-Paragraph').innerHTML += "Your phone number will be updated to " + Mobile + "<br>"; }
                    if (Email != OriginalEmail) {
                        document.getElementById('UCD-Confirm-Paragraph').innerHTML += "Your email address will be updated to:<br>" + Email + "<br>";
                    }
                    if (MarketingEmail != OriginalMarketingEmail)
                    { document.getElementById('UCD-Confirm-Paragraph').innerHTML += "Marketing Email: " + (MarketingEmail ? "Opt In" : "Opt Out") + "<br>"; }
                    if (document.getElementById('ucd-electronic-communication-label').style.display == 'block' && ElectronicCommunication)
                    { document.getElementById('UCD-Confirm-Paragraph').innerHTML += "Electronic Communication: Enabled<br>"; }

                    if (Email != OriginalEmail) {
                        document.getElementById('UCD-Confirm-Paragraph').innerHTML += "<br>You will be required to log in again.";
                    }

                    document.getElementById('Update-Contact-Details').style.display = 'none';
                    document.getElementById('UpdateContactConfirm').style.display = 'block';
                    setTimeout(function () { lockBackground(); }, 100);
                }
            },
            error: function (error) {

                if (error.responseText.includes("User cannot perform action on this api")) {
                    document.location = "/api/Account/Login";
                }
                else {
                    $('.modal').hide();
                    document.getElementById('FailModal').style.display = 'block';
                    $('#UCD-Submit-Button').html("Update");
                    $('#UCD-Submit-Button').attr("disabled", false);
                }
            }
        });
    });

    $('#UCD-Update-Contact-Confirm').on('click', function () {

        var $container = $('#Update-Contact-Details-Form');
        var Address = $container.find("#ucd-mailing-address").val();
        var Mobile = $container.find("#ucd-mobile-number").val();
        var Email = $container.find("#ucd-email-address").val();
        var OriginalEmail = $container.find("#ucd-email-address").attr("original");
        var MarketingEmail = document.getElementById("ucd-marketing-emails").checked;
        var ElectronicCommunication = document.getElementById("ucd-electronic-communication").checked;

        var payload = {
            'Address': Address,
            'Phone': Mobile,
            'Email': Email,
            'Marketing': MarketingEmail,
            'ElectronicCommunication': ElectronicCommunication,
        }

        console.log(payload);

        $.ajax({
            url: '/api/calmsdashboard/updatecustomer',
            data: payload,
            success: function (result) {
                console.log(result);
                $('#Update-Contact-Details').hide();
                document.getElementById('UpdateContactConfirm').style.display = 'none';
                lockBackground();

                if (Email == OriginalEmail) {
                    document.getElementById('RequestSubmittedModal').style.display = 'block';
                }
                else {
                    document.getElementById('UCD-LogoutModal').style.display = 'block';
                    document.getElementById('UCD-updated-email-span').innerText = Email;
                }
            },
            error: function (error) {

                if (error.responseText.includes("User cannot perform action on this api")) {
                    document.location = "/api/Account/Login";
                }
                else {
                    $('.modal').hide();
                    document.getElementById('FailModal').style.display = 'block';
                    $('#UCD-Submit-Button').html("Update");
                    $('#UCD-Submit-Button').attr("disabled", false);
                }
            }
        });
    });
}

function addressToString(address) {

    if (address.line1 == "") { return "";}

    var addressString = address.line1;
    if (address.line2 != "") { addressString += " " + address.line2; }
    if (address.line3 != "") { addressString += " " + address.line3; }

    addressString += ", " + address.suburb;
    addressString += " " + address.state;
    addressString += " " + address.postcode;
    addressString = addressString.replace(/\s+/g, ' ').replace(/^\s+|\s+$/, '');

    return addressString;
}