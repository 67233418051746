import $ from 'jquery'

export default function (window) {
  $('body').on('kenticoForm_BeforeSubmit', 'form', function (e, formId, formName) {
    if (!window.dataLayer || !window.dataLayer.push) {
      return
    }

    window.dataLayer.push({
      'event': 'FormSubmitted',
      'eventCategory': 'Form submitted',
      'eventAction': formName,
      'eventLabel': window.location.href
    })
  })
}