import $ from 'jquery';

export default function () {
  const API_URL = window.location.origin + "/api/alert";

  // Hit Get alerts
  // Figure out what notfications they are
  // Show them
  $.ajax({
    url: API_URL + "/getalerts",
    type: "POST",
    data: {},
    contentType: "application/json",
    dataType: "json",
    success: function (result) {
      if( result.success === true ){
        displayAlerts(result.data);
      }
      else{
        console.log('Error getting alerts');
      }
    }
  });

  function displayAlerts(data){
    let alerts = [];

    // delete viewed alerts, based on if there is a localStorage flag for it
    $.each(data, function (k, alert) {
      const alreadyClicked = localStorage.getItem('alert_' + alert.AlertID);
      if (alreadyClicked === "yes") {
        //console.warn('SKIPPING NOTIFICATION' + alert.AlertID);
      }
      else{
        alert.Content = parseContent(alert.Content);
        alerts.push(alert);
      }
    });

    // stop if there is a Popup Confirmation within the alerts
    let doNext = true;
    $.each(alerts, function (k, alert) {
      if (alert.Type === 'popup_confirmation') {
        showCheckboxPopup(alert);
        doNext = false;
        return false;
      }
    });

    // Otherwise, show the first alert in the list
    if (doNext) {
      $.each(alerts, function (k, alert) {
        switch (alert.Type) {
          case'popup':
            showPopup(alert);
            return false;
          case'genie':
            showGeniePopup(alert);
            return false;
          case'banner':
            showBanner(alert);
            return false;
        }
      })
    }
  }

  function parseContent(content) {
    var el = $('<span></span>');
    el.html(content);

    $('a', el).each(function (k, elem) {
      $(elem).attr('target', '_blank');
      if ($(elem).attr('href').includes('~')) {
        var oldhref = $(elem).attr('href');
        $(elem).attr('href', oldhref.replace('~', ''));
      }
    }); // All the anchor elements
    $('img', el).each(function (k, elem) {
      var origsrc = $(elem).attr('src');
      $(elem).attr('src', origsrc.replace('~', ''));
    });

    //console.log("After",el.html());
    return el.html();

  }

  function showGeniePopup(alert) {
    // Add alert popup to body
    $('body').addClass('alert-opened');
    // generate alert and pop it up
    var genieAlert = `
        <div class="alert genie" >
            <a class="genie-alert-close" href="#" data-alert-id="${alert.AlertID}"><i class="fal fa-times"></i></a>
            <p> ${alert.Content} </p>
        </div> 
        `
    $('body').prepend(genieAlert);
  }

  function showPopup(alert) {
    // Add alert popup to body
    // generate alert and pop it up
    $('body').addClass('alert-opened');

    var popupTemplate = `
        <div class="reveal alert popup" id="alert_popup" data-reveal data-options="closeOnBackgroundClick:false;closeOnClick:false;closeOnEsc:false;">
            
            <h5 class="title">${alert.Title}</h5>
            <div class="content">${alert.Content} </div>
            <!--<button class="button popup-alert-close"  data-close aria-label="Close modal" data-alert-id="${alert.AlertID}" data-close type="button">
                Submit 
            </button>-->
           <button class="close-button popup-alert-close " data-alert-id="${alert.AlertID}" data-close aria-label="Close modal" type="button">
                <span aria-hidden="true">&times;</span>
              </button> 
        </div>
        `

    $('body').append(popupTemplate);
    $(document).foundation();
    $("#alert_popup").foundation('open');
  }

  function showCheckboxPopup(alert) {
    // Add alert popup to body
    // Add alert popup to body
    $('body').addClass('alert-opened');
    // generate alert and pop it up
    var popupTemplate = `
        <div class="reveal alert popup" id="alert_popup" data-reveal data-options="closeOnBackgroundClick:false;closeOnClick:false;closeOnEsc:false;">
            <h5 class="title">${alert.Title}</h5>
            <div class="content">${alert.Content} </div>
            <div class="confirmation">
                <input class="checkbox" name="popup_confirmation_checkbox" type="checkbox" id="popup_confirmation_checkbox" value="yes">
                <label for="popup_confirmation_checkbox"><strong>I acknowledge that I have read and fully understand the terms and conditions</strong></label>
                </div>
                <input class="button popup-alert-close disabled" id="popup_alert_close" data-close aria-label="Close modal" data-alert-id="${alert.AlertID}" data-close type="button" disabled="disabled" value="Submit" />
                
        </div>
        `

    $('body').append(popupTemplate);
    $(document).foundation();
    $("#alert_popup").foundation('open');

  }

  function showBanner(alert) {
    // generate alert and pop it up
    $("body").addClass('banner-popup');
    var bannerAlert = `
        <div class="alert banner" >
            <a class="banner-alert-close" href="#" data-alert-id="${alert.AlertID}"><i class="fal fa-times"></i></a>
            <p> ${alert.Content} </p>
        </div> 
        `

    $('body').prepend(bannerAlert);
    $('.banner-popup').attr('style', 'padding-top:' + $('.alert.banner').height() + 'px');
  }

  function hideBanner(){
    $("body").removeClass('banner-popup');
    $('.alert').remove();
  }

  function dismissAlert(alertID) {
    localStorage.setItem('alert_' + alertID, "yes");
    if(typeof(alertID) === undefined){
        return;
    }
    if(parseInt(alertID) >=0) {
      $.ajax({
               url: API_URL + "/markalertasread/id/" + alertID,
               type: "POST",
               data: {},
               contentType: "application/json",
               dataType: "json",
               success: function (result) {
                 if (result.success === true) {
                     hideBanner();
                 }
                   window.location.reload();
                   //console.log('Error dismissing alert');
                   //hideBanner();

               }
             });
    }
  }

  $('body').on('click', '.genie-alert-close', function () {
    var alertID = $(this).attr('data-alert-id');
    dismissAlert(alertID);
  });

  $('body').on('click', '.banner-alert-close', function () {
    $('body').removeClass('banner-popup').attr('style', '');
    $('.alert.banner').slideUp();
    var alertID = $(this).attr('data-alert-id');
    dismissAlert(alertID);
  });
  $('body').on('click', '.popup-alert-close', function () {
    $('.alert.popup').slideUp();
    $('body').removeClass('banner-popup');
    var alertID = $(this).attr('data-alert-id');
    dismissAlert(alertID);

  });
  $("body").on('change', '#popup_confirmation_checkbox', function (e) {
    if ($("#popup_confirmation_checkbox").is(':checked')) {
      $("#popup_alert_close").removeClass('disabled').removeAttr('disabled').removeProp('disabled');
    } else {
      $("#popup_alert_close").addClass('disabled').prop('disabled', 'disabled');
    }
  })

};
