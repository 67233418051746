/*
 * Calculator CTA Buttons
 *  Contains code to handle Callback / Email / Print Call to actions:
 */
import $ from 'jquery';

import {
    //fillCaluclatorForm,
    formatCurrency,
    generateVirtualPageView,
    maybeParseInt,
    maybeParseFloat
} from "./calculator-utils";
import getFieldValues from "./calculator-get-values";


export default function (window) {
    // Don't run on calculator page
    if ($('.calculator-page').length <= 0) {
        return;
    }

    let currentlyActiveCalculator = "";
    const $printContainers = $('.print-container');
    let $printContainer = null;

    const FORMS = {
        borrow: $('#borrow_form'),
        deposit: $('#deposit_form'),
        repayments: $('#repayments_form'),
        lmilpc: $('#lmilpc_form'),
    }


    /** Call back button handler
     | Generates virtual page view
     | handles closing Email fcorm
     | shows CTA form
     */
    $("#callback_button").on('click', function () {
        if ($("#callback_button").hasClass('active')) {
            return;
        }
        generateVirtualPageView('callback');

        $('.call-to-actions .active').removeClass('active');
        $('.cta-form:visible').slideUp();
        $("#callback_form").slideDown();
        $("#callback_button").addClass('active');
        $('.cta-success-message').slideUp();
    });

    /** Callback Form Handler
     | Generates Virtal Page View for submission event
     | Checks for validation errors
     | Submits form & displays success message
     */
    $("#callback_form").on('submit', function (e) {
        var self = this;
        e.preventDefault();

        // delay to allow validation
        setTimeout(function () {

            // Explicitly set the parameter to 0 since this loads before
            // the email form which is 1.
            if (grecaptcha.getResponse(0) == '') {
                console.log("captcha failed");
                generateVirtualPageView('callback/fail');
                return;
            }

            if ($('.form-error.is-visible', self).length > 0) {
                console.log("form errors found");
                generateVirtualPageView('callback/fail');
                return;
            }

            $(".call-to-actions .button.active").removeClass('active');
            $('.cta-success-message h3').html('Thank you.<br/> Your details have been sent, and one of our friendly and experienced consultants will contact you within one working day.');

            //fillCaluclatorForm(window);
            generateVirtualPageView('callback/submit');
            $("#callback_form").foundation("validateForm");
        }, 500);

    });

    /** "Email" button handler
     | Generates virtual page view
     | handles closing C fcorm
     | shows CTA form
     */
    $("#email_button").on('click', function () {
        if ($("#email_button").hasClass('active')) {
            return;
        }

        generateVirtualPageView('email');

        $('.call-to-actions .active').removeClass('active');
        $('.cta-form:visible').slideUp();
        $("#email_form").slideDown();
        $("#email_button").addClass('active');
        $('.cta-success-message').slideUp();
        $('#cta-email-input').show();
        $('#cta-email-sent').hide();
        $('.cta-form-submit').show();
    });

    /** Email Form Handler
     | Generates Virtal Page View for submission event
     | Checks for validation errors
     | Submits form & displays success message
     */
    $("#email_form").on('submit', function (e) {
        var self = this;
        e.preventDefault();

        // delay to allow validation
        setTimeout(function () {
            if (grecaptcha.getResponse(0) == '') {
                console.log("captcha response fail");
                generateVirtualPageView('email/fail');
                return;
            }

            if ($('.form-error.is-visible', self).length > 0) {

                generateVirtualPageView('email/fail');
                return;
            }

            $('.cta-form-submit').slideUp();

            //$(".call-to-actions .button.active").removeClass('active');
            //$('.cta-success-message h3').html('An email will be sent to you shortly');

            // TODO send ajax request to email service and get that done
            //fillCaluclatorForm(window);
            $("#email_form").foundation("validateForm");
            generateVirtualPageView('email/submit');

            let activeTabHref = $('#calculator-tabs .is-active a ').attr('href');
            let leadSourceMap = {
                "#borrow": "BORROW_CALCULATOR",
                "#deposit": "DEPOSIT_CALCULATOR",
                "#repayments": "REPAYMENT_CALCULATOR",
                "#lmilpc": "LPC_CALCULATOR",
            };
            let leadSource = leadSourceMap[activeTabHref];

            let data = {
                FirstName: $('#email_firstname').val(),
                LastName: $('#email_lastname').val(),
                Email: $('#email_email').val(),
                //Phone: ,
                //BestTimeToCall: ,
                LeadSource: leadSource,
                Borrower1Income: $('[name="borrow_borrower_1_income"]').val(),
                Borrower2Income: $('[name="borrow_borrower_2_income"]').val(),
                Borrower3Income: $('[name="borrow_borrower_3_income"]').val(),
                Borrower4Income: $('[name="borrow_borrower_4_income"]').val(),
                //Borrower5Income: ,
                Borrower1PaymentFrequency: $('[name="borrow_borrower_1_income_frequency"]').val(),
                Borrower2PaymentFrequency: $('[name="borrow_borrower_2_income_frequency"]').val(),
                Borrower3PaymentFrequency: $('[name="borrow_borrower_3_income_frequency"]').val(),
                Borrower4PaymentFrequency: $('[name="borrow_borrower_4_income_frequency"]').val(),
                //Borrower5PaymentFrequency: ,
                FirstHomeBuyer: ($('input[name="borrow_first_home_buyer"]:checked').val() == "yes") ? "true" : "false",
                BuildOrBuy: ($('input[name="borrow_build_or_buy"]:checked').val() == "buy") ? "BuyHome" : "BuyLandAndBuild",
                NumberOfDependents: maybeParseInt($("#borrow_number_of_dependants").val()),
                PropertyPurchasePrice: (maybeParseFloat($("#borrow_property_price").val()) == 0) ? "" : maybeParseFloat($("#borrow_property_price").val()),
                Suburb: ($("#borrow_suburb").val() != "") ? $("#borrow_suburb").val() : "ADELAIDE",
                OtherCommitments: maybeParseInt($("#borrow_other_commitments").val()),
                TotalCredit: maybeParseInt($("#borrow_total_credit_limit").val()),
                LoanAmount: $("#repayments_loan_amount").val(),
                DepositAmount: $("#lmilpc_deposit").val()                
            };

            console.log(data);
            let url = window.location.origin + '/api/calculator/HandleForm';
            $.ajax({
                url: url,
                type: "GET",
                data: data,
                contentType: "application/json",
                dataType: "json",
                success: function (result) {
                    console.log(result);
                    $(".call-to-actions .button.active").removeClass('active');
                    $("#cta-email-input").hide();
                    $('#cta-email-sent').html('An email will be sent to ' + $("#email_email").val() + ' shortly');
                    $('#cta-email-sent').show();
                    $('.cta-form-submit').show();
                },
                error: function() {
                    $('.cta-form-submit').show();
                }
            });
        }, 500);
    })

    /** CTA Form "Submit" button
     | Triggers a submit on the visibile CTA form
     |  - As only one is ever displayed, this works great.
     */
    $('.cta-form-submit').on('click', function () {

        $('.cta-form:visible').submit();
    });

    /** CTA Form "Cancel" button
     | Generates virtual page view
     | Closes CTA form
     */
    $('.cta-form-cancel').on('click', function () {
        let currentForm = $('.call-to-actions .active').attr('id').replace('_form', '').replace('_button', '');
        generateVirtualPageView(currentForm + "/cancel");
        $('.call-to-actions .active').removeClass('active');

        $('.cta-form:visible').slideUp();
    });


    // When swapping from small to medium we need to shift where the call back buttons live
    var isCTAMobile = !Foundation.MediaQuery.is('medium up');
    var ctaTimeout;

    /** function updateCTALayout
     | Calculator CTA Buttons need to be displayed in different areas of the DOM on Mobile
     | This handles moving the buttons on Foundations breakpoint event
     */
    function updateCTALayout() {
        if ($('.call-to-actions').length >= 2) {
            //console.log('removing duplicated cta buttons');
            $('.call-to-actions').not(':first').remove();
        }
        clearTimeout(ctaTimeout);
        ctaTimeout = setTimeout(function () {
            // desktop -> mobile
            if (!isCTAMobile && !Foundation.MediaQuery.is('medium up')) {
                //console.log('Moving CTA buttons to homestart accordion')
                window.setTimeout(function () {
                    $('.call-to-actions').appendTo($(".results-tabs:visible .accordion-item:visible .accordion-content"));

                }, 150)
                isCTAMobile = true;
            }
            // mobile -> desktop
            else if (isCTAMobile && Foundation.MediaQuery.is('medium up')) {
                //console.log('Moving CTA buttons to homestart tabs')
                isCTAMobile = false;
                $('.call-to-actions').insertAfter($("#repayments_results"));
            }
        }, 250);
        if ($('.call-to-actions').length >= 2) {
            //console.log('removing duplicated cta buttons');
            $('.call-to-actions').not(':first').remove();
        }
    }

    // Event Listener for media query changes
    $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
        updateCTALayout(); // update on breakpoint change
    });
    // Initially set the buttons in the correct place on DOM loan
    updateCTALayout(); // init on page load

    /** Mobile CTA
     | when an accordion is opened
     | the CTA buttons will need to be moved to the currently open accordion
     */
    $('.calculator-page').on('click', '.results-tabs:visible .accordion-title', function (e) {
        //console.log('Move contact buttons to opening accordion');
        if ($('.call-to-actions').length >= 2) {
            //console.log('removing duplicated cta buttons');
            $('.call-to-actions').not(':first').remove();
        }

        let $currentElem = $(e.currentTarget);

        if ($currentElem.hasClass('is-active')) {
            //alert('already open not scrolling');
            return;
        }
        let $targetElem = $currentElem.parent().find('.accordion-content');
        let $scrollTarget = $targetElem.parent().find('.accordion-title');
        //alert("Scroll to" + $targetElem.offset().top);
        //$targetElem.scrollIntoView();
        let $ctaButtons = $('.call-to-actions').appendTo($targetElem);
        $('.call-to-actions').show(); // if for some reason it's hidden (e.g. opening print window)
        //console.log('Scrolling to ', $currentElem.attr('id'), 'offset', $currentElem.offset().top)
        window.setTimeout(function () {
            $('html,body').animate({ scrollTop: $currentElem.offset().top - 100 }, 200);
        }, 750)
        //window.setTimeout(function () {
        //
        //$(document).scrollTo($scrollTarget.offset().top -150);
        //}, 250);
    })
    $('.calculator-page').on('click', '.accordion-title', function (e) {
        let $currentElem = $(e.currentTarget);


    });

    /**
     * Changes the print visibility to the active tab
     */
    function updatePrintContainers(isPrintClick = false) {
        // get active tab
        currentlyActiveCalculator = $('#calculator-tabs .is-active a').attr('href');
        currentlyActiveCalculator = currentlyActiveCalculator != null ? currentlyActiveCalculator.split('#')[1] : '';

        // disable all print containers
        $printContainers.removeClass('selected-for-print');

        // get print container
        $printContainer = $printContainers.filter('#' + currentlyActiveCalculator + '_print_container');
        $printContainer.addClass('selected-for-print'); // make visible on print
    }

    /** CTA Print button
     | Generates Virtual Page View
     | Opens print window
     */
    $("#print_button").on('click', function (e) {
        generateVirtualPageView('print');
        updatePrintContainers(true);

        window.print();
    });

    /** function fillPrintDetails
     | Populates the print container with the users calculatioln results
     | in  preperation for viewing in Print Mode
     */
    function fillPrintDetails(type, $form, $printContainers) {
        if ($form == null || $printContainers == null || $form.length <= 0 || $printContainers.length <= 0) {
            return;
        }

        // get values
        let details = getFieldValues(type, $form);

        // apply
        $.each(details, function (attributeName, value) {
            if ((value == 0 || value == "") && $printContainers.find('td[data-printfill="' + attributeName + '"]').is('[data-canHide]')) {
                // Skip filling (hide?) if it's zero
                //console.log('Hiding ' + attributeName + 'value of ' + value);
                $printContainers.find('td[data-printfill="' + attributeName + '"]').parent().hide();
            } else {

                //console.log(attributeName + 'value of ' + value);
                $printContainers.find('td[data-printfill="' + attributeName + '"]').parent().show();
                if ($printContainers.find('td[data-printfill="' + attributeName + '"]').is('[data-formatCurrency]')) {
                    //console.log('Formatting currency for ' + attributeName + "value : " + value);

                    if (value !== undefined) {
                        $printContainers.find('td[data-printfill="' + attributeName + '"]').text(formatCurrency(value.replace(/,/g, '')))
                    } else {

                        $printContainers.find('td[data-printfill="' + attributeName + '"]').parent().hide();
                        console.log('error on:' + attributeName);
                    }

                    //console.log('showing ' + attributeName);
                    $printContainers.find('td[data-printfill="' + attributeName + '"]').parent().show();
                } else {
                    if (value !== undefined) {
                        $printContainers.find('td[data-printfill="' + attributeName + '"]').text(value)
                    } else {
                        $printContainers.find('td[data-printfill="' + attributeName + '"]').parent().hide();
                        console.log('error2 on:' + attributeName);
                    }
                }
            }
        })

    }

    // listen to tab change and fill Print Details
    $("#calculator-tabs").on('change.zf.tabs', function (e) {
        updatePrintContainers();

        const type = currentlyActiveCalculator;
        fillPrintDetails(type, FORMS[type], $printContainers.filter('#' + type + '_print_container'));
    });

    // listen to user inputs
    // Update print containers with new input
    $.each(FORMS, function (type, $form) {
        var inputDelay;
        $('#' + type).find('input, select').on('change input', () => {
            clearTimeout(inputDelay);
            inputDelay = setTimeout(function () {
                fillPrintDetails(type, $form, $printContainers.filter('#' + type + '_print_container'));
            }, 500);
        });
    });

    // init
    updatePrintContainers();
    fillPrintDetails(currentlyActiveCalculator, FORMS[currentlyActiveCalculator], $printContainers.filter('#' + currentlyActiveCalculator + '_print_container'));
}

