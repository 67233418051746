import $ from 'jquery';

export default function() {
  const $reports = $("#aggregator_report");
  if( $reports.length > 0 ){
    console.log('Aggregator report init');
    // API Request for Table HTML
    $.getJSON(window.location.origin + "/api/aggregatorreport/get", {}, function (data) {

      // loop through each report
      $reports.each(function(){
        initReport($(this), data.content);
      });
    })
  }
};


const initReport = ($report, html) => {
  // Append HTML to each report
  $report.html(html);

  // Add view more button
  //const $viewMore = $('<a class="view-more-ssrs">View more</a>');
  //$report.find('[aria-label="Report text"]').prepend($viewMore);

  // Add view more trigger
  //$viewMore.on('click', () => {
    //$report.addClass('show-all');
  //})
}