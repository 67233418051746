import $ from 'jquery';

import {
    calculatorLoadError,
    formatCurrency,
    updateCalculatorButtonText,
    showCallToActions, generateVirtualPageView, mobileCalculatorAccordionReset
} from "./calculator-utils";

export default function (results) {
    // Update calculator button with the top amount
    window.results.Standard = results;
    if (results.status != "Success" ) {
        console.log('Encountered API error', results);
        calculatorLoadError(JSON.stringify(results.errors));
    }
    let formatted_results = {}
    $.each(results.scenarios, function (k, item) {
        var yearsDecimalString = (item.estimatedTerms / 12).toString();
        var years = Math.floor(item.estimatedTerms / 12);
        var months = item.estimatedTerms % 12;
        console.log("YEARS:" + years + " | MONTHS:" + months + " | FROM : " + item.estimatedTerms);

        if (years.length == 1) {
            // No months?
            console.log("no months?");
            console.log(years);
        }
        if ((years * 12) + months != item.estimatedTerms) {
            //alert(1);
            console.warn("LOAN TERM DOES NOT ADD UP");
            console.warn((years * 12) + "+" + months + "=" + item.estimatedTerms)
        }
        formatted_results[item.scenarioName.replace('-', '_')] = {
            years: years,
            months: months,
            amount: (item.repaymentsAmount >= 0) ? item.repaymentsAmount : 0,
        }
    });
    //console.log(formatted_results);
    var lowestWeekly = 9999999;
    $.each(formatted_results, function (k, v) {
        if (k.includes("WEEKLY")) {

            if (v.amount < lowestWeekly) {
                lowestWeekly = v.amount;
            }
        }
    });
    if (lowestWeekly == 9999999) {
        lowestWeekly = 0;
        //calculatorLoadError("Enter a value above zero");
        //return;
    }


    updateCalculatorButtonText("Your estimated weekly repayments would be", formatCurrency(lowestWeekly, true));
    $(".advantage_text").hide();
    $(".calculate-button:visible").removeClass('loading');

    // Update tab titles
    $("#repayments_homestart_loan_results-label").find('span').text(formatCurrency(formatted_results.STANDARDWEEKLY.amount, true) + " per week");
    $("#repayments_graduate_loan_results-label").find('span').text(formatCurrency(formatted_results.GRADUATEWEEKLY.amount, true) + " per week");
    $("#repayments_lowdeposit_loan_results-label").find('span').text(formatCurrency(formatted_results.LOWDEPOSITWEEKLY.amount, true) + " per week");
    //  Update weekly/fortnightly/monthly in each
    // Standard
    $("#repayments_homestart_loan_results").find('.weekly .amount').text(formatCurrency(formatted_results.STANDARDWEEKLY.amount, true))
    $("#repayments_homestart_loan_results").find('.weekly .timeframe').text(formatted_results.STANDARDWEEKLY.years + " years and " + formatted_results.STANDARDWEEKLY.months + " months")
    $("#repayments_homestart_loan_results").find('.fortnightly .amount').text(formatCurrency(formatted_results.STANDARDFORTNIGHTLY.amount, true))
    $("#repayments_homestart_loan_results").find('.fortnightly .timeframe').text(formatted_results.STANDARDFORTNIGHTLY.years + " years and " + formatted_results.STANDARDFORTNIGHTLY.months + " months")
    $("#repayments_homestart_loan_results").find('.monthly .amount').text(formatCurrency(formatted_results.STANDARDMONTHLY.amount, true))
    $("#repayments_homestart_loan_results").find('.monthly .timeframe').text(formatted_results.STANDARDMONTHLY.years + " years and " + formatted_results.STANDARDMONTHLY.months + " months")

    // Graduate
    $("#repayments_graduate_loan_results").find('.monthly .amount').text(formatCurrency(formatted_results.GRADUATEMONTHLY.amount, true))
    $("#repayments_graduate_loan_results").find('.monthly .timeframe').text(formatted_results.GRADUATEMONTHLY.years + " years and " + formatted_results.GRADUATEMONTHLY.months + " months")

    $("#repayments_graduate_loan_results").find('.weekly .amount').text(formatCurrency(formatted_results.GRADUATEWEEKLY.amount, true))
    $("#repayments_graduate_loan_results").find('.weekly .timeframe').text(formatted_results.GRADUATEWEEKLY.years + " years and " + formatted_results.GRADUATEWEEKLY.months + " months")

    $("#repayments_graduate_loan_results").find('.fortnightly .amount').text(formatCurrency(formatted_results.GRADUATEFORTNIGHTLY.amount, true))
    $("#repayments_graduate_loan_results").find('.fortnightly .timeframe').text(formatted_results.GRADUATEFORTNIGHTLY.years + " years and " + formatted_results.GRADUATEFORTNIGHTLY.months + " months")

    $("#repayments_graduate_loan_results").find('.monthly .amount').text(formatCurrency(formatted_results.GRADUATEMONTHLY.amount, true))
    $("#repayments_graduate_loan_results").find('.monthly .timeframe').text(formatted_results.GRADUATEMONTHLY.years + " years and " + formatted_results.GRADUATEMONTHLY.months + " months")


    // LowDeposit
    $("#repayments_lowdeposit_loan_results").find('.monthly .amount').text(formatCurrency(formatted_results.LOWDEPOSITMONTHLY.amount, true))
    $("#repayments_lowdeposit_loan_results").find('.monthly .timeframe').text(formatted_results.LOWDEPOSITMONTHLY.years + " years and " + formatted_results.LOWDEPOSITMONTHLY.months + " months")

    $("#repayments_lowdeposit_loan_results").find('.weekly .amount').text(formatCurrency(formatted_results.LOWDEPOSITWEEKLY.amount, true))
    $("#repayments_lowdeposit_loan_results").find('.weekly .timeframe').text(formatted_results.LOWDEPOSITWEEKLY.years + " years and " + formatted_results.LOWDEPOSITWEEKLY.months + " months")

    $("#repayments_lowdeposit_loan_results").find('.fortnightly .amount').text(formatCurrency(formatted_results.LOWDEPOSITFORTNIGHTLY.amount, true))
    $("#repayments_lowdeposit_loan_results").find('.fortnightly .timeframe').text(formatted_results.LOWDEPOSITFORTNIGHTLY.years + " years and " + formatted_results.LOWDEPOSITFORTNIGHTLY.months + " months")

    $("#repayments_lowdeposit_loan_results").find('.monthly .amount').text(formatCurrency(formatted_results.LOWDEPOSITMONTHLY.amount, true))
    $("#repayments_lowdeposit_loan_results").find('.monthly .timeframe').text(formatted_results.LOWDEPOSITMONTHLY.years + " years and " + formatted_results.LOWDEPOSITMONTHLY.months + " months")

    if (!Foundation.MediaQuery.is('medium up')) {
        mobileCalculatorAccordionReset();
    } else {
        $("#repayments_results").find('.is-active').removeClass('is-active');
        $("#repayments_homestart_loan_results-label").parent().addClass('is-active'); // first one remains hidden until shown
        $("#repayments_homestart_loan_results").addClass('is-active'); /* first one remains hidden until shown       */
    }

    $("#repayments_results").show();
    showCallToActions();
    generateVirtualPageView();

};