import $ from "jquery";

export default (window) => {
    $('#Switch-Interest-Rate-Button').on('click', function () {
        console.log("Switch interest rate");
        lockBackground();
        document.getElementById('Switch-Interest-Rate').style.display = 'block';
        var container = document.getElementById('Interest-Rate-Buttons');
        container.innerHTML = "<img src='/Content/img/ovalDark.svg' width='64px' style='margin: auto; margin-top: 30px; margin-bottom: 30px;'>";
        //var HULID = $('#primary-loan-div').attr('HULID');

        $.ajax({
            url: "/api/calmsdashboard/GetInterestRateDetails"
        }).done(function (result) {

            console.log(result);

            if (result.errors.length > 0) {

                document.getElementById('Switch-Interest-Error').style.display = 'block';
                document.getElementById('Switch-Interest-Error-h').innerText = "An error has occurred";
                document.getElementById('Switch-Interest-Error-p').innerText = result.errors[0].description;
                document.getElementById('Switch-Interest-Unavailable').style.display = 'none';
                document.getElementById('Switch-Interest-Available').style.display = 'none';
            }
            else if (result.interestRates.length == 0) {
                document.getElementById('Switch-Interest-Unavailable').style.display = 'block';
                document.getElementById('Switch-Interest-Available').style.display = 'none';
                document.getElementById('Switch-Interest-Error').style.display = 'none';
            }
            else {
                document.getElementById('Switch-Interest-Unavailable').style.display = 'none';
                document.getElementById('Switch-Interest-Available').style.display = 'block';
                document.getElementById('Switch-Interest-Error').style.display = 'none';
                container.innerHTML = "";
                var labels = [];

                for (var i = 0; i < result.interestRates.length; i++) {

                    /*<label class="interest-selector medium-4 tiny-12 columns" style="padding-right: 0px; padding-left: 0">
                        <input type="radio" id="interest-option-1" name="interest" value="4.33" />
                        <span>
                            <br />
                            <span id="interest-option-1-value">???%</span>
                            <span id="interest-option-1-description">Fixed interest rate for 1 year</span>
                        </span>
                    </label>*/


                    var rate = result.interestRates[i].interestRate;
                    var code = result.interestRates[i].rateCode;

                    labels[i] = document.createElement("label");
                    labels[i].classList = "interest-selector medium-4 tiny-12 columns";
                    labels[i].style.paddingLeft = "0px";
                    labels[i].style.paddingRight = "0px";
                    // labels[i].setAttribute("InterestRate", rate);
                    //labels[i].setAttribute("RateCode", code);
                    labels[i].onclick = function () { selectRate(); };
                    //label.value = result.interestRates[i].interestRate;

                    var input = document.createElement("input");
                    input.type = "radio";
                    input.name = "interest";
                    input.setAttribute("InterestRate", rate);
                    input.setAttribute("RateCode", code);
                    input.setAttribute("RateType", result.interestRates[i].interestRateType);
                    input.setAttribute("RateDescription", result.interestRates[i].interestDescription);
                    labels[i].appendChild(input);

                    var span = document.createElement("span");

                    var br = document.createElement("br");
                    span.appendChild(br);

                    var interestRateSpan = document.createElement("span");
                    interestRateSpan.innerText = rate + "%";
                    interestRateSpan.style.textIndent = 0;
                    span.appendChild(interestRateSpan);

                    var interestDescriptionSpan = document.createElement("span");
                    interestDescriptionSpan.innerText = result.interestRates[i].interestDescription;
                    span.appendChild(interestDescriptionSpan);

                    labels[i].appendChild(span);
                    container.appendChild(labels[i]);
                }
            }

        });

    });

    $('#Switch-Interest-Rate-Submit-Button').on('click', function () {

        var selector = document.getElementById('Interest-Rate-Buttons');

        if (selector.getAttribute("InterestRate") == null) { return; }

        $('#Switch-Interest-Rate-Submit-Button').html("<img src='/Content/img/oval.svg' width='18px'>");
        $('#Switch-Interest-Rate-Submit-Button').prop('disabled', true);

        var payload = {
            NewRate: selector.getAttribute("InterestRate"),
            RateCode: selector.getAttribute("RateCode"),
            RateType: selector.getAttribute("RateType"),
            RateDescription: selector.getAttribute("RateDescription")
        };

        console.log(payload);

        $.ajax({
            url: "/api/calmsdashboard/SetNewInterestRate",
            data: payload,
            success: function (result) {
                console.log(result);

                $('#Switch-Interest-Rate-Submit-Button').html("Submit");
                $('#Switch-Interest-Rate-Submit-Button').prop('disabled', false);
                document.getElementById('Switch-Interest-Rate').style.display = 'none';
                document.getElementById('SIRRequestSubmittedModal').style.display = 'block';
                unlockBackground();
            },
            error: function () {
                $('.modal').hide();
                document.getElementById('FailModal').style.display = 'block';
                $('#Switch-Interest-Rate-Submit-Button').html("Submit");
                $('#Switch-Interest-Rate-Submit-Button').prop('disabled', false);
            }
        });
    });

    function selectRate() {

        var elem = event.srcElement;
        console.log(elem);

        var buttonsDiv = document.getElementById('Interest-Rate-Buttons');
        buttonsDiv.setAttribute("InterestRate", elem.getAttribute("InterestRate"));
        buttonsDiv.setAttribute("RateCode", elem.getAttribute("RateCode"));
        buttonsDiv.setAttribute("RateType", elem.getAttribute("RateType"));
        buttonsDiv.setAttribute("RateDescription", elem.getAttribute("RateDescription"));
    }
}