import $                      from 'jquery';

export default function (window) {
    const numberInputs = $('.row.calculator-tabs input[type="number"]');

    numberInputs.each(function (index, element) {
        let min = $(element).attr('min') ? parseInt($(element).attr('min')) : null;
        let max = $(element).attr('max') ? parseInt($(element).attr('max')) : null;

        $(element).on('blur', function (e) {
            // Reset value if it is in the range between min and max
            if (min !== null && parseFloat($(this).val()) < min) {
                $(this).val(min);
            } else if (max !== null && parseFloat($(this).val()) > max) {
                $(this).val(max);
            }
        })
    })
}

