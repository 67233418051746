import $                                                                  from "jquery";
import {getBorrowerCount, maybeParseFloat, maybeParseInt, formatCurrency} from "./calculator-utils";

export default (type, $form) => {
    let details = {};
    switch (type) {
        case "borrow":
            details = borrowFieldValues($form);
            break;
        case "deposit":
            details = depositFieldValues($form);
            break;
        case "repayments":
            details = repaymentsFieldValues($form);
            break;
        case "lmilpc":
            details = lmilpcFieldValues($form);
            break;
        default:
            break;
    }
    return details;
}

export const depositFieldValues = ($form) => {
    return {
        // Your Details
        "IsFirstHomeBuyer": $form.find('input[name="deposit_first_home_buyer"]:checked').val() == "yes" ? "Yes" : "No",

        // Your Purchase
        "PurchasePrice": maybeParseFloat($form.find("#deposit_property_price").val()),
        "Purpose": $form.find('input[name="deposit_build_or_buy"]:checked').val() == "buy" ? "Buy" : "Build",
        "Locality": $form.find("#deposit_suburb").val() != "" ? $form.find("#deposit_suburb").val() : "Adelaide",
    };
}

export const borrowFieldValues = ($form) => {
    return {
        // Your Details
        "NumberOfBorrowers": getBorrowerCount(), // visible borrower elements
        "NumberOfDependants": maybeParseInt($form.find("#borrow_number_of_dependants").val().replace(/,/g, '')),
        "IsFirstHomeBuyer": $form.find('input[name="borrow_first_home_buyer"]:checked').val() == "yes" ? "Yes" : "No",

        // Your Earnings
        "Borrower1Earnings": ($("#borrow_borrower_1_income").val() != "") ? formatCurrency($("#borrow_borrower_1_income").val().replace(/,/g,"")) + " /" + $("#borrow_borrower_1_income_frequency").val() : "",
        "Borrower2Earnings": ($("#borrow_borrower_2_income").val() != "") ? formatCurrency($("#borrow_borrower_2_income").val().replace(/,/g,"")) + " /" + $("#borrow_borrower_2_income_frequency").val() : "",
        "Borrower3Earnings": ($("#borrow_borrower_3_income").val() != "") ? formatCurrency($("#borrow_borrower_3_income").val().replace(/,/g,"")) + " /" + $("#borrow_borrower_3_income_frequency").val() : "",
        "Borrower4Earnings": ($("#borrow_borrower_4_income").val() != "") ? formatCurrency($("#borrow_borrower_4_income").val().replace(/,/g,"")) + " /" + $("#borrow_borrower_4_income_frequency").val() : "",
        //"Borrower5Earnings": ($("#borrow_borrower_5_income").val() != "") ? formatCurrency($("#borrow_borrower_5_income").val().replace(/,/g,"")) + " /" + $("#borrow_borrower_5_income_frequency").val() : "",

        // Your Expenses
        "StoreCreditCardLimits": $("#borrow_total_credit_limit").val(),
        "OtherMonthlyCommitments": ($("#borrow_other_commitments").val() != "") ? formatCurrency($("#borrow_other_commitments").val().replace(/,/g,"")) + " /" + $("#borrow_other_commitments_frequency").val() : "",

        // Your Purchase
        "Purpose": $form.find('input[name="borrow_build_or_buy"]:checked').val() == "buy" ? "Buy" : "Build",
        "Locality": $form.find("#borrow_suburb").val() != "" ? $("#borrow_suburb").val() : "Adelaide",
        "PurchasePrice": maybeParseFloat($("#borrow_property_price").val()),
        "LoanAmount": $form.find("#repayments_loan_amount").val(),
        "ResultsJson": " "
    };
}

export const repaymentsFieldValues = ($form) => {
    return {
        // Your Loan
        "LoanAmount": $form.find("#repayments_loan_amount").val(),
    };
}

export const lmilpcFieldValues = ($form) => {
    return {
        // Your Deposit
        "Deposit": maybeParseFloat($form.find("#lmilpc_deposit").val()),

        // Property Price
        "PropertyPrice": maybeParseFloat($form.find("#lmilpc_property_price").val()),
    };
}