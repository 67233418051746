import $ from 'jquery';

export default function () {
    const API_URL = window.location.origin + "/api/boardcm/index";

    const API_URL_DOWNLOAD = window.location.origin + "/api/boardcm/getdocument";

    var startPageNumber = 1;
    // check widget is on the page 
    if ($('.document-list-api.board').length == 0) {
        return;
    }

    // Initialize default query - page 1 of results
    prepDocuments(startPageNumber);

    // Handle API query based on requested page number
    function prepDocuments(i) {
        $('.document-list-api.board').each(function (k, elem) {
            var $elem = $(elem);
            var searchURI = $elem.data('search-uri');
            $.ajax({
                url: API_URL + `?savedSearchURI=${searchURI}&pageNumber=` + i + `&pageSize=12`,
                type: "GET",
                data: {},
                contentType: "application/json",
                dataType: "json",
                success: function (result) {
                    if (!result.error) {
                        //console.log(result);
                        $('#loading').toggleClass('visible');
                        displayDocuments(result);
                    }
                    /*
                    else {
                        console.log(result.error);
                    }
                },
                error: function (error) {
                    console.log(error);*/
                }
            });
        })
    }

    // Display documents from query & pagination
    function displayDocuments(result) {

        // Loop through API query results and display each document
        $.each(result.record, function (i, item) {
            var savedName = item.recordTitle.replace('/[^\w ]/g', "-").toLowerCase().replaceAll(' ', '-');
            var documentItem = `
            <div class="document-unit row">
                <div class="document-title">${item.recordTitle}</div>
                <div class="document-icons"><a class="${item.documentType}" target="_blank" href="${API_URL_DOWNLOAD}?name=${savedName}&URI=${item.uri}" data-document-source="${item.uri}"></a></div>
            </div>
            `;

            if (i < 6) {
                $("#documentsListingLeft").append(documentItem);
            }
            else if (i < 12) {
                $("#documentsListingRight").append(documentItem);
            }
        });

        // Pagination constructor and buttons
        var pageCount = parseInt(result.pageCount);
        var pageNumber = parseInt(result.pageNumber);
        var middle = parseInt(result.pageNumber);
        var first = 1;
        if (middle - 2 > first) { first = middle - 2; }
        var last = pageCount;
        if (first + 4 < last) { last = first + 4; }
        if (last - 4 > first) { first = last - 4; }
        //console.log("first: " + first + ", middle: " + middle + ", last: " + last + ", pageCount: " + pageCount);

        if (pageNumber > 1 && pageCount > 1) {
            var firstButton = document.createElement("a");
            firstButton.onclick = function () { LoadPage(pageNumber - 1); }
            firstButton.innerHTML = "<img src=\"/Content/img/icons/prev.svg\" />";
            $(".pagination").append(firstButton);
        }

        for (let i = first; i <= last; i++) {
            var button = document.createElement("a");
            if (i == middle) { button.classList = "paginationButton active" }
            else { button.classList = "paginationButton" }
            button.onclick = function () { LoadPage(i); }
            button.innerHTML = (i);
            $(".pagination").append(button);
        }

        if (pageNumber < pageCount && pageCount > 1) {
            var lastButton = document.createElement("a");
            lastButton.onclick = function () { LoadPage(pageNumber + 1); }
            lastButton.innerHTML = "<img src=\"/Content/img/icons/next.svg\" />";
            $(".pagination").append(lastButton);
        }
    }

    // Clear documents from current page, query next page of results.
    function LoadPage(i) {
        $(".pagination").empty();
        $('#documentsListingLeft').empty();
        $('#documentsListingRight').empty();
        $('#loading').toggleClass('visible');
        prepDocuments(i);
    }
}