import $ from "jquery";

export default (window) => {
    $('#Statements-And-Documents-Button').on('click', function () {

        lockBackground();
        LoadPage(1);
        document.getElementById('Statements-And-Documents').style.display = 'block'
    });

    function LoadPage(pageNumber) {

        document.getElementById('interim-error').innerText = "";
        console.log("load page " + pageNumber);
        $('#SDCurrentPage').empty();
        var loading = document.createElement("img");
        loading.src = "/Content/img/ovalDark.svg";
        loading.style.margin = "auto";
        loading.style.display = "flex";
        loading.style.marginTop = "auto";
        loading.style.padding = "100px";
        $('#SDCurrentPage').append(loading);

        var pagination = $('#SDPagination');
        pagination.empty();

        $.ajax({
            type: "GET",
            url: "api/calmsdashboard/getinterimstatementdates",
            success: function (result) {
                console.log(result);
                $('#interim-statement-dates').text(result);
            }
        });

        $.ajax({
            type: "GET",
            url: "api/calmsdashboard/getdocumentlist?pageNumber=" + pageNumber + "&pageSize=6",
            success: function (data) {
                console.log(data);
                $('#SDCurrentPage').empty();
                console.log("loaded " + data.totalRecords + " documents");

                if (data.totalRecords == 0) {
                    var message = document.createElement("p");
                    message.innerHTML = "No documents found.";
                    $('#SDCurrentPage').append(message);
                }
                else {
                    for (let i = 0; i < data.record.length; i++) {
                        var doc = document.createElement("a");
                        doc.className = "flex-row";
                        var TitleColumn = document.createElement("div");
                        TitleColumn.className = "flex-column";
                        var Title = document.createElement("span");
                        Title.className = "document-title";
                        Title.innerText = data.record[i].recordTitle;
                        Title.style.paddingLeft = "10px";
                        TitleColumn.append(Title);
                        doc.append(TitleColumn);

                        var DateColumn = document.createElement("div");
                        DateColumn.className = "flex-right";

                        var DateElem = document.createElement("span");
                        DateElem.className = "document-title";
                        var dateRegistered = new Date(data.record[i].dateRegistered);
                        console.log("dateRegistered = " + dateRegistered);
                        DateElem.innerHTML = dateRegistered.toLocaleString('en-GB', { month: 'long', day: 'numeric', year: 'numeric' });
                        console.log("DateElem.innerHTML = " + DateElem.innerHTML);

                        DateElem.style.marginRight = "10px";
                        DateColumn.append(DateElem);

                        var Icon = document.createElement("span");
                        if (data.record[i].documentType == "PDF") {
                            Icon.innerHTML = "<img src=\"/Content/img/icons/icon-pdf.png\" style=\"margin-left:11px; vertical-align:middle; width: 26px; height: 26px;\" />";
                        } else if (data.record[i].documentType == "DOC" || data.record[i].documentType == "DOCX") {
                            Icon.innerHTML = "<img src=\"/Content/img/icons/icon-doc.png\" style=\"margin-left:11px; vertical-align:middle; width: 26px; height: 26px;\" />";
                        } else if (data.record[i].documentType == "XLS" || data.record[i].documentType == "XLSX") {
                            Icon.innerHTML = "<img src=\"/Content/img/icons/icon-xls.png\" style=\"margin-left:11px; vertical-align:middle; width: 26px; height: 26px;\" />";
                        } else {
                            Icon.innerHTML = data.record[i].documentType;
                            Icon.className = "icon";
                        }
                        Icon.style.marginRight = "10px";
                        DateElem.append(Icon);

                        doc.append(DateColumn);

                        $('#SDCurrentPage').append(doc);
                        //var URI = data.record[i].uri;
                        doc.href = "api/calmsdashboard/getdocument?name=" + data.record[i].recordTitle + "&URI=" + data.record[i].uri;
                        doc.target = "_blank";
                        //console.log("data.record[" + i + "]: " + data.record[i]);
                        doc.style.cursor = "pointer";

                        var hr = document.createElement("hr");
                        hr.style.margin = ".5rem";
                        doc.after(hr);

                    }

                    var pageCount = data.pageCount;
                    //if (data.totalRecords % data.pageSize > 0) { pageCount++; }
                    var middle = parseInt(data.pageNumber);
                    var first = 1;
                    if (middle - 2 > first) { first = middle - 2; }
                    var last = pageCount;
                    if (first + 4 < last) { last = first + 4; }
                    if (last - 4 > first) { first = last - 4; }

                    console.log("first: " + first + ", middle: " + middle + ", last: " + last + ", pageCount: " + pageCount);

                    if (pageNumber > 0 && pageCount > 1) {
                        var firstButton = document.createElement("a");
                        firstButton.style.border = "1px solid #0093d6";
                        firstButton.onclick = function () { LoadPage(pageNumber - 1); }
                        firstButton.innerHTML = "<img src=\"/Content/img/icons/prev.svg\" />";
                        pagination.append(firstButton);
                    }

                    for (let i = first; i <= last; i++) {
                        var button = document.createElement("a");

                        if (i == middle) { button.classList = "paginationButton active" }
                        else { button.classList = "paginationButton" }

                        button.onclick = function () { LoadPage(i); }
                        button.innerHTML = (i);
                        pagination.append(button);
                    }

                    if (pageNumber < pageCount && pageCount > 1) {
                        var lastButton = document.createElement("a");
                        lastButton.style.border = "1px solid #0093d6";
                        lastButton.onclick = function () { LoadPage(pageNumber + 1); }
                        lastButton.innerHTML = "<img src=\"/Content/img/icons/next.svg\" />";
                        pagination.append(lastButton);
                    }
                }
            },
            error: function (error) {
                if (error.responseText.includes("User cannot perform action on this api")) {
                    document.location = "/api/Account/Login";
                } else {
                    console.log("Error");
                    console.log(error.responseText);
                    $('#SDCurrentPage').empty();
                    var message = document.createElement("p");
                    message.innerHTML = "Failed to retrieve documents.";
                    $('#SDCurrentPage').append(message);
                }
               
            }
        });
    }

    /*   function GetFile(URI) {

           window.location.href = "api/calmsdashboard/getdocument?URI=" + URI;
           /*$.ajax({
               type: "GET",
               url: "api/calmsdashboard/getdocument?URI=" + URI,
               success: function (data) {

               }
           });
       }*/

    $('#InterimStatementModal-Close').on('click', function () {
        LoadPage(1);
        document.getElementById('InterimStatementModal').style.display = 'none';
    });

    $('#interim-statement-button').on('click', function () {

        var checked = $('.loanCheckbox:checked');

        if (checked.length == 0) {
            document.getElementById('interim-error').innerText = "Please select at least one loan.";
            return;
        }

        document.getElementById('interim-error').innerText = "";
        $("#interim_button_loading").css("display", "inline");
        $("#interim_button_ready").css("display", "none");

        var HULIDS = "";
        checked.each(function () {
            HULIDS += this.id + "|";
            //hulidArray.push(this.id);
        });

        HULIDS = HULIDS.slice(0, -1);

        console.log(HULIDS);

        $.ajax({
            url: "api/calmsdashboard/InterimStatement?HULIDS=" + HULIDS,
            type: "POST",
            success: function (result) {
                console.log(result);
                if (result.errors.length > 0) {
                    $('.modal').hide();
                    document.getElementById('ErrorModal').style.display = 'block';
                    document.getElementById('ErrorModal-Description').innerText = result.errors[0].message;
                    $("#interim_button_loading").css("display", "none");
                    $("#interim_button_ready").css("display", "inline");
                }
                else {
                    $('#SDCurrentPage').empty();
                    $('#InterimStatementModal').show();
                    $("#interim_button_loading").css("display", "none");
                    $("#interim_button_ready").css("display", "inline");
                }
            },
            error: function (xhr) {
                console.log("Error: " + xhr.responseText);
                $("#interim_button_loading").css("display", "none");
                $("#interim_button_ready").css("display", "inline");
            }
        });
    });
}
