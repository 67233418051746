import $ from 'jquery';
import {formatCurrency, calculateButtonReset} from "../../components/calculators/calculator-utils";

export const initBreadCrumbFix = () => {
    if ($('.broker-template').length) {
        var firstLink = $('ul.breadcrumbs li:nth-child(2) a');
        if (firstLink.length > 0) {
            if (firstLink.attr('href').toLowerCase() == '/board') {
                $('ul.breadcrumbs li a[href="/Board"]').parent().remove();
                $('ul.breadcrumbs li a[href="/board"]').parent().remove();
                $('ul.breadcrumbs li:first a').attr('href', '/Board');
            } else {
                if (firstLink.attr('href').toLowerCase() == '/broker') {
                    $('ul.breadcrumbs li a[href="/Broker"]').parent().remove();
                    $('ul.breadcrumbs li a[href="/broker"]').parent().remove();
                    $('ul.breadcrumbs li:first a').attr('href', '/broker');
                } else {
                    // Default to broker
                    //$('ul.breadcrumbs li a[href="/Broker"]').parent().remove();
                    $('ul.breadcrumbs li:nth-child(2) a').parent().remove();
                    $('ul.breadcrumbs li:first a').attr('href', '/broker');
                }
            }
        }
    }
};
export const initNoSubmitOnEnter = (window) => {

    if ($('.broker-search').length >= 1 ||
        $("#year_to_date_tab").length >= 1 ||
        $("#location_search_submit").length >= 1
    ) {
        $('input:not([name="searchtext"])').keypress(
            function (event) {
                if (event.which == '13') {
                    event.preventDefault();
                }
            });
    }
}
export const determineSecondaryProduct = (loanPurpose, secondaryProduct) => {
    if (loanPurpose != "BuyLand") {
        return "Advantage";
    }

    return secondaryProduct;
}
export const determineCalculatorCall = () => {
    var buttonClick = $("#buttonClick").val();
    var loanPurpose = $("#home_loan_purpose").val();
    var builderId = $("#homeloan_builder").val() == "" ? 0 : parseInt($("#homeloan_builder").val());
    var propertyValue = determinePropertyPurchasePrice();
    var customerContribution = $("#homeloan_funds_to_contribute").val().replace(/,/g, '') == "" ? 0 : parseFloat($("#homeloan_funds_to_contribute").val().replace(/,/g, ''));
    var calculatorCall = {
        // default is MaxLend
        "CalculatorName": "MaxLend",
        "OnlyAdvantageSecondary": false,
    };
    if (loanPurpose == "RefinanceAHome") {
        calculatorCall.CalculatorName = "RefinanceAHome";
        calculatorCall.OnlyAdvantageSecondary = true;
        return calculatorCall;
    }

    if (buttonClick == "GivenDeposit") {
        if (loanPurpose == "BuyLandAndBuild" && builderId != 0) {
            calculatorCall.CalculatorName = "StructuredConstruction";
            calculatorCall.OnlyAdvantageSecondary = true;
            return calculatorCall;
        }

        if (propertyValue > 0) {
            if (customerContribution == 0) {
                calculatorCall.CalculatorName = "GivenPurchasePrice";
                calculatorCall.OnlyAdvantageSecondary = false;
                return calculatorCall;
            }

            if (customerContribution > 0) {
                if (loanPurpose == "BuyLandAndBuild") {
                    if (builderId != 0) {
                        calculatorCall.CalculatorName = "StructuredConstruction";
                        calculatorCall.OnlyAdvantageSecondary = true;
                        return calculatorCall;
                    } else {
                        calculatorCall.CalculatorName = "GivenPurchasePriceUseAllDeposit";
                        calculatorCall.OnlyAdvantageSecondary = false;
                        return calculatorCall;
                    }
                } else {
                    calculatorCall.CalculatorName = "GivenPurchasePriceUseAllDeposit";
                    calculatorCall.OnlyAdvantageSecondary = false;
                    return calculatorCall;
                }
            }
        } else {
            if (customerContribution > 0) {
                calculatorCall.CalculatorName = "GivenDeposit";
                calculatorCall.OnlyAdvantageSecondary = false;
                return calculatorCall;
            }
        }
    }

    if (buttonClick == "GivenPurchasePrice") {
        calculatorCall.CalculatorName = "GivenPurchasePrice";
        calculatorCall.OnlyAdvantageSecondary = false;
        return calculatorCall;
    }

    return calculatorCall;
}
export const determinePropertyPurchasePrice = () => {
    /*
     If purpose is BuyLandAndBuild, and LandValue = 0,

     Use LandValue = GPP/2 (otherwise LandValue)
     Otherwise, if Purpose is BuyLand, and LandValue = 0,
     Use LandValue = GPP (otherwise LandValue)
     Otherwise, if Purpose is Refinance or RefinanceLandAndBuild,
     Use LandValue = 0
     For all other Purposes, use GivenPurchasePrice
     */
    var loanPurpose = $("#home_loan_purpose").val();
    var propertyValue = 0;
    switch (loanPurpose) {
        case "BuyLandAndBuild" :
            var landCost = parseFloat($("#homeloan_land_cost").val() == "" ? 0 : $("#homeloan_land_cost").val().replace(/,/g, ''));
            var buildCost = parseFloat($("#homeloan_build_cost").val() == "" ? 0 : $("#homeloan_build_cost").val().replace(/,/g, ''));
            propertyValue = landCost + buildCost;
            break;
        case "BuyLand" :
            var landCost = parseFloat($("#homeloan_land_cost").val().replace(/,/g, ''));
            propertyValue = landCost;
            break;
        case "RefinanceAHome":
        case "BuyHome":
        default:
            propertyValue = $("#homeloan_property_value").val().replace(/,/g, '') == "" ? 0 : parseFloat($("#homeloan_property_value").val().replace(/,/g, ''));
            break;
    }
    return propertyValue;
}

export const determinePropertyAge = (originalPropertyAge) => {
    var buttonClick = $("#buttonClick").val();
    var loanPurpose = $("#home_loan_purpose").val();
    var builderId = $("#homeloan_builder").val() == "" ? 0 : parseInt($("#homeloan_builder").val());
    var propertyValue = determinePropertyPurchasePrice();

    var customerContribution = $("#homeloan_funds_to_contribute").val().replace(/,/g, '') == "" ? 0 : parseFloat($("#homeloan_funds_to_contribute").val().replace(/,/g, ''));
    if (buttonClick == "GivenDeposit") {
        if (propertyValue > 0) {
            if (customerContribution > 0) {
                if (loanPurpose == "BuyLandAndBuild") {
                    if (builderId == 0) {
                        return "OffThePlan";
                    }
                }

            }
        }
    }
    if (loanPurpose == "BuyLandAndBuild") {
        return "OffThePlan";
    }
    return originalPropertyAge
}
export const requireField = (fieldName) => {
    if ($("#" + fieldName).val() == "") {
        $("#" + fieldName).addClass('is-invalid-input');
        $('label[for="' + fieldName + '"]').addClass('is-invalid-label');
        return false;
    }
    return true;
};

export const unrequireField = (fieldName) => {
    $("#" + fieldName).removeClass('is-invalid-input');
    $('label[for="' + fieldName + '"]').removeClass('is-invalid-label');
};
