import $ from 'jquery';
import {
    calculateButtonReset,
}        from "./calculator-utils";

export default function (window) {

    $('#borrow_form input,#deposit_form input,#repayments_form input').on('change', function (e) {
        //alert('change event on input');
        //calculateButtonReset();
        if ($('.call-to-actions').is(':visible')) {
            calculateButtonReset();
        }
    });
    $('#borrow_form input,#deposit_form input,#repayments_form input').on('input', function (e) {
        //alert('input event on input');
        //calculateButtonReset();
        if ($('.call-to-actions').is(':visible')) {
            calculateButtonReset();
        }
    });
    $('#borrow_form,#deposit_form,#repayments_form').on('change', function (e) {
        //alert('change event on form');
        //calculateButtonReset();
        if ($('.call-to-actions').is(':visible')) {
            calculateButtonReset();
        }
    });

    $('#lmilpc_form').on('input', function (e) {
        //alert('gottem');
        $("#lmilpc_result_graph .graph-results").hide();
        $("#lmilpc_result_graph .initial").show();
    })
    // Hack to fix IE not liking the logos being floated
    $("html.ie .print-logo-subpages").remove();
};
