import $ from 'jquery';


export const initFaqAutoOpen = (window) => {
    var paramKey = window.location.search.toString();
    paramKey = paramKey.replace('?', '');
    if ($('section.faq').length >= 1 || $('section.document-list').length >= 1) {
        // Check we have the FAQ item we're after
        var $target = $("[data-open-id]");
        if ($target.length == 0) {
            return;
        }
        if ($target.length >= 2) {
            $target = $target.first();
        }
        $target.trigger('click');
        // open it

        const menuHeight = $('header').filter(':visible').outerHeight();
        /*$('html, body').animate(
            {
                scrollTop: $target.offset().top - menuHeight // offset menu height so it's not blocking content
            }, 500);*/
    }
}
