import $ from 'jquery';

const init = function () {
    const menuHeight = $('header').filter(':visible').outerHeight();

    if (window.location.hash != "") {

        let selector = window.location.hash;
        if($(selector).length ==0) {
            let name = window.location.hash.replace('#','');
            selector = 'a[name="'+name+'"]';
        }
        if(selector.length ==0) {
            console.log('no selector found') ;
        }
        $('html, body').animate({
            scrollTop: $(selector).offset().top - menuHeight // offset menu height so it's not blocking content
        }, 500);
    }

    if(!$('body').hasClass('calculator-page')) {
        $(document).on('click', '[href^="#"], [href^="."]', function (event) {
            event.preventDefault();

            const targetSelector = $.attr(this, 'href');
            if( targetSelector.length <= 0 || targetSelector == '#' ) return;
            const $target = $($.attr(this, 'href'));
            if( $target.length <= 0 ) return;

            // scroll to position
            $('html, body').animate({
                scrollTop: $target.offset().top - menuHeight // offset menu height so it's not blocking content
            }, 500);
        });
    }
}

export default (window) => {
    init();
}
