import $ from 'jquery';

import {
    calculatorLoadError,
    formatCurrency,
    mobileCalculatorAccordionReset,
    updateTabTitle,
    showCallToActions,
    calculateDuration,
    generateVirtualPageView,
    updateCalculatorButtonText
} from "./calculator-utils";

import { isSuburbMetro } from "./calculator-suburb-list";

export default function (results) {

    console.log("Deposit results", results);

    if (results.ErrorMessage != "" && results.ErrorMessage != null) {
        console.log('Encountered API error', results);
        calculatorLoadError(results.ErrorMessage);
    }
    if (Object.keys(results).length < 3) {
        console.log('Skipping rendering of results', results, Object.keys(results).length)
        return;
    }
    $(".calculate-button:visible").removeClass('loading');
    if (Foundation.MediaQuery.is('medium down')) {
        mobileCalculatorAccordionReset();
    }


    // HOMESTART-BL: When buy is selected Low Deposit loans are not valid (even though the API returns valid results)
    if (($('input[name="deposit_build_or_buy"]:checked').val() == "build")) {
        $('.calculator-results').addClass('no-low-deposit')
    }

    let standardAdvantage = results.scenarios.find(function (value) { return value.scenarioName == "STANDARDADVANTAGE"; });
    let graduateAdvantage = results.scenarios.find(function (value) { return value.scenarioName == "GRADUATEADVANTAGE" && value.status != "Fail"; });
    let lowDepositAdvantage = results.scenarios.find(function (value) { return value.scenarioName == "LOWDEPOSITADVANTAGE" && value.status != "Fail"; });

    let lowestDeposit = standardAdvantage.totalDepositRequired;

    results.scenarios.forEach(function (item) {
        if (item.totalDepositRequired < lowestDeposit && item.status != "Fail") {
            lowestDeposit = item.totalDepositRequired;
        }
    });

    updateCalculatorButtonText("We estimate your total required deposit is", formatCurrency(lowestDeposit, true));

    //GRADUATE
    if (graduateAdvantage) {
        depositFillResultsTable("#deposit_graduate_loan_results .primary", graduateAdvantage);
        updateTabTitle("#deposit_graduate_loan_results-label", formatCurrency(graduateAdvantage.totalDepositRequired, true));
    } else {
        $('.calculator-results').addClass('no-graduate');
    }
    // STANDARD
    depositFillResultsTable("#deposit_homestart_loan_results .primary", standardAdvantage);
    updateTabTitle("#deposit_homestart_loan_results-label", formatCurrency(standardAdvantage.totalDepositRequired, true));

    // LOW DEPOSIT
    if (lowDepositAdvantage) {
        depositFillResultsTable("#deposit_lowdeposit_loan_results .primary", lowDepositAdvantage);
        updateTabTitle("#deposit_lowdeposit_loan_results-label", formatCurrency(lowDepositAdvantage.totalDepositRequired, true));
    } else {
        $('.calculator-results').addClass('no-low-deposit');
    }

    if (!Foundation.MediaQuery.is('medium up')) {
        mobileCalculatorAccordionReset();
    } else {
        $("#deposit_results").find('.is-active').removeClass('is-active');

        $("#deposit_homestart_loan_results").addClass('is-active'); // first one remains hidden until shown
        $("#deposit_homestart_loan_results-label").parent().addClass('is-active'); // first one remains hidden until shown

    }

    $("#deposit_results").show();
    showCallToActions();
    generateVirtualPageView();
}


function depositFillResultsTable(selector, scenario) {
    let $loanResults = $(selector);
    // console.log('DEPOSIT RESULT');

    if (!scenario || scenario.status == "Fail") {
        console.log('scenario is not valid');
        $loanResults.hide();
        $loanResults.prev('.subtitle').hide();
    } else {
        //console.log('showing results for ' + scenario.scenarioName);
        $loanResults.show();
        $loanResults.prev('.subtitle').show();

        calculateDuration($loanResults, scenario);

        //let grandTotal = formatCurrency(scenario.homeDeposit + scenario.totalFeesAndCharges, true);

        let $borrowUpTo = $loanResults.find('.borrow_up_to')
        let $repaymentsOf = $loanResults.find('.repayments_of')
        let $depositRequired = $loanResults.find('.deposit_required')

        $borrowUpTo.find('.amount').text(formatCurrency(scenario.homeDeposit, true));
        $repaymentsOf.find('.amount').text(formatCurrency(scenario.totalFeesAndCharges, true));
        $depositRequired.find('.amount').text(formatCurrency(scenario.totalDepositRequired, true));
        $loanResults.find('.property_value').text(formatCurrency(scenario.purchasePrice, true));
        $loanResults.find('.startup-help-text').css('display', (scenario.assistanceLoanEligibility ? 'block' : 'none'));

        var grant = scenario.totalGrants > 0;//$('input[name="borrow_first_home_buyer"]:checked').val() == "yes" && $('input[name="borrow_build_or_buy"]:checked').val() == "build";
        $loanResults.find('.government_grants_showhide').css('display', (grant ? 'inline' : 'none'));

        //fillResultsTable(selector,scenario,'deposit')
        var feesTable = document.querySelector(selector).querySelector('table.fees-and-charges');
        feesTable.innerHTML = '';
        // Home Deposit Row
        let row = document.createElement('tr');
        row.classList.add("bold")

        let name = document.createElement('td');
        name.innerText = 'Home Deposit';
        row.append(name);

        let amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("deposit");
        amount.innerText = formatCurrency(scenario.homeDeposit, true);
        row.append(amount);
        feesTable.append(row);

        // Fees & Charges ROW
        row = document.createElement('tr');
        row.classList.add("bold")

        name = document.createElement('td');
        name.innerText = 'Fees & Charges';
        row.append(name);

        amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("subtotal");
        amount.innerText = formatCurrency(scenario.totalFeesAndCharges, true);
        row.append(amount);
        feesTable.append(row);

        // Other fees & charges
        if (scenario.feesAndChargesBreakdown) {
            for (let i = 0; i < scenario.feesAndChargesBreakdown.length; i++) {
                row = document.createElement('tr');
                row.classList.add("sub");
                name = document.createElement('td');
                name.innerText = scenario.feesAndChargesBreakdown[i].name;
                row.append(name);
                amount = document.createElement('td');
                amount.innerText = formatCurrency(scenario.feesAndChargesBreakdown[i].value);
                row.append(amount);
                feesTable.append(row);
            }
        }

        //Grants
        if (scenario.totalGrants > 0) {
            row = document.createElement('tr');
            row.classList.add("total")

            name = document.createElement('td');
            name.innerText = 'Government Grants';
            row.append(name);

            amount = document.createElement('td');
            amount.classList.add("fees");
            amount.classList.add("grandtotal");
            amount.innerText = "-" + formatCurrency(scenario.totalGrants, true);
            row.append(amount);

            feesTable.append(row);

            for (let i = 0; i < scenario.grants.length; i++) {
                row = document.createElement('tr');
                row.classList.add("sub");
                name = document.createElement('td');
                name.innerText = scenario.grants[i].name;
                row.append(name);
                amount = document.createElement('td');
                amount.innerText = "-" + formatCurrency(scenario.grants[i].value);
                row.append(amount);
                feesTable.append(row);
            }
        }

        // line / space
        row = document.createElement('tr');
        row.classList.add("spacer")
        row.append(document.createElement('td'));
        row.append(document.createElement('td'));
        feesTable.append(row);

        // Totals
        row = document.createElement('tr');
        row.classList.add("total")

        name = document.createElement('td');
        name.innerText = 'Total contribution required';
        row.append(name);

        amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("grandtotal");
        amount.innerText = formatCurrency(scenario.totalDepositRequired, true);
        row.append(amount);

        feesTable.append(row);
    }
};
