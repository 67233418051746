import $ from "jquery";

export default (window) => {
    $('#Redraw-Button').on('click', function () {

        lockBackground();
        document.getElementById('Redraw').style.display = 'block';
        var HULID = $('#primary-loan-div').attr('HULID');

        console.log("redraw check for HULID: " + HULID);

        $('#redraw-amount-input').removeClass('is-invalid-input');
        $('#redraw-amount-input').val('');
        $('.error').remove();

        $.ajax({
            url: '/api/calmsdashboard/GetRedraw?HULID=' + HULID
        }).done(function (result) {

            console.log(result);

            if (parseFloat(result.redrawAmount) > 0) {
                $('#Redraw-Available').css("display", 'block');
                $('#Redraw-Not-Available').css("display", 'none');

                $('#Redraw').attr("MaxRedraw", result.redrawLimit);
                $('#Redraw').attr("AccountEnding", result.bankDetails.accountNumber);
                $('#redraw-amount-input').blur();
                $('#redraw-amount-p').html("You can redraw a maximum of $" + result.redrawLimit + ".");
                $('#Redraw-Account-Number').html(result.bankDetails.accountNumber);
                $('#Redraw-All-Sign').html(result.allToSignEnabled ? " Your co-borrower will be required to approve this request." : "");
            }
            else {
                $('#Redraw-Available').css("display", 'none');
                $('#Redraw-Not-Available').css("display", 'block');
                $('#redraw-error-h').html("An error has occurred");
                $('#redraw-error-p').html(result.errors[0].message);

            }
        });
    });

    $('#Redraw-Update-Button').on('click', function () {
        $('#redraw-amount-input').removeClass('is-invalid-input');
        $('.error').remove();

        var redraw = $('#redraw-amount-input').val();
        redraw = redraw.replace('$', "");
        redraw = redraw.replace(/,/g, "");

        var maxRedraw = $('#Redraw').attr("MaxRedraw");

        if (parseFloat(redraw) > parseFloat(maxRedraw) || redraw == "" || parseFloat(redraw) <= 0) {
            var error = document.createElement("span");
            $('#redraw-amount-input').addClass('is-invalid-input');
            error.innerHTML = "Please enter $" + maxRedraw + " or less.";
            error.className = "error";
            error.style.marginTop = '-16px';
            error.style.display = 'block';
            $('#redraw-amount-input').after(error);
        }
        else {
            var confirmParagraph = document.getElementById('Redraw-Confirm-Paragraph');
            confirmParagraph.innerHTML = "Please confirm that you wish to redraw $" + redraw + " to an account ending with " + $('#Redraw').attr("AccountEnding");

            document.getElementById('RedrawConfirm').style.display = 'block';
            document.getElementById('Redraw').style.display = 'none';
            setTimeout(function () { lockBackground(); }, 100);
        }
    });

    $('#Redraw-Confirm-Button').on('click', function () {

        var redraw = $('#redraw-amount-input').val();
        redraw = redraw.replace('$', "");
        redraw = redraw.replace(/,/g, "");

        var payload = {
            Amount: redraw,
            HULID: $('#primary-loan-div').attr('HULID')
        }

        console.log(payload);

        $.ajax({
            url: '/api/calmsdashboard/updateredraw',
            data: payload,
            success: function (result) {
                console.log(result);

                document.getElementById('RedrawConfirm').style.display = 'none';
                setTimeout(function () { lockBackground(); }, 100);

                if (result.allToSignEnabled) { document.getElementById('RedrawConfirmAllSign').style.display = 'block'; }
                else { document.getElementById('RequestSubmittedModal').style.display = 'block'; }
            },
            error: function () {
                $('.modal').hide();
                document.getElementById('FailModal').style.display = 'block';

            }
        });
    });

    $('.customer-action-button').on('click', function () {

        var actionID = this.getAttribute('actionid');
        console.log(actionID);

        $.ajax({
            url: "/api/calmsdashboard/GetCustomerAction?id=" + actionID
        }).done(function (result) {

            console.log(result);
            $('#CustomerActionMenu-Title').html(result.longTitle);
            $('#CustomerActionMenu-Text').html(result.longDescription);
            $('#CustomerActionMenu-Buttons').empty();

            for (var i = 0; i < result.actionsListOptions.length; i++)
            {
                var button = document.createElement("button");
                button.innerText = result.actionsListOptions[i].actionLabel;
                button.setAttribute('option', result.actionsListOptions[i].id);
                button.setAttribute('action', actionID);

                if (i == 0)
                { button.className = "button-blue modal-button customer-action-option-button"; }
                else
                {
                    button.style.marginLeft = "10px";
                    button.className = "button-white modal-button customer-action-option-button";
                }

                document.getElementById('CustomerActionMenu-Buttons').appendChild(button);
            }

            $('.customer-action-option-button').click(function () {

                var payload = {
                    ActionId: $(this).attr('action'),
                    OptionId: $(this).attr('option')
                }

                console.log(payload);

                $.ajax({
                    url: "/api/calmsdashboard/ApplyCustomerAction",
                    data: payload,
                    success: function (result) {

                        console.log(result);
                        if (result.status == "Success") {
                            $('#CustomerActionResponse-Title').html(result.messageTitle);
                            $('#CustomerActionResponse-Text').html(result.message);
                            $("[actionid='" + payload.ActionId + "']").css("display", "none");
                        }
                        else {
                            $('#CustomerActionResponse-Title').html("An error has occurred");
                            $('#CustomerActionResponse-Text').html(result.errors[0].message);
                        }

                        document.getElementById('CustomerActionMenu').style.display = 'none';
                        document.getElementById('CustomerActionResponse').style.display = 'block';
                        lockBackground();
                    },
                    error: function () {
                        $('.modal').hide();
                        document.getElementById('FailModal').style.display = 'block';

                    }
                });
            });

            document.getElementById('CustomerActionMenu').style.display = 'block';
            lockBackground();
        });
    });
}
