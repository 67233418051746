import $ from 'jquery';

export default () => {
    // Show ones currently selected
    let selector1 = $('select[name="loanSelection1"]');
    let selector2 = $('select[name="loanSelection2"]');
    let selector3 = $('select[name="loanSelection3"]');
    $('.' + selector1.val()).show();
    $('.' + selector2.val()).show();
    $('.' + selector3.val()).show();
    window.currentlySelected = {
        1: selector1.val(),
        2: selector2.val(),
        3: selector3.val(),
    }

    updateTable(); // initialize
    updateDisabledOptions();

    function showError(){
        $('.error').slideDown(100);
        window.setTimeout(function() {
            $('.error').slideUp(100);
        },3500)
    }

    function hideError(){
        $('.error').slideUp(100);
    }

    function updateDisabledOptions(){
        // enable all
        $('option[disabled]', selector1).removeAttr('disabled');
        $('option[disabled]', selector2).removeAttr('disabled');
        $('option[disabled]', selector3).removeAttr('disabled');

        // disable duplicate values
        $('option[value="'+window.currentlySelected[1]+'"]', selector2).attr('disabled', true);
        $('option[value="'+window.currentlySelected[1]+'"]', selector3).attr('disabled', true);

        $('option[value="'+window.currentlySelected[2]+'"]', selector1).attr('disabled', true);
        $('option[value="'+window.currentlySelected[2]+'"]', selector3).attr('disabled', true);

        $('option[value="'+window.currentlySelected[3]+'"]', selector1).attr('disabled', true);
        $('option[value="'+window.currentlySelected[3]+'"]', selector2).attr('disabled', true);
    }

    function updateTable() {
        let $table = $("#compare_home_loans_table");
        let $rows = $table.find("tr");
        $rows.each(function (k, v) {
            let $this_row = $(v);
            // Loop through each td
            // Check its class list
            // If its class list is in window.currentlySelected
            // Shift it to position n+1
            // Start at item 3
            $this_row.find("td,th").each(function (k, v) {
                let $item = $(v);
                if ($item.hasClass(window.currentlySelected[3])) {
                    $item.detach().insertAfter($this_row.find(':first-child'));
                }
            })
            $this_row.find("td,th").each(function (k, v) {
                let $item = $(v);
                if ($item.hasClass(window.currentlySelected[2])) {
                    $item.detach().insertAfter($this_row.find(':first-child'));
                }
            })
            $this_row.find("td,th").each(function (k, v) {
                let $item = $(v);
                if ($item.hasClass(window.currentlySelected[1])) {
                    $item.detach().insertAfter($this_row.find(':first-child'));
                }
            });

        });
        $table.find("th:not(.first),td:not(.first)").hide();
        $.each(window.currentlySelected, function (k, v) {
            $('.'+v).show()
        });
    }

    selector1.on('change', function () {
        //  if the new selection is already selected, don't allow (show error)
        let newValue = $(this).val();
        let oldValue = window.currentlySelected[1];

        //console.log('Newvalue'+newValue);
        if (window.currentlySelected[2] == newValue || window.currentlySelected[3] == newValue) {
            //alert('same lol');
            //selector1.val(oldValue);
            showError();
            selector1.val(oldValue);
            return;
        } else {
            //$('.'+window.currentlySelected[1]).hide();
            window.currentlySelected[1] = newValue;
            updateTable();
            hideError();
            //$('.' + newValue).show();

            updateDisabledOptions();
        }
    });
    selector2.on('change', function () {
        //  if the new selection is already selected, don't allow (show error)
        let newValue = $(this).val();
        let oldValue = window.currentlySelected[2];
        //console.log('Newvalue'+newValue);
        if (window.currentlySelected[1] == newValue || window.currentlySelected[3] == newValue) {
            //alert('same lol');
            //selector2.value(oldValue);
            showError();
            selector2.val(oldValue);
            return;
        } else {
            //$('.' + window.currentlySelected[2]).hide();
            window.currentlySelected[2] = newValue;
            updateTable();
            hideError();
            //$('.' + newValue).show();

            updateDisabledOptions();
        }
    });
    selector3.on('change', function () {
        //  if the new selection is already selected, don't allow (show error)
        let newValue = $(this).val();
        let oldValue = window.currentlySelected[3];
        //console.log('Newvalue'+newValue);
        if (window.currentlySelected[2] == newValue || window.currentlySelected[1] == newValue) {
            //alert('same lol');
            //selector1.value(oldValue);
            showError();
            selector1.val(oldValue);
            return;
        } else {
            //$('.' + window.currentlySelected[3]).hide();
            window.currentlySelected[3] = newValue;
            updateTable();
            hideError();
            //$('.' + newValue).show();

            updateDisabledOptions();
        }
    });
};


