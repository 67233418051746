import $ from 'jquery';

import handleBorrowForm     from "./calculator-handle-borrow-request";
import handleDepositForm    from "./calculator-handle-deposit-request";
import handleRepaymentsForm from "./calculator-handle-repayments-request";
import handleBrokerSearch    from "../../broker/components/broker-search";
import handleLocationSearch  from "../../broker/components/location-search";
import handleValuerSearch from "../../broker/components/valuer-search";
import { handleYTD } from "../../broker/components/year-to-date";
import { handleSeniorsEquity } from "../../broker/components/seniors-equity";

export default function (window) {
    // Corporate Calculators
    let $borrowCalculate = $("#borrow_calculate");
    $borrowCalculate.on('click', function (e) {
        e.preventDefault();
        $("#borrow_form").foundation("validateForm");
    });

    let $depositCalculate = $("#deposit_calculate");
    $depositCalculate.on('click', function (e) {
        e.preventDefault();
        $("#deposit_form").foundation("validateForm");
    });

    let $repaymentsCalculate = $("#repayments_calculate");
    $repaymentsCalculate.on('click', function (e) {
        e.preventDefault();
        $("#repayments_form").foundation("validateForm");
    });

    // Broker Searches
    let $brokerSearchSubmit = $("#broker_search_submit");
    if ($brokerSearchSubmit.length >= 1) {
        $brokerSearchSubmit.on('click', function (e) {
            e.preventDefault();
            $("#broker_search_form").foundation("validateForm");
        });
    }    

    let $YTDCalculate = $("#ytd_calculate");
    if ($YTDCalculate.length >= 1) {
        $YTDCalculate.on('click', function (e) {
            e.preventDefault();
            console.log($("#gross_ytd_income").val());
            if ($("#gross_ytd_income").val() != "") {
                //$("#gross_ytd_income").removeProp('required').removeAttr('required');
            } else {

                //$("#gross_ytd_income").prop('required','required').removeAttr('required','required');
            }
            //$(document).foundation();
            $("#year_to_date_form").foundation("validateForm");
        });
    }
    let $SeniorsEquity = $("#seniors_equity_calculate");
    if ($SeniorsEquity.length >= 1) {
        $SeniorsEquity.on('click', function (e) {
            e.preventDefault();
            $("#seniors_equity_form").foundation("validateForm");
        });
    }

    // Location & Valuer Searches

    let $locationSearchSubmit = $("#location_search_submit");
    if ($locationSearchSubmit.length >= 1) {
        $locationSearchSubmit.on('click', function (e) {
            e.preventDefault();
            $("#location_search_form").foundation("validateForm");
        });
    }

    let $valuerSearchSubmit = $("#valuer_search_submit");
    if ($valuerSearchSubmit.length >= 1) {
        $valuerSearchSubmit.on('click', function (e) {
            e.preventDefault();
            $("#valuer_search_form").foundation("validateForm");
        });
    }
    let $calculatePropertyValue = $("#calculate_property_value");
    if ($calculatePropertyValue.length >= 1) {
        $calculatePropertyValue.on('click', function (e) {
            e.preventDefault();
            $("#calculation_type").val('property_value');
            $("#home_loan_form").foundation("validateForm");
        });
    }
    let $searchSaveLoadForms = $("#search_save_load_forms");
    if ($searchSaveLoadForms.length >= 1) {
      $searchSaveLoadForms.on('click', function (e) {
            e.preventDefault();
            $("#save_load_form").foundation("validateForm");
        });
    }

    $(document).on("formvalid.zf.abide", function (e, form) {

        console.log($("#calculation_type").val());
        switch (form.attr('id')) {
            case "borrow_form" :
                handleBorrowForm();
                break;
            case "deposit_form" :
                handleDepositForm();
                break;
            case "repayments_form" :
                handleRepaymentsForm();
                break;
            case "callback_form":
                break;
            case "email_form":
                //fillCaluclatorForm();
                break;
            case "broker_search_form":
                handleBrokerSearch(window);
                break;                
            case "location_search_form":
                handleLocationSearch(window);
                break;
            case "valuer_search_form":
                handleValuerSearch(window);
                break;
            case "home_loan_form":
                console.log($("#calculation_type").val());
                let type = $("#calculation_type").val();
                handleHomeLoan(window,type);
                break;
            case "year_to_date_form":
                handleYTD(window);
                break;
            case "seniors_equity_form":
                console.log('seniors equity');
                handleSeniorsEquity(window);
                break;
            default:
                console.warn('uncaught form ');
                console.log(e, form);
                break;
        }
    });

}
;
