//	Responsive Accordion v1.3, Copyright 2014, Joe Mottershaw, https://github.com/joemottershaw/
//	============================================================================================

import $ from 'jquery';

export default function()  {
  $(".responsive-accordion").each(function() {
    $(".responsive-accordion-minus", this).hide(), $(".responsive-accordion-panel", this).hide(), $(".responsive-accordion-head", this).click(function() {
      var i = $(this).parent().parent(),
        s = $(this),
        e = s.find(".responsive-accordion-plus"),
        n = s.find(".responsive-accordion-minus"),
        o = s.siblings(".responsive-accordion-panel");
      i.find(".responsive-accordion-plus").show(), i.find(".responsive-accordion-minus").hide(), s.hasClass("active") ? (s.removeClass("active"), e.show(), n.hide(), o.removeClass("active").slideUp()) : (s.addClass("active"), e.hide(), n.show(), o.addClass("active").slideDown())
    })
  })
}