import $ from 'jquery';

import {maskElement} from "../../../utils";

export const initCalculatorScrolling = (window) => {
    var paramKey = window.location.search.toString();
    //console.log("params: " + paramKey);
    paramKey = paramKey.replace('?', '');

    if ($('.broker-calculator-tabs').length >= 1) {
        var tabToOpen = false;
        var $target = $("section.page-content");
        var doScroll = true;

        if (paramKey.includes('seniors-equity')) {
            console.log('scroll for seniors-equity');
            tabToOpen = $("#seniors_equity_tab-label");
        } else if (paramKey.includes('year-to-date')) {
            tabToOpen = $('#year_to_date_tab-label');
            console.log('scroll for YTD');
        } else {
            // no scrolls
            doScroll = false;
            //console.log('scroll for default');
        }
        if (tabToOpen != false) {
            // trigger tab change
            //console.log('click tab');
            tabToOpen.trigger('click');
        }
        if (doScroll) {
            const menuHeight = $('header').filter(':visible').outerHeight();
            $('html, body').animate({
                scrollTop: $target.offset().top - menuHeight // offset menu height so it's not blocking content
            }, 500);
        }
    }

    if($("#location_valuer_search").length >=1) {
        $target =  $("#location_valuer_search");
        tabToOpen = false;
        doScroll=false;
        if(paramKey.includes('valuer')) {
            tabToOpen = $('a[href="#valuer_search"]');
            doScroll=true;
        }
        if(paramKey.includes('location')) {
            tabToOpen = $('a[href="#location_search"]');
            doScroll=true;
        }
        if (tabToOpen != false) {
            // trigger tab change
            tabToOpen.trigger('click');
        }
        /*if (doScroll) {
            const menuHeight = $('header').filter(':visible').outerHeight();
            $('html, body').animate(
                {
                    scrollTop: $target.offset().top - menuHeight // offset menu height so it's not blocking content
                }, 500);
        }*/
    }
    /*if($(".document-list").length >=1) {
        $target =  $("[data-open-id='"+paramKey+"']");
        if($target.length >=1) {
            $target.trigger('click');
            const menuHeight = $('header').filter(':visible').outerHeight();
            $('html, body').animate(
            {
                scrollTop: $target.offset().top - menuHeight // offset menu height so it's not blocking content
            }, 500);
        }
    }*/

    // Fix multiple accordions
    /*$(document).on("up.zf.accordion", function(event) {
        var $parent = $('.is-active').parent();
        // Get the offset alone
        var offset = $parent.length > 0 ? $parent.offset().top : 0;
        // If the offset is less than the scroll position
        const menuHeight = $('header').filter(':visible').outerHeight();
        if (offset < ($(window).scrollTop() + menuHeight)) {
            let activeOffset = $('.is-active').length > 0 ? $('.is-active').offset().top : 0;
            setTimeout(function () {
                $('html,body').animate({scrollTop: (activeOffset - menuHeight)}, 'fast');
            }, 250); //Adjust to match slideSpeed
        }
    });*/
};

