/**
 * https://stackoverflow.com/a/35913983
 */

import $ from 'jquery';

export default function () {
  /**
   * Link the state of multiple accordions with data-multi-expand="false"
   */


    if ($('.accordion-title[data-open-id="calculators"]').length >= 1) {
        $('.accordion-title[data-open-id="calculators"]').trigger('click');
        /*const menuHeight = $('header').filter(':visible').outerHeight();
        /*$('html, body').animate({
            scrollTop: $target.offset().top - menuHeight // offset menu height so it's not blocking content
        }, 500);*/
    }

  $('.accordion-title').on('mouseup', function (e) {

    // get accordion item
    var $accordionItem = $(this).parent();

    //is the section hidden? if the section is not yet visible, the click is to open it
    var opening = ($accordionItem.find('.accordion-content:hidden').length === 1);

    // the click is to open it
    if (opening) {

      //the accordion that has just been clicked
      var $currentAccordion = $accordionItem.parent();

      // if the current accordion is set as data-multi-expand="false", close all others of the same setting
      if( $currentAccordion.attr("data-multi-expand") === "false" ){

        //close all other accordions with the same setting
        $('.accordion[data-multi-expand="false"]').each(function () {
          var $acc = $(this);

          //ignore the accordion that was just clicked
          if ($acc[0] !== $currentAccordion[0]) {

            //find any open sections
            var $openSections = $acc.find('.accordion-item.is-active .accordion-content');

            // close each open section
            $openSections.each(function (i, section) {
              $acc.foundation('up', $(section));
            });
          }
        });
      }
    }
  });

  // Prevent accordions from auto-opening
  $('[data-accordion][data-auto-expand="false"]:not([disabled])').each(function (i, accordion) {
    let $accordion = $(accordion);

    // disable the accordion to prevent it auto-expanding
    $accordion.attr('disabled', 'disabled');

    // remove the disabled flag
    setTimeout(function(){
      $accordion.removeAttr('disabled');
    }, 500);

    // $accordion.on('down.zf.accordion', function(){
    //
    //   // close open sections
    //   $('.accordion-item.is-active .accordion-content', accordion).each(function (i, section) {
    //     let $section = $(section);
    //     $accordion.foundation('up', $section);
    //   });
    // });
  });
};