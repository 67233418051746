import $ from 'jquery';

import {getFullSuburbList, getSuburbListAutoComplete} from "../../components/calculators/calculator-suburb-list";
import {maskElement}                                  from "../../utils";
import {
    calculatorLoadError,
    calculatorLoadStart,
    calculatorReset, formatCurrency, formatCurrencyTwoPlaces, generateVirtualPageView,
    updateCalculatorButtonText, updateCalculatorButtonTextNoAmount
} from "../../components/calculators/calculator-utils";
import {htmlEncode}                                   from "./broker-utils";

export const initSeniorsEquity = (window) => {
    if ($("#seniors_equity_tab").length >= 1) {
        maskElement($("#seniors_property_value"));
        maskElement($("#seniors_loan_amount"));
    }
    initSeniorsEquityForms(window);
    initSeniorsEquityReset();
}
export const initSeniorsEquityReset = () => {
    $("#seniors_equity_form").on('change', function () {
        if ($("#seniors_equity_results").is(':visible')) {
            calculatorReset();
            seniorsEquityResultsReset();
            console.log('resetting seniors equity');
        }
    })
}
export const handleSeniorsEquity = (window) => {
    // Init
    calculatorReset();
    calculatorLoadStart();
    seniorsEquityResultsReset();
    generateVirtualPageView('/broker/calculator/seniors-equity/calculate');
    let API_URL = '/api/seniorsequity/getseniorsequity';
    var data = {
        "AgeOfYoungestBorrower": parseInt($("#seniors_age").val()),
        "PropertyValuationValue": parseInt($("#seniors_property_value").val().replace(/,/g, '')),
        "Purpose": "BuyHome",
        "RequestedLoanAmount": parseInt($("#seniors_loan_amount").val().replace(/,/g, '')),
        "Locality": "ADELAIDE",
    };
    console.log("seniors equity data", data);

    $.ajax({
            url: window.location.origin + API_URL,
            type: "GET",
            data: data,
            contentType: "application/json",
            dataType: "json",
            success: function (result) {
                console.log("result", result)
                if (result.errorMessage != null && result.errorMessage != "") {
                    console.log('error mesage happened');
                    calculatorLoadError(result.errorMessage);
                } else {
                    // LVR Not Maximum LVR
                    //var maximumLvr = parseFloat(result.maximumLVR);
                    var maximumLvr = parseFloat(result.lvr);
                    maximumLvr = parseFloat(maximumLvr * 100).toFixed(2);
                    var maximumLoanAmount = result.maximumLoanAmount;
                    $("#seniors_lvr_result").text(maximumLvr + "%");
                    $("#seniors_max_loan_result").text("$" + maximumLoanAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

                    updateCalculatorButtonTextNoAmount("Result");
                    $("#seniors_equity_results").slideDown(250);
                    $("#seniors_equity_results").slideDown(250);
                    window.lastResult = result;
                }

                //$("#seniors_equiry_results").html('<pre>' + JSON.stringify(result) + "</pre>");

            }
        }
    )
};
export const seniorsEquityResultsReset = () => {
    $("#seniors_lvr_result").text();
    $("#seniors_max_loan_result").text();
    $("#seniors_equity_results").slideUp(250);
}
export const initSeniorsEquityForms = (window) => {
    initSeniorsEquityEmail();
    initSeniorsEquityPrint();

}

export const initSeniorsEquityPrint = () => {
    $("#se_print").on('click', function (e) {
        e.preventDefault();
        $("#se_print").addClass('active');
        $("#se_email").removeClass('active');
        $("#se_email_form").hide();
        $("#se_print_form").slideDown();
        generateVirtualPageView('/broker/calculator/seniors-equity/calculate/print');
    });
    $("#se_print_submit").on('click', function (e) {
        /* Sanity checks*/
        var valid = true;
        if ($("#se_print_firstname").val() == "") {
            valid = false;
        } else {
            $("#se_print_firstname").parent().find('.form-error').hide();
        }
        if ($("#se_print_lastname").val() == "") {
            valid = false;
        } else {
            $("#se_print_lastname").parent().find('.form-error').hide();
        }
        if (!valid) {
            if($("#se_print_firstname").val() =="") {
                $("#se_print_firstname").parent().find('.form-error').show();
            }
            if($("#se_print_lastname").val() =="") {
                $("#se_print_lastname").parent().find('.form-error').show();
            }
            return;
        }
        $("#se_print_form").find('.form-error').hide();
        $("#se_print_submit").addClass('loading');
        generateVirtualPageView('/broker/calculator/seniors-equity/calculate/print/submit');


        e.preventDefault();
        var feesAndChargesString = generateSeniorsEquityFeesAndChargesDetails();


        var data = {
            "templateTitle": "Seniors Equity Quote",
            "calculationDate": new Date().toDateString("%Y"),
            "applicantName": $("#se_print_firstname").val() + " " + $("#se_print_lastname").val(),
            "ageOfYoungestBorrower": $("#seniors_age").val(),
            "propertyValuation": '$' + $("#seniors_property_value").val(),
            "maximumLoanAmount": $('#seniors_max_loan_result').text(),
            "maximumLVR": $("#seniors_lvr_result").text(),
            "feesAndChargesDetails": feesAndChargesString,
        }
        //console.log(data);
        let API_URL = '/api/seniorsequity/createprintdocument';

        // Create the window result immediately in-scope with the click event. Doing this via ajax is disabled in iOS
        let winPDFResult = window.open('', 'PrintSeniorsEquityResults');

        $.ajax(
            {
                url: window.location.origin + API_URL,
                type: "GET",
                data: data,
                contentType: "application/json",
                dataType: "json",
                success: function (result) {
                    console.log("result", result)

                    $("#se_print_submit").removeClass('loading');
                    if (result.errorMessage != "" && result.errorMessage != null) {
                        console.warn('error', result.errorMessage);
                        calculatorLoadError(result.errorMessage);
                    } else {
                        //console.log(result);
                        if (result.hasOwnProperty('documentUrl') && (result.documentUrl != null && result.documentUrl != "")) {

                            // Assign the result to the previously created window reference
                            winPDFResult.location.href = result.documentUrl;
                        }
                    }
                }
            }
        )
    });
}

export const initSeniorsEquityEmail = () => {
    $("#se_email").on('click', function (e) {
        e.preventDefault();
        $("#se_print").removeClass('active');
        $("#se_email").addClass('active');
        $("#se_print_form").hide();
        $("#se_email_form").slideDown();
        generateVirtualPageView('/broker/calculator/seniors-equity/calculate/email');
    });
    $("#se_email_submit").on('click', function (e) {
        /* Sanity checks*/
        var valid = true;
        if ($("#se_email_firstname").val() == "") {
            valid = false;
        } else {
            $("#se_email_firstname").parent().find('.form-error').hide();
        }
        if ($("#se_email_lastname").val() == "") {
            valid = false;
        } else {
            $("#se_email_lastname").parent().find('.form-error').hide();
        }
        if ($("#se_email_email").val() == "") {
            valid = false;
        } else {
            $("#se_email_email").parent().find('.form-error').hide();
        }
        if (!valid) {
            if($("#se_email_firstname").val() =="") {
                $("#se_email_firstname").parent().find('.form-error').show();
            }
            if($("#se_email_lastname").val() =="") {
                $("#se_email_lastname").parent().find('.form-error').show();
            }
            return;
        }
        $("#se_email_submit").addClass('loading');
        $("#se_email_form").find('.form-error').hide();
        generateVirtualPageView('/broker/calculator/seniors-equity/calculate/email/submit');
        e.preventDefault();
        var data = {
            "templateTitle": "Seniors Equity Calculation Result",
            "emailToAddresses": $("#se_email_email").val(),
            "customer": $("#se_email_firstname").val() + " " + $("#se_email_lastname").val(),
            "template": "HTML",
            "marginLeft": "20",
            "marginRight": "20",
            "marginBottom": "10",
            "marginTop": "20",
            "isLandscape": "false"
        };
        var feesAndChargesString = generateSeniorsEquityFeesAndChargesDetails();


        data.attachment = {
            "templateTitle": "Seniors Equity Calculation Results",
            "calculationDate": new Date().toDateString("%Y"),
            "applicantName": $("#se_email_firstname").val() + " " + $("#se_email_lastname").val(),
            "ageOfYoungestBorrower": $("#seniors_age").val(),
            "propertyValuation": '$' + $("#seniors_property_value").val(),
            "maximumLoanAmount": $('#seniors_max_loan_result').text(),
            "maximumLVR": $("#seniors_lvr_result").text(),
            "feesAndChargesDetails": feesAndChargesString,
        }
        //console.log(data);
        let API_URL = '/api/seniorsequity/createemail';
        var jsonData = JSON.stringify(data);
        console.log('sending ', jsonData)
        $.ajax(
            {
                url: window.location.origin + API_URL,
                type: "POST",
                data: jsonData,
                contentType: "application/json",
                success: function (result) {
                    console.log("result", result)

                    $("#se_email_submit").removeClass('loading');
                    if (result.ErrorMessage != "" && result.ErrorMessage != null) {
                        //console.warn('error', result.errorMessage);
                        //calculatorLoadError(result.errorMessage);
                        $("#se_email_form").append('<p class="text-center" id="email_success"><br/>Something went wrong, please try again shortly.</p>');
                    } else {
                        $("#se_email_form").append('<p class="text-center" id="email_success"><br/>Thank you, an email will be sent shortly</p>');

                    }
                    window.setTimeout(function () {
                        $("#email_success").remove();
                        $("#se_email_firstname").val("")
                        $("#se_email_lastname").val("")
                        $("#se_email_email").val("")
                        $("#se_email_form").slideUp();
                    }, 10000)
                }
            }
        )
    });
}

export const generateSeniorsEquityFeesAndChargesDetails = () => {
    var feesAndChargesString = "&lt;table style='width:100%'&gt;";
    if (window.lastResult.hasOwnProperty('feesAndCharges')) {
        var feesAndCharges = window.lastResult.feesAndCharges;
        $.each(feesAndCharges, function (k, fee) {
            if(fee.value >= 1) {
                feesAndChargesString += "&lt;tr&gt;&lt;td&gt;" + fee.key + "&lt;/td&gt;&lt;td style='text-align:right'&gt;" + formatCurrencyTwoPlaces(fee.value) + "&lt;/td&gt;";
            }
        })
    }

    feesAndChargesString += "&lt;tr&gt;&lt;td&gt;&lt;strong&gt;Total Fees & Charges&lt;/strong&gt;&lt;/td&gt;&lt;td style='text-align:right'&gt;" + formatCurrencyTwoPlaces(window.lastResult.totalFees) + "&lt;/td&gt;";
    feesAndChargesString += "&lt;table&gt;";
        return feesAndChargesString;
}

