import $ from "jquery";

export default (window) => {
    if ($('#Home-Equity-Loan-Enquiry').length == 0) {
        console.log("Home equity loan div not found");
        return;
    }   

    const API_URL = '/api/calmsdashboard/PrintHomeEquityLoanPDF';//'https://my.homestart.com.au/api/calmsdashboard/test';//'/api/homeequityloanenquiry/submit'

    $("#home-equity-loan-button").on("click", function () {
        lockBackground();
        document.getElementById('Home-Equity-Loan-Enquiry').style.display = 'block';
        //$("#hel_button_loading").css("display", "none");
        //$("#hel_button_ready").css("display", "inline");
    });

    $('#Home-Equity-Loan-Enquiry-Form').on('submit', function (e) {

        console.log("submit button clicked");

        e.preventDefault();
        $('.error').remove();        

        var invalid = $(this).find('.is-invalid-input');
        console.log(invalid.length, 'invalids');

        if (invalid.length != 0) {
            for (var i = 0; i < invalid.length; i++) {
                var error = document.createElement("span");
                error.innerHTML = invalid[i].getAttribute("errorText");
                error.className = "error";
                error.style.marginTop = invalid[i].getAttribute("errorTopMargin");
                invalid[i].after(error);
            }

            isAmountValid();
            $('.checkbox-div').each(addErrorMessageIfBlank);
            return;
        }

        if (!isAmountValid()) { return; }

        $("#hel_submit_button").prop('disabled', 'disabled');
        $("#hel_button_loading").css("display", "inline");
        $("#hel_button_ready").css("display", "none");

        // Handle the submission of the formvar
        var $container = $('#Home-Equity-Loan-Enquiry');
        var purpose = $container.find("textarea[name='HEL-purpose']").val();
        var amount = $("#hel_amount_input").val();
        var liveinaddress = $container.find("input[name='hel_liveinaddress']:checked").val();
        var employmentstatus = $container.find("input[name='hel_employmentstatus']:checked").val();
        var rates = $container.find("input[name='hel_rates']:checked").val();
        var defaultsorjudgements = $container.find("input[name='hel_defaultsorjudgements']:checked").val();
        var contactnumber = $container.find("input[name='hel_contactnumber']").val();
        var timetocall = $container.find("select[name='hel_timetocall']").val();

        var payload = {
            'purpose': purpose,
            'amount': amount,
            'liveinaddress': liveinaddress,
            'employmentstatus': employmentstatus,
            'rates': rates,
            'defaultsorjudgements': defaultsorjudgements,
            'contactnumber': contactnumber,
            'timetocall': timetocall
        }

        console.log("Sending HEL form");
        console.log(payload);

        $.ajax({
            url: API_URL,
            data: payload,
            success: function (result) {
                console.log(result);
                //{ success: true, message: ''}
                $('#Home-Equity-Loan-Enquiry').hide();
                $("#hel_submit_button").removeAttr('disabled');

                if (result == "Success") {
                    $('#RequestSubmittedModal').show();
                    $('#Home-Equity-Loan-Enquiry-Form')[0].reset();
                    $("#hel_button_loading").css("display", "none");
                    $("#hel_button_ready").css("display", "inline");
                } else if (result == "Logout") {
                    document.location = "/api/Account/Login";
                } else {                  
                        $('.modal').hide();
                        document.getElementById('FailModal').style.display = 'block';
                        $("#rfa_button_loading").css("display", "none");
                        $("#rfa_button_ready").css("display", "inline");
                }
            },
            error: function (error) {
                if (error.responseText.includes("User cannot perform action on this api")) {
                    document.location = "/api/Account/Login";
                } else {
                    $('.modal').hide();
                    document.getElementById('FailModal').style.display = 'block';
                    $("#rfa_button_loading").css("display", "none");
                    $("#rfa_button_ready").css("display", "inline");
                }
            }
        });

        /*$.post(API_URL, payload, function (result) {
            //alert(result);
            console.log(result);
        });*/
    });

    console.log("Home Equity Loan form script updated");
}

function isAmountValid()
{
    var amountInput = $('#hel_amount_input');
    var amount = amountInput.val();
    console.log(amount);
    amount = amount.replace(/\D/g, '');
    amount = parseInt(amount);

    if (isNaN(amount)) {
        amountInput.addClass("is-invalid-input");
        var error = document.createElement("span");
        error.innerHTML = "Please enter the amount you wish to borrow.";
        error.className = "error";
        error.style.marginTop = "2px";
        amountInput.after(error);
        return false;
    }

    if (amount < 500000) { //2 extra zeros because this is looking at cents, not dollars
        amountInput.addClass("is-invalid-input");
        var error = document.createElement("span");
        error.innerHTML = "The minimum loan is $5000.";
        error.className = "error";
        error.style.marginTop = "2px";
        amountInput.after(error);
        return false;
    }

    return true;
}

function addErrorMessageIfBlank(item) {

    if ($(this).find(':checked').length == 0) {
        var error = document.createElement("span");
        error.innerHTML = "Please select an option.";
        error.className = "error";
        error.style.marginBottom = "-16px";
        error.style.marginTop = "6px";
        error.style.display = "block";
        //error.style.marginTop = invalid[i].getAttribute("errorTopMargin");
        $(this).after(error);
    }
}