//import $ from 'jquery'
//import { maskElement, replaceAll } from "../utils";

// import download from 'downloadjs'

document.addEventListener('DOMContentLoaded', function () {
    function getPdfLink(loanAmount, interestType) {
        console.log('Parameters: ', loanAmount, interestType);

        var $form = $('div[id^="form-KeyFactsSheet"]').find('form');
        $form.find('input[type="submit"]').val("...");

        return $.ajax({
            url: '/KeyFacts/GenerateKFSPDF',
            data: {
                'loanAmount': loanAmount,
                'interestType': interestType
            },
            success: function (data) {
                console.log("result url: " + data);
                if (window.canRenderPDF) {
                    window.open(data);
                } else {
                    addDownloadButton(nextFormWrapperId, data);
                    // download(pdfUrl); // cannot download due to Cross-Origin-Policy settings on broker.homestart.com.au
                }

                $form.find('input[type="submit"]').val("Submit");
                applyNewBinding();
            }
        });
    }

    function displayError() {
        var $formWrapper = $('div[id^="form-KeyFactsSheet"]');
        var $form = $formWrapper.find('form');
        if (!$form.length) {
            console.log('KFS: failed to find updated form to insert error')
        }

        $form.find('.key-facts-error').remove()
        $form.append(`
    <div class="form-error key-facts-error" style="display: block; margin-top: 20px;">
      There was a problem retrieving your file. Please try again later or <a href="/contact-us">contact us for assistance</a>.
    </div>`)
    }

    function addDownloadButton(formWrapperId, pdfUrl) {
        var $formWrapper = $('div[id^="form-KeyFactsSheet"]');
        var $form = $formWrapper.find('form');

        if (!$form.length) {
            console.log('KFS: failed to find updated form to insert error')
            console.error(formWrapperId);
        }

        if ($('html').hasClass('ie')) {
            $form.append('<br/><p><strong>To download your Key Facts Results, please right click and save as: </strong> <a href="' + pdfUrl + '" download>this link</a></p>');
        } else {
            $form.append('<br/><p><a href="' + pdfUrl + '" class="button" target="_blank" download>Download Results</a></p>');
        }
    }

    function applyNewBinding() {
        console.log('KFS: Applying new binding');
        // ajax events
        var $formWrapper = $('div[id^="form-KeyFactsSheet"]');
        var $form = $formWrapper.find('form');
        if ($form.length >= 1) {

            var $submitbutton = $form.find('input[type="submit"]');
            $submitbutton.unbind();
            $submitbutton.removeAttr('onclick');

            $submitbutton.on('click', function (e) {
                $('.error').remove();        
                let maxLoan = parseFloat($('#KFSdata').attr('data-property-kfsmaxloan'));
                let minLoan = parseFloat($('#KFSdata').attr('data-property-kfsminloan'));
                const loanAmount = $form.find('input[name*=LoanAmount]').val().replace(/,/g, '');
                const interestType = $form.find('select[name*=InterestType]').val();

                //console.log('KFS: click, ' + loanAmount + '/' + maxLoan);

                if (loanAmount > maxLoan) {
                    var error = document.createElement("span");
                    error.innerHTML = $('#KFSdata').attr('data-property-kfsmaxloanmessage');
                    error.className = "error";
                    error.style.color = "Red";
                    //error.style.marginTop = 10;
                    $formWrapper.after(error);
                    setTimeout(applyNewBinding, 500);
                    return;
                }

                if (loanAmount < minLoan) {
                    var error = document.createElement("span");
                    error.innerHTML = $('#KFSdata').attr('data-property-kfsminloanmessage');
                    error.className = "error";
                    error.style.color = "Red";
                    //error.style.marginTop = 10;
                    $formWrapper.after(error);
                    setTimeout(applyNewBinding, 500);
                    return;
                }

                // Check if form is valid
                if ($form.find('.field-validation-error').length) {
                    setTimeout(applyNewBinding, 500);
                    return;
                }

                // The id attribute chanses after every submission so we need to grab it from the response
                const nextFormWrapperId = $form.attr('data-ktc-ajax-update')
                $(nextFormWrapperId).css('border', '3px red');
                //const nextFormWrapperId = formId;

                getPdfLink(loanAmount, interestType).catch(() => displayError(nextFormWrapperId));
                /*.then((pdfUrl) => {
                    console.log('pdfUrl', pdfUrl);
                    if (window.canRenderPDF) {
                        window.open(pdfUrl);
                    } else {
                        addDownloadButton(nextFormWrapperId, pdfUrl);
                        // download(pdfUrl); // cannot download due to Cross-Origin-Policy settings on broker.homestart.com.au
                    }
                    applyNewBinding();
                })*/
            });
        } /*else {
            console.log('KFS: No form found, refreshing');
            window.location = window.location;
        }*/
    }

    applyNewBinding();
})


export default (window) => { }
