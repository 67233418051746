import $ from "jquery";
import { objectLength } from "../../utils";

export const maybeParseInt = (value) => {
    if (value === "") {
        return "0"; // Needs to be an string for the api
    }
    value = value.toString().replace(/,/g, ''); // safely remove commas if they are present
    return parseInt(value).toString();
}
export const maybeParseFloat = (value) => {
    if (value === "") {
        return "0";
    }
    value = value.toString().replace(/,/g, '');
    return parseFloat(value).toString();
}

export const isElementInViewport = (el) => {
    if (el === undefined) {
        return;
    }
    // Special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

export function calculatorLoadStart() {
    $(".calculate-button:visible").removeClass('calculator-error').addClass('loading');
    $('.results-tabs').hide();
    $('.call-to-actions').hide();
    $('.calculator-results').removeClass('no-low-deposit');
    $('.secondary_product a').attr('href', '/home-loans/borrowing-boost-loans/shared-equity-option'); //  HERE
    $('.secondary_product a').text('Shared Equity Option*'); /*  HERE*/
}

export const formatCurrency = (number, doRoundup = false) => {
    if (typeof number == "undefined" || number == null || number == "") {
        return "$0";
    }
    if (number == 0) {
        return "$0";
    }
    if (number <= 0) {
        /*return "$0";*/
        return "$" + parseInt(number).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (doRoundup) {
        return "$" + mRound(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "$" + parseInt(number).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const formatCurrencyTwoPlaces = (number, doRoundup = false) => {
    if (typeof number == "undefined" || number == null || number == "") {
        return "$0";
    }
    if (number == 0) {
        return "$0";
    }
    if (number <= 0) {
        /*return "$0";*/
        return "$" + parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (doRoundup) {
        return "$" + parseFloat(mRound(number)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "$" + parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function mRound(number) {
    if (number % 1 !== 0) {
        var roundto = 1;
        return roundto * Math.round(number / roundto);
    } else {
        return number;
    }
}

export function calculateDuration($loanResults, scenario) {
    var months = scenario.estimatedTerms;
    /*switch (scenario.repaymentsFrequency) {
        case "Fortnightly":
            weeks *= 2;
            break;
        case "Monthly":
            weeks *= 4;
            break;
    }*/

    var years = Math.trunc(months / 12);
    $loanResults.find('.years').text(Math.trunc(years));
    $loanResults.find('.months').text(years % 12);
    //console.log(Math.trunc(years) + " Years, " + (years % 12) + " Months");
}
/**
 * Doesn't enforce 2 places, just rounds to a maximum of 2
 * e.g.
 * 100 => 100
 * 100.1 => 100.1
 * 100.11 => 100.11
 * 100.111 => 100.11
 *
 * @param number
 * @returns {number}
 */
export const roundToTwoPlaces = (number) => {
    return Math.round(((number) + Number.EPSILON) * 100) / 100;
}

export function calculatorLoadError(text = false) {
    //$(".calculate-button:visible").removeClass('loading').addClass('calculator-error');
    calculateButtonReset();
    if (text != false) {
        // Update error text

        //console.log('custom error text', text);
        $('.calculate-button:visible .error > p').text(text);
    }
    $(".calculate-button:visible").removeClass('loading');
    $(".calculate-button:visible").addClass('calculator-error');
    $('.results-tabs').hide();
}

export function calculateButtonReset() {
    $('.total.grants').hide();
    $(".calculate-button").removeClass('loading');
    $(".calculate-button").removeClass('calculator-error');
    $(".calculate-button").removeClass('results');
    $('.calculate-button .error > p').text("The system is unable to load the results at the moment due to an internet connection issue.");
    $(".calculate-button span.text").html('Calculate');
}

export function calculatorReset() {
    calculateButtonReset();
    window.results = new Array(); // reset any potential hanging results
    $('.show-fees-charges.open').removeClass('open').addClass('closed');
    $('.fees-and-charges-wrapper').slideUp();
    $("#borrow_results").hide();
    $("#deposit_results").hide();
    $("#repayments_results").hide();
    $('.call-to-actions .button.active').removeClass('active');
    $('.total.grants').hide();
    $('.secondary_product a').attr('href', '/home-loans/borrowing-boost-loans/shared-equity-option'); //  HERE
    $('.secondary_product a').text('Shared Equity Option*'); //  HERE
    // reset
    $(".advantage_text").show();
    $("#email_form,#callback_form").slideUp();
    $('.call-to-actions').hide();
    $('.no-low-deposit').removeClass('no-low-deposit');
    $('.no-graduate').removeClass('no-graduate');
    $('.no-shared-equity').removeClass('no-shared-equity');
    //$('.advantage_text').hide();
    $('.calculator-results .tabs-panel  h3.subtitle:last-child > span').hide();
    // reset mobile panels

}

export function mobileCalculatorAccordionReset() {
    if (!Foundation.MediaQuery.is('medium up')) {

        var $currentAccordion = $('ul.accordion:visible')
        if ($currentAccordion.length >= 1) {
            $currentAccordion.foundation('destroy');
        }

        var elem = new Foundation.ResponsiveAccordionTabs($currentAccordion);

        $currentAccordion.show();
        if ($('.call-to-actions').length >= 2) {
            console.log('removing duplicated cta buttons');
            $('.call-to-actions').not(':first').remove();
        }
    }
}

export function calculateTotalBorrowerIncome() {
    let total = 0;
    let $visibleBorrowerFields = $('div:visible[class^="text-input borrower-income"]');

    $visibleBorrowerFields.each(function (k, elem) {
        let $elem = $(elem);
        // Pull value, replace commas
        let income = parseInt($elem.find('input').val().toString().replace(/,/g, ''));
        if (income == '') {
            return;
        }
        let frequency = $elem.find('.frequency-dropdown').val();
        let borrowerMonthlyIncome = 0;
        switch (frequency) {
            case 'weekly':
                borrowerMonthlyIncome = parseFloat((income * 52) / 12)
                break;
            case 'fortnightly':
                borrowerMonthlyIncome = parseFloat((income * 26) / 12)
                break;
            case 'monthly':
                borrowerMonthlyIncome = income;
                break;
            case 'yearly':
                borrowerMonthlyIncome = parseFloat(income / 12)
                break;
        }
        total += borrowerMonthlyIncome;
        console.log('currentTotal ' + total);
    });

    return maybeParseInt(total);
}

export function getBorrowerCount() {
    return maybeParseInt($('div:visible[class^="text-input borrower-income"]').length);
}

export function updateCalculatorButtonText(pretext, amount) {
    $(".calculate-button:visible").removeClass('loading');
    $(".calculate-button:visible").removeClass('calculator-error');
    $(".calculate-button:visible").addClass('results');
    $('.calculate-button:visible span.text').html("<span>" + pretext + "</span>" + amount);
}

export function updateCalculatorButtonTextNoAmount(text) {
    $(".calculate-button:visible").removeClass('loading');
    $(".calculate-button:visible").removeClass('calculator-error');
    $(".calculate-button:visible").addClass('results');
    $('.calculate-button:visible span.text').html(text);
}

export function updateTabTitle(selector, text) {
    let $tabTitle = $(selector);

    if (typeof text == "undefined" || text.length <= 0) {
        console.log('hide [' + text + ']');
        $tabTitle.find('span').hide();
    } else {
        console.log('show [' + text + ']');
        $tabTitle.find('span').show().text(text);
    }
}
/*
export function borrowerFillResultsTable(selector, results) {
    let $loanResults = $(selector);

    if (objectLength(results) <= 0) {
        $loanResults.hide();
        $loanResults.prev('.subtitle').hide();
        $loanResults.prev('.subtitle').prev('.boost-your-borrowing-power').hide();
    } else {
        $loanResults.show();
        $loanResults.prev('.subtitle').show();
        $loanResults.prev('.subtitle').prev('.boost-your-borrowing-power').show();

        // Update tab title w/ total amount
        // Update 3 column loan info amounts
        let $borrowUpTo = $loanResults.find('.borrow_up_to')
        let $repaymentsOf = $loanResults.find('.repayments_of')
        let $depositRequired = $loanResults.find('.deposit_required')

        $borrowUpTo.find('.amount').text(formatCurrency(results.total));
        $repaymentsOf.find('.amount').text(formatCurrency(results.repayments, true));
        $depositRequired.find('.amount').text(formatCurrency(results.additionalfundsrequired, true));

        // Update based upon XXX value
        fillResultsTable(selector, results);

        // Other fees & charges
    }
}*/

/*export function fillCaluclatorForm(window) {
    debugger;
    const $container = $("#CalculatorForm");
    const $form = $container.find('form');
    // Calculate Lead Source by active tab

    let activeTabHref = $('#calculator-tabs .is-active a ').attr('href');
    let leadSourceMap = {
        "#borrow": "BORROW_CALCULATOR",
        "#deposit": "DEPOSIT_CALCULATOR",
        "#repayments": "REPAYMENT_CALCULATOR",
        "#lmilpc": "LPC_CALCULATOR",
    };
    let leadSource = leadSourceMap[activeTabHref];

    //console.log(leadSourceMap, leadSource, activeTabHref);

    // Sanity checks to decide what name we're pulling from
    let firstName, lastName = ""
    if ($("#callback_firstname").val() != "" && $("#callback_lastname").val() != "") {
        firstName = $("#callback_firstname").val();
        lastName = $("#callback_lastname").val();
    } else {
        firstName = $("#email_firstname").val();
        lastName = $("#email_lastname").val();
    }
    // key = selector for Calculator Input Fields
    // Value = value to be saved in the form

    // Window.results doesn't like being JSONified
    let resultsJSON = {}
    for (var result in window.results) {
        let scenario = window.results[result].scenarioName
        if (scenario) {
            resultsJSON[window.results[result].scenarioName] = window.results[result]
        }
    }
    let fieldMap = {
        //"FirstName_Value": firstName,
        //"LastName_Value": lastName,
        //"Phone_Value": $("#callback_phone").val(),
        //"Email_Email": ($("#callback_email").val() != "") ? $("#callback_email").val() : $("#email_email").val(),
        //"Newsletter_Value": $('input[name="email_subscribe"]:visible').is(':checked') ? true : false,
        //"BestTimeToCall_Value": $('#callback_besttimetocall').val(),
        "LeadSource_Value": leadSource,

        "Borrower1Income_Value": $('[name="borrow_borrower_1_income"]').val(),
        "Borrower2Income_Value": $('[name="borrow_borrower_2_income"]').val(),
        "Borrower3Income_Value": $('[name="borrow_borrower_3_income"]').val(),
        "Borrower4Income_Value": $('[name="borrow_borrower_4_income"]').val(),

        "Borrower1PaymentFrequency_Value": $('[name="borrow_borrower_1_income_frequency"]').val(),
        "Borrower2PaymentFrequency_Value": $('[name="borrow_borrower_2_income_frequency"]').val(),
        "Borrower3PaymentFrequency_Value": $('[name="borrow_borrower_3_income_frequency"]').val(),
        "Borrower4PaymentFrequency_Value": $('[name="borrow_borrower_4_income_frequency"]').val(),

        "FirstHomeBuyer_Value": ($('input[name="borrow_first_home_buyer"]:checked').val() == "yes") ? "true" : "false",
        "BuildOrBuy_Value": ($('input[name="borrow_build_or_buy"]:checked').val() == "buy") ? "BuyHome" : "BuyLandAndBuild",
        "NumberOfDependents_Value": maybeParseInt($("#borrow_number_of_dependants").val()),
        "PropertyPurchasePrice_Value": (maybeParseFloat($("#borrow_property_price").val()) == 0) ? "" : maybeParseFloat($("#borrow_property_price").val()),
        "Suburb_Value": ($("#borrow_suburb").val() != "") ? $("#borrow_suburb").val() : "ADELAIDE",
        "OtherCommitments_Value": maybeParseInt($("#borrow_other_commitments").val()),
        "TotalCredit_Value": maybeParseInt($("#borrow_total_credit_limit").val()),

        "LoanAmount_Value": $("#repayments_loan_amount").val(),

        "DepositAmount_Value": $("#lmilpc_deposit").val(),

        "RepaymentAmount_Value": $('.repayments_of:visible .amount').first().text(),

        "RepaymentsFrequency_Value": "Fortnightly",

        "BorrowingLimit_Value": $('.borrow_up_to:visible .amount').first().text(),

        "DepositRequired_Value": $('.deposit_required:visible .amount').first().text(),

        "AnticipatedLoanTerm_Value": $('.based-on:visible .years').first().text() + "Years " + $('.based-on:visible .months').first().text() + " Months",

        "LoanType_Value": $('.tabs-panel.is-active > h3.subtitle').first().text(),

        "RepaymentFrequency_Value": "Fortnightly",

        "HomeDeposit_Value": $('tr.bold .deposit').first().text(),

        "FeesAndCharges_Value": $('tr.bold .subtotal').first().text(),

        "TotalDepositNeeded_Value": $('.grandtotal').first().text(),

        "ResultsJSON_STANDARDADVANTAGE_Value": JSON.stringify(resultsJSON["STANDARDADVANTAGE"]),
        "ResultsJSON_STANDARDSHAREDEQUITY_Value": JSON.stringify(resultsJSON["STANDARDSHAREDEQUITY"]),

        "ResultsJSON_GRADUATEADVANTAGE_Value": JSON.stringify(resultsJSON["GRADUATEADVANTAGE"]),
        "ResultsJSON_GRADUATESHAREDEQUITY_Value": JSON.stringify(resultsJSON["GRADUATESHAREDEQUITY"]),

        "ResultsJSON_LOWDEPOSITADVANTAGE_Value": JSON.stringify(resultsJSON["LOWDEPOSITADVANTAGE"]),
        "ResultsJSON_LOWDEPOSITSHAREDEQUITY_Value": JSON.stringify(resultsJSON["LOWDEPOSITSHAREDEQUITY"]),

    }
    //console.log(fieldMap);
    // Find calculator form that's embedded
    // Update fields in the form with their value;
    $.each(fieldMap, function (name, value) {
        console.log(`setting Form field: #${name} with ${value} `);
        //$form.find("#" + name).val(value);
        $form.find("input[id$='" + name + "']").val(value);
    });
    //if (fieldMap["Newsletter_Value"]) {
    //    $form.find("input[id$='Newsletter.Value']").prop('checked','checked')
    //    $form.find("input[id$='Newsletter_Value']").prop('checked','checked')
    //    //$("#Newsletter_Value").prop('checked', 'checked');
    //    //$("#Newsletter.Value").prop('checked', 'checked');
    //}

    //$("#CalculatorForm form").show()
    //alert('calculator filled');
    //$("#CalculatorForm form").unbind('submit');
    //$form.find('input[type="submit"]').trigger('click');
}*/

//export function depositFillResultsTable(selector, results) {
//    let $loanResults = $(selector);
//    // Update tab title w/ total amount
//    // Update 3 column loan info amounts
//    let $borrowUpTo = $loanResults.find('.borrow_up_to')
//    let $repaymentsOf = $loanResults.find('.repayments_of')
//    let $depositRequired = $loanResults.find('.deposit_required')
//    $borrowUpTo.find('.amount').text(formatCurrency(results.deposit, true));
//    $repaymentsOf.find('.amount').text(formatCurrency(results.fees.subtotal, true));
//    $depositRequired.find('.amount').text(formatCurrency(results.additionalfundsrequired, true));
//    // Update based upon XXX value
//    fillResultsTable(selector, results, 'deposit')
//}

export function fillResultsTable(selector, scenario, type = 'borrower') {
    //let $loanResults = $(selector);

    var feesTable = document.querySelector(selector).querySelector('table.fees-and-charges');
    // reset the table
    feesTable.innerHTML = '';

    let grandTotal = formatCurrency(scenario.homeDeposit + scenario.totalFees, true)

    // Home Deposit Row
    let row = document.createElement('tr');
    row.classList.add("bold")

    let name = document.createElement('td');
    name.innerText = 'Home Deposit';
    row.append(name);

    let amount = document.createElement('td');
    amount.classList.add("fees");
    amount.classList.add("deposit");
    amount.innerText = formatCurrency(scenario.homeDeposit, true);
    row.append(amount);
    feesTable.append(row);

    // Fees & Charges ROW
    row = document.createElement('tr');
    row.classList.add("bold")

    name = document.createElement('td');
    name.innerText = 'Fees & Charges';
    row.append(name);

    amount = document.createElement('td');
    amount.classList.add("fees");
    amount.classList.add("subtotal");
    amount.innerText = formatCurrency(scenario.totalFees, true);
    row.append(amount);
    feesTable.append(row);

    // Other fees & charges
    for (let i = 0; i < scenario.feesAndChargesBreakdown.length; i++) {
        row = document.createElement('tr');
        row.classList.add("sub")
        name = document.createElement('td');
        name.innerText = scenario.feesAndChargesBreakdown[i].name;
        row.append(name);
        amount = document.createElement('td');
        amount.innerText = formatCurrency(scenario.feesAndChargesBreakdown[i].value, true);
        row.append(amount);
        feesTable.append(row);
    }

    row = document.createElement('tr');
    row.classList.add("spacer")
    row.append(document.createElement('td'));
    row.append(document.createElement('td'));
    feesTable.append(row);

    row = document.createElement('tr');
    row.classList.add("total")

    name = document.createElement('td');
    name.innerText = 'Total Deposit required';
    row.append(name);

    amount = document.createElement('td');
    amount.classList.add("fees");
    amount.classList.add("grandtotal");
    amount.innerText = grandTotal;
    row.append(amount);

    feesTable.append(row);
}

export function showCallToActions() {
    console.log('Current media query: ' + Foundation.MediaQuery.current)

    // If desktop just show
    if (Foundation.MediaQuery.is('medium up')) {
        console.log('Showing CTA buttons under repayments results');
        $('.call-to-actions').appendTo($(".calculator-results"));
    }

    // If mobile, make sure it's appended to the right spot
    else {
        console.log('Showing CTA buttons in accordion');
        $('.call-to-actions').appendTo($(".results-tabs:visible .accordion-content:visible"));
    }

    $('.call-to-actions').show();
}

export function checkDuplicateResults(left, right) {
    if (left == false || right == false) {
        return false;
    }
    //console.log("LEFT:", left, "RIGHT:", right);
    // fields to check
    let duplicate = true;
    if (left.additionalfundsrequired != right.additionalfundsrequired) {
        duplicate = false;
    }
    if (left.deposit != right.deposit) {
        duplicate = false;
    }
    if (left.property_price != right.property_price) {
        duplicate = false;
    }
    if (left.repayments != right.repayments) {
        duplicate = false;
    }
    if (left.total != right.total) {
        duplicate = false;
    }
    return duplicate;


}

export function checkForNoResultsFound(result) {
    if (typeof result == "undefined") {
        return true;
    }
    if (typeof result.AdditionalFundsRequired == "undefined") {
        return true;
    }
    if (result.AdditionalFundsRequired <= 0) {
        return true;
    }
    return false;
}//

export function calculateMonthlyCommitments() {

    let amount = $("#borrow_other_commitments").val().replace(/,/g, '');
    let frequency = $("#borrow_other_commitments_frequency").val()
    let monthlyCommitments = 0;
    switch (frequency) {
        case 'weekly':
            monthlyCommitments = parseFloat((amount * 52) / 12)
            break;
        case 'fortnightly':
            monthlyCommitments = parseFloat((amount * 26) / 12)
            break;
        case 'monthly':
            monthlyCommitments = amount;
            break;
        case 'yearly':
            monthlyCommitments = parseFloat(amount / 12)
            break;
    }
    return monthlyCommitments;
}

export function generateVirtualPageView(form = '') {
    var virtualPageViewUrl = form;
    if (!$('html').hasClass('broker-template')) {
        let activeTabHref = $('#calculator-tabs .is-active a ').attr('href');
        let activeResultsTab = $('.results-tabs:visible');
        if (typeof activeTabHref !== undefined) {
            activeTabHref = activeTabHref.replace('#', '');
        } else {
            activeTabHref = activeResultsTab.find('.tabs-title.is-active a').attr('id');

        }
        let resultsTabID = ""
        if (activeResultsTab.length >= 1) {
            let activeResultsTabID = $('.results-tabs:visible').attr('id');

            resultsTabID = activeResultsTab.find('.tabs-title.is-active a').attr('id');
            if (resultsTabID !== undefined) {
                if (activeTabHref !== undefined) {
                    resultsTabID = resultsTabID.replace(activeTabHref + "_", '');
                    resultsTabID = resultsTabID.replace('_loan_results-label', '');
                } else {
                    activeTabHref = $('.accordion-item.is-active a').attr('id');
                    resultsTabID = $(".accordion-content.is-active").attr('id');
                    resultsTabID = resultsTabID.replace(activeTabHref + "_", '');
                    resultsTabID = resultsTabID.replace('_loan_results', '');
                    let activeResultsTab = $('.results-tabs:visible');
                }
            } else {

                // Mobile check
                resultsTabID = $(".accordion-content.is-active").attr('id');
                if (!resultsTabID) {
                    //alert('trouble figuring out what went on here, using borrow');
                    resultsTabID = $(".accordion-content:visible").attr('id');
                    //resultsTabID = "homestart";
                    resultsTabID = resultsTabID.replace('_loan_results', '');
                    resultsTabID = resultsTabID.split('_');
                    resultsTabID = resultsTabID[1];
                } else {
                    resultsTabID = resultsTabID.replace(activeTabHref + "_", '');
                    resultsTabID = resultsTabID.replace('_loan_results', '');
                }

                //alert(resultsTabID);
            }
        }
        if (activeTabHref === undefined || resultsTabID === undefined || typeof resultsTabID === undefined || typeof activeTabHref === undefined) {
            console.log('Ran into issue generating virtual URL, aborting early');
            return;
        }
        virtualPageViewUrl = "/calculator/" + activeTabHref;
        if (resultsTabID != "") {
            virtualPageViewUrl = "/calculator/" + activeTabHref + "/" + resultsTabID;
        }
        if (form != '') {
            virtualPageViewUrl = "/calculator/" + activeTabHref + "/" + resultsTabID + "/" + form;
        }
    }

    let pageTitleMapper = {
        // Borrow Calculator (HomeStart)
        "/calculator/borrow": "Borrow Calculator - HomeStart Loan",
        "/calculator/deposit": "Deposit Calculator - HomeStart Loan",
        "/calculator/repayments": "Repayments Calculator - HomeStart Loan",

        '/calculator/borrow/homestart': "Borrow Calculator - HomeStart Loan",
        '/calculator/borrow/homestart/email': "Borrow Calculator - HomeStart Loan - Email ",
        '/calculator/borrow/homestart/print': "Borrow Calculator - HomeStart Loan - Print ",
        '/calculator/borrow/homestart/callback': "Borrow Calculator - HomeStart Loan - Call ",
        '/calculator/borrow/homestart/callback/fail': "Borrow Calculator - HomeStart Loan - Call - Fail",
        '/calculator/borrow/homestart/callback/submit': "Borrow Calculator - HomeStart Loan - Call - Submit",
        '/calculator/borrow/homestart/callback/cancel': "Borrow Calculator - HomeStart Loan - Call - Cancel",

        '/calculator/borrow/graduate': "Borrow Calculator - Graduate Loan",
        '/calculator/borrow/graduate/email': "Borrow Calculator - Graduate Loan - Email",
        '/calculator/borrow/graduate/print': "Borrow Calculator - Graduate Loan - Print",
        '/calculator/borrow/graduate/callback': "Borrow Calculator - Graduate Loan - Call",
        '/calculator/borrow/graduate/callback/fail': "Borrow Calculator - Graduate Loan - Call - Fail",
        '/calculator/borrow/graduate/callback/submit': "Borrow Calculator - Graduate Loan - Call - Submit",
        '/calculator/borrow/graduate/callback/cancel': "Borrow Calculator - Graduate Loan - Call - Cancel",

        '/calculator/borrow/lowdeposit': "Borrow Calculator - Low Deposit Loan",
        '/calculator/borrow/lowdeposit/email': "Borrow Calculator - Low Deposit Loan - Email ",
        '/calculator/borrow/lowdeposit/print': "Borrow Calculator - Low Deposit Loan - Print ",
        '/calculator/borrow/lowdeposit/callback': "Borrow Calculator - Low Deposit Loan - Callback ",
        '/calculator/borrow/lowdeposit/callback/fail': "Borrow Calculator - Low Deposit Loan - Fail ",
        '/calculator/borrow/lowdeposit/callback/submit': "Borrow Calculator - Low Deposit Loan - Submit ",
        '/calculator/borrow/lowdeposit/callback/cancel': "Borrow Calculator - Low Deposit Loan - Cancel ",

        // Deposit Calculator (HomeStart )
        '/calculator/deposit/homestart': "Deposit Calculator - HomeStart Loan",
        '/calculator/deposit/homestart/email': "Deposit Calculator - HomeStart Loan - Email ",
        '/calculator/deposit/homestart/print': "Deposit Calculator - HomeStart Loan - Print ",
        '/calculator/deposit/homestart/callback': "Deposit Calculator - HomeStart Loan - Callback ",
        '/calculator/deposit/homestart/callback/fail': "Deposit Calculator - HomeStart Loan - Fail ",
        '/calculator/deposit/homestart/callback/submit': "Deposit Calculator - HomeStart Loan - Submit ",
        '/calculator/deposit/homestart/callback/cancel': "Deposit Calculator - HomeStart Loan - Cancel ",

        '/calculator/deposit/graduate': "Deposit Calculator - Graduate Loan",
        '/calculator/deposit/graduate/email': "Deposit Calculator - Graduate Loan - Email ",
        '/calculator/deposit/graduate/print': "Deposit Calculator - Graduate Loan - Print ",
        '/calculator/deposit/graduate/callback': "Deposit Calculator - Graduate Loan - Callback ",
        '/calculator/deposit/graduate/callback/fail': "Deposit Calculator - Graduate Loan - Fail ",
        '/calculator/deposit/graduate/callback/submit': "Deposit Calculator - Graduate Loan - Submit ",
        '/calculator/deposit/graduate/callback/cancel': "Deposit Calculator - Graduate Loan - Cancel ",

        '/calculator/deposit/lowdeposit': "Deposit Calculator - Low Deposit Loan",
        '/calculator/deposit/lowdeposit/email': "Deposit Calculator - Low Deposit Loan - Email ",
        '/calculator/deposit/lowdeposit/print': "Deposit Calculator - Low Deposit Loan - Print ",
        '/calculator/deposit/lowdeposit/callback': "Deposit Calculator - Low Deposit Loan - Callback ",
        '/calculator/deposit/lowdeposit/callback/fail': "Deposit Calculator - Low Deposit Loan - Fail ",
        '/calculator/deposit/lowdeposit/callback/submit': "Deposit Calculator - Low Deposit Loan - Submit ",
        '/calculator/deposit/lowdeposit/callback/cancel': "Deposit Calculator - Low Deposit Loan - Cancel ",


        '/calculator/repayments/homestart': "Repayments Calculator - HomeStart Loan",
        '/calculator/repayments/homestart/email': "Repayments Calculator - HomeStart Loan - Email ",
        '/calculator/repayments/homestart/print': "Repayments Calculator - HomeStart Loan - Print ",
        '/calculator/repayments/homestart/callback': "Repayments Calculator - HomeStart Loan - Callback ",
        '/calculator/repayments/homestart/callback/fail': "Repayments Calculator - HomeStart Loan - Fail ",
        '/calculator/repayments/homestart/callback/submit': "Repayments Calculator - HomeStart Loan - Submit ",
        '/calculator/repayments/homestart/callback/cancel': "Repayments Calculator - HomeStart Loan - Cancel ",

        '/calculator/repayments/graduate': "Repayments Calculator - Graduate Loan",
        '/calculator/repayments/graduate/email': "Repayments Calculator - Graduate Loan - Email ",
        '/calculator/repayments/graduate/print': "Repayments Calculator - Graduate Loan - Print ",
        '/calculator/repayments/graduate/callback': "Repayments Calculator - Graduate Loan - Callback ",
        '/calculator/repayments/graduate/callback/fail': "Repayments Calculator - Graduate Loan - Fail ",
        '/calculator/repayments/graduate/callback/submit': "Repayments Calculator - Graduate Loan - Submit ",
        '/calculator/repayments/graduate/callback/cancel': "Repayments Calculator - Graduate Loan - Cancel ",

        '/calculator/repayments/lowdeposit': "Repayments Calculator - Low Deposit Loan",
        '/calculator/repayments/lowdeposit/email': "Repayments Calculator - Low Deposit Loan - Email ",
        '/calculator/repayments/lowdeposit/print': "Repayments Calculator - Low Deposit Loan - Print ",
        '/calculator/repayments/lowdeposit/callback': "Repayments Calculator - Low Deposit Loan - Callback ",
        '/calculator/repayments/lowdeposit/callback/fail': "Repayments Calculator - Low Deposit Loan - Fail ",
        '/calculator/repayments/lowdeposit/callback/submit': "Repayments Calculator - Low Deposit Loan - Submit ",
        '/calculator/repayments/lowdeposit/callback/cancel': "Repayments Calculator - Low Deposit Loan - Cancel ",

        // Broker
        "/calculator/seniors_equity_tab": "Broker - Calculator - Seniors Equity",
        "/calculator/year_to_date_tab": "Broker - Calculator - YTD Income",
        "/calculator/home_loan_tab": "Broker - Calculator - Home Loan",
        "/broker/calculator/home-loan/open-existing": "Broker - Calculator - Home Loan - Search Existing",
        "/broker/calculator/home-loan/open-existing/load-existing": "Broker - Calculator - Home Loan - Open Exisiting - Load Existing",
        "/broker/calculator/home-loan/open-existing/close": "Broker - Calculator - Home Loan - Open Exisiting - Close Box",
        "/broker/calculator/home-loan/open-existing/delete-existing": "Broker - Calculator - Home Loan - Open Exisiting - Delete Existing",
        "/broker/calculator/home-loan/calculate-max-borrow/email": "Broker - Calculator - Home Loan - Max Borrow - Email",
        "/broker/calculator/home-loan/calculate-max-borrow/print": "Broker - Calculator - Home Loan - Max Borrow - Print",
        "/broker/calculator/home-loan/calculate-max-borrow/save": "Broker - Calculator - Home Loan - Max Borrow - Save",
        "/broker/calculator/home-loan/calculate-on-property-value/email": "Broker - Calculator - Home Loan - Property Value - Email",
        "/broker/calculator/home-loan/calculate-on-property-value/print": "Broker - Calculator - Home Loan - Property Value - Print",
        "/broker/calculator/home-loan/calculate-on-property-value/save": "Broker - Calculator - Home Loan - Property Value - Save",
        "/broker/calculator/home-loan/calculate-on-all-funds/email": "Broker - Calculator - Home Loan - All Funds - Email",
        "/broker/calculator/home-loan/calculate-on-all-funds/print": "Broker - Calculator - Home Loan - All Funds - Print",
        "/broker/calculator/home-loan/calculate-on-all-funds/save": "Broker - Calculator - Home Loan - All Funds - Save",
        "/broker/calculator/seniors-equity/calculate/email": "Broker - Calculator - Seniors Equity - Calculate - Email",
        "/broker/calculator/seniors-equity/calculate/print": "Broker - Calculator - Seniors Equity - Calculate - Print",
        "/broker/calculator/ytd-income/calculate/print": "Broker - Calculator - YTD Income - Calculate - Print",

        "/broker/calculator/home-loan/calculate-max-borrow": "Broker - Calculator - Home Loan - Max Borrow ",
        "/broker/calculator/home-loan/calculate-on-property-value": "Broker - Calculator - Home Loan - Property Value ",
        "/broker/calculator/home-loan/calculate-on-all-funds": "Broker - Calculator - Home Loan - All Funds ",
        "/broker/calculator/seniors-equity/calculate": "Broker - Calculator - Seniors Equity - Calculate ",
        "/broker/calculator/ytd-income/calculate": "Broker - Calculator - YTD Income - Calculate",
        "/broker/calculator/home-loan/calculate-max-borrow/email/submit": "Broker - Calculator - Home Loan - Max Borrow - Email - Submit",
        "/broker/calculator/home-loan/calculate-max-borrow/print/submit": "Broker - Calculator - Home Loan - Max Borrow - Print - Submit",
        "/broker/calculator/home-loan/calculate-max-borrow/save/submit": "Broker - Calculator - Home Loan - Max Borrow - Save - Submit",
        "/broker/calculator/home-loan/calculate-on-property-value/email/submit": "Broker - Calculator - Home Loan - Property Value - Email - Submit",
        "/broker/calculator/home-loan/calculate-on-property-value/print/submit": "Broker - Calculator - Home Loan - Property Value - Print - Submit",
        "/broker/calculator/home-loan/calculate-on-property-value/save/submit": "Broker - Calculator - Home Loan - Property Value - Save - Submit",
        "/broker/calculator/home-loan/calculate-on-all-funds/email/submit": "Broker - Calculator - Home Loan - All Funds - Email - Submit",
        "/broker/calculator/home-loan/calculate-on-all-funds/print/submit": "Broker - Calculator - Home Loan - All Funds - Print - Submit",
        "/broker/calculator/home-loan/calculate-on-all-funds/save/submit": "Broker - Calculator - Home Loan - All Funds - Save - Submit",
        "/broker/calculator/seniors-equity/calculate/email/submit": "Broker - Calculator - Seniors Equity - Calculate - Email - Submit",
        "/broker/calculator/seniors-equity/calculate/print/submit": "Broker - Calculator - Seniors Equity - Calculate - Print - Submit",

    };

    let pageTitle = ""
    if (!pageTitleMapper.hasOwnProperty(virtualPageViewUrl)) {
        console.warn('Virtual Page URL: has no title' + virtualPageViewUrl);
        pageTitle = "No page title: " + virtualPageViewUrl;
    } else {
        pageTitle = pageTitleMapper[virtualPageViewUrl];
    }

    console.log("Virtual page view: [" + virtualPageViewUrl + "] Title:" + pageTitle);
    if (dataLayer) {
        dataLayer.push({
            'event': 'VirtualPageview',
            virtualPageURL: virtualPageViewUrl,
            virtualPageTitle: virtualPageViewUrl
        });
    } else {
        window.dataLayer.push({
            'event': 'VirtualPageview',
            virtualPageURL: virtualPageViewUrl,
            virtualPageTitle: virtualPageViewUrl
        });
    }
}

