import $ from 'jquery';

export default function()  {

    $('a.accordion-trigger').on('click', function (e) {
        // TODO: Change from more to less
        var button = $(this);
        e.preventDefault();

        var toShow = $(this).parent().parent().parent().parent().parent().find('.call-to-action-showme');
        if (!toShow.hasClass('edit-mode')) { // Don't show/hide on edit mode (always visible)
            if (toShow.is(':visible')) {
                toShow.slideUp();
                button.removeClass('less').html("More");
                // button.removeClass('less').html("More <i class='icon fal fa-angle-down'></i>");
            } else {
                toShow.slideDown();
                button.addClass('less').html("Less");
                // button.addClass('less').html("Less <i class='icon fal fa-angle-up'></i>");
            }
        }
    });
 }