import $             from 'jquery';
import {maskElement} from "../../utils";

export default function () {
    let inputIdsToMask = [
        "borrow_borrower_1_income",
        "borrow_borrower_2_income",
        "borrow_borrower_3_income",
        "borrow_borrower_4_income",
        //"borrow_borrower_5_income",
        "borrow_total_credit_limit",
        "borrow_other_commitments",
        "borrow_property_price",
        "deposit_property_price",
        "repayments_loan_amount",
    ];

    $.each(inputIdsToMask, function (k, elemId) {
        maskElement($("#" + elemId));
    });

}
