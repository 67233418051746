import $ from "jquery";

export default (window) => {
    if ($('#Request-Financial-Assistance').length == 0) {
        console.log("RFA div not found");
        return;
    }   

    const API_URL = '/api/calmsdashboard/PrintrequestfinancialassistancePDF';

    $("#request-financial-assistance-button").on("click", function () {
        lockBackground();
        document.getElementById('Request-Financial-Assistance').style.display = 'block';
        //$("#rfa_button_loading").css("display", "none");
        //$("#rfa_button_ready").css("display", "inline");
    });

    $('#Request-Financial-Assistance-Form').on('submit', function (e) {

        e.preventDefault();
        $('.error').remove();

        var invalid = $(this).find('.is-invalid-label');
        console.log(invalid.length, 'invalids');

        if (invalid.length != 0) {
            for (var i = 0; i < invalid.length; i++) {
                var error = document.createElement("span");
                error.innerHTML = invalid[i].getAttribute("errorText");
                error.className = "error";
                error.style.marginTop = invalid[i].getAttribute("errorTopMargin");
                invalid[i].after(error);
            }
            return;
        }

        $("#rfa_submit_button").prop('disabled', 'disabled');
        $("#rfa_button_loading").css("display", "inline");
        $("#rfa_button_ready").css("display", "none");

        // Handle the submission of the formvar
        var $container = $('#Request-Financial-Assistance');
        var reason = $container.find("select[name='rfa_reason']").val();
        var proposal = $container.find("input[name='rfa_proposal']").val();
        var frequency = $container.find("select[name='rfa_frequency']").val();
        var additional_information = $container.find("textarea[name='rfa_additional_information']").val();
        var resume_date = $container.find("input[name='rfa_resume_date']").val();
        var calltime = $container.find("select[name='rfa_calltime']").val();
        var contactnumber = $container.find("input[name='rfa_contactnumber']").val();

        var payload = {
            'reason': reason,
            'proposal': proposal,
            'frequency': frequency,
            'additional_information': additional_information,
            'resume_date': resume_date,
            'calltime': calltime,
            'contactnumber': contactnumber
        }

        console.log(payload);

        $.ajax({
            url: API_URL,
            data: payload,
            success: function (result) {
                console.log(result);

                $('#Request-Financial-Assistance').hide();
                $("#rfa_submit_button").removeAttr('disabled');

                if (result == "Success") {
                    $('#FinancialAssistanceThankYouModal').show();
                    $('#Request-Financial-Assistance-Form')[0].reset();
                    $("#rfa_button_loading").css("display", "none");
                    $("#rfa_button_ready").css("display", "inline");
                } else if (result == "Logout") {
                    document.location = "/api/Account/Login";
                } else {                   
                        $('.modal').hide();
                        document.getElementById('FailModal').style.display = 'block';
                        $("#rfa_button_loading").css("display", "none");
                        $("#rfa_button_ready").css("display", "inline");
                }
            },
            error: function (error) {
                if (error.responseText.includes("User cannot perform action on this api")) {
                    document.location = "/api/Account/Login";
                } else {
                    $('.modal').hide();
                    document.getElementById('FailModal').style.display = 'block';
                    $("#rfa_button_loading").css("display", "none");
                    $("#rfa_button_ready").css("display", "inline");
                }
            }
        });

    });

    console.log("RFA form ready");
}