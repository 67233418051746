import $ from 'jquery'
import {scrollTo} from '../utils'

export default (window) => {
    function updateIframeHeight(iframe){
        // delay detect content height
        setTimeout(function(){
            iframe.style.height = 'auto';
            iframe.style.height = iframe.contentWindow.document.body.offsetHeight + 'px';
        }, 150);
    }

    $('iframe[data-height-listener][data-src]').each(function(){
        this.src = $(this).data('src');

        $(this).on('load', function() {
            updateIframeHeight(this);
        });
    });

    var resizeTimer;
    $(window).on('resize', function(){
       clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            $('iframe[data-height-listener]').each(function(){
                updateIframeHeight(this);
            });
        }, 150);
    });
}