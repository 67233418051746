import $ from 'jquery';

import {
    maybeParseInt,
    calculatorLoadStart,
    calculatorLoadError,
}                                         from "./calculator-utils";
import calculatorDisplayRepaymentsResults from "./calculator-handle-repayments-results";


export default function () {

    calculatorLoadStart();
    window.results = new Array(); // reset any potential other results
    let loanAmount = maybeParseInt($("#repayments_loan_amount").val().toString().replace(',', ''));
    let minLoanAmount = parseFloat($('#validation-data').attr('data-loan-min'));
    let maxLoanAmount = parseFloat($('#validation-data').attr('data-loan-max'));
    let data = {
        "LoanAmount": loanAmount,
    };

    if (parseFloat(loanAmount) >= maxLoanAmount) {
        calculatorLoadError('No result(s) found for the provided parameter(s)');
        return;
    }
    if (parseFloat(loanAmount) <= minLoanAmount) {
        calculatorLoadError(`Please enter a loan amount greater than $${minLoanAmount}.`);
        return;
    }

    let url = window.location.origin + '/api/calculator/WAMR';
    $.ajax(
        {
            url: url,
            type: "GET",
            data: data,
            contentType: "application/json",
            dataType: "json",
            success: function (result) {
                if (result.status == 'Fail') {
                    if (result.errors.length > 0) {
                        calculatorLoadError('Error retrieving the data'); //result.errors[0].description
                    } else {
                        calculatorLoadError('Error retrieving the data');
                    }
                } else {
                    calculatorDisplayRepaymentsResults(result);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                calculatorLoadError()
            }
        });
}
