import $ from 'jquery';

export default function () {

    $('.show-fees-charges').on('click', function (e) {
        e.preventDefault();
        let $this = $(this);
        let $elemToShow = $(this).parent().parent().parent().parent().find('.fees-and-charges-wrapper');
        if ($elemToShow.is(':visible')) {
            $elemToShow.slideUp(500);
            $this.removeClass('open');
            $this.addClass('closed');
        } else {
            $elemToShow.slideDown(500);
            $this.removeClass('closed');
            $this.addClass('open');
        }
    })
};
