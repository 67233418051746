import $ from 'jquery';

export default function() {
  // add resize trigger
  const boxes = $('.article-box').attr('data-equalizer-watch','');

  // set parent to init all articles
  new Foundation.Equalizer($('.page-content'), {
    equalizeOnStack: true,
    equalizeByRow: false,
  });
};