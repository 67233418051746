import $ from 'jquery';

import {getFullSuburbList, getSuburbListAutoComplete} from "../../components/calculators/calculator-suburb-list";
import {maskElement}                                  from "../../utils";
import {
    calculatorLoadError,
    calculatorLoadStart,
    calculatorReset, generateVirtualPageView,
    updateCalculatorButtonTextNoAmount
} from "../../components/calculators/calculator-utils";

export const initYTD = (window) => {
    if ($("#year_to_date_tab").length >= 1) {
        //maskElement($("#gross_ytd_income"));
    }
    $("#ytd_print").on('click', function (e) {
        $("#ytd_print_form").show();
        $("ytd_print").addClass('active');
    })
    $("#year_to_date_form").on('change', function () {
        if ($("#ytd_results").is(":visible")) {
            console.log('resetting form!');
            calculatorReset();
            ytdReset();
        }
    });
    $("#employment_type").on('change',function(e) {
        if($("#employment_type").val() == "Casual") {
            $("#casual_employment_message").show();
        } else {
            $("#casual_employment_message").hide();
        }
    })
};


export const handleYTD = (window) => {
    // Init
    // get variables
    calculatorReset();
    ytdReset();
    calculatorLoadStart();

    //alert("handling");
    generateVirtualPageView('/broker/calculator/ytd-income/calculate');

    let API_URL = '/api/yeartodate/getyeartodate';
    var fromDate = new Date($("#from_date").val());
    var toDate = new Date($("#latest_pay_date").val());
    var data = {
        "EmploymentType": $("#employment_type").val(),
        "PaymentFrequency": $("#pay_frequency").val(),
        "PayPeriodFrom": fromDate.toISOString().substring(0, 10),
        "PayPeriodTo": toDate.toISOString().substring(0, 10),

        "GrossYTDIncome": parseInt($("#gross_ytd_income").val().replace(/,/g, '')),
    };
    console.log("data", data);

    $.ajax({
               url: window.location.origin + API_URL,
               type: "GET",
               data: data,
               contentType: "application/json",
               dataType: "json",
               success: function (result) {
                   console.log("result", result)
                   if (result.errorMessage != "" && result.errorMessage != null) {
                       console.warn('error', result.errorMessage);
                       calculatorLoadError(result.errorMessage);
                   } else {
                       //$("#ytd_results").html('<pre>'+JSON.stringify(result)+"</pre>");
                       var grossIncome = result.grossIncome;
                       var averageGrossIncome = result.averageGrossIncome;
                       $("#ytd_gross_income_result").text("$" + averageGrossIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                       $("#ytd_annualized_gross_income_result").text("$" + grossIncome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))

                       $("#ytd_pay_period_result").text(result.payPeriods);
                       updateCalculatorButtonTextNoAmount("Result");
                       $("#ytd_results").slideDown(250);
                   }

               }
           }
    )
};
export const ytdReset = () => {
    $("#ytd_gross_income_result").text('');
    $("#ytd_annualized_gross_income_result").text('');
    $("#ytd_pay_period_result").text('');
    $("#ytd_results").slideUp(250);
    calculatorReset();
}
export const initYTDPrint = (window) => {
    $("#ytd_print").on('click', function (e) {
        e.preventDefault();
        $("#ytd_print_form").slideDown();
        generateVirtualPageView('/broker/calculator/ytd-income/calculate/print');
    });

    $("#ytd_print_submit").on('click', function (e) {
        /* Sanity checks*/
        var valid = true;
        if ($("#ytd_print_firstname").val() == "") {
            valid = false;
        } else {
            $("#ytd_print_firstname").parent().find('.form-error').hide();
        }
        if ($("#ytd_print_lastname").val() == "") {
            valid = false;
        } else {
            $("#ytd_print_lastname").parent().find('.form-error').hide();
        }
        if (!valid) {
            if($("#ytd_print_firstname").val() =="") {
                $("#ytd_print_firstname").parent().find('.form-error').show();
            }
            if($("#ytd_print_lastname").val() =="") {
                $("#ytd_print_lastname").parent().find('.form-error').show();
            }
            return;
        }
        $("#ytd_print_form").find('.form-error').hide();
        $("#ytd_print_submit").addClass('loading');
        generateVirtualPageView('/broker/calculator/ytd-income/calculate/print/submit');

        e.preventDefault();

        var fromDate = new Date($("#from_date").val());
        var toDate = new Date($("#latest_pay_date").val());
        var data = {
            "templateTitle": "Year To Date Calculation Results",
            "calculationDate": new Date().toDateString("%Y"),
            "applicantName": $("#ytd_print_firstname").val() + " " + $("#ytd_print_lastname").val(),
            "printGrossYTDIncome": '$' + parseInt($("#gross_ytd_income").val().replace(/,/g, '')).toString(),
            "printPaymentFrequency": $("#pay_frequency").val(),
            "printEmploymentType": $("#employment_type").val(),
            "printPayPeriodFrom": fromDate.toISOString(),
            "printPayPeriodTo": toDate.toISOString(),
            "printAverageGrossIncome": $("#ytd_gross_income_result").text(),
            "printGrossAnnualIncome": $("#ytd_annualized_gross_income_result").text(),
            "printPayPeriods": $('#ytd_pay_period_result').text(),
        }
        console.log(data);
        let API_URL = '/api/yeartodate/createPrintDocument';

      let winPDFResult = window.open('', 'PrintYearToDateResults');

        $.ajax({
                   url: window.location.origin + API_URL,
                   type: "GET",
                   data: data,
                   contentType: "application/json",
                   dataType: "json",
                   success: function (result) {
                       console.log("result", result)
                       $("#ytd_print_submit").removeClass('loading');
                       if (result.error != "" && result.error != null) {
                           console.warn('error', result.errorMessage);
                           calculatorLoadError(result.error);
                       } else {
                           if (result.hasOwnProperty('documentUrl') && (result.documentUrl != null && result.documentUrl != "")) {
                             // Assign the result to the previously created window reference
                             winPDFResult.location.href = result.documentUrl;
                           } else {
                           }

                       }

                   }
               }
        )
    });
}
