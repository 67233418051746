import $ from "jquery";

export function getSuburbList(full = false) {
    if (full) {
        return getFullSuburbList();
    }
    let suburbs = getFullSuburbList()
    let reduced = [];
    $.each(suburbs, function (k, obj) {
        reduced[obj['suburbName']] = obj['suburbName'];
    });
    return reduced;
}
export function getSuburbListAutoComplete(full = false) {
    if (full) {
        return getFullSuburbList();
    }
    let suburbs = getFullSuburbList()
    let reduced = [];
    $.each(suburbs, function (k, obj) {
        reduced.push(obj['suburbName']);
    });
    return reduced;
}

export function isSuburbMetro(suburb) {
    let suburbs = getFullSuburbList();

    var isMetro = false;
    if (suburb == "") {
        return true; // No suburb is defaulted to ADELAIDE, which is a metro suburb
    }
    $.each(suburbs, function (k, v) {
        if (v['suburbName'] == suburb.toUpperCase()) {
            console.log("Object match:", v, suburb);
            console.log('returning: ', v.isMetro);
            isMetro = v.isMetro;
            return;
        }
    });
    return isMetro;

}

export function getPostCode(suburb) {
    let suburbs = getFullSuburbList();
    if (suburb == "") {
        return '5000';
    }

    var postCode = 5000;

    $.each(suburbs, function (k, v) {
        if (v['suburbName'] == suburb.toUpperCase() || v['postCode'] == suburb) {
            postCode = v.postCode;
        }
    });

    return postCode;
}

    export function getFullSuburbList() {
        return [
            {
                "suburbId": 1,
                "suburbName": "ABERFOYLE PARK",
                "postCode": "5159",
                "isMetro": true
            },
            {
                "suburbId": 2,
                "suburbName": "ABMINGA STATION",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 3,
                "suburbName": "ADELAIDE",
                "postCode": "5000",
                "isMetro": true
            },
            {
                "suburbId": 4,
                "suburbName": "ADELAIDE AIRPORT",
                "postCode": "5950",
                "isMetro": true
            },
            {
                "suburbId": 5,
                "suburbName": "AGERY",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 6,
                "suburbName": "ALAWOONA",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 7,
                "suburbName": "ALBERT PARK",
                "postCode": "5014",
                "isMetro": true
            },
            {
                "suburbId": 8,
                "suburbName": "ALBERTON",
                "postCode": "5014",
                "isMetro": true
            },
            {
                "suburbId": 9,
                "suburbName": "ALDGATE",
                "postCode": "5154",
                "isMetro": true
            },
            {
                "suburbId": 10,
                "suburbName": "ALDINGA",
                "postCode": "5173",
                "isMetro": true
            },
            {
                "suburbId": 11,
                "suburbName": "ALDINGA BEACH",
                "postCode": "5173",
                "isMetro": true
            },
            {
                "suburbId": 12,
                "suburbName": "ALFORD",
                "postCode": "5555",
                "isMetro": false
            },
            {
                "suburbId": 13,
                "suburbName": "ALLANDALE STATION",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 14,
                "suburbName": "ALLENBY GARDENS",
                "postCode": "5009",
                "isMetro": true
            },
            {
                "suburbId": 15,
                "suburbName": "ALLENDALE EAST",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 16,
                "suburbName": "ALLENDALE NORTH",
                "postCode": "5373",
                "isMetro": false
            },
            {
                "suburbId": 17,
                "suburbName": "ALMA",
                "postCode": "5401",
                "isMetro": false
            },
            {
                "suburbId": 18,
                "suburbName": "ALPANA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 19,
                "suburbName": "ALTON DOWNS STATION",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 20,
                "suburbName": "ALTONA",
                "postCode": "5351",
                "isMetro": false
            },
            {
                "suburbId": 21,
                "suburbName": "AMATA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 22,
                "suburbName": "AMERICAN BEACH",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 23,
                "suburbName": "AMERICAN RIVER",
                "postCode": "5221",
                "isMetro": false
            },
            {
                "suburbId": 24,
                "suburbName": "AMYTON",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 25,
                "suburbName": "ANAMA",
                "postCode": "5464",
                "isMetro": false
            },
            {
                "suburbId": 26,
                "suburbName": "ANANGU PITJANTJATJARA YANKUNYTJATJARA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 27,
                "suburbName": "ANDAMOOKA",
                "postCode": "5722",
                "isMetro": false
            },
            {
                "suburbId": 28,
                "suburbName": "ANDAMOOKA STATION",
                "postCode": "5722",
                "isMetro": false
            },
            {
                "suburbId": 29,
                "suburbName": "ANDREWS",
                "postCode": "5454",
                "isMetro": false
            },
            {
                "suburbId": 30,
                "suburbName": "ANDREWS FARM",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 31,
                "suburbName": "ANGAS PLAINS",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 32,
                "suburbName": "ANGAS VALLEY",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 33,
                "suburbName": "ANGASTON",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 34,
                "suburbName": "ANGEPENA",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 35,
                "suburbName": "ANGLE PARK",
                "postCode": "5010",
                "isMetro": true
            },
            {
                "suburbId": 36,
                "suburbName": "ANGLE VALE",
                "postCode": "5117",
                "isMetro": true
            },
            {
                "suburbId": 37,
                "suburbName": "ANGORIGINA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 38,
                "suburbName": "ANNA CREEK",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 39,
                "suburbName": "ANNADALE",
                "postCode": "5356",
                "isMetro": false
            },
            {
                "suburbId": 40,
                "suburbName": "ANTECHAMBER BAY",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 41,
                "suburbName": "APAMURRA",
                "postCode": "5237",
                "isMetro": false
            },
            {
                "suburbId": 42,
                "suburbName": "APOINGA",
                "postCode": "5413",
                "isMetro": false
            },
            {
                "suburbId": 43,
                "suburbName": "APPILA",
                "postCode": "5480",
                "isMetro": false
            },
            {
                "suburbId": 44,
                "suburbName": "ARCKARINGA",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 45,
                "suburbName": "ARCOONA",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 46,
                "suburbName": "ARDROSSAN",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 47,
                "suburbName": "ARKAROOLA",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 48,
                "suburbName": "ARKAROOLA VILLAGE",
                "postCode": "5701",
                "isMetro": false
            },
            {
                "suburbId": 49,
                "suburbName": "ARMAGH",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 50,
                "suburbName": "ARNO BAY",
                "postCode": "5603",
                "isMetro": false
            },
            {
                "suburbId": 51,
                "suburbName": "ARTHURTON",
                "postCode": "5572",
                "isMetro": false
            },
            {
                "suburbId": 52,
                "suburbName": "ASCOT PARK",
                "postCode": "5043",
                "isMetro": true
            },
            {
                "suburbId": 53,
                "suburbName": "ASHBOURNE",
                "postCode": "5157",
                "isMetro": false
            },
            {
                "suburbId": 54,
                "suburbName": "ASHFORD",
                "postCode": "5035",
                "isMetro": true
            },
            {
                "suburbId": 55,
                "suburbName": "ASHTON",
                "postCode": "5137",
                "isMetro": true
            },
            {
                "suburbId": 56,
                "suburbName": "ASHVILLE",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 57,
                "suburbName": "ATHELSTONE",
                "postCode": "5076",
                "isMetro": true
            },
            {
                "suburbId": 58,
                "suburbName": "ATHOL PARK",
                "postCode": "5012",
                "isMetro": true
            },
            {
                "suburbId": 59,
                "suburbName": "AUBURN",
                "postCode": "5451",
                "isMetro": false
            },
            {
                "suburbId": 60,
                "suburbName": "AULDANA",
                "postCode": "5072",
                "isMetro": true
            },
            {
                "suburbId": 61,
                "suburbName": "AUSTRALIA PLAINS",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 62,
                "suburbName": "AVENUE RANGE",
                "postCode": "5273",
                "isMetro": false
            },
            {
                "suburbId": 63,
                "suburbName": "AVOCA DELL",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 64,
                "suburbName": "AVON",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 65,
                "suburbName": "AYERS RANGE SOUTH",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 66,
                "suburbName": "BACK VALLEY",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 67,
                "suburbName": "BACKY POINT",
                "postCode": "5601",
                "isMetro": false
            },
            {
                "suburbId": 68,
                "suburbName": "BAGOT WELL",
                "postCode": "5373",
                "isMetro": false
            },
            {
                "suburbId": 69,
                "suburbName": "BAIRD BAY",
                "postCode": "5671",
                "isMetro": false
            },
            {
                "suburbId": 70,
                "suburbName": "BAKARA",
                "postCode": "5354",
                "isMetro": false
            },
            {
                "suburbId": 71,
                "suburbName": "BAKARA WELL",
                "postCode": "5354",
                "isMetro": false
            },
            {
                "suburbId": 72,
                "suburbName": "BALAH",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 73,
                "suburbName": "BALAKLAVA",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 74,
                "suburbName": "BALD HILLS",
                "postCode": "5203",
                "isMetro": false
            },
            {
                "suburbId": 75,
                "suburbName": "BALDINA",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 76,
                "suburbName": "BALGOWAN",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 77,
                "suburbName": "BALHANNAH",
                "postCode": "5242",
                "isMetro": true
            },
            {
                "suburbId": 78,
                "suburbName": "BALLAST HEAD",
                "postCode": "5221",
                "isMetro": false
            },
            {
                "suburbId": 79,
                "suburbName": "BANGHAM",
                "postCode": "5268",
                "isMetro": false
            },
            {
                "suburbId": 80,
                "suburbName": "BANGOR",
                "postCode": "5481",
                "isMetro": false
            },
            {
                "suburbId": 81,
                "suburbName": "BANKSIA PARK",
                "postCode": "5091",
                "isMetro": true
            },
            {
                "suburbId": 82,
                "suburbName": "BARABBA",
                "postCode": "5460",
                "isMetro": false
            },
            {
                "suburbId": 83,
                "suburbName": "BARATTA",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 84,
                "suburbName": "BARINIA",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 85,
                "suburbName": "BARMERA",
                "postCode": "5345",
                "isMetro": false
            },
            {
                "suburbId": 86,
                "suburbName": "BARNA",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 87,
                "suburbName": "BARNDIOOTA",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 88,
                "suburbName": "BAROOTA",
                "postCode": "5495",
                "isMetro": false
            },
            {
                "suburbId": 89,
                "suburbName": "BAROSSA GOLDFIELDS",
                "postCode": "5351",
                "isMetro": true
            },
            {
                "suburbId": 90,
                "suburbName": "BARUNGA GAP",
                "postCode": "5520",
                "isMetro": false
            },
            {
                "suburbId": 91,
                "suburbName": "BASKET RANGE",
                "postCode": "5138",
                "isMetro": true
            },
            {
                "suburbId": 92,
                "suburbName": "BAUDIN BEACH",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 93,
                "suburbName": "BAY OF SHOALS",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 94,
                "suburbName": "BEACHPORT",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 95,
                "suburbName": "BEATTY",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 96,
                "suburbName": "BEAUFORT",
                "postCode": "5550",
                "isMetro": false
            },
            {
                "suburbId": 97,
                "suburbName": "BEAUMONT",
                "postCode": "5066",
                "isMetro": true
            },
            {
                "suburbId": 98,
                "suburbName": "BEAUMONTS",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 99,
                "suburbName": "BEDFORD PARK",
                "postCode": "5042",
                "isMetro": true
            },
            {
                "suburbId": 100,
                "suburbName": "BEETALOO VALLEY",
                "postCode": "5523",
                "isMetro": false
            },
            {
                "suburbId": 101,
                "suburbName": "BELAIR",
                "postCode": "5052",
                "isMetro": true
            },
            {
                "suburbId": 102,
                "suburbName": "BELALIE EAST",
                "postCode": "5491",
                "isMetro": false
            },
            {
                "suburbId": 103,
                "suburbName": "BELALIE NORTH",
                "postCode": "5491",
                "isMetro": false
            },
            {
                "suburbId": 104,
                "suburbName": "BELLEVUE HEIGHTS",
                "postCode": "5050",
                "isMetro": true
            },
            {
                "suburbId": 105,
                "suburbName": "BELTANA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 106,
                "suburbName": "BELTANA STATION",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 107,
                "suburbName": "BELTON",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 108,
                "suburbName": "BELVIDERE",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 109,
                "suburbName": "BENBOURNIE",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 110,
                "suburbName": "BENDA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 111,
                "suburbName": "BERRI",
                "postCode": "5343",
                "isMetro": false
            },
            {
                "suburbId": 112,
                "suburbName": "BETHANY",
                "postCode": "5352",
                "isMetro": false
            },
            {
                "suburbId": 113,
                "suburbName": "BETHEL",
                "postCode": "5373",
                "isMetro": false
            },
            {
                "suburbId": 114,
                "suburbName": "BEULAH PARK",
                "postCode": "5067",
                "isMetro": true
            },
            {
                "suburbId": 115,
                "suburbName": "BEVERLEY",
                "postCode": "5009",
                "isMetro": true
            },
            {
                "suburbId": 116,
                "suburbName": "BIBARINGA",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 117,
                "suburbName": "BIBLIANDO",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 118,
                "suburbName": "BIG BEND",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 119,
                "suburbName": "BIGGS FLAT",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 120,
                "suburbName": "BILLA KALINA",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 121,
                "suburbName": "BILLIATT",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 122,
                "suburbName": "BIMBOWRIE",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 123,
                "suburbName": "BINDARRAH",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 124,
                "suburbName": "BINNUM",
                "postCode": "5262",
                "isMetro": false
            },
            {
                "suburbId": 125,
                "suburbName": "BIRCHMORE",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 126,
                "suburbName": "BIRDWOOD",
                "postCode": "5234",
                "isMetro": true
            },
            {
                "suburbId": 127,
                "suburbName": "BIRKENHEAD",
                "postCode": "5015",
                "isMetro": true
            },
            {
                "suburbId": 128,
                "suburbName": "BLACK FOREST",
                "postCode": "5035",
                "isMetro": true
            },
            {
                "suburbId": 129,
                "suburbName": "BLACK HILL",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 130,
                "suburbName": "BLACK HILL STATION",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 131,
                "suburbName": "BLACK POINT",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 132,
                "suburbName": "BLACK ROCK",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 133,
                "suburbName": "BLACK SPRINGS",
                "postCode": "5413",
                "isMetro": false
            },
            {
                "suburbId": 134,
                "suburbName": "BLACKFELLOWS CAVES",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 135,
                "suburbName": "BLACKFELLOWS CREEK",
                "postCode": "5201",
                "isMetro": false
            },
            {
                "suburbId": 136,
                "suburbName": "BLACKFORD",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 137,
                "suburbName": "BLACKWOOD",
                "postCode": "5051",
                "isMetro": true
            },
            {
                "suburbId": 138,
                "suburbName": "BLAIR ATHOL",
                "postCode": "5084",
                "isMetro": true
            },
            {
                "suburbId": 139,
                "suburbName": "BLAKEVIEW",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 140,
                "suburbName": "BLAKISTON",
                "postCode": "5250",
                "isMetro": true
            },
            {
                "suburbId": 141,
                "suburbName": "BLANCHE HARBOR",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 142,
                "suburbName": "BLANCHETOWN",
                "postCode": "5357",
                "isMetro": false
            },
            {
                "suburbId": 143,
                "suburbName": "BLETCHLEY",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 144,
                "suburbName": "BLEWITT SPRINGS",
                "postCode": "5171",
                "isMetro": true
            },
            {
                "suburbId": 145,
                "suburbName": "BLINMAN",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 146,
                "suburbName": "BLUFF BEACH",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 147,
                "suburbName": "BLYTH",
                "postCode": "5462",
                "isMetro": false
            },
            {
                "suburbId": 148,
                "suburbName": "BOATSWAIN POINT",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 149,
                "suburbName": "BOCKELBERG",
                "postCode": "5655",
                "isMetro": false
            },
            {
                "suburbId": 150,
                "suburbName": "BOCONNOC PARK",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 151,
                "suburbName": "BOLIVAR",
                "postCode": "5110",
                "isMetro": true
            },
            {
                "suburbId": 152,
                "suburbName": "BOLLARDS LAGOON",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 153,
                "suburbName": "BOLTO",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 154,
                "suburbName": "BON BON",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 155,
                "suburbName": "BOOBOROWIE",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 156,
                "suburbName": "BOOKABIE",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 157,
                "suburbName": "BOOKPURNONG",
                "postCode": "5333",
                "isMetro": false
            },
            {
                "suburbId": 158,
                "suburbName": "BOOL LAGOON",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 159,
                "suburbName": "BOOLCOOMATTA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 160,
                "suburbName": "BOOLEROO CENTRE",
                "postCode": "5482",
                "isMetro": false
            },
            {
                "suburbId": 161,
                "suburbName": "BOOLGUN",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 162,
                "suburbName": "BOONERDO",
                "postCode": "5633",
                "isMetro": false
            },
            {
                "suburbId": 163,
                "suburbName": "BOORS PLAIN",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 164,
                "suburbName": "BORDERTOWN",
                "postCode": "5268",
                "isMetro": false
            },
            {
                "suburbId": 165,
                "suburbName": "BORDERTOWN SOUTH",
                "postCode": "5268",
                "isMetro": false
            },
            {
                "suburbId": 166,
                "suburbName": "BORRIKA",
                "postCode": "5309",
                "isMetro": false
            },
            {
                "suburbId": 167,
                "suburbName": "BOSTON",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 168,
                "suburbName": "BOSWORTH",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 169,
                "suburbName": "BOWDEN",
                "postCode": "5007",
                "isMetro": true
            },
            {
                "suburbId": 170,
                "suburbName": "BOWER",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 171,
                "suburbName": "BOWHILL",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 172,
                "suburbName": "BOWILLIA",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 173,
                "suburbName": "BOWMANS",
                "postCode": "5550",
                "isMetro": false
            },
            {
                "suburbId": 174,
                "suburbName": "BRADBURY",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 175,
                "suburbName": "BRADY CREEK",
                "postCode": "5381",
                "isMetro": false
            },
            {
                "suburbId": 176,
                "suburbName": "BRAHMA LODGE",
                "postCode": "5109",
                "isMetro": true
            },
            {
                "suburbId": 177,
                "suburbName": "BRAMFIELD",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 178,
                "suburbName": "BRAY",
                "postCode": "5276",
                "isMetro": false
            },
            {
                "suburbId": 179,
                "suburbName": "BRENDA PARK",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 180,
                "suburbName": "BRENTWOOD",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 181,
                "suburbName": "BRIDGEWATER",
                "postCode": "5155",
                "isMetro": true
            },
            {
                "suburbId": 182,
                "suburbName": "BRIGHT",
                "postCode": "5381",
                "isMetro": false
            },
            {
                "suburbId": 183,
                "suburbName": "BRIGHTON",
                "postCode": "5048",
                "isMetro": true
            },
            {
                "suburbId": 184,
                "suburbName": "BRIMBAGO",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 185,
                "suburbName": "BRINKLEY",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 186,
                "suburbName": "BRINKWORTH",
                "postCode": "5464",
                "isMetro": false
            },
            {
                "suburbId": 187,
                "suburbName": "BROADVIEW",
                "postCode": "5083",
                "isMetro": true
            },
            {
                "suburbId": 188,
                "suburbName": "BROMPTON",
                "postCode": "5007",
                "isMetro": true
            },
            {
                "suburbId": 189,
                "suburbName": "BROOKER",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 190,
                "suburbName": "BROOKLYN PARK",
                "postCode": "5032",
                "isMetro": true
            },
            {
                "suburbId": 191,
                "suburbName": "BROUGHTON RIVER VALLEY",
                "postCode": "5454",
                "isMetro": false
            },
            {
                "suburbId": 192,
                "suburbName": "BROWN BEACH",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 193,
                "suburbName": "BROWN HILL CREEK",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 194,
                "suburbName": "BROWNLOW",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 195,
                "suburbName": "BROWNLOW KI",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 196,
                "suburbName": "BRUCE",
                "postCode": "5433",
                "isMetro": false
            },
            {
                "suburbId": 197,
                "suburbName": "BRUKUNGA",
                "postCode": "5252",
                "isMetro": false
            },
            {
                "suburbId": 198,
                "suburbName": "BUCHANAN",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 199,
                "suburbName": "BUCHFELDE",
                "postCode": "5118",
                "isMetro": false
            },
            {
                "suburbId": 200,
                "suburbName": "BUCKINGHAM",
                "postCode": "5270",
                "isMetro": false
            },
            {
                "suburbId": 201,
                "suburbName": "BUCKLAND PARK",
                "postCode": "5120",
                "isMetro": true
            },
            {
                "suburbId": 202,
                "suburbName": "BUCKLEBOO",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 203,
                "suburbName": "BUGLE HUT",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 204,
                "suburbName": "BUGLE RANGES",
                "postCode": "5251",
                "isMetro": false
            },
            {
                "suburbId": 205,
                "suburbName": "BULGUNNIA",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 206,
                "suburbName": "BULL CREEK",
                "postCode": "5157",
                "isMetro": false
            },
            {
                "suburbId": 207,
                "suburbName": "BULLOO CREEK",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 208,
                "suburbName": "BUMBUNGA",
                "postCode": "5520",
                "isMetro": false
            },
            {
                "suburbId": 209,
                "suburbName": "BUNBURY",
                "postCode": "5266",
                "isMetro": false
            },
            {
                "suburbId": 210,
                "suburbName": "BUNDALEER GARDENS",
                "postCode": "5491",
                "isMetro": false
            },
            {
                "suburbId": 211,
                "suburbName": "BUNDALEER NORTH",
                "postCode": "5491",
                "isMetro": false
            },
            {
                "suburbId": 212,
                "suburbName": "BUNDEY",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 213,
                "suburbName": "BUNGAMA",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 214,
                "suburbName": "BUNGAREE",
                "postCode": "5464",
                "isMetro": false
            },
            {
                "suburbId": 215,
                "suburbName": "BUNGEROO",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 216,
                "suburbName": "BUNYUNG",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 217,
                "suburbName": "BURDETT",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 218,
                "suburbName": "BURNSFIELD",
                "postCode": "5520",
                "isMetro": false
            },
            {
                "suburbId": 219,
                "suburbName": "BURNSIDE",
                "postCode": "5066",
                "isMetro": true
            },
            {
                "suburbId": 220,
                "suburbName": "BURR WELL",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 221,
                "suburbName": "BURRA",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 222,
                "suburbName": "BURRA EASTERN DISTRICTS",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 223,
                "suburbName": "BURRUNGULE",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 224,
                "suburbName": "BURTON",
                "postCode": "5110",
                "isMetro": true
            },
            {
                "suburbId": 225,
                "suburbName": "BUTE",
                "postCode": "5560",
                "isMetro": false
            },
            {
                "suburbId": 226,
                "suburbName": "BUTLER",
                "postCode": "5605",
                "isMetro": false
            },
            {
                "suburbId": 227,
                "suburbName": "CADELL",
                "postCode": "5321",
                "isMetro": false
            },
            {
                "suburbId": 228,
                "suburbName": "CADELL LAGOON",
                "postCode": "5321",
                "isMetro": false
            },
            {
                "suburbId": 229,
                "suburbName": "CADGEE",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 230,
                "suburbName": "CALCA",
                "postCode": "5671",
                "isMetro": false
            },
            {
                "suburbId": 231,
                "suburbName": "CALIPH",
                "postCode": "5310",
                "isMetro": false
            },
            {
                "suburbId": 232,
                "suburbName": "CALLANNA",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 233,
                "suburbName": "CALLINGTON",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 234,
                "suburbName": "CALOMBA",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 235,
                "suburbName": "CALOOTE",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 236,
                "suburbName": "CALPERUM STATION",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 237,
                "suburbName": "CALTOWIE",
                "postCode": "5490",
                "isMetro": false
            },
            {
                "suburbId": 238,
                "suburbName": "CALTOWIE NORTH",
                "postCode": "5490",
                "isMetro": false
            },
            {
                "suburbId": 239,
                "suburbName": "CALTOWIE WEST",
                "postCode": "5490",
                "isMetro": false
            },
            {
                "suburbId": 240,
                "suburbName": "CAMBRAI",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 241,
                "suburbName": "CAMDEN PARK",
                "postCode": "5038",
                "isMetro": true
            },
            {
                "suburbId": 242,
                "suburbName": "CAMPBELLTOWN",
                "postCode": "5074",
                "isMetro": true
            },
            {
                "suburbId": 243,
                "suburbName": "CAMPOONA",
                "postCode": "5640",
                "isMetro": false
            },
            {
                "suburbId": 244,
                "suburbName": "CANEGRASS",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 245,
                "suburbName": "CANNAWIGARA",
                "postCode": "5268",
                "isMetro": false
            },
            {
                "suburbId": 246,
                "suburbName": "CANOWIE",
                "postCode": "5419",
                "isMetro": false
            },
            {
                "suburbId": 247,
                "suburbName": "CANOWIE BELT",
                "postCode": "5420",
                "isMetro": false
            },
            {
                "suburbId": 248,
                "suburbName": "CANUNDA",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 249,
                "suburbName": "CAPE BORDA",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 250,
                "suburbName": "CAPE DOUGLAS",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 251,
                "suburbName": "CAPE JAFFA",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 252,
                "suburbName": "CAPE JERVIS",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 253,
                "suburbName": "CARALUE",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 254,
                "suburbName": "CARAWA",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 255,
                "suburbName": "CARCUMA",
                "postCode": "5301",
                "isMetro": false
            },
            {
                "suburbId": 256,
                "suburbName": "CAREW",
                "postCode": "5270",
                "isMetro": false
            },
            {
                "suburbId": 257,
                "suburbName": "CAREY GULLY",
                "postCode": "5144",
                "isMetro": true
            },
            {
                "suburbId": 258,
                "suburbName": "CAROLINE",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 259,
                "suburbName": "CARPENTER ROCKS",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 260,
                "suburbName": "CARRICKALINGA",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 261,
                "suburbName": "CARRIETON",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 262,
                "suburbName": "CARRIEWERLOO",
                "postCode": "5715",
                "isMetro": false
            },
            {
                "suburbId": 263,
                "suburbName": "CASSINI",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 264,
                "suburbName": "CASTAMBUL",
                "postCode": "5076",
                "isMetro": true
            },
            {
                "suburbId": 265,
                "suburbName": "CAURNAMONT",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 266,
                "suburbName": "CAVAN",
                "postCode": "5094",
                "isMetro": true
            },
            {
                "suburbId": 267,
                "suburbName": "CAVENAGH",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 268,
                "suburbName": "CAVETON",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 269,
                "suburbName": "CEDUNA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 270,
                "suburbName": "CEDUNA WATERS",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 271,
                "suburbName": "CHAFFEY",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 272,
                "suburbName": "CHAIN OF PONDS",
                "postCode": "5231",
                "isMetro": true
            },
            {
                "suburbId": 273,
                "suburbName": "CHANDADA",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 274,
                "suburbName": "CHANDLERS HILL",
                "postCode": "5159",
                "isMetro": true
            },
            {
                "suburbId": 275,
                "suburbName": "CHAPEL HILL",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 276,
                "suburbName": "CHAPMAN BORE",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 277,
                "suburbName": "CHARLESTON",
                "postCode": "5244",
                "isMetro": true
            },
            {
                "suburbId": 278,
                "suburbName": "CHARLTON GULLY",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 279,
                "suburbName": "CHARRA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 280,
                "suburbName": "CHELTENHAM",
                "postCode": "5014",
                "isMetro": true
            },
            {
                "suburbId": 281,
                "suburbName": "CHERRY GARDENS",
                "postCode": "5157",
                "isMetro": true
            },
            {
                "suburbId": 282,
                "suburbName": "CHERRYVILLE",
                "postCode": "5134",
                "isMetro": true
            },
            {
                "suburbId": 283,
                "suburbName": "CHILPENUNDA",
                "postCode": "5660",
                "isMetro": false
            },
            {
                "suburbId": 284,
                "suburbName": "CHINAMAN WELLS",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 285,
                "suburbName": "CHINBINGINA",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 286,
                "suburbName": "CHITON",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 287,
                "suburbName": "CHOWILLA",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 288,
                "suburbName": "CHRISTIE DOWNS",
                "postCode": "5164",
                "isMetro": true
            },
            {
                "suburbId": 289,
                "suburbName": "CHRISTIES BEACH",
                "postCode": "5165",
                "isMetro": true
            },
            {
                "suburbId": 290,
                "suburbName": "CHUNDARIA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 291,
                "suburbName": "CLAPHAM",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 292,
                "suburbName": "CLARE",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 293,
                "suburbName": "CLARENCE GARDENS",
                "postCode": "5039",
                "isMetro": true
            },
            {
                "suburbId": 294,
                "suburbName": "CLARENCE PARK",
                "postCode": "5034",
                "isMetro": true
            },
            {
                "suburbId": 295,
                "suburbName": "CLARENDON",
                "postCode": "5157",
                "isMetro": true
            },
            {
                "suburbId": 296,
                "suburbName": "CLAY WELLS",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 297,
                "suburbName": "CLAYPANS",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 298,
                "suburbName": "CLAYTON BAY",
                "postCode": "5256",
                "isMetro": false
            },
            {
                "suburbId": 299,
                "suburbName": "CLAYTON STATION",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 300,
                "suburbName": "CLEARVIEW",
                "postCode": "5085",
                "isMetro": true
            },
            {
                "suburbId": 301,
                "suburbName": "CLELAND",
                "postCode": "5152",
                "isMetro": true
            },
            {
                "suburbId": 302,
                "suburbName": "CLEMENTS GAP",
                "postCode": "5523",
                "isMetro": false
            },
            {
                "suburbId": 303,
                "suburbName": "CLEVE",
                "postCode": "5640",
                "isMetro": false
            },
            {
                "suburbId": 304,
                "suburbName": "CLIFTON HILLS STATION",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 305,
                "suburbName": "CLINTON",
                "postCode": "5570",
                "isMetro": false
            },
            {
                "suburbId": 306,
                "suburbName": "CLINTON CENTRE",
                "postCode": "5570",
                "isMetro": false
            },
            {
                "suburbId": 307,
                "suburbName": "CLOVELLY PARK",
                "postCode": "5042",
                "isMetro": true
            },
            {
                "suburbId": 308,
                "suburbName": "COBDOGLA",
                "postCode": "5346",
                "isMetro": false
            },
            {
                "suburbId": 309,
                "suburbName": "COCATA",
                "postCode": "5654",
                "isMetro": false
            },
            {
                "suburbId": 310,
                "suburbName": "COCKALEECHIE",
                "postCode": "5631",
                "isMetro": false
            },
            {
                "suburbId": 311,
                "suburbName": "COCKATOO VALLEY",
                "postCode": "5351",
                "isMetro": true
            },
            {
                "suburbId": 312,
                "suburbName": "COCKBURN",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 313,
                "suburbName": "COFFIN BAY",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 314,
                "suburbName": "COLEBATCH",
                "postCode": "5266",
                "isMetro": false
            },
            {
                "suburbId": 315,
                "suburbName": "COLES",
                "postCode": "5272",
                "isMetro": false
            },
            {
                "suburbId": 316,
                "suburbName": "COLLEGE PARK",
                "postCode": "5069",
                "isMetro": true
            },
            {
                "suburbId": 317,
                "suburbName": "COLLEY",
                "postCode": "5671",
                "isMetro": false
            },
            {
                "suburbId": 318,
                "suburbName": "COLLINSFIELD",
                "postCode": "5555",
                "isMetro": false
            },
            {
                "suburbId": 319,
                "suburbName": "COLLINSVILLE",
                "postCode": "5418",
                "isMetro": false
            },
            {
                "suburbId": 320,
                "suburbName": "COLLINSWOOD",
                "postCode": "5081",
                "isMetro": true
            },
            {
                "suburbId": 321,
                "suburbName": "COLONEL LIGHT GARDENS",
                "postCode": "5041",
                "isMetro": true
            },
            {
                "suburbId": 322,
                "suburbName": "COLTON",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 323,
                "suburbName": "COMAUM",
                "postCode": "5277",
                "isMetro": false
            },
            {
                "suburbId": 324,
                "suburbName": "COMMISSARIAT POINT",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 325,
                "suburbName": "COMMONWEALTH HILL",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 326,
                "suburbName": "COMPTON",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 327,
                "suburbName": "CONCORDIA",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 328,
                "suburbName": "CONDOWIE",
                "postCode": "5464",
                "isMetro": false
            },
            {
                "suburbId": 329,
                "suburbName": "CONMURRA",
                "postCode": "5272",
                "isMetro": false
            },
            {
                "suburbId": 330,
                "suburbName": "COOBER PEDY",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 331,
                "suburbName": "COOBOWIE",
                "postCode": "5583",
                "isMetro": false
            },
            {
                "suburbId": 332,
                "suburbName": "COOKE PLAINS",
                "postCode": "5261",
                "isMetro": false
            },
            {
                "suburbId": 333,
                "suburbName": "COOLILLIE",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 334,
                "suburbName": "COOLTONG",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 335,
                "suburbName": "COOMANDOOK",
                "postCode": "5261",
                "isMetro": false
            },
            {
                "suburbId": 336,
                "suburbName": "COOMBE",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 337,
                "suburbName": "COOMOOROO",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 338,
                "suburbName": "COOMUNGA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 339,
                "suburbName": "COONALPYN",
                "postCode": "5265",
                "isMetro": false
            },
            {
                "suburbId": 340,
                "suburbName": "COONAMIA",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 341,
                "suburbName": "COONAWARRA",
                "postCode": "5263",
                "isMetro": false
            },
            {
                "suburbId": 342,
                "suburbName": "COONDAMBO",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 343,
                "suburbName": "COORABIE",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 344,
                "suburbName": "COORONG",
                "postCode": "5264",
                "isMetro": false
            },
            {
                "suburbId": 345,
                "suburbName": "COOTRA",
                "postCode": "5650",
                "isMetro": false
            },
            {
                "suburbId": 346,
                "suburbName": "COOYERDOO",
                "postCode": "5611",
                "isMetro": false
            },
            {
                "suburbId": 347,
                "suburbName": "COPEVILLE",
                "postCode": "5308",
                "isMetro": false
            },
            {
                "suburbId": 348,
                "suburbName": "COPLEY",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 349,
                "suburbName": "CORDILLO DOWNS",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 350,
                "suburbName": "CORNY POINT",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 351,
                "suburbName": "COROMANDEL EAST",
                "postCode": "5157",
                "isMetro": true
            },
            {
                "suburbId": 352,
                "suburbName": "COROMANDEL VALLEY",
                "postCode": "5051",
                "isMetro": true
            },
            {
                "suburbId": 353,
                "suburbName": "CORTLINYE",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 354,
                "suburbName": "CORUNNA STATION",
                "postCode": "5611",
                "isMetro": false
            },
            {
                "suburbId": 355,
                "suburbName": "COUCH BEACH",
                "postCode": "5577",
                "isMetro": false
            },
            {
                "suburbId": 356,
                "suburbName": "COULTA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 357,
                "suburbName": "COWANDILLA",
                "postCode": "5033",
                "isMetro": true
            },
            {
                "suburbId": 358,
                "suburbName": "COWARIE",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 359,
                "suburbName": "COWELL",
                "postCode": "5602",
                "isMetro": false
            },
            {
                "suburbId": 360,
                "suburbName": "COWIRRA",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 361,
                "suburbName": "COWLEDS LANDING",
                "postCode": "5609",
                "isMetro": false
            },
            {
                "suburbId": 362,
                "suburbName": "CRADOCK",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 363,
                "suburbName": "CRAFERS",
                "postCode": "5152",
                "isMetro": true
            },
            {
                "suburbId": 364,
                "suburbName": "CRAFERS WEST",
                "postCode": "5152",
                "isMetro": true
            },
            {
                "suburbId": 365,
                "suburbName": "CRAIGBURN FARM",
                "postCode": "5051",
                "isMetro": true
            },
            {
                "suburbId": 366,
                "suburbName": "CRAIGMORE",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 367,
                "suburbName": "CRESCENT",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 368,
                "suburbName": "CROMER",
                "postCode": "5235",
                "isMetro": false
            },
            {
                "suburbId": 369,
                "suburbName": "CROSS ROADS",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 370,
                "suburbName": "CROWN POINT",
                "postCode": "5734",
                "isMetro": false
            },
            {
                "suburbId": 371,
                "suburbName": "CROYDON",
                "postCode": "5008",
                "isMetro": true
            },
            {
                "suburbId": 372,
                "suburbName": "CROYDON PARK",
                "postCode": "5008",
                "isMetro": true
            },
            {
                "suburbId": 373,
                "suburbName": "CRYSTAL BROOK",
                "postCode": "5523",
                "isMetro": false
            },
            {
                "suburbId": 374,
                "suburbName": "CUDLEE CREEK",
                "postCode": "5232",
                "isMetro": true
            },
            {
                "suburbId": 375,
                "suburbName": "CULBURRA",
                "postCode": "5261",
                "isMetro": false
            },
            {
                "suburbId": 376,
                "suburbName": "CULTANA",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 377,
                "suburbName": "CUMBERLAND PARK",
                "postCode": "5041",
                "isMetro": true
            },
            {
                "suburbId": 378,
                "suburbName": "CUMMINS",
                "postCode": "5631",
                "isMetro": false
            },
            {
                "suburbId": 379,
                "suburbName": "CUNGENA",
                "postCode": "5660",
                "isMetro": false
            },
            {
                "suburbId": 380,
                "suburbName": "CUNLIFFE",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 381,
                "suburbName": "CUNNINGHAM",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 382,
                "suburbName": "CUNYARIE",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 383,
                "suburbName": "CURNAMONA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 384,
                "suburbName": "CURRAMULKA",
                "postCode": "5580",
                "isMetro": false
            },
            {
                "suburbId": 385,
                "suburbName": "CURRENCY CREEK",
                "postCode": "5214",
                "isMetro": false
            },
            {
                "suburbId": 386,
                "suburbName": "CUSTON",
                "postCode": "5269",
                "isMetro": false
            },
            {
                "suburbId": 387,
                "suburbName": "CUTTLEFISH BAY",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 388,
                "suburbName": "CYGNET RIVER",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 389,
                "suburbName": "DALKEY",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 390,
                "suburbName": "DANGGALI",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 391,
                "suburbName": "DARKE PEAK",
                "postCode": "5642",
                "isMetro": false
            },
            {
                "suburbId": 392,
                "suburbName": "DARLINGTON",
                "postCode": "5047",
                "isMetro": true
            },
            {
                "suburbId": 393,
                "suburbName": "DAVENPORT",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 394,
                "suburbName": "DAVEYSTON",
                "postCode": "5355",
                "isMetro": false
            },
            {
                "suburbId": 395,
                "suburbName": "DAVOREN PARK",
                "postCode": "5113",
                "isMetro": true
            },
            {
                "suburbId": 396,
                "suburbName": "DAW PARK",
                "postCode": "5041",
                "isMetro": true
            },
            {
                "suburbId": 397,
                "suburbName": "DAWESLEY",
                "postCode": "5252",
                "isMetro": false
            },
            {
                "suburbId": 398,
                "suburbName": "DAWSON",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 399,
                "suburbName": "DE MOLE RIVER",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 400,
                "suburbName": "DE ROSE HILL",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 401,
                "suburbName": "DEEP CREEK",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 402,
                "suburbName": "DEEPWATER",
                "postCode": "5266",
                "isMetro": false
            },
            {
                "suburbId": 403,
                "suburbName": "DELAMERE",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 404,
                "suburbName": "DENIAL BAY",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 405,
                "suburbName": "DERNANCOURT",
                "postCode": "5075",
                "isMetro": true
            },
            {
                "suburbId": 406,
                "suburbName": "D'ESTREES BAY",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 407,
                "suburbName": "DEVLINS POUND",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 408,
                "suburbName": "DEVON PARK",
                "postCode": "5008",
                "isMetro": true
            },
            {
                "suburbId": 409,
                "suburbName": "DEVONBOROUGH DOWNS",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 410,
                "suburbName": "DINGABLEDINGA",
                "postCode": "5172",
                "isMetro": false
            },
            {
                "suburbId": 411,
                "suburbName": "DIREK",
                "postCode": "5110",
                "isMetro": true
            },
            {
                "suburbId": 412,
                "suburbName": "DISMAL SWAMP",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 413,
                "suburbName": "DONOVANS",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 414,
                "suburbName": "DORSET VALE",
                "postCode": "5157",
                "isMetro": true
            },
            {
                "suburbId": 415,
                "suburbName": "DOUGLAS POINT",
                "postCode": "5601",
                "isMetro": false
            },
            {
                "suburbId": 416,
                "suburbName": "DOUGLAS POINT SOUTH",
                "postCode": "5601",
                "isMetro": false
            },
            {
                "suburbId": 417,
                "suburbName": "DOVER GARDENS",
                "postCode": "5048",
                "isMetro": true
            },
            {
                "suburbId": 418,
                "suburbName": "DOWLINGVILLE",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 419,
                "suburbName": "DRY CREEK",
                "postCode": "5094",
                "isMetro": true
            },
            {
                "suburbId": 420,
                "suburbName": "DUBLIN",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 421,
                "suburbName": "DUCK PONDS",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 422,
                "suburbName": "DUDLEY EAST",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 423,
                "suburbName": "DUDLEY PARK",
                "postCode": "5008",
                "isMetro": true
            },
            {
                "suburbId": 424,
                "suburbName": "DUDLEY WEST",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 425,
                "suburbName": "DULKANINNA",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 426,
                "suburbName": "DULWICH",
                "postCode": "5065",
                "isMetro": true
            },
            {
                "suburbId": 427,
                "suburbName": "DUNCAN",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 428,
                "suburbName": "DUTTON",
                "postCode": "5356",
                "isMetro": false
            },
            {
                "suburbId": 429,
                "suburbName": "DUTTON EAST",
                "postCode": "5356",
                "isMetro": false
            },
            {
                "suburbId": 430,
                "suburbName": "EAST MOONTA",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 431,
                "suburbName": "EASTWOOD",
                "postCode": "5063",
                "isMetro": true
            },
            {
                "suburbId": 432,
                "suburbName": "EBA",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 433,
                "suburbName": "EBA ANCHORAGE",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 434,
                "suburbName": "EBENEZER",
                "postCode": "5355",
                "isMetro": false
            },
            {
                "suburbId": 435,
                "suburbName": "ECHUNGA",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 436,
                "suburbName": "EDEN HILLS",
                "postCode": "5050",
                "isMetro": true
            },
            {
                "suburbId": 437,
                "suburbName": "EDEN VALLEY",
                "postCode": "5235",
                "isMetro": false
            },
            {
                "suburbId": 438,
                "suburbName": "EDIACARA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 439,
                "suburbName": "EDILLILIE",
                "postCode": "5630",
                "isMetro": false
            },
            {
                "suburbId": 440,
                "suburbName": "EDINBURGH",
                "postCode": "5111",
                "isMetro": true
            },
            {
                "suburbId": 441,
                "suburbName": "EDINBURGH NORTH",
                "postCode": "5113",
                "isMetro": true
            },
            {
                "suburbId": 442,
                "suburbName": "EDITHBURGH",
                "postCode": "5583",
                "isMetro": false
            },
            {
                "suburbId": 443,
                "suburbName": "EDWARDSTOWN",
                "postCode": "5039",
                "isMetro": true
            },
            {
                "suburbId": 444,
                "suburbName": "EIGHT MILE CREEK",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 445,
                "suburbName": "ELIZABETH",
                "postCode": "5112",
                "isMetro": true
            },
            {
                "suburbId": 446,
                "suburbName": "ELIZABETH DOWNS",
                "postCode": "5113",
                "isMetro": true
            },
            {
                "suburbId": 447,
                "suburbName": "ELIZABETH EAST",
                "postCode": "5112",
                "isMetro": true
            },
            {
                "suburbId": 448,
                "suburbName": "ELIZABETH GROVE",
                "postCode": "5112",
                "isMetro": true
            },
            {
                "suburbId": 449,
                "suburbName": "ELIZABETH NORTH",
                "postCode": "5113",
                "isMetro": true
            },
            {
                "suburbId": 450,
                "suburbName": "ELIZABETH PARK",
                "postCode": "5113",
                "isMetro": true
            },
            {
                "suburbId": 451,
                "suburbName": "ELIZABETH SOUTH",
                "postCode": "5112",
                "isMetro": true
            },
            {
                "suburbId": 452,
                "suburbName": "ELIZABETH VALE",
                "postCode": "5112",
                "isMetro": true
            },
            {
                "suburbId": 453,
                "suburbName": "ELLISTON",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 454,
                "suburbName": "ELWOMPLE",
                "postCode": "5260",
                "isMetro": false
            },
            {
                "suburbId": 455,
                "suburbName": "EMEROO",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 456,
                "suburbName": "EMU BAY",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 457,
                "suburbName": "EMU DOWNS",
                "postCode": "5381",
                "isMetro": false
            },
            {
                "suburbId": 458,
                "suburbName": "EMU FLAT",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 459,
                "suburbName": "ENCOUNTER BAY",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 460,
                "suburbName": "ENFIELD",
                "postCode": "5085",
                "isMetro": true
            },
            {
                "suburbId": 461,
                "suburbName": "ERINDALE",
                "postCode": "5066",
                "isMetro": true
            },
            {
                "suburbId": 462,
                "suburbName": "ERINGA",
                "postCode": "5734",
                "isMetro": false
            },
            {
                "suburbId": 463,
                "suburbName": "ERITH",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 464,
                "suburbName": "ERSKINE",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 465,
                "suburbName": "ERUDINA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 466,
                "suburbName": "ETADUNNA",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 467,
                "suburbName": "ETHELTON",
                "postCode": "5015",
                "isMetro": true
            },
            {
                "suburbId": 468,
                "suburbName": "ETTRICK",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 469,
                "suburbName": "EUDUNDA",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 470,
                "suburbName": "EURELIA",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 471,
                "suburbName": "EUROMINA",
                "postCode": "5454",
                "isMetro": false
            },
            {
                "suburbId": 472,
                "suburbName": "EVANDALE",
                "postCode": "5069",
                "isMetro": true
            },
            {
                "suburbId": 473,
                "suburbName": "EVANSTON",
                "postCode": "5116",
                "isMetro": true
            },
            {
                "suburbId": 474,
                "suburbName": "EVANSTON GARDENS",
                "postCode": "5116",
                "isMetro": true
            },
            {
                "suburbId": 475,
                "suburbName": "EVANSTON PARK",
                "postCode": "5116",
                "isMetro": true
            },
            {
                "suburbId": 476,
                "suburbName": "EVANSTON SOUTH",
                "postCode": "5116",
                "isMetro": true
            },
            {
                "suburbId": 477,
                "suburbName": "EVELYN DOWNS",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 478,
                "suburbName": "EVERARD CENTRAL",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 479,
                "suburbName": "EVERARD PARK",
                "postCode": "5035",
                "isMetro": true
            },
            {
                "suburbId": 480,
                "suburbName": "EXETER",
                "postCode": "5019",
                "isMetro": true
            },
            {
                "suburbId": 481,
                "suburbName": "EYRE",
                "postCode": "5121",
                "isMetro": true
            },
            {
                "suburbId": 482,
                "suburbName": "FAIRVIEW PARK",
                "postCode": "5126",
                "isMetro": true
            },
            {
                "suburbId": 483,
                "suburbName": "FALSE BAY",
                "postCode": "5601",
                "isMetro": false
            },
            {
                "suburbId": 484,
                "suburbName": "FARAWAY HILL",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 485,
                "suburbName": "FARINA",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 486,
                "suburbName": "FARINA STATION",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 487,
                "suburbName": "FARM BEACH",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 488,
                "suburbName": "FARRELL FLAT",
                "postCode": "5416",
                "isMetro": false
            },
            {
                "suburbId": 489,
                "suburbName": "FELIXSTOW",
                "postCode": "5070",
                "isMetro": true
            },
            {
                "suburbId": 490,
                "suburbName": "FERRYDEN PARK",
                "postCode": "5010",
                "isMetro": true
            },
            {
                "suburbId": 491,
                "suburbName": "FIELD",
                "postCode": "5265",
                "isMetro": false
            },
            {
                "suburbId": 492,
                "suburbName": "FINDON",
                "postCode": "5023",
                "isMetro": true
            },
            {
                "suburbId": 493,
                "suburbName": "FINNISS",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 494,
                "suburbName": "FIRLE",
                "postCode": "5070",
                "isMetro": true
            },
            {
                "suburbId": 495,
                "suburbName": "FISCHER",
                "postCode": "5502",
                "isMetro": false
            },
            {
                "suburbId": 496,
                "suburbName": "FISHER",
                "postCode": "5354",
                "isMetro": false
            },
            {
                "suburbId": 497,
                "suburbName": "FISHERMAN BAY",
                "postCode": "5522",
                "isMetro": false
            },
            {
                "suburbId": 498,
                "suburbName": "FITZGERALD BAY",
                "postCode": "5601",
                "isMetro": false
            },
            {
                "suburbId": 499,
                "suburbName": "FITZROY",
                "postCode": "5082",
                "isMetro": true
            },
            {
                "suburbId": 500,
                "suburbName": "FIVE MILES",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 501,
                "suburbName": "FLAGSTAFF HILL",
                "postCode": "5159",
                "isMetro": true
            },
            {
                "suburbId": 502,
                "suburbName": "FLAXLEY",
                "postCode": "5153",
                "isMetro": false
            },
            {
                "suburbId": 503,
                "suburbName": "FLAXMAN VALLEY",
                "postCode": "5235",
                "isMetro": false
            },
            {
                "suburbId": 504,
                "suburbName": "FLINDERS CHASE",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 505,
                "suburbName": "FLINDERS PARK",
                "postCode": "5025",
                "isMetro": true
            },
            {
                "suburbId": 506,
                "suburbName": "FLINDERS RANGES",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 507,
                "suburbName": "FLORINA STATION",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 508,
                "suburbName": "FORDS",
                "postCode": "5373",
                "isMetro": false
            },
            {
                "suburbId": 509,
                "suburbName": "FOREST RANGE",
                "postCode": "5139",
                "isMetro": true
            },
            {
                "suburbId": 510,
                "suburbName": "FORESTVILLE",
                "postCode": "5035",
                "isMetro": true
            },
            {
                "suburbId": 511,
                "suburbName": "FORRESTON",
                "postCode": "5233",
                "isMetro": true
            },
            {
                "suburbId": 512,
                "suburbName": "FORSTER",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 513,
                "suburbName": "FOUL BAY",
                "postCode": "5577",
                "isMetro": false
            },
            {
                "suburbId": 514,
                "suburbName": "FOUNTAIN",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 515,
                "suburbName": "FOWLERS BAY",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 516,
                "suburbName": "FOX",
                "postCode": "5272",
                "isMetro": false
            },
            {
                "suburbId": 517,
                "suburbName": "FRAHNS",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 518,
                "suburbName": "FRANCES",
                "postCode": "5262",
                "isMetro": false
            },
            {
                "suburbId": 519,
                "suburbName": "FRANKLYN",
                "postCode": "5421",
                "isMetro": false
            },
            {
                "suburbId": 520,
                "suburbName": "FRANKTON",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 521,
                "suburbName": "FRAYVILLE",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 522,
                "suburbName": "FREELING",
                "postCode": "5372",
                "isMetro": false
            },
            {
                "suburbId": 523,
                "suburbName": "FREWVILLE",
                "postCode": "5063",
                "isMetro": true
            },
            {
                "suburbId": 524,
                "suburbName": "FROME DOWNS",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 525,
                "suburbName": "FULHAM",
                "postCode": "5024",
                "isMetro": true
            },
            {
                "suburbId": 526,
                "suburbName": "FULHAM GARDENS",
                "postCode": "5024",
                "isMetro": true
            },
            {
                "suburbId": 527,
                "suburbName": "FULLARTON",
                "postCode": "5063",
                "isMetro": true
            },
            {
                "suburbId": 528,
                "suburbName": "FURNER",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 529,
                "suburbName": "GALGA",
                "postCode": "5308",
                "isMetro": false
            },
            {
                "suburbId": 530,
                "suburbName": "GAMMON RANGES",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 531,
                "suburbName": "GARDEN ISLAND",
                "postCode": "5960",
                "isMetro": true
            },
            {
                "suburbId": 532,
                "suburbName": "GAWLER",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 533,
                "suburbName": "GAWLER BELT",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 534,
                "suburbName": "GAWLER EAST",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 535,
                "suburbName": "GAWLER RANGES",
                "postCode": "5655",
                "isMetro": false
            },
            {
                "suburbId": 536,
                "suburbName": "GAWLER RIVER",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 537,
                "suburbName": "GAWLER SOUTH",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 538,
                "suburbName": "GAWLER WEST",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 539,
                "suburbName": "GEMMELLS",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 540,
                "suburbName": "GEORGETOWN",
                "postCode": "5472",
                "isMetro": false
            },
            {
                "suburbId": 541,
                "suburbName": "GEPPS CROSS",
                "postCode": "5094",
                "isMetro": true
            },
            {
                "suburbId": 542,
                "suburbName": "GERANIUM",
                "postCode": "5301",
                "isMetro": false
            },
            {
                "suburbId": 543,
                "suburbName": "GERANIUM PLAINS",
                "postCode": "5381",
                "isMetro": false
            },
            {
                "suburbId": 544,
                "suburbName": "GERARD",
                "postCode": "5343",
                "isMetro": false
            },
            {
                "suburbId": 545,
                "suburbName": "GERMAN CREEK",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 546,
                "suburbName": "GERMAN FLAT",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 547,
                "suburbName": "GERMEIN BAY",
                "postCode": "5495",
                "isMetro": false
            },
            {
                "suburbId": 548,
                "suburbName": "GIDGEALPA",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 549,
                "suburbName": "GIFFORD HILL",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 550,
                "suburbName": "GILBERTON",
                "postCode": "5081",
                "isMetro": true
            },
            {
                "suburbId": 551,
                "suburbName": "GILES CORNER",
                "postCode": "5411",
                "isMetro": false
            },
            {
                "suburbId": 552,
                "suburbName": "GILLENTOWN",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 553,
                "suburbName": "GILLES DOWNS",
                "postCode": "5611",
                "isMetro": false
            },
            {
                "suburbId": 554,
                "suburbName": "GILLES PLAINS",
                "postCode": "5086",
                "isMetro": true
            },
            {
                "suburbId": 555,
                "suburbName": "GILLMAN",
                "postCode": "5013",
                "isMetro": true
            },
            {
                "suburbId": 556,
                "suburbName": "GLADSTONE",
                "postCode": "5473",
                "isMetro": false
            },
            {
                "suburbId": 557,
                "suburbName": "GLANDORE",
                "postCode": "5037",
                "isMetro": true
            },
            {
                "suburbId": 558,
                "suburbName": "GLANVILLE",
                "postCode": "5015",
                "isMetro": true
            },
            {
                "suburbId": 559,
                "suburbName": "GLEN OSMOND",
                "postCode": "5064",
                "isMetro": true
            },
            {
                "suburbId": 560,
                "suburbName": "GLENALTA",
                "postCode": "5052",
                "isMetro": true
            },
            {
                "suburbId": 561,
                "suburbName": "GLENBURNIE",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 562,
                "suburbName": "GLENCOE",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 563,
                "suburbName": "GLENDAMBO",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 564,
                "suburbName": "GLENELG",
                "postCode": "5045",
                "isMetro": true
            },
            {
                "suburbId": 565,
                "suburbName": "GLENELG EAST",
                "postCode": "5045",
                "isMetro": true
            },
            {
                "suburbId": 566,
                "suburbName": "GLENELG NORTH",
                "postCode": "5045",
                "isMetro": true
            },
            {
                "suburbId": 567,
                "suburbName": "GLENELG SOUTH",
                "postCode": "5045",
                "isMetro": true
            },
            {
                "suburbId": 568,
                "suburbName": "GLENGOWRIE",
                "postCode": "5044",
                "isMetro": true
            },
            {
                "suburbId": 569,
                "suburbName": "GLENROY",
                "postCode": "5277",
                "isMetro": false
            },
            {
                "suburbId": 570,
                "suburbName": "GLENSIDE",
                "postCode": "5065",
                "isMetro": true
            },
            {
                "suburbId": 571,
                "suburbName": "GLENUNGA",
                "postCode": "5064",
                "isMetro": true
            },
            {
                "suburbId": 572,
                "suburbName": "GLOBE DERBY PARK",
                "postCode": "5110",
                "isMetro": true
            },
            {
                "suburbId": 573,
                "suburbName": "GLOSSOP",
                "postCode": "5344",
                "isMetro": false
            },
            {
                "suburbId": 574,
                "suburbName": "GLUEPOT",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 575,
                "suburbName": "GLYNDE",
                "postCode": "5070",
                "isMetro": true
            },
            {
                "suburbId": 576,
                "suburbName": "GOLDEN GROVE",
                "postCode": "5125",
                "isMetro": true
            },
            {
                "suburbId": 577,
                "suburbName": "GOLDEN HEIGHTS",
                "postCode": "5322",
                "isMetro": false
            },
            {
                "suburbId": 578,
                "suburbName": "GOMERSAL",
                "postCode": "5352",
                "isMetro": true
            },
            {
                "suburbId": 579,
                "suburbName": "GOOD HOPE LANDING",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 580,
                "suburbName": "GOODWOOD",
                "postCode": "5034",
                "isMetro": true
            },
            {
                "suburbId": 581,
                "suburbName": "GOOLWA",
                "postCode": "5214",
                "isMetro": false
            },
            {
                "suburbId": 582,
                "suburbName": "GOOLWA BEACH",
                "postCode": "5214",
                "isMetro": false
            },
            {
                "suburbId": 583,
                "suburbName": "GOOLWA NORTH",
                "postCode": "5214",
                "isMetro": false
            },
            {
                "suburbId": 584,
                "suburbName": "GOOLWA SOUTH",
                "postCode": "5214",
                "isMetro": false
            },
            {
                "suburbId": 585,
                "suburbName": "GOSSE",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 586,
                "suburbName": "GOULD CREEK",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 587,
                "suburbName": "GOYDER",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 588,
                "suburbName": "GRACE PLAINS",
                "postCode": "5502",
                "isMetro": false
            },
            {
                "suburbId": 589,
                "suburbName": "GRAMPUS",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 590,
                "suburbName": "GRANGE",
                "postCode": "5022",
                "isMetro": true
            },
            {
                "suburbId": 591,
                "suburbName": "GREEN FIELDS",
                "postCode": "5107",
                "isMetro": true
            },
            {
                "suburbId": 592,
                "suburbName": "GREEN HILLS RANGE",
                "postCode": "5153",
                "isMetro": false
            },
            {
                "suburbId": 593,
                "suburbName": "GREEN PATCH",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 594,
                "suburbName": "GREENACRES",
                "postCode": "5086",
                "isMetro": true
            },
            {
                "suburbId": 595,
                "suburbName": "GREENBANKS",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 596,
                "suburbName": "GREENHILL",
                "postCode": "5140",
                "isMetro": true
            },
            {
                "suburbId": 597,
                "suburbName": "GREENOCK",
                "postCode": "5360",
                "isMetro": true
            },
            {
                "suburbId": 598,
                "suburbName": "GREENWAYS",
                "postCode": "5272",
                "isMetro": false
            },
            {
                "suburbId": 599,
                "suburbName": "GREENWITH",
                "postCode": "5125",
                "isMetro": true
            },
            {
                "suburbId": 600,
                "suburbName": "GULFVIEW HEIGHTS",
                "postCode": "5096",
                "isMetro": true
            },
            {
                "suburbId": 601,
                "suburbName": "GULNARE",
                "postCode": "5471",
                "isMetro": false
            },
            {
                "suburbId": 602,
                "suburbName": "GUM CREEK",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 603,
                "suburbName": "GUM CREEK STATION",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 604,
                "suburbName": "GUMERACHA",
                "postCode": "5233",
                "isMetro": true
            },
            {
                "suburbId": 605,
                "suburbName": "GURRA GURRA",
                "postCode": "5343",
                "isMetro": false
            },
            {
                "suburbId": 606,
                "suburbName": "HACKHAM",
                "postCode": "5163",
                "isMetro": true
            },
            {
                "suburbId": 607,
                "suburbName": "HACKHAM WEST",
                "postCode": "5163",
                "isMetro": true
            },
            {
                "suburbId": 608,
                "suburbName": "HACKLINS CORNER",
                "postCode": "5454",
                "isMetro": false
            },
            {
                "suburbId": 609,
                "suburbName": "HACKNEY",
                "postCode": "5069",
                "isMetro": true
            },
            {
                "suburbId": 610,
                "suburbName": "HAHNDORF",
                "postCode": "5245",
                "isMetro": true
            },
            {
                "suburbId": 611,
                "suburbName": "HAINES",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 612,
                "suburbName": "HALBURY",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 613,
                "suburbName": "HALIDON",
                "postCode": "5309",
                "isMetro": false
            },
            {
                "suburbId": 614,
                "suburbName": "HALLELUJAH HILLS",
                "postCode": "5381",
                "isMetro": false
            },
            {
                "suburbId": 615,
                "suburbName": "HALLETT",
                "postCode": "5419",
                "isMetro": false
            },
            {
                "suburbId": 616,
                "suburbName": "HALLETT COVE",
                "postCode": "5158",
                "isMetro": true
            },
            {
                "suburbId": 617,
                "suburbName": "HAMBIDGE",
                "postCode": "5642",
                "isMetro": false
            },
            {
                "suburbId": 618,
                "suburbName": "HAMILTON",
                "postCode": "5373",
                "isMetro": false
            },
            {
                "suburbId": 619,
                "suburbName": "HAMLEY",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 620,
                "suburbName": "HAMLEY BRIDGE",
                "postCode": "5401",
                "isMetro": false
            },
            {
                "suburbId": 621,
                "suburbName": "HAMMOND",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 622,
                "suburbName": "HAMPDEN",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 623,
                "suburbName": "HAMPSTEAD GARDENS",
                "postCode": "5086",
                "isMetro": true
            },
            {
                "suburbId": 624,
                "suburbName": "HANSBOROUGH",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 625,
                "suburbName": "HANSON",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 626,
                "suburbName": "HAPPY VALLEY",
                "postCode": "5159",
                "isMetro": true
            },
            {
                "suburbId": 627,
                "suburbName": "HARDWICKE BAY",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 628,
                "suburbName": "HARDY",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 629,
                "suburbName": "HARROGATE",
                "postCode": "5244",
                "isMetro": false
            },
            {
                "suburbId": 630,
                "suburbName": "HART",
                "postCode": "5464",
                "isMetro": false
            },
            {
                "suburbId": 631,
                "suburbName": "HARTLEY",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 632,
                "suburbName": "HASLAM",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 633,
                "suburbName": "HATHERLEIGH",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 634,
                "suburbName": "HAWKER",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 635,
                "suburbName": "HAWKS NEST STATION",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 636,
                "suburbName": "HAWSON",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 637,
                "suburbName": "HAWTHORN",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 638,
                "suburbName": "HAWTHORNDENE",
                "postCode": "5051",
                "isMetro": true
            },
            {
                "suburbId": 639,
                "suburbName": "HAY FLAT",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 640,
                "suburbName": "HAY VALLEY",
                "postCode": "5252",
                "isMetro": false
            },
            {
                "suburbId": 641,
                "suburbName": "HAYBOROUGH",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 642,
                "suburbName": "HAZELWOOD PARK",
                "postCode": "5066",
                "isMetro": true
            },
            {
                "suburbId": 643,
                "suburbName": "HEATHFIELD",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 644,
                "suburbName": "HEATHPOOL",
                "postCode": "5068",
                "isMetro": true
            },
            {
                "suburbId": 645,
                "suburbName": "HECTORVILLE",
                "postCode": "5073",
                "isMetro": true
            },
            {
                "suburbId": 646,
                "suburbName": "HENDON",
                "postCode": "5014",
                "isMetro": true
            },
            {
                "suburbId": 647,
                "suburbName": "HENLEY BEACH",
                "postCode": "5022",
                "isMetro": true
            },
            {
                "suburbId": 648,
                "suburbName": "HENLEY BEACH SOUTH",
                "postCode": "5022",
                "isMetro": true
            },
            {
                "suburbId": 649,
                "suburbName": "HEWETT",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 650,
                "suburbName": "HIGHBURY",
                "postCode": "5089",
                "isMetro": true
            },
            {
                "suburbId": 651,
                "suburbName": "HIGHGATE",
                "postCode": "5063",
                "isMetro": true
            },
            {
                "suburbId": 652,
                "suburbName": "HIGHLAND VALLEY",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 653,
                "suburbName": "HILL RIVER",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 654,
                "suburbName": "HILLBANK",
                "postCode": "5112",
                "isMetro": true
            },
            {
                "suburbId": 655,
                "suburbName": "HILLCREST",
                "postCode": "5086",
                "isMetro": true
            },
            {
                "suburbId": 656,
                "suburbName": "HILLIER",
                "postCode": "5116",
                "isMetro": true
            },
            {
                "suburbId": 657,
                "suburbName": "HILLTOWN",
                "postCode": "5455",
                "isMetro": false
            },
            {
                "suburbId": 658,
                "suburbName": "HILTABA",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 659,
                "suburbName": "HILTON",
                "postCode": "5033",
                "isMetro": true
            },
            {
                "suburbId": 660,
                "suburbName": "HINCKS",
                "postCode": "5603",
                "isMetro": false
            },
            {
                "suburbId": 661,
                "suburbName": "HINDMARSH",
                "postCode": "5007",
                "isMetro": true
            },
            {
                "suburbId": 662,
                "suburbName": "HINDMARSH ISLAND",
                "postCode": "5214",
                "isMetro": false
            },
            {
                "suburbId": 663,
                "suburbName": "HINDMARSH TIERS",
                "postCode": "5202",
                "isMetro": false
            },
            {
                "suburbId": 664,
                "suburbName": "HINDMARSH VALLEY",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 665,
                "suburbName": "HOLDEN HILL",
                "postCode": "5088",
                "isMetro": true
            },
            {
                "suburbId": 666,
                "suburbName": "HOLDER",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 667,
                "suburbName": "HOLDER SIDING",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 668,
                "suburbName": "HOLOWILIENA",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 669,
                "suburbName": "HOLOWILIENA SOUTH",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 670,
                "suburbName": "HONITON",
                "postCode": "5576",
                "isMetro": false
            },
            {
                "suburbId": 671,
                "suburbName": "HOPE FOREST",
                "postCode": "5172",
                "isMetro": true
            },
            {
                "suburbId": 672,
                "suburbName": "HOPE GAP",
                "postCode": "5555",
                "isMetro": false
            },
            {
                "suburbId": 673,
                "suburbName": "HOPE VALLEY",
                "postCode": "5090",
                "isMetro": true
            },
            {
                "suburbId": 674,
                "suburbName": "HORNSDALE",
                "postCode": "5491",
                "isMetro": false
            },
            {
                "suburbId": 675,
                "suburbName": "HORSNELL GULLY",
                "postCode": "5141",
                "isMetro": true
            },
            {
                "suburbId": 676,
                "suburbName": "HOSKIN CORNER",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 677,
                "suburbName": "HOUGHTON",
                "postCode": "5131",
                "isMetro": true
            },
            {
                "suburbId": 678,
                "suburbName": "HOVE",
                "postCode": "5048",
                "isMetro": true
            },
            {
                "suburbId": 679,
                "suburbName": "HOYLETON",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 680,
                "suburbName": "HUDDLESTON",
                "postCode": "5523",
                "isMetro": false
            },
            {
                "suburbId": 681,
                "suburbName": "HUMBUG SCRUB",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 682,
                "suburbName": "HUNTFIELD HEIGHTS",
                "postCode": "5163",
                "isMetro": true
            },
            {
                "suburbId": 683,
                "suburbName": "HYDE PARK",
                "postCode": "5061",
                "isMetro": true
            },
            {
                "suburbId": 684,
                "suburbName": "HYNAM",
                "postCode": "5262",
                "isMetro": false
            },
            {
                "suburbId": 685,
                "suburbName": "ILLEROO",
                "postCode": "5715",
                "isMetro": false
            },
            {
                "suburbId": 686,
                "suburbName": "INGLE FARM",
                "postCode": "5098",
                "isMetro": true
            },
            {
                "suburbId": 687,
                "suburbName": "INGLEWOOD",
                "postCode": "5133",
                "isMetro": true
            },
            {
                "suburbId": 688,
                "suburbName": "INGOMAR",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 689,
                "suburbName": "INKERMAN",
                "postCode": "5550",
                "isMetro": false
            },
            {
                "suburbId": 690,
                "suburbName": "INKSTER",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 691,
                "suburbName": "INMAN VALLEY",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 692,
                "suburbName": "INNAMINCKA",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 693,
                "suburbName": "INNESTON",
                "postCode": "5577",
                "isMetro": false
            },
            {
                "suburbId": 694,
                "suburbName": "IRON BARON",
                "postCode": "5611",
                "isMetro": false
            },
            {
                "suburbId": 695,
                "suburbName": "IRON KNOB",
                "postCode": "5611",
                "isMetro": false
            },
            {
                "suburbId": 696,
                "suburbName": "IRONBANK",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 697,
                "suburbName": "IRONSTONE",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 698,
                "suburbName": "ISLAND BEACH",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 699,
                "suburbName": "ISLAND LAGOON",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 700,
                "suburbName": "IWANTJA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 701,
                "suburbName": "JABUK",
                "postCode": "5301",
                "isMetro": false
            },
            {
                "suburbId": 702,
                "suburbName": "JAMES WELL",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 703,
                "suburbName": "JAMESTOWN",
                "postCode": "5491",
                "isMetro": false
            },
            {
                "suburbId": 704,
                "suburbName": "JAMIESON",
                "postCode": "5640",
                "isMetro": false
            },
            {
                "suburbId": 705,
                "suburbName": "JERICHO",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 706,
                "suburbName": "JERUSALEM",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 707,
                "suburbName": "JERVOIS",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 708,
                "suburbName": "JOANNA",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 709,
                "suburbName": "JOHNBURGH",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 710,
                "suburbName": "JOSLIN",
                "postCode": "5070",
                "isMetro": true
            },
            {
                "suburbId": 711,
                "suburbName": "JULANKA HOLDINGS",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 712,
                "suburbName": "JULIA",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 713,
                "suburbName": "JUPITER CREEK",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 714,
                "suburbName": "KADINA",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 715,
                "suburbName": "KAINTON",
                "postCode": "5552",
                "isMetro": false
            },
            {
                "suburbId": 716,
                "suburbName": "KALABITY",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 717,
                "suburbName": "KALAMURINA",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 718,
                "suburbName": "KALANBI",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 719,
                "suburbName": "KALANGADOO",
                "postCode": "5278",
                "isMetro": false
            },
            {
                "suburbId": 720,
                "suburbName": "KALBEEBA",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 721,
                "suburbName": "KALDOONERA",
                "postCode": "5655",
                "isMetro": false
            },
            {
                "suburbId": 722,
                "suburbName": "KALKA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 723,
                "suburbName": "KALKAROO",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 724,
                "suburbName": "KALLORA",
                "postCode": "5550",
                "isMetro": false
            },
            {
                "suburbId": 725,
                "suburbName": "KALTJITI",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 726,
                "suburbName": "KANGARILLA",
                "postCode": "5157",
                "isMetro": true
            },
            {
                "suburbId": 727,
                "suburbName": "KANGAROO FLAT",
                "postCode": "5118",
                "isMetro": false
            },
            {
                "suburbId": 728,
                "suburbName": "KANGAROO HEAD",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 729,
                "suburbName": "KANGAROO INN",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 730,
                "suburbName": "KANMANTOO",
                "postCode": "5252",
                "isMetro": false
            },
            {
                "suburbId": 731,
                "suburbName": "KANNI",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 732,
                "suburbName": "KANPI",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 733,
                "suburbName": "KANYAKA",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 734,
                "suburbName": "KAPINNIE",
                "postCode": "5632",
                "isMetro": false
            },
            {
                "suburbId": 735,
                "suburbName": "KAPPAWANTA",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 736,
                "suburbName": "KAPUNDA",
                "postCode": "5373",
                "isMetro": false
            },
            {
                "suburbId": 737,
                "suburbName": "KARATTA",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 738,
                "suburbName": "KARCULTABY",
                "postCode": "5654",
                "isMetro": false
            },
            {
                "suburbId": 739,
                "suburbName": "KARKOO",
                "postCode": "5632",
                "isMetro": false
            },
            {
                "suburbId": 740,
                "suburbName": "KAROONDA",
                "postCode": "5307",
                "isMetro": false
            },
            {
                "suburbId": 741,
                "suburbName": "KARTE",
                "postCode": "5304",
                "isMetro": false
            },
            {
                "suburbId": 742,
                "suburbName": "KATARAPKO",
                "postCode": "5343",
                "isMetro": false
            },
            {
                "suburbId": 743,
                "suburbName": "KATUNGA STATION",
                "postCode": "5611",
                "isMetro": false
            },
            {
                "suburbId": 744,
                "suburbName": "KEILIRA",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 745,
                "suburbName": "KEITH",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 746,
                "suburbName": "KELLIDIE BAY",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 747,
                "suburbName": "KELLY",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 748,
                "suburbName": "KENSINGTON",
                "postCode": "5068",
                "isMetro": true
            },
            {
                "suburbId": 749,
                "suburbName": "KENSINGTON GARDENS",
                "postCode": "5068",
                "isMetro": true
            },
            {
                "suburbId": 750,
                "suburbName": "KENSINGTON PARK",
                "postCode": "5068",
                "isMetro": true
            },
            {
                "suburbId": 751,
                "suburbName": "KENT TOWN",
                "postCode": "5067",
                "isMetro": true
            },
            {
                "suburbId": 752,
                "suburbName": "KENTON VALLEY",
                "postCode": "5233",
                "isMetro": true
            },
            {
                "suburbId": 753,
                "suburbName": "KEPA",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 754,
                "suburbName": "KEPPOCH",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 755,
                "suburbName": "KERSBROOK",
                "postCode": "5231",
                "isMetro": true
            },
            {
                "suburbId": 756,
                "suburbName": "KESWICK",
                "postCode": "5035",
                "isMetro": true
            },
            {
                "suburbId": 757,
                "suburbName": "KESWICK TERMINAL",
                "postCode": "5035",
                "isMetro": true
            },
            {
                "suburbId": 758,
                "suburbName": "KEYNETON",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 759,
                "suburbName": "KI KI",
                "postCode": "5261",
                "isMetro": false
            },
            {
                "suburbId": 760,
                "suburbName": "KIANA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 761,
                "suburbName": "KIDMAN PARK",
                "postCode": "5025",
                "isMetro": true
            },
            {
                "suburbId": 762,
                "suburbName": "KIELPA",
                "postCode": "5642",
                "isMetro": false
            },
            {
                "suburbId": 763,
                "suburbName": "KILBURN",
                "postCode": "5084",
                "isMetro": true
            },
            {
                "suburbId": 764,
                "suburbName": "KILKENNY",
                "postCode": "5009",
                "isMetro": true
            },
            {
                "suburbId": 765,
                "suburbName": "KIMBA",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 766,
                "suburbName": "KINGOONYA",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 767,
                "suburbName": "KINGS PARK",
                "postCode": "5034",
                "isMetro": true
            },
            {
                "suburbId": 768,
                "suburbName": "KINGSCOTE",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 769,
                "suburbName": "KINGSFORD",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 770,
                "suburbName": "KINGSTON ON MURRAY",
                "postCode": "5331",
                "isMetro": false
            },
            {
                "suburbId": 771,
                "suburbName": "KINGSTON PARK",
                "postCode": "5049",
                "isMetro": true
            },
            {
                "suburbId": 772,
                "suburbName": "KINGSTON SE",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 773,
                "suburbName": "KINGSWOOD",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 774,
                "suburbName": "KLEMZIG",
                "postCode": "5087",
                "isMetro": true
            },
            {
                "suburbId": 775,
                "suburbName": "KOHINOOR",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 776,
                "suburbName": "KOKATHA",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 777,
                "suburbName": "KOLENDO",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 778,
                "suburbName": "KONDOOLKA",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 779,
                "suburbName": "KONGAL",
                "postCode": "5270",
                "isMetro": false
            },
            {
                "suburbId": 780,
                "suburbName": "KONGORONG",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 781,
                "suburbName": "KOOLGERA",
                "postCode": "5661",
                "isMetro": false
            },
            {
                "suburbId": 782,
                "suburbName": "KOOLUNGA",
                "postCode": "5464",
                "isMetro": false
            },
            {
                "suburbId": 783,
                "suburbName": "KOOLYWURTIE",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 784,
                "suburbName": "KOONAMORE",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 785,
                "suburbName": "KOONGAWA",
                "postCode": "5650",
                "isMetro": false
            },
            {
                "suburbId": 786,
                "suburbName": "KOONIBBA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 787,
                "suburbName": "KOONOONA",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 788,
                "suburbName": "KOONUNGA",
                "postCode": "5373",
                "isMetro": false
            },
            {
                "suburbId": 789,
                "suburbName": "KOORINE",
                "postCode": "5279",
                "isMetro": false
            },
            {
                "suburbId": 790,
                "suburbName": "KOOROONA",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 791,
                "suburbName": "KOOTABERRA",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 792,
                "suburbName": "KOPPAMURRA",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 793,
                "suburbName": "KOPPIO",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 794,
                "suburbName": "KORUNYE",
                "postCode": "5502",
                "isMetro": false
            },
            {
                "suburbId": 795,
                "suburbName": "KRINGIN",
                "postCode": "5304",
                "isMetro": false
            },
            {
                "suburbId": 796,
                "suburbName": "KRONDORF",
                "postCode": "5352",
                "isMetro": false
            },
            {
                "suburbId": 797,
                "suburbName": "KRONGART",
                "postCode": "5278",
                "isMetro": false
            },
            {
                "suburbId": 798,
                "suburbName": "KUDLA",
                "postCode": "5115",
                "isMetro": true
            },
            {
                "suburbId": 799,
                "suburbName": "KUITPO",
                "postCode": "5201",
                "isMetro": true
            },
            {
                "suburbId": 800,
                "suburbName": "KUITPO COLONY",
                "postCode": "5172",
                "isMetro": false
            },
            {
                "suburbId": 801,
                "suburbName": "KULPARA",
                "postCode": "5552",
                "isMetro": false
            },
            {
                "suburbId": 802,
                "suburbName": "KURRALTA PARK",
                "postCode": "5037",
                "isMetro": true
            },
            {
                "suburbId": 803,
                "suburbName": "KYANCUTTA",
                "postCode": "5651",
                "isMetro": false
            },
            {
                "suburbId": 804,
                "suburbName": "KYBUNGA",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 805,
                "suburbName": "KYBYBOLITE",
                "postCode": "5262",
                "isMetro": false
            },
            {
                "suburbId": 806,
                "suburbName": "KYEEMA",
                "postCode": "5172",
                "isMetro": false
            },
            {
                "suburbId": 807,
                "suburbName": "LAFFER",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 808,
                "suburbName": "LAKE ALBERT",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 809,
                "suburbName": "LAKE ALEXANDRINA",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 810,
                "suburbName": "LAKE CARLET",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 811,
                "suburbName": "LAKE EVERARD",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 812,
                "suburbName": "LAKE EYRE",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 813,
                "suburbName": "LAKE FROME",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 814,
                "suburbName": "LAKE GAIRDNER",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 815,
                "suburbName": "LAKE GILLES",
                "postCode": "5611",
                "isMetro": false
            },
            {
                "suburbId": 816,
                "suburbName": "LAKE HARRIS",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 817,
                "suburbName": "LAKE MACFARLANE",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 818,
                "suburbName": "LAKE PLAINS",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 819,
                "suburbName": "LAKE TORRENS",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 820,
                "suburbName": "LAKE TORRENS STATION",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 821,
                "suburbName": "LAKE VIEW",
                "postCode": "5555",
                "isMetro": false
            },
            {
                "suburbId": 822,
                "suburbName": "LAMBINA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 823,
                "suburbName": "LAMEROO",
                "postCode": "5302",
                "isMetro": false
            },
            {
                "suburbId": 824,
                "suburbName": "LANGHORNE CREEK",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 825,
                "suburbName": "LANGS LANDING",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 826,
                "suburbName": "LARGS BAY",
                "postCode": "5016",
                "isMetro": true
            },
            {
                "suburbId": 827,
                "suburbName": "LARGS NORTH",
                "postCode": "5016",
                "isMetro": true
            },
            {
                "suburbId": 828,
                "suburbName": "LAURA",
                "postCode": "5480",
                "isMetro": false
            },
            {
                "suburbId": 829,
                "suburbName": "LAURA BAY",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 830,
                "suburbName": "LAURIE PARK",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 831,
                "suburbName": "LEABROOK",
                "postCode": "5068",
                "isMetro": true
            },
            {
                "suburbId": 832,
                "suburbName": "LEASINGHAM",
                "postCode": "5452",
                "isMetro": false
            },
            {
                "suburbId": 833,
                "suburbName": "LEAWOOD GARDENS",
                "postCode": "5150",
                "isMetro": true
            },
            {
                "suburbId": 834,
                "suburbName": "LEIGH CREEK",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 835,
                "suburbName": "LEIGH CREEK STATION",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 836,
                "suburbName": "LEIGHTON",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 837,
                "suburbName": "LENSWOOD",
                "postCode": "5240",
                "isMetro": true
            },
            {
                "suburbId": 838,
                "suburbName": "LEWISTON",
                "postCode": "5501",
                "isMetro": true
            },
            {
                "suburbId": 839,
                "suburbName": "LIGHT PASS",
                "postCode": "5355",
                "isMetro": false
            },
            {
                "suburbId": 840,
                "suburbName": "LIGHTSVIEW",
                "postCode": "5085",
                "isMetro": true
            },
            {
                "suburbId": 841,
                "suburbName": "LINCOLN GAP",
                "postCode": "5715",
                "isMetro": false
            },
            {
                "suburbId": 842,
                "suburbName": "LINCOLN NATIONAL PARK",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 843,
                "suburbName": "LINDEN PARK",
                "postCode": "5065",
                "isMetro": true
            },
            {
                "suburbId": 844,
                "suburbName": "LINDLEY",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 845,
                "suburbName": "LINDON",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 846,
                "suburbName": "LINWOOD",
                "postCode": "5410",
                "isMetro": false
            },
            {
                "suburbId": 847,
                "suburbName": "LIPSON",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 848,
                "suburbName": "LITTLE DOUGLAS",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 849,
                "suburbName": "LITTLEHAMPTON",
                "postCode": "5250",
                "isMetro": true
            },
            {
                "suburbId": 850,
                "suburbName": "LOBETHAL",
                "postCode": "5241",
                "isMetro": true
            },
            {
                "suburbId": 851,
                "suburbName": "LOCHABER",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 852,
                "suburbName": "LOCHIEL",
                "postCode": "5510",
                "isMetro": false
            },
            {
                "suburbId": 853,
                "suburbName": "LOCK",
                "postCode": "5633",
                "isMetro": false
            },
            {
                "suburbId": 854,
                "suburbName": "LOCKES CLAYPAN",
                "postCode": "5655",
                "isMetro": false
            },
            {
                "suburbId": 855,
                "suburbName": "LOCKLEYS",
                "postCode": "5032",
                "isMetro": true
            },
            {
                "suburbId": 856,
                "suburbName": "LONG FLAT",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 857,
                "suburbName": "LONG PLAINS",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 858,
                "suburbName": "LONGWOOD",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 859,
                "suburbName": "LONSDALE",
                "postCode": "5160",
                "isMetro": true
            },
            {
                "suburbId": 860,
                "suburbName": "LOUTH BAY",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 861,
                "suburbName": "LOVEDAY",
                "postCode": "5345",
                "isMetro": false
            },
            {
                "suburbId": 862,
                "suburbName": "LOWAN VALE",
                "postCode": "5268",
                "isMetro": false
            },
            {
                "suburbId": 863,
                "suburbName": "LOWBANK",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 864,
                "suburbName": "LOWER BROUGHTON",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 865,
                "suburbName": "LOWER HERMITAGE",
                "postCode": "5131",
                "isMetro": true
            },
            {
                "suburbId": 866,
                "suburbName": "LOWER INMAN VALLEY",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 867,
                "suburbName": "LOWER LIGHT",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 868,
                "suburbName": "LOWER MITCHAM",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 869,
                "suburbName": "LOXTON",
                "postCode": "5333",
                "isMetro": false
            },
            {
                "suburbId": 870,
                "suburbName": "LOXTON NORTH",
                "postCode": "5333",
                "isMetro": false
            },
            {
                "suburbId": 871,
                "suburbName": "LUCINDALE",
                "postCode": "5272",
                "isMetro": false
            },
            {
                "suburbId": 872,
                "suburbName": "LUCKY BAY",
                "postCode": "5602",
                "isMetro": false
            },
            {
                "suburbId": 873,
                "suburbName": "LYNDHURST",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 874,
                "suburbName": "LYNDOCH",
                "postCode": "5351",
                "isMetro": true
            },
            {
                "suburbId": 875,
                "suburbName": "LYNTON",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 876,
                "suburbName": "LYRUP",
                "postCode": "5343",
                "isMetro": false
            },
            {
                "suburbId": 877,
                "suburbName": "MAAOUPE",
                "postCode": "5277",
                "isMetro": false
            },
            {
                "suburbId": 878,
                "suburbName": "MABEL CREEK",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 879,
                "suburbName": "MACCLESFIELD",
                "postCode": "5153",
                "isMetro": false
            },
            {
                "suburbId": 880,
                "suburbName": "MACDONALD PARK",
                "postCode": "5121",
                "isMetro": true
            },
            {
                "suburbId": 881,
                "suburbName": "MACGILLIVRAY",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 882,
                "suburbName": "MACUMBA",
                "postCode": "5734",
                "isMetro": false
            },
            {
                "suburbId": 883,
                "suburbName": "MAGAREY",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 884,
                "suburbName": "MAGDALA",
                "postCode": "5400",
                "isMetro": false
            },
            {
                "suburbId": 885,
                "suburbName": "MAGGEA",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 886,
                "suburbName": "MAGILL",
                "postCode": "5072",
                "isMetro": true
            },
            {
                "suburbId": 887,
                "suburbName": "MAHANEWO",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 888,
                "suburbName": "MAITLAND",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 889,
                "suburbName": "MAKIN",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 890,
                "suburbName": "MALINONG",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 891,
                "suburbName": "MALLALA",
                "postCode": "5502",
                "isMetro": false
            },
            {
                "suburbId": 892,
                "suburbName": "MALPAS",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 893,
                "suburbName": "MALTEE",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 894,
                "suburbName": "MALVERN",
                "postCode": "5061",
                "isMetro": true
            },
            {
                "suburbId": 895,
                "suburbName": "MAMBRAY CREEK",
                "postCode": "5495",
                "isMetro": false
            },
            {
                "suburbId": 896,
                "suburbName": "MANGALO",
                "postCode": "5602",
                "isMetro": false
            },
            {
                "suburbId": 897,
                "suburbName": "MANNA HILL",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 898,
                "suburbName": "MANNANARIE",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 899,
                "suburbName": "MANNERS WELL",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 900,
                "suburbName": "MANNINGHAM",
                "postCode": "5086",
                "isMetro": true
            },
            {
                "suburbId": 901,
                "suburbName": "MANNUM",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 902,
                "suburbName": "MANOORA",
                "postCode": "5414",
                "isMetro": false
            },
            {
                "suburbId": 903,
                "suburbName": "MANSFIELD PARK",
                "postCode": "5012",
                "isMetro": true
            },
            {
                "suburbId": 904,
                "suburbName": "MANTUNG",
                "postCode": "5308",
                "isMetro": false
            },
            {
                "suburbId": 905,
                "suburbName": "MANUNDA STATION",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 906,
                "suburbName": "MARAMA",
                "postCode": "5307",
                "isMetro": false
            },
            {
                "suburbId": 907,
                "suburbName": "MARANANGA",
                "postCode": "5355",
                "isMetro": false
            },
            {
                "suburbId": 908,
                "suburbName": "MARBLE HILL",
                "postCode": "5137",
                "isMetro": true
            },
            {
                "suburbId": 909,
                "suburbName": "MARCOLLAT",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 910,
                "suburbName": "MARDEN",
                "postCode": "5070",
                "isMetro": true
            },
            {
                "suburbId": 911,
                "suburbName": "MARINO",
                "postCode": "5049",
                "isMetro": true
            },
            {
                "suburbId": 912,
                "suburbName": "MARION",
                "postCode": "5043",
                "isMetro": true
            },
            {
                "suburbId": 913,
                "suburbName": "MARION BAY",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 914,
                "suburbName": "MARKARANKA",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 915,
                "suburbName": "MARKS LANDING",
                "postCode": "5354",
                "isMetro": false
            },
            {
                "suburbId": 916,
                "suburbName": "MARLA",
                "postCode": "5724",
                "isMetro": false
            },
            {
                "suburbId": 917,
                "suburbName": "MARLESTON",
                "postCode": "5033",
                "isMetro": true
            },
            {
                "suburbId": 918,
                "suburbName": "MAROLA",
                "postCode": "5464",
                "isMetro": false
            },
            {
                "suburbId": 919,
                "suburbName": "MARRABEL",
                "postCode": "5413",
                "isMetro": false
            },
            {
                "suburbId": 920,
                "suburbName": "MARREE",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 921,
                "suburbName": "MARREE STATION",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 922,
                "suburbName": "MARRYATVILLE",
                "postCode": "5068",
                "isMetro": true
            },
            {
                "suburbId": 923,
                "suburbName": "MARTINS WELL",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 924,
                "suburbName": "MARYVALE",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 925,
                "suburbName": "MASLIN BEACH",
                "postCode": "5170",
                "isMetro": true
            },
            {
                "suburbId": 926,
                "suburbName": "MATTA FLAT",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 927,
                "suburbName": "MAUDE",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 928,
                "suburbName": "MAWSON LAKES",
                "postCode": "5095",
                "isMetro": true
            },
            {
                "suburbId": 929,
                "suburbName": "MAYFIELD",
                "postCode": "5454",
                "isMetro": false
            },
            {
                "suburbId": 930,
                "suburbName": "MAYLANDS",
                "postCode": "5069",
                "isMetro": true
            },
            {
                "suburbId": 931,
                "suburbName": "MCBEAN POUND",
                "postCode": "5357",
                "isMetro": false
            },
            {
                "suburbId": 932,
                "suburbName": "MCCALLUM",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 933,
                "suburbName": "MCCRACKEN",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 934,
                "suburbName": "MCDOUALL PEAK",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 935,
                "suburbName": "MCHARG CREEK",
                "postCode": "5157",
                "isMetro": false
            },
            {
                "suburbId": 936,
                "suburbName": "MCLAREN FLAT",
                "postCode": "5171",
                "isMetro": true
            },
            {
                "suburbId": 937,
                "suburbName": "MCLAREN VALE",
                "postCode": "5171",
                "isMetro": true
            },
            {
                "suburbId": 938,
                "suburbName": "MEADOWS",
                "postCode": "5201",
                "isMetro": true
            },
            {
                "suburbId": 939,
                "suburbName": "MEDINDIE",
                "postCode": "5081",
                "isMetro": true
            },
            {
                "suburbId": 940,
                "suburbName": "MEDINDIE GARDENS",
                "postCode": "5081",
                "isMetro": true
            },
            {
                "suburbId": 941,
                "suburbName": "MELROSE",
                "postCode": "5483",
                "isMetro": false
            },
            {
                "suburbId": 942,
                "suburbName": "MELROSE PARK",
                "postCode": "5039",
                "isMetro": true
            },
            {
                "suburbId": 943,
                "suburbName": "MELTON",
                "postCode": "5552",
                "isMetro": false
            },
            {
                "suburbId": 944,
                "suburbName": "MELTON STATION",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 945,
                "suburbName": "MENINGIE",
                "postCode": "5264",
                "isMetro": false
            },
            {
                "suburbId": 946,
                "suburbName": "MENINGIE EAST",
                "postCode": "5264",
                "isMetro": false
            },
            {
                "suburbId": 947,
                "suburbName": "MENINGIE WEST",
                "postCode": "5264",
                "isMetro": false
            },
            {
                "suburbId": 948,
                "suburbName": "MENZIES",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 949,
                "suburbName": "MERCUNDA",
                "postCode": "5308",
                "isMetro": false
            },
            {
                "suburbId": 950,
                "suburbName": "MERGHINY",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 951,
                "suburbName": "MERIBAH",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 952,
                "suburbName": "MERRITON",
                "postCode": "5523",
                "isMetro": false
            },
            {
                "suburbId": 953,
                "suburbName": "MERTY MERTY",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 954,
                "suburbName": "MIDDLE BEACH",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 955,
                "suburbName": "MIDDLE RIVER",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 956,
                "suburbName": "MIDDLEBACK RANGE",
                "postCode": "5609",
                "isMetro": false
            },
            {
                "suburbId": 957,
                "suburbName": "MIDDLETON",
                "postCode": "5213",
                "isMetro": false
            },
            {
                "suburbId": 958,
                "suburbName": "MIDGEE",
                "postCode": "5602",
                "isMetro": false
            },
            {
                "suburbId": 959,
                "suburbName": "MILANG",
                "postCode": "5256",
                "isMetro": false
            },
            {
                "suburbId": 960,
                "suburbName": "MILE END",
                "postCode": "5031",
                "isMetro": true
            },
            {
                "suburbId": 961,
                "suburbName": "MILE END SOUTH",
                "postCode": "5031",
                "isMetro": true
            },
            {
                "suburbId": 962,
                "suburbName": "MILENDELLA",
                "postCode": "5237",
                "isMetro": false
            },
            {
                "suburbId": 963,
                "suburbName": "MILLBROOK",
                "postCode": "5231",
                "isMetro": true
            },
            {
                "suburbId": 964,
                "suburbName": "MIL-LEL",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 965,
                "suburbName": "MILLERS CREEK",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 966,
                "suburbName": "MILLICENT",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 967,
                "suburbName": "MILLSWOOD",
                "postCode": "5034",
                "isMetro": true
            },
            {
                "suburbId": 968,
                "suburbName": "MILTALIE",
                "postCode": "5602",
                "isMetro": false
            },
            {
                "suburbId": 969,
                "suburbName": "MIMILI",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 970,
                "suburbName": "MINBRIE",
                "postCode": "5602",
                "isMetro": false
            },
            {
                "suburbId": 971,
                "suburbName": "MINBURRA",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 972,
                "suburbName": "MINBURRA PLAIN",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 973,
                "suburbName": "MINBURRA STATION",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 974,
                "suburbName": "MINDARIE",
                "postCode": "5309",
                "isMetro": false
            },
            {
                "suburbId": 975,
                "suburbName": "MINGARY",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 976,
                "suburbName": "MINGBOOL",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 977,
                "suburbName": "MINLATON",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 978,
                "suburbName": "MINNIPA",
                "postCode": "5654",
                "isMetro": false
            },
            {
                "suburbId": 979,
                "suburbName": "MINTARO",
                "postCode": "5415",
                "isMetro": false
            },
            {
                "suburbId": 980,
                "suburbName": "MINVALARA",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 981,
                "suburbName": "MIRANDA",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 982,
                "suburbName": "MITCHAM",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 983,
                "suburbName": "MITCHELL",
                "postCode": "5632",
                "isMetro": false
            },
            {
                "suburbId": 984,
                "suburbName": "MITCHELL PARK",
                "postCode": "5043",
                "isMetro": true
            },
            {
                "suburbId": 985,
                "suburbName": "MITCHELLVILLE",
                "postCode": "5602",
                "isMetro": false
            },
            {
                "suburbId": 986,
                "suburbName": "MITCHIDY MOOLA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 987,
                "suburbName": "MOANA",
                "postCode": "5169",
                "isMetro": true
            },
            {
                "suburbId": 988,
                "suburbName": "MOBILONG",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 989,
                "suburbName": "MOCULTA",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 990,
                "suburbName": "MODBURY",
                "postCode": "5092",
                "isMetro": true
            },
            {
                "suburbId": 991,
                "suburbName": "MODBURY HEIGHTS",
                "postCode": "5092",
                "isMetro": true
            },
            {
                "suburbId": 992,
                "suburbName": "MODBURY NORTH",
                "postCode": "5092",
                "isMetro": true
            },
            {
                "suburbId": 993,
                "suburbName": "MOERLONG",
                "postCode": "5278",
                "isMetro": false
            },
            {
                "suburbId": 994,
                "suburbName": "MONARTO",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 995,
                "suburbName": "MONARTO SOUTH",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 996,
                "suburbName": "MONASH",
                "postCode": "5342",
                "isMetro": false
            },
            {
                "suburbId": 997,
                "suburbName": "MONBULLA",
                "postCode": "5277",
                "isMetro": false
            },
            {
                "suburbId": 998,
                "suburbName": "MONGOLATA",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 999,
                "suburbName": "MONTACUTE",
                "postCode": "5134",
                "isMetro": true
            },
            {
                "suburbId": 1000,
                "suburbName": "MONTARRA",
                "postCode": "5172",
                "isMetro": false
            },
            {
                "suburbId": 1001,
                "suburbName": "MONTEITH",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1002,
                "suburbName": "MOOCKRA",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 1003,
                "suburbName": "MOODY",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1004,
                "suburbName": "MOOLAWATANA",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1005,
                "suburbName": "MOOLEULOOLOO",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1006,
                "suburbName": "MOOLOOLOO",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1007,
                "suburbName": "MOONAREE",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 1008,
                "suburbName": "MOONTA",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 1009,
                "suburbName": "MOONTA BAY",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 1010,
                "suburbName": "MOONTA MINES",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 1011,
                "suburbName": "MOORAK",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1012,
                "suburbName": "MOORILLAH",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1013,
                "suburbName": "MOORLANDS",
                "postCode": "5301",
                "isMetro": false
            },
            {
                "suburbId": 1014,
                "suburbName": "MOOROOK",
                "postCode": "5332",
                "isMetro": false
            },
            {
                "suburbId": 1015,
                "suburbName": "MOOROOK SOUTH",
                "postCode": "5332",
                "isMetro": false
            },
            {
                "suburbId": 1016,
                "suburbName": "MOPPA",
                "postCode": "5355",
                "isMetro": false
            },
            {
                "suburbId": 1017,
                "suburbName": "MORCHARD",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 1018,
                "suburbName": "MORGAN",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 1019,
                "suburbName": "MORN HILL",
                "postCode": "5371",
                "isMetro": false
            },
            {
                "suburbId": 1020,
                "suburbName": "MORPHETT VALE",
                "postCode": "5162",
                "isMetro": true
            },
            {
                "suburbId": 1021,
                "suburbName": "MORPHETTS FLAT",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 1022,
                "suburbName": "MORPHETTVILLE",
                "postCode": "5043",
                "isMetro": true
            },
            {
                "suburbId": 1023,
                "suburbName": "MORTANA",
                "postCode": "5671",
                "isMetro": false
            },
            {
                "suburbId": 1024,
                "suburbName": "MOSELEY",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 1025,
                "suburbName": "MOSQUITO HILL",
                "postCode": "5214",
                "isMetro": false
            },
            {
                "suburbId": 1026,
                "suburbName": "MOTPENA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1027,
                "suburbName": "MOUNT ARDEN",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 1028,
                "suburbName": "MOUNT BARKER",
                "postCode": "5251",
                "isMetro": true
            },
            {
                "suburbId": 1029,
                "suburbName": "MOUNT BARKER JUNCTION",
                "postCode": "5251",
                "isMetro": true
            },
            {
                "suburbId": 1030,
                "suburbName": "MOUNT BARKER SPRINGS",
                "postCode": "5251",
                "isMetro": true
            },
            {
                "suburbId": 1031,
                "suburbName": "MOUNT BARKER SUMMIT",
                "postCode": "5251",
                "isMetro": true
            },
            {
                "suburbId": 1032,
                "suburbName": "MOUNT BARRY",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 1033,
                "suburbName": "MOUNT BENSON",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1034,
                "suburbName": "MOUNT BRYAN",
                "postCode": "5418",
                "isMetro": false
            },
            {
                "suburbId": 1035,
                "suburbName": "MOUNT BRYAN EAST",
                "postCode": "5419",
                "isMetro": false
            },
            {
                "suburbId": 1036,
                "suburbName": "MOUNT BURR",
                "postCode": "5279",
                "isMetro": false
            },
            {
                "suburbId": 1037,
                "suburbName": "MOUNT CHARLES",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 1038,
                "suburbName": "MOUNT CLARENCE STATION",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 1039,
                "suburbName": "MOUNT COMPASS",
                "postCode": "5210",
                "isMetro": false
            },
            {
                "suburbId": 1040,
                "suburbName": "MOUNT COOPER",
                "postCode": "5671",
                "isMetro": false
            },
            {
                "suburbId": 1041,
                "suburbName": "MOUNT CRAWFORD",
                "postCode": "5351",
                "isMetro": false
            },
            {
                "suburbId": 1042,
                "suburbName": "MOUNT DAMPER",
                "postCode": "5654",
                "isMetro": false
            },
            {
                "suburbId": 1043,
                "suburbName": "MOUNT DRUMMOND",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1044,
                "suburbName": "MOUNT DUTTON BAY",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1045,
                "suburbName": "MOUNT EBA",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 1046,
                "suburbName": "MOUNT FALKLAND",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1047,
                "suburbName": "MOUNT FREELING",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 1048,
                "suburbName": "MOUNT GAMBIER",
                "postCode": "5290",
                "isMetro": false
            },
            {
                "suburbId": 1049,
                "suburbName": "MOUNT GEORGE",
                "postCode": "5155",
                "isMetro": true
            },
            {
                "suburbId": 1050,
                "suburbName": "MOUNT HAVELOCK",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 1051,
                "suburbName": "MOUNT HOPE",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1052,
                "suburbName": "MOUNT IVE",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 1053,
                "suburbName": "MOUNT JAGGED",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 1054,
                "suburbName": "MOUNT JOY",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 1055,
                "suburbName": "MOUNT LIGHT",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1056,
                "suburbName": "MOUNT LYNDHURST",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 1057,
                "suburbName": "MOUNT MAGNIFICENT",
                "postCode": "5210",
                "isMetro": false
            },
            {
                "suburbId": 1058,
                "suburbName": "MOUNT MARY",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 1059,
                "suburbName": "MOUNT MCINTYRE",
                "postCode": "5279",
                "isMetro": false
            },
            {
                "suburbId": 1060,
                "suburbName": "MOUNT MCKENZIE",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 1061,
                "suburbName": "MOUNT OBSERVATION",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1062,
                "suburbName": "MOUNT OSMOND",
                "postCode": "5064",
                "isMetro": true
            },
            {
                "suburbId": 1063,
                "suburbName": "MOUNT PLEASANT",
                "postCode": "5235",
                "isMetro": false
            },
            {
                "suburbId": 1064,
                "suburbName": "MOUNT SARAH",
                "postCode": "5734",
                "isMetro": false
            },
            {
                "suburbId": 1065,
                "suburbName": "MOUNT SCHANK",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1066,
                "suburbName": "MOUNT SERLE",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1067,
                "suburbName": "MOUNT TEMPLETON",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 1068,
                "suburbName": "MOUNT TORRENS",
                "postCode": "5244",
                "isMetro": true
            },
            {
                "suburbId": 1069,
                "suburbName": "MOUNT VICTOR STATION",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1070,
                "suburbName": "MOUNT VIVIAN",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 1071,
                "suburbName": "MOUNT WEDGE",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 1072,
                "suburbName": "MOUNT WILLOUGHBY",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 1073,
                "suburbName": "MOYHALL",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1074,
                "suburbName": "MUDAMUCKLA",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1075,
                "suburbName": "MULGA VIEW",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1076,
                "suburbName": "MULGARIA",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 1077,
                "suburbName": "MULGATHING",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 1078,
                "suburbName": "MULGUNDAWA",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1079,
                "suburbName": "MULKA",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 1080,
                "suburbName": "MULLAQUANA",
                "postCode": "5608",
                "isMetro": false
            },
            {
                "suburbId": 1081,
                "suburbName": "MULOORINA",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 1082,
                "suburbName": "MULYUNGARIE",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1083,
                "suburbName": "MUNDALLIO",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 1084,
                "suburbName": "MUNDI MUNDI",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1085,
                "suburbName": "MUNDIC CREEK",
                "postCode": "5340",
                "isMetro": false
            },
            {
                "suburbId": 1086,
                "suburbName": "MUNDOO ISLAND",
                "postCode": "5214",
                "isMetro": false
            },
            {
                "suburbId": 1087,
                "suburbName": "MUNDOORA",
                "postCode": "5555",
                "isMetro": false
            },
            {
                "suburbId": 1088,
                "suburbName": "MUNDOWDNA",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 1089,
                "suburbName": "MUNDULLA",
                "postCode": "5270",
                "isMetro": false
            },
            {
                "suburbId": 1090,
                "suburbName": "MUNDULLA WEST",
                "postCode": "5270",
                "isMetro": false
            },
            {
                "suburbId": 1091,
                "suburbName": "MUNGERANIE",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 1092,
                "suburbName": "MUNNO PARA",
                "postCode": "5115",
                "isMetro": true
            },
            {
                "suburbId": 1093,
                "suburbName": "MUNNO PARA DOWNS",
                "postCode": "5115",
                "isMetro": true
            },
            {
                "suburbId": 1094,
                "suburbName": "MUNNO PARA WEST",
                "postCode": "5115",
                "isMetro": true
            },
            {
                "suburbId": 1095,
                "suburbName": "MURBKO",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 1096,
                "suburbName": "MURDINGA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1097,
                "suburbName": "MURLONG",
                "postCode": "5642",
                "isMetro": false
            },
            {
                "suburbId": 1098,
                "suburbName": "MURNINNIE BEACH",
                "postCode": "5609",
                "isMetro": false
            },
            {
                "suburbId": 1099,
                "suburbName": "MURNPEOWIE",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 1100,
                "suburbName": "MURPUTJA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 1101,
                "suburbName": "MURRAWONG",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1102,
                "suburbName": "MURRAY BRIDGE",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1103,
                "suburbName": "MURRAY BRIDGE EAST",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1104,
                "suburbName": "MURRAY BRIDGE NORTH",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1105,
                "suburbName": "MURRAY BRIDGE SOUTH",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1106,
                "suburbName": "MURRAY TOWN",
                "postCode": "5481",
                "isMetro": false
            },
            {
                "suburbId": 1107,
                "suburbName": "MURTHO",
                "postCode": "5340",
                "isMetro": false
            },
            {
                "suburbId": 1108,
                "suburbName": "MUSTON",
                "postCode": "5221",
                "isMetro": false
            },
            {
                "suburbId": 1109,
                "suburbName": "MUTOOROO",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1110,
                "suburbName": "MYLOR",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 1111,
                "suburbName": "MYOLA STATION",
                "postCode": "5611",
                "isMetro": false
            },
            {
                "suburbId": 1112,
                "suburbName": "MYPOLONGA",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1113,
                "suburbName": "MYPONGA",
                "postCode": "5202",
                "isMetro": false
            },
            {
                "suburbId": 1114,
                "suburbName": "MYPONGA BEACH",
                "postCode": "5202",
                "isMetro": false
            },
            {
                "suburbId": 1115,
                "suburbName": "MYRTLE BANK",
                "postCode": "5064",
                "isMetro": true
            },
            {
                "suburbId": 1116,
                "suburbName": "MYRTLE SPRINGS",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 1117,
                "suburbName": "NACKARA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1118,
                "suburbName": "NADIA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1119,
                "suburbName": "NAIDIA",
                "postCode": "5354",
                "isMetro": false
            },
            {
                "suburbId": 1120,
                "suburbName": "NAILSWORTH",
                "postCode": "5083",
                "isMetro": true
            },
            {
                "suburbId": 1121,
                "suburbName": "NAIN",
                "postCode": "5360",
                "isMetro": false
            },
            {
                "suburbId": 1122,
                "suburbName": "NAIRNE",
                "postCode": "5252",
                "isMetro": true
            },
            {
                "suburbId": 1123,
                "suburbName": "NALPA",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1124,
                "suburbName": "NALYAPPA",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 1125,
                "suburbName": "NANBONA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1126,
                "suburbName": "NANGKITA",
                "postCode": "5210",
                "isMetro": false
            },
            {
                "suburbId": 1127,
                "suburbName": "NANGWARRY",
                "postCode": "5277",
                "isMetro": false
            },
            {
                "suburbId": 1128,
                "suburbName": "NANTAWARRA",
                "postCode": "5550",
                "isMetro": false
            },
            {
                "suburbId": 1129,
                "suburbName": "NAPPERBY",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1130,
                "suburbName": "NARACOORTE",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1131,
                "suburbName": "NARLABY",
                "postCode": "5655",
                "isMetro": false
            },
            {
                "suburbId": 1132,
                "suburbName": "NARRIDY",
                "postCode": "5523",
                "isMetro": false
            },
            {
                "suburbId": 1133,
                "suburbName": "NARRINA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1134,
                "suburbName": "NARRUNG",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 1135,
                "suburbName": "NATURI",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 1136,
                "suburbName": "NEALES FLAT",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 1137,
                "suburbName": "NECTAR BROOK",
                "postCode": "5495",
                "isMetro": false
            },
            {
                "suburbId": 1138,
                "suburbName": "NELSHABY",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1139,
                "suburbName": "NENE VALLEY",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1140,
                "suburbName": "NEPABUNNA",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1141,
                "suburbName": "NEPEAN BAY",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1142,
                "suburbName": "NETHERBY",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 1143,
                "suburbName": "NETHERTON",
                "postCode": "5301",
                "isMetro": false
            },
            {
                "suburbId": 1144,
                "suburbName": "NETLEY",
                "postCode": "5037",
                "isMetro": true
            },
            {
                "suburbId": 1145,
                "suburbName": "NETLEY GAP",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1146,
                "suburbName": "NEW PORT",
                "postCode": "5015",
                "isMetro": true
            },
            {
                "suburbId": 1147,
                "suburbName": "NEW RESIDENCE",
                "postCode": "5333",
                "isMetro": false
            },
            {
                "suburbId": 1148,
                "suburbName": "NEW TOWN",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 1149,
                "suburbName": "NEW WELL",
                "postCode": "5357",
                "isMetro": false
            },
            {
                "suburbId": 1150,
                "suburbName": "NEWLAND",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1151,
                "suburbName": "NEWTON",
                "postCode": "5074",
                "isMetro": true
            },
            {
                "suburbId": 1152,
                "suburbName": "NGAPALA",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 1153,
                "suburbName": "NGARKAT",
                "postCode": "5302",
                "isMetro": false
            },
            {
                "suburbId": 1154,
                "suburbName": "NILDOTTIE",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1155,
                "suburbName": "NILPENA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1156,
                "suburbName": "NILPINNA STATION",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 1157,
                "suburbName": "NINNES",
                "postCode": "5560",
                "isMetro": false
            },
            {
                "suburbId": 1158,
                "suburbName": "NOARLUNGA CENTRE",
                "postCode": "5168",
                "isMetro": true
            },
            {
                "suburbId": 1159,
                "suburbName": "NOARLUNGA DOWNS",
                "postCode": "5168",
                "isMetro": true
            },
            {
                "suburbId": 1160,
                "suburbName": "NONNING",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 1161,
                "suburbName": "NORA CREINA",
                "postCode": "5276",
                "isMetro": false
            },
            {
                "suburbId": 1162,
                "suburbName": "NORMANVILLE",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 1163,
                "suburbName": "NORTH ADELAIDE",
                "postCode": "5006",
                "isMetro": true
            },
            {
                "suburbId": 1164,
                "suburbName": "NORTH BEACH",
                "postCode": "5556",
                "isMetro": false
            },
            {
                "suburbId": 1165,
                "suburbName": "NORTH BOOBOROWIE",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 1166,
                "suburbName": "NORTH BRIGHTON",
                "postCode": "5048",
                "isMetro": true
            },
            {
                "suburbId": 1167,
                "suburbName": "NORTH CAPE",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1168,
                "suburbName": "NORTH HAVEN",
                "postCode": "5018",
                "isMetro": true
            },
            {
                "suburbId": 1169,
                "suburbName": "NORTH HILLS",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 1170,
                "suburbName": "NORTH MOOLOOLOO",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1171,
                "suburbName": "NORTH MOONTA",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 1172,
                "suburbName": "NORTH PLYMPTON",
                "postCode": "5037",
                "isMetro": true
            },
            {
                "suburbId": 1173,
                "suburbName": "NORTH SHIELDS",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1174,
                "suburbName": "NORTH WEST BEND",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 1175,
                "suburbName": "NORTH YELTA",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 1176,
                "suburbName": "NORTHERN HEIGHTS",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1177,
                "suburbName": "NORTHFIELD",
                "postCode": "5085",
                "isMetro": true
            },
            {
                "suburbId": 1178,
                "suburbName": "NORTHGATE",
                "postCode": "5085",
                "isMetro": true
            },
            {
                "suburbId": 1179,
                "suburbName": "NORTON SUMMIT",
                "postCode": "5136",
                "isMetro": true
            },
            {
                "suburbId": 1180,
                "suburbName": "NORWOOD",
                "postCode": "5067",
                "isMetro": true
            },
            {
                "suburbId": 1181,
                "suburbName": "NOTTS WELL",
                "postCode": "5357",
                "isMetro": false
            },
            {
                "suburbId": 1182,
                "suburbName": "NOVAR GARDENS",
                "postCode": "5040",
                "isMetro": true
            },
            {
                "suburbId": 1183,
                "suburbName": "NULLARBOR",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1184,
                "suburbName": "NUNJIKOMPITA",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1185,
                "suburbName": "NURIOOTPA",
                "postCode": "5355",
                "isMetro": true
            },
            {
                "suburbId": 1186,
                "suburbName": "NUROM",
                "postCode": "5523",
                "isMetro": false
            },
            {
                "suburbId": 1187,
                "suburbName": "NURRAGI",
                "postCode": "5256",
                "isMetro": false
            },
            {
                "suburbId": 1188,
                "suburbName": "NYAPARI",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 1189,
                "suburbName": "OAK VALLEY",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1190,
                "suburbName": "OAKBANK",
                "postCode": "5243",
                "isMetro": true
            },
            {
                "suburbId": 1191,
                "suburbName": "OAKDEN",
                "postCode": "5086",
                "isMetro": true
            },
            {
                "suburbId": 1192,
                "suburbName": "OAKDEN HILLS",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 1193,
                "suburbName": "OAKLANDS PARK",
                "postCode": "5046",
                "isMetro": true
            },
            {
                "suburbId": 1194,
                "suburbName": "OAKVALE STATION",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 1195,
                "suburbName": "OB FLAT",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1196,
                "suburbName": "O'HALLORAN HILL",
                "postCode": "5158",
                "isMetro": true
            },
            {
                "suburbId": 1197,
                "suburbName": "OLARY",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1198,
                "suburbName": "OLD CALPERUM",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 1199,
                "suburbName": "OLD KOOMOOLOO",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 1200,
                "suburbName": "OLD NOARLUNGA",
                "postCode": "5168",
                "isMetro": true
            },
            {
                "suburbId": 1201,
                "suburbName": "OLD REYNELLA",
                "postCode": "5161",
                "isMetro": true
            },
            {
                "suburbId": 1202,
                "suburbName": "OLD TEAL FLAT",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1203,
                "suburbName": "OLYMPIC DAM",
                "postCode": "5725",
                "isMetro": false
            },
            {
                "suburbId": 1204,
                "suburbName": "ONE TREE HILL",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 1205,
                "suburbName": "ONKAPARINGA HILLS",
                "postCode": "5163",
                "isMetro": true
            },
            {
                "suburbId": 1206,
                "suburbName": "OODLA WIRRA",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 1207,
                "suburbName": "OODNADATTA",
                "postCode": "5734",
                "isMetro": false
            },
            {
                "suburbId": 1208,
                "suburbName": "ORATUNGA STATION",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1209,
                "suburbName": "ORROROO",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 1210,
                "suburbName": "OSBORNE",
                "postCode": "5017",
                "isMetro": true
            },
            {
                "suburbId": 1211,
                "suburbName": "O'SULLIVAN BEACH",
                "postCode": "5166",
                "isMetro": true
            },
            {
                "suburbId": 1212,
                "suburbName": "OTTOWAY",
                "postCode": "5013",
                "isMetro": true
            },
            {
                "suburbId": 1213,
                "suburbName": "OULNINA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1214,
                "suburbName": "OULNINA PARK",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1215,
                "suburbName": "OUTALPA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1216,
                "suburbName": "OUTER HARBOR",
                "postCode": "5018",
                "isMetro": true
            },
            {
                "suburbId": 1217,
                "suburbName": "OVERLAND CORNER",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 1218,
                "suburbName": "OVINGHAM",
                "postCode": "5082",
                "isMetro": true
            },
            {
                "suburbId": 1219,
                "suburbName": "OWEN",
                "postCode": "5460",
                "isMetro": false
            },
            {
                "suburbId": 1220,
                "suburbName": "PADTHAWAY",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1221,
                "suburbName": "PAECHTOWN",
                "postCode": "5245",
                "isMetro": true
            },
            {
                "suburbId": 1222,
                "suburbName": "PAGES FLAT",
                "postCode": "5172",
                "isMetro": false
            },
            {
                "suburbId": 1223,
                "suburbName": "PAISLEY",
                "postCode": "5357",
                "isMetro": false
            },
            {
                "suburbId": 1224,
                "suburbName": "PALKAGEE",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 1225,
                "suburbName": "PALLAMANA",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1226,
                "suburbName": "PALMER",
                "postCode": "5237",
                "isMetro": false
            },
            {
                "suburbId": 1227,
                "suburbName": "PANDIE PANDIE",
                "postCode": "5733",
                "isMetro": false
            },
            {
                "suburbId": 1228,
                "suburbName": "PANDURRA",
                "postCode": "5715",
                "isMetro": false
            },
            {
                "suburbId": 1229,
                "suburbName": "PANITYA",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 1230,
                "suburbName": "PANORAMA",
                "postCode": "5041",
                "isMetro": true
            },
            {
                "suburbId": 1231,
                "suburbName": "PARA HILLS",
                "postCode": "5096",
                "isMetro": true
            },
            {
                "suburbId": 1232,
                "suburbName": "PARA HILLS WEST",
                "postCode": "5096",
                "isMetro": true
            },
            {
                "suburbId": 1233,
                "suburbName": "PARA VISTA",
                "postCode": "5093",
                "isMetro": true
            },
            {
                "suburbId": 1234,
                "suburbName": "PARACHILNA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1235,
                "suburbName": "PARACOMBE",
                "postCode": "5132",
                "isMetro": true
            },
            {
                "suburbId": 1236,
                "suburbName": "PARADISE",
                "postCode": "5075",
                "isMetro": true
            },
            {
                "suburbId": 1237,
                "suburbName": "PARAFIELD",
                "postCode": "5106",
                "isMetro": true
            },
            {
                "suburbId": 1238,
                "suburbName": "PARAFIELD GARDENS",
                "postCode": "5107",
                "isMetro": true
            },
            {
                "suburbId": 1239,
                "suburbName": "PARAKYLIA",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 1240,
                "suburbName": "PARALOWIE",
                "postCode": "5108",
                "isMetro": true
            },
            {
                "suburbId": 1241,
                "suburbName": "PARAMATTA",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 1242,
                "suburbName": "PARATOO",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 1243,
                "suburbName": "PARAWA",
                "postCode": "5203",
                "isMetro": false
            },
            {
                "suburbId": 1244,
                "suburbName": "PARCOOLA",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 1245,
                "suburbName": "PARHAM",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 1246,
                "suburbName": "PARILLA",
                "postCode": "5303",
                "isMetro": false
            },
            {
                "suburbId": 1247,
                "suburbName": "PARINGA",
                "postCode": "5340",
                "isMetro": false
            },
            {
                "suburbId": 1248,
                "suburbName": "PARIS CREEK",
                "postCode": "5201",
                "isMetro": false
            },
            {
                "suburbId": 1249,
                "suburbName": "PARK HOLME",
                "postCode": "5043",
                "isMetro": true
            },
            {
                "suburbId": 1250,
                "suburbName": "PARKSIDE",
                "postCode": "5063",
                "isMetro": true
            },
            {
                "suburbId": 1251,
                "suburbName": "PARNAROO",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 1252,
                "suburbName": "PARNDANA",
                "postCode": "5220",
                "isMetro": false
            },
            {
                "suburbId": 1253,
                "suburbName": "PARRAKIE",
                "postCode": "5301",
                "isMetro": false
            },
            {
                "suburbId": 1254,
                "suburbName": "PARSONS BEACH",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 1255,
                "suburbName": "PARUNA",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 1256,
                "suburbName": "PASADENA",
                "postCode": "5042",
                "isMetro": true
            },
            {
                "suburbId": 1257,
                "suburbName": "PASKEVILLE",
                "postCode": "5552",
                "isMetro": false
            },
            {
                "suburbId": 1258,
                "suburbName": "PATA",
                "postCode": "5333",
                "isMetro": false
            },
            {
                "suburbId": 1259,
                "suburbName": "PAYNEHAM",
                "postCode": "5070",
                "isMetro": true
            },
            {
                "suburbId": 1260,
                "suburbName": "PAYNEHAM SOUTH",
                "postCode": "5070",
                "isMetro": true
            },
            {
                "suburbId": 1261,
                "suburbName": "PEAKE",
                "postCode": "5301",
                "isMetro": false
            },
            {
                "suburbId": 1262,
                "suburbName": "PEARLAH",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1263,
                "suburbName": "PEEBINGA",
                "postCode": "5304",
                "isMetro": false
            },
            {
                "suburbId": 1264,
                "suburbName": "PEEP HILL",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 1265,
                "suburbName": "PEKINA",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 1266,
                "suburbName": "PELICAN LAGOON",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 1267,
                "suburbName": "PELICAN POINT",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1268,
                "suburbName": "PELLARING FLAT",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1269,
                "suburbName": "PENFIELD",
                "postCode": "5121",
                "isMetro": true
            },
            {
                "suburbId": 1270,
                "suburbName": "PENFIELD GARDENS",
                "postCode": "5121",
                "isMetro": true
            },
            {
                "suburbId": 1271,
                "suburbName": "PENNESHAW",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 1272,
                "suburbName": "PENNINGTON",
                "postCode": "5013",
                "isMetro": true
            },
            {
                "suburbId": 1273,
                "suburbName": "PENOLA",
                "postCode": "5277",
                "isMetro": false
            },
            {
                "suburbId": 1274,
                "suburbName": "PENONG",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1275,
                "suburbName": "PENRICE",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 1276,
                "suburbName": "PENWORTHAM",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 1277,
                "suburbName": "PERLUBIE",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1278,
                "suburbName": "PERNATTY",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 1279,
                "suburbName": "PERPONDA",
                "postCode": "5308",
                "isMetro": false
            },
            {
                "suburbId": 1280,
                "suburbName": "PETERBOROUGH",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 1281,
                "suburbName": "PETERHEAD",
                "postCode": "5016",
                "isMetro": true
            },
            {
                "suburbId": 1282,
                "suburbName": "PETERSVILLE",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 1283,
                "suburbName": "PETHERICK",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 1284,
                "suburbName": "PETINA",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1285,
                "suburbName": "PETWOOD",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1286,
                "suburbName": "PEWSEY VALE",
                "postCode": "5351",
                "isMetro": false
            },
            {
                "suburbId": 1287,
                "suburbName": "PICCADILLY",
                "postCode": "5151",
                "isMetro": true
            },
            {
                "suburbId": 1288,
                "suburbName": "PIEDNIPPIE",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1289,
                "suburbName": "PIKE RIVER",
                "postCode": "5340",
                "isMetro": false
            },
            {
                "suburbId": 1290,
                "suburbName": "PIMBA",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 1291,
                "suburbName": "PIMBAACLA",
                "postCode": "5661",
                "isMetro": false
            },
            {
                "suburbId": 1292,
                "suburbName": "PINDA SPRINGS",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1293,
                "suburbName": "PINE CREEK",
                "postCode": "5419",
                "isMetro": false
            },
            {
                "suburbId": 1294,
                "suburbName": "PINE CREEK STATION",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1295,
                "suburbName": "PINE HILL",
                "postCode": "5269",
                "isMetro": false
            },
            {
                "suburbId": 1296,
                "suburbName": "PINE POINT",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 1297,
                "suburbName": "PINE VALLEY STATION",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 1298,
                "suburbName": "PINERY",
                "postCode": "5460",
                "isMetro": false
            },
            {
                "suburbId": 1299,
                "suburbName": "PINJARRA STATION",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1300,
                "suburbName": "PINKAWILLINIE",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 1301,
                "suburbName": "PINKERTON PLAINS",
                "postCode": "5400",
                "isMetro": false
            },
            {
                "suburbId": 1302,
                "suburbName": "PINKS BEACH",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1303,
                "suburbName": "PINNAROO",
                "postCode": "5304",
                "isMetro": false
            },
            {
                "suburbId": 1304,
                "suburbName": "PIPALYATJARA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 1305,
                "suburbName": "PIRIE EAST",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1306,
                "suburbName": "PLEASANT PARK",
                "postCode": "5277",
                "isMetro": false
            },
            {
                "suburbId": 1307,
                "suburbName": "PLUMBAGO",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1308,
                "suburbName": "PLYMPTON",
                "postCode": "5038",
                "isMetro": true
            },
            {
                "suburbId": 1309,
                "suburbName": "PLYMPTON PARK",
                "postCode": "5038",
                "isMetro": true
            },
            {
                "suburbId": 1310,
                "suburbName": "POINT BOSTON",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1311,
                "suburbName": "POINT LOWLY",
                "postCode": "5601",
                "isMetro": false
            },
            {
                "suburbId": 1312,
                "suburbName": "POINT LOWLY NORTH",
                "postCode": "5601",
                "isMetro": false
            },
            {
                "suburbId": 1313,
                "suburbName": "POINT PASS",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 1314,
                "suburbName": "POINT PEARCE",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 1315,
                "suburbName": "POINT SOUTTAR",
                "postCode": "5577",
                "isMetro": false
            },
            {
                "suburbId": 1316,
                "suburbName": "POINT STURT",
                "postCode": "5256",
                "isMetro": false
            },
            {
                "suburbId": 1317,
                "suburbName": "POINT TURTON",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 1318,
                "suburbName": "POLDA",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 1319,
                "suburbName": "POLISH HILL RIVER",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 1320,
                "suburbName": "POLTALLOCH",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 1321,
                "suburbName": "POMPOOTA",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1322,
                "suburbName": "PONDE",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1323,
                "suburbName": "POOCHERA",
                "postCode": "5655",
                "isMetro": false
            },
            {
                "suburbId": 1324,
                "suburbName": "POOGINAGORIC",
                "postCode": "5268",
                "isMetro": false
            },
            {
                "suburbId": 1325,
                "suburbName": "POOGINOOK",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 1326,
                "suburbName": "POONINDIE",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1327,
                "suburbName": "POORAKA",
                "postCode": "5095",
                "isMetro": true
            },
            {
                "suburbId": 1328,
                "suburbName": "PORKY FLAT",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 1329,
                "suburbName": "PORT ADELAIDE",
                "postCode": "5015",
                "isMetro": true
            },
            {
                "suburbId": 1330,
                "suburbName": "PORT ARTHUR",
                "postCode": "5552",
                "isMetro": false
            },
            {
                "suburbId": 1331,
                "suburbName": "PORT AUGUSTA",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 1332,
                "suburbName": "PORT AUGUSTA WEST",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 1333,
                "suburbName": "PORT BONYTHON",
                "postCode": "5601",
                "isMetro": false
            },
            {
                "suburbId": 1334,
                "suburbName": "PORT BROUGHTON",
                "postCode": "5522",
                "isMetro": false
            },
            {
                "suburbId": 1335,
                "suburbName": "PORT DAVIS",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1336,
                "suburbName": "PORT ELLIOT",
                "postCode": "5212",
                "isMetro": false
            },
            {
                "suburbId": 1337,
                "suburbName": "PORT GAWLER",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 1338,
                "suburbName": "PORT GERMEIN",
                "postCode": "5495",
                "isMetro": false
            },
            {
                "suburbId": 1339,
                "suburbName": "PORT GIBBON",
                "postCode": "5602",
                "isMetro": false
            },
            {
                "suburbId": 1340,
                "suburbName": "PORT HUGHES",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 1341,
                "suburbName": "PORT JULIA",
                "postCode": "5580",
                "isMetro": false
            },
            {
                "suburbId": 1342,
                "suburbName": "PORT KENNY",
                "postCode": "5671",
                "isMetro": false
            },
            {
                "suburbId": 1343,
                "suburbName": "PORT LINCOLN",
                "postCode": "5606",
                "isMetro": false
            },
            {
                "suburbId": 1344,
                "suburbName": "PORT MACDONNELL",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1345,
                "suburbName": "PORT MANNUM",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1346,
                "suburbName": "PORT MOOROWIE",
                "postCode": "5576",
                "isMetro": false
            },
            {
                "suburbId": 1347,
                "suburbName": "PORT NEILL",
                "postCode": "5604",
                "isMetro": false
            },
            {
                "suburbId": 1348,
                "suburbName": "PORT NOARLUNGA",
                "postCode": "5167",
                "isMetro": true
            },
            {
                "suburbId": 1349,
                "suburbName": "PORT NOARLUNGA SOUTH",
                "postCode": "5167",
                "isMetro": true
            },
            {
                "suburbId": 1350,
                "suburbName": "PORT PATERSON",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 1351,
                "suburbName": "PORT PIRIE",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1352,
                "suburbName": "PORT PIRIE SOUTH",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1353,
                "suburbName": "PORT PIRIE WEST",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1354,
                "suburbName": "PORT RICKABY",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 1355,
                "suburbName": "PORT VICTORIA",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 1356,
                "suburbName": "PORT VINCENT",
                "postCode": "5581",
                "isMetro": false
            },
            {
                "suburbId": 1357,
                "suburbName": "PORT WAKEFIELD",
                "postCode": "5550",
                "isMetro": false
            },
            {
                "suburbId": 1358,
                "suburbName": "PORT WILLUNGA",
                "postCode": "5173",
                "isMetro": true
            },
            {
                "suburbId": 1359,
                "suburbName": "PORTER LAGOON",
                "postCode": "5416",
                "isMetro": false
            },
            {
                "suburbId": 1360,
                "suburbName": "PRELINNA",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 1361,
                "suburbName": "PRICE",
                "postCode": "5570",
                "isMetro": false
            },
            {
                "suburbId": 1362,
                "suburbName": "PROOF RANGE",
                "postCode": "5550",
                "isMetro": false
            },
            {
                "suburbId": 1363,
                "suburbName": "PROSPECT",
                "postCode": "5082",
                "isMetro": true
            },
            {
                "suburbId": 1364,
                "suburbName": "PROSPECT HILL",
                "postCode": "5201",
                "isMetro": false
            },
            {
                "suburbId": 1365,
                "suburbName": "PUALCO RANGE",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1366,
                "suburbName": "PUKATJA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 1367,
                "suburbName": "PUNTABIE",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1368,
                "suburbName": "PUNTHARI",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1369,
                "suburbName": "PUNYELROO",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 1370,
                "suburbName": "PUREBA",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1371,
                "suburbName": "PURNONG",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1372,
                "suburbName": "PURPLE DOWNS",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 1373,
                "suburbName": "PUTTAPA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1374,
                "suburbName": "PYAP",
                "postCode": "5333",
                "isMetro": false
            },
            {
                "suburbId": 1375,
                "suburbName": "PYAP WEST",
                "postCode": "5333",
                "isMetro": false
            },
            {
                "suburbId": 1376,
                "suburbName": "PYGERY",
                "postCode": "5652",
                "isMetro": false
            },
            {
                "suburbId": 1377,
                "suburbName": "QUALCO",
                "postCode": "5322",
                "isMetro": false
            },
            {
                "suburbId": 1378,
                "suburbName": "QUEENSTOWN",
                "postCode": "5014",
                "isMetro": true
            },
            {
                "suburbId": 1379,
                "suburbName": "QUINYAMBIE",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1380,
                "suburbName": "QUONDONG",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 1381,
                "suburbName": "QUORN",
                "postCode": "5433",
                "isMetro": false
            },
            {
                "suburbId": 1382,
                "suburbName": "RACECOURSE BAY",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1383,
                "suburbName": "RAMCO",
                "postCode": "5322",
                "isMetro": false
            },
            {
                "suburbId": 1384,
                "suburbName": "RAMCO HEIGHTS",
                "postCode": "5322",
                "isMetro": false
            },
            {
                "suburbId": 1385,
                "suburbName": "RAMSAY",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 1386,
                "suburbName": "RAPID BAY",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 1387,
                "suburbName": "RED CREEK",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1388,
                "suburbName": "REDBANKS",
                "postCode": "5502",
                "isMetro": false
            },
            {
                "suburbId": 1389,
                "suburbName": "REDHILL",
                "postCode": "5521",
                "isMetro": false
            },
            {
                "suburbId": 1390,
                "suburbName": "REDWOOD PARK",
                "postCode": "5097",
                "isMetro": true
            },
            {
                "suburbId": 1391,
                "suburbName": "REEDY CREEK",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1392,
                "suburbName": "REEVES PLAINS",
                "postCode": "5502",
                "isMetro": false
            },
            {
                "suburbId": 1393,
                "suburbName": "REGENCY PARK",
                "postCode": "5010",
                "isMetro": true
            },
            {
                "suburbId": 1394,
                "suburbName": "REID",
                "postCode": "5118",
                "isMetro": false
            },
            {
                "suburbId": 1395,
                "suburbName": "RENDELSHAM",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 1396,
                "suburbName": "RENMARK",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 1397,
                "suburbName": "RENMARK NORTH",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 1398,
                "suburbName": "RENMARK SOUTH",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 1399,
                "suburbName": "RENMARK WEST",
                "postCode": "5341",
                "isMetro": false
            },
            {
                "suburbId": 1400,
                "suburbName": "RENOWN PARK",
                "postCode": "5008",
                "isMetro": true
            },
            {
                "suburbId": 1401,
                "suburbName": "REYNELLA",
                "postCode": "5161",
                "isMetro": true
            },
            {
                "suburbId": 1402,
                "suburbName": "REYNELLA EAST",
                "postCode": "5161",
                "isMetro": true
            },
            {
                "suburbId": 1403,
                "suburbName": "RHYNIE",
                "postCode": "5412",
                "isMetro": false
            },
            {
                "suburbId": 1404,
                "suburbName": "RICHMOND",
                "postCode": "5033",
                "isMetro": true
            },
            {
                "suburbId": 1405,
                "suburbName": "RIDGEHAVEN",
                "postCode": "5097",
                "isMetro": true
            },
            {
                "suburbId": 1406,
                "suburbName": "RIDLEYTON",
                "postCode": "5008",
                "isMetro": true
            },
            {
                "suburbId": 1407,
                "suburbName": "RISDON PARK",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1408,
                "suburbName": "RISDON PARK SOUTH",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1409,
                "suburbName": "RIVERGLADES",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1410,
                "suburbName": "RIVERGLEN",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1411,
                "suburbName": "RIVERTON",
                "postCode": "5412",
                "isMetro": false
            },
            {
                "suburbId": 1412,
                "suburbName": "ROBE",
                "postCode": "5276",
                "isMetro": false
            },
            {
                "suburbId": 1413,
                "suburbName": "ROBERTSTOWN",
                "postCode": "5381",
                "isMetro": false
            },
            {
                "suburbId": 1414,
                "suburbName": "ROCHESTER",
                "postCode": "5464",
                "isMetro": false
            },
            {
                "suburbId": 1415,
                "suburbName": "ROCKLEIGH",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1416,
                "suburbName": "ROCKY CAMP",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 1417,
                "suburbName": "ROCKY GULLY",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1418,
                "suburbName": "ROCKY PLAIN",
                "postCode": "5381",
                "isMetro": false
            },
            {
                "suburbId": 1419,
                "suburbName": "ROCKY POINT",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1420,
                "suburbName": "ROGUES POINT",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 1421,
                "suburbName": "ROSE PARK",
                "postCode": "5067",
                "isMetro": true
            },
            {
                "suburbId": 1422,
                "suburbName": "ROSEDALE",
                "postCode": "5350",
                "isMetro": false
            },
            {
                "suburbId": 1423,
                "suburbName": "ROSETOWN",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1424,
                "suburbName": "ROSEWATER",
                "postCode": "5013",
                "isMetro": true
            },
            {
                "suburbId": 1425,
                "suburbName": "ROSEWORTHY",
                "postCode": "5371",
                "isMetro": true
            },
            {
                "suburbId": 1426,
                "suburbName": "ROSSLYN PARK",
                "postCode": "5072",
                "isMetro": true
            },
            {
                "suburbId": 1427,
                "suburbName": "ROSTREVOR",
                "postCode": "5073",
                "isMetro": true
            },
            {
                "suburbId": 1428,
                "suburbName": "ROWLAND FLAT",
                "postCode": "5352",
                "isMetro": true
            },
            {
                "suburbId": 1429,
                "suburbName": "ROXBY DOWNS",
                "postCode": "5725",
                "isMetro": false
            },
            {
                "suburbId": 1430,
                "suburbName": "ROXBY DOWNS STATION",
                "postCode": "5725",
                "isMetro": false
            },
            {
                "suburbId": 1431,
                "suburbName": "ROYAL PARK",
                "postCode": "5014",
                "isMetro": true
            },
            {
                "suburbId": 1432,
                "suburbName": "ROYSTON PARK",
                "postCode": "5070",
                "isMetro": true
            },
            {
                "suburbId": 1433,
                "suburbName": "RUDALL",
                "postCode": "5642",
                "isMetro": false
            },
            {
                "suburbId": 1434,
                "suburbName": "SADDLEWORTH",
                "postCode": "5413",
                "isMetro": false
            },
            {
                "suburbId": 1435,
                "suburbName": "SAINTS",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 1436,
                "suburbName": "SALEM",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1437,
                "suburbName": "SALISBURY",
                "postCode": "5108",
                "isMetro": true
            },
            {
                "suburbId": 1438,
                "suburbName": "SALISBURY DOWNS",
                "postCode": "5108",
                "isMetro": true
            },
            {
                "suburbId": 1439,
                "suburbName": "SALISBURY EAST",
                "postCode": "5109",
                "isMetro": true
            },
            {
                "suburbId": 1440,
                "suburbName": "SALISBURY HEIGHTS",
                "postCode": "5109",
                "isMetro": true
            },
            {
                "suburbId": 1441,
                "suburbName": "SALISBURY NORTH",
                "postCode": "5108",
                "isMetro": true
            },
            {
                "suburbId": 1442,
                "suburbName": "SALISBURY PARK",
                "postCode": "5109",
                "isMetro": true
            },
            {
                "suburbId": 1443,
                "suburbName": "SALISBURY PLAIN",
                "postCode": "5109",
                "isMetro": true
            },
            {
                "suburbId": 1444,
                "suburbName": "SALISBURY SOUTH",
                "postCode": "5106",
                "isMetro": true
            },
            {
                "suburbId": 1445,
                "suburbName": "SALT CREEK",
                "postCode": "5264",
                "isMetro": false
            },
            {
                "suburbId": 1446,
                "suburbName": "SALTER SPRINGS",
                "postCode": "5401",
                "isMetro": false
            },
            {
                "suburbId": 1447,
                "suburbName": "SALTIA",
                "postCode": "5433",
                "isMetro": false
            },
            {
                "suburbId": 1448,
                "suburbName": "SAMPSON FLAT",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 1449,
                "suburbName": "SANDALWOOD",
                "postCode": "5309",
                "isMetro": false
            },
            {
                "suburbId": 1450,
                "suburbName": "SANDERGROVE",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1451,
                "suburbName": "SANDERSTON",
                "postCode": "5237",
                "isMetro": false
            },
            {
                "suburbId": 1452,
                "suburbName": "SANDILANDS",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 1453,
                "suburbName": "SANDLETON",
                "postCode": "5356",
                "isMetro": false
            },
            {
                "suburbId": 1454,
                "suburbName": "SANDY CREEK",
                "postCode": "5350",
                "isMetro": true
            },
            {
                "suburbId": 1455,
                "suburbName": "SANDY GROVE",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1456,
                "suburbName": "SAPPHIRETOWN",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 1457,
                "suburbName": "SCEALE BAY",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1458,
                "suburbName": "SCHELL WELL",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 1459,
                "suburbName": "SCOTT CREEK",
                "postCode": "5153",
                "isMetro": true
            },
            {
                "suburbId": 1460,
                "suburbName": "SEACLIFF",
                "postCode": "5049",
                "isMetro": true
            },
            {
                "suburbId": 1461,
                "suburbName": "SEACLIFF PARK",
                "postCode": "5049",
                "isMetro": true
            },
            {
                "suburbId": 1462,
                "suburbName": "SEACOMBE GARDENS",
                "postCode": "5047",
                "isMetro": true
            },
            {
                "suburbId": 1463,
                "suburbName": "SEACOMBE HEIGHTS",
                "postCode": "5047",
                "isMetro": true
            },
            {
                "suburbId": 1464,
                "suburbName": "SEAFORD",
                "postCode": "5169",
                "isMetro": true
            },
            {
                "suburbId": 1465,
                "suburbName": "SEAFORD HEIGHTS",
                "postCode": "5169",
                "isMetro": true
            },
            {
                "suburbId": 1466,
                "suburbName": "SEAFORD MEADOWS",
                "postCode": "5169",
                "isMetro": true
            },
            {
                "suburbId": 1467,
                "suburbName": "SEAFORD RISE",
                "postCode": "5169",
                "isMetro": true
            },
            {
                "suburbId": 1468,
                "suburbName": "SEAL BAY",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1469,
                "suburbName": "SEATON",
                "postCode": "5023",
                "isMetro": true
            },
            {
                "suburbId": 1470,
                "suburbName": "SEAVIEW DOWNS",
                "postCode": "5049",
                "isMetro": true
            },
            {
                "suburbId": 1471,
                "suburbName": "SEBASTOPOL",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 1472,
                "suburbName": "SECOND VALLEY",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 1473,
                "suburbName": "SECRET ROCKS",
                "postCode": "5611",
                "isMetro": false
            },
            {
                "suburbId": 1474,
                "suburbName": "SEDAN",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 1475,
                "suburbName": "SEDDON",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1476,
                "suburbName": "SEFTON PARK",
                "postCode": "5083",
                "isMetro": true
            },
            {
                "suburbId": 1477,
                "suburbName": "SELLICKS BEACH",
                "postCode": "5174",
                "isMetro": true
            },
            {
                "suburbId": 1478,
                "suburbName": "SELLICKS HILL",
                "postCode": "5174",
                "isMetro": true
            },
            {
                "suburbId": 1479,
                "suburbName": "SEMAPHORE",
                "postCode": "5019",
                "isMetro": true
            },
            {
                "suburbId": 1480,
                "suburbName": "SEMAPHORE PARK",
                "postCode": "5019",
                "isMetro": true
            },
            {
                "suburbId": 1481,
                "suburbName": "SEMAPHORE SOUTH",
                "postCode": "5019",
                "isMetro": true
            },
            {
                "suburbId": 1482,
                "suburbName": "SENIOR",
                "postCode": "5268",
                "isMetro": false
            },
            {
                "suburbId": 1483,
                "suburbName": "SEPPELTSFIELD",
                "postCode": "5355",
                "isMetro": false
            },
            {
                "suburbId": 1484,
                "suburbName": "SEVENHILL",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 1485,
                "suburbName": "SHAGGY RIDGE",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 1486,
                "suburbName": "SHAUGH",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 1487,
                "suburbName": "SHEAOAK FLAT",
                "postCode": "5581",
                "isMetro": false
            },
            {
                "suburbId": 1488,
                "suburbName": "SHEA-OAK LOG",
                "postCode": "5371",
                "isMetro": false
            },
            {
                "suburbId": 1489,
                "suburbName": "SHEIDOW PARK",
                "postCode": "5158",
                "isMetro": true
            },
            {
                "suburbId": 1490,
                "suburbName": "SHERINGA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1491,
                "suburbName": "SHERLOCK",
                "postCode": "5301",
                "isMetro": false
            },
            {
                "suburbId": 1492,
                "suburbName": "SHERWOOD",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 1493,
                "suburbName": "SHORT",
                "postCode": "5279",
                "isMetro": false
            },
            {
                "suburbId": 1494,
                "suburbName": "SIAM",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 1495,
                "suburbName": "SILVERTON",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 1496,
                "suburbName": "SIMPSON DESERT",
                "postCode": "5734",
                "isMetro": false
            },
            {
                "suburbId": 1497,
                "suburbName": "SKYE",
                "postCode": "5072",
                "isMetro": true
            },
            {
                "suburbId": 1498,
                "suburbName": "SLEAFORD",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1499,
                "suburbName": "SMITHFIELD",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 1500,
                "suburbName": "SMITHFIELD PLAINS",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 1501,
                "suburbName": "SMOKY BAY",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1502,
                "suburbName": "SNOWTOWN",
                "postCode": "5520",
                "isMetro": false
            },
            {
                "suburbId": 1503,
                "suburbName": "SOLOMON",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 1504,
                "suburbName": "SOLOMONTOWN",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1505,
                "suburbName": "SOMERTON PARK",
                "postCode": "5044",
                "isMetro": true
            },
            {
                "suburbId": 1506,
                "suburbName": "SOUTH BRIGHTON",
                "postCode": "5048",
                "isMetro": true
            },
            {
                "suburbId": 1507,
                "suburbName": "SOUTH GAP",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 1508,
                "suburbName": "SOUTH HUMMOCKS",
                "postCode": "5550",
                "isMetro": false
            },
            {
                "suburbId": 1509,
                "suburbName": "SOUTH KILKERRAN",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 1510,
                "suburbName": "SOUTH PLYMPTON",
                "postCode": "5038",
                "isMetro": true
            },
            {
                "suburbId": 1511,
                "suburbName": "SOUTHEND",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 1512,
                "suburbName": "SPALDING",
                "postCode": "5454",
                "isMetro": false
            },
            {
                "suburbId": 1513,
                "suburbName": "SPECTACLE LAKE",
                "postCode": "5345",
                "isMetro": false
            },
            {
                "suburbId": 1514,
                "suburbName": "SPENCE",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1515,
                "suburbName": "SPRING FARM",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 1516,
                "suburbName": "SPRING GULLY",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 1517,
                "suburbName": "SPRINGFIELD",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 1518,
                "suburbName": "SPRINGTON",
                "postCode": "5235",
                "isMetro": false
            },
            {
                "suburbId": 1519,
                "suburbName": "SQUARE MILE",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1520,
                "suburbName": "ST AGNES",
                "postCode": "5097",
                "isMetro": true
            },
            {
                "suburbId": 1521,
                "suburbName": "ST CLAIR",
                "postCode": "5011",
                "isMetro": true
            },
            {
                "suburbId": 1522,
                "suburbName": "ST GEORGES",
                "postCode": "5064",
                "isMetro": true
            },
            {
                "suburbId": 1523,
                "suburbName": "ST IVES",
                "postCode": "5252",
                "isMetro": false
            },
            {
                "suburbId": 1524,
                "suburbName": "ST JOHNS",
                "postCode": "5373",
                "isMetro": false
            },
            {
                "suburbId": 1525,
                "suburbName": "ST KILDA",
                "postCode": "5110",
                "isMetro": true
            },
            {
                "suburbId": 1526,
                "suburbName": "ST KITTS",
                "postCode": "5356",
                "isMetro": false
            },
            {
                "suburbId": 1527,
                "suburbName": "ST MARYS",
                "postCode": "5042",
                "isMetro": true
            },
            {
                "suburbId": 1528,
                "suburbName": "ST MORRIS",
                "postCode": "5068",
                "isMetro": true
            },
            {
                "suburbId": 1529,
                "suburbName": "ST PETERS",
                "postCode": "5069",
                "isMetro": true
            },
            {
                "suburbId": 1530,
                "suburbName": "STANLEY",
                "postCode": "5415",
                "isMetro": false
            },
            {
                "suburbId": 1531,
                "suburbName": "STANLEY FLAT",
                "postCode": "5453",
                "isMetro": false
            },
            {
                "suburbId": 1532,
                "suburbName": "STANSBURY",
                "postCode": "5582",
                "isMetro": false
            },
            {
                "suburbId": 1533,
                "suburbName": "STEELTON",
                "postCode": "5413",
                "isMetro": false
            },
            {
                "suburbId": 1534,
                "suburbName": "STEINFELD",
                "postCode": "5356",
                "isMetro": false
            },
            {
                "suburbId": 1535,
                "suburbName": "STEPHENSTON",
                "postCode": "5433",
                "isMetro": false
            },
            {
                "suburbId": 1536,
                "suburbName": "STEPNEY",
                "postCode": "5069",
                "isMetro": true
            },
            {
                "suburbId": 1537,
                "suburbName": "STEWART RANGE",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1538,
                "suburbName": "STIRLING",
                "postCode": "5152",
                "isMetro": true
            },
            {
                "suburbId": 1539,
                "suburbName": "STIRLING NORTH",
                "postCode": "5710",
                "isMetro": false
            },
            {
                "suburbId": 1540,
                "suburbName": "STOCKPORT",
                "postCode": "5410",
                "isMetro": false
            },
            {
                "suburbId": 1541,
                "suburbName": "STOCKWELL",
                "postCode": "5355",
                "isMetro": false
            },
            {
                "suburbId": 1542,
                "suburbName": "STOCKYARD CREEK",
                "postCode": "5460",
                "isMetro": false
            },
            {
                "suburbId": 1543,
                "suburbName": "STOCKYARD PLAIN",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 1544,
                "suburbName": "STOKES BAY",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1545,
                "suburbName": "STONE HUT",
                "postCode": "5480",
                "isMetro": false
            },
            {
                "suburbId": 1546,
                "suburbName": "STONE WELL",
                "postCode": "5352",
                "isMetro": false
            },
            {
                "suburbId": 1547,
                "suburbName": "STONYFELL",
                "postCode": "5066",
                "isMetro": true
            },
            {
                "suburbId": 1548,
                "suburbName": "STOW",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 1549,
                "suburbName": "STRATHALBYN",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1550,
                "suburbName": "STREAKY BAY",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1551,
                "suburbName": "STRUAN",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1552,
                "suburbName": "STRZELECKI DESERT",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 1553,
                "suburbName": "STUART",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 1554,
                "suburbName": "STUARTS CREEK",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 1555,
                "suburbName": "STUN'SAIL BOOM",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1556,
                "suburbName": "STURT",
                "postCode": "5047",
                "isMetro": true
            },
            {
                "suburbId": 1557,
                "suburbName": "STURT VALE",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 1558,
                "suburbName": "SULTANA POINT",
                "postCode": "5583",
                "isMetro": false
            },
            {
                "suburbId": 1559,
                "suburbName": "SUMMERTOWN",
                "postCode": "5141",
                "isMetro": true
            },
            {
                "suburbId": 1560,
                "suburbName": "SUNLANDS",
                "postCode": "5322",
                "isMetro": false
            },
            {
                "suburbId": 1561,
                "suburbName": "SUNNYBRAE",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 1562,
                "suburbName": "SUNNYDALE",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 1563,
                "suburbName": "SUNNYSIDE",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1564,
                "suburbName": "SUNNYVALE",
                "postCode": "5552",
                "isMetro": false
            },
            {
                "suburbId": 1565,
                "suburbName": "SURREY DOWNS",
                "postCode": "5126",
                "isMetro": true
            },
            {
                "suburbId": 1566,
                "suburbName": "SUTHERLANDS",
                "postCode": "5374",
                "isMetro": false
            },
            {
                "suburbId": 1567,
                "suburbName": "SUTTONTOWN",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1568,
                "suburbName": "SWAN REACH",
                "postCode": "5354",
                "isMetro": false
            },
            {
                "suburbId": 1569,
                "suburbName": "SWANPORT",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1570,
                "suburbName": "SWEDE FLAT",
                "postCode": "5270",
                "isMetro": false
            },
            {
                "suburbId": 1571,
                "suburbName": "TAILEM BEND",
                "postCode": "5260",
                "isMetro": false
            },
            {
                "suburbId": 1572,
                "suburbName": "TALDRA",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 1573,
                "suburbName": "TALIA",
                "postCode": "5670",
                "isMetro": false
            },
            {
                "suburbId": 1574,
                "suburbName": "TANTANOOLA",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 1575,
                "suburbName": "TANUNDA",
                "postCode": "5352",
                "isMetro": true
            },
            {
                "suburbId": 1576,
                "suburbName": "TAPEROO",
                "postCode": "5017",
                "isMetro": true
            },
            {
                "suburbId": 1577,
                "suburbName": "TAPLAN",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 1578,
                "suburbName": "TARATAP",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1579,
                "suburbName": "TARCOOLA",
                "postCode": "5701",
                "isMetro": false
            },
            {
                "suburbId": 1580,
                "suburbName": "TARCOWIE",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 1581,
                "suburbName": "TARLEE",
                "postCode": "5411",
                "isMetro": false
            },
            {
                "suburbId": 1582,
                "suburbName": "TARNMA",
                "postCode": "5413",
                "isMetro": false
            },
            {
                "suburbId": 1583,
                "suburbName": "TARPEENA",
                "postCode": "5277",
                "isMetro": false
            },
            {
                "suburbId": 1584,
                "suburbName": "TATACHILLA",
                "postCode": "5171",
                "isMetro": true
            },
            {
                "suburbId": 1585,
                "suburbName": "TAUNTON",
                "postCode": "5235",
                "isMetro": false
            },
            {
                "suburbId": 1586,
                "suburbName": "TAYLORVILLE",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 1587,
                "suburbName": "TAYLORVILLE STATION",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 1588,
                "suburbName": "TEA TREE GULLY",
                "postCode": "5091",
                "isMetro": true
            },
            {
                "suburbId": 1589,
                "suburbName": "TEAL FLAT",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1590,
                "suburbName": "TELOWIE",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1591,
                "suburbName": "TEMPLERS",
                "postCode": "5371",
                "isMetro": false
            },
            {
                "suburbId": 1592,
                "suburbName": "TENNYSON",
                "postCode": "5022",
                "isMetro": true
            },
            {
                "suburbId": 1593,
                "suburbName": "TEPCO STATION",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1594,
                "suburbName": "TEPKO",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1595,
                "suburbName": "TERINGIE",
                "postCode": "5072",
                "isMetro": true
            },
            {
                "suburbId": 1596,
                "suburbName": "TEROWIE",
                "postCode": "5421",
                "isMetro": false
            },
            {
                "suburbId": 1597,
                "suburbName": "THE GAP",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1598,
                "suburbName": "THE PINES",
                "postCode": "5577",
                "isMetro": false
            },
            {
                "suburbId": 1599,
                "suburbName": "THE RANGE",
                "postCode": "5172",
                "isMetro": true
            },
            {
                "suburbId": 1600,
                "suburbName": "THEBARTON",
                "postCode": "5031",
                "isMetro": true
            },
            {
                "suburbId": 1601,
                "suburbName": "THEVENARD",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1602,
                "suburbName": "THOMAS PLAIN",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 1603,
                "suburbName": "THOMPSON BEACH",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 1604,
                "suburbName": "THORNGATE",
                "postCode": "5082",
                "isMetro": true
            },
            {
                "suburbId": 1605,
                "suburbName": "THORNLEA",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 1606,
                "suburbName": "THREE CREEKS",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 1607,
                "suburbName": "THRINGTON",
                "postCode": "5552",
                "isMetro": false
            },
            {
                "suburbId": 1608,
                "suburbName": "THURLGA",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 1609,
                "suburbName": "TIATUKIA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1610,
                "suburbName": "TICKERA",
                "postCode": "5555",
                "isMetro": false
            },
            {
                "suburbId": 1611,
                "suburbName": "TIDDY WIDDY BEACH",
                "postCode": "5571",
                "isMetro": false
            },
            {
                "suburbId": 1612,
                "suburbName": "TIEYON",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 1613,
                "suburbName": "TIKALINA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1614,
                "suburbName": "TILLEY SWAMP",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1615,
                "suburbName": "TINTINARA",
                "postCode": "5266",
                "isMetro": false
            },
            {
                "suburbId": 1616,
                "suburbName": "TODMORDEN",
                "postCode": "5734",
                "isMetro": false
            },
            {
                "suburbId": 1617,
                "suburbName": "TOLDEROL",
                "postCode": "5256",
                "isMetro": false
            },
            {
                "suburbId": 1618,
                "suburbName": "TONSLEY",
                "postCode": "5042",
                "isMetro": true
            },
            {
                "suburbId": 1619,
                "suburbName": "TOOLIGIE",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1620,
                "suburbName": "TOOPERANG",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1621,
                "suburbName": "TOORA",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1622,
                "suburbName": "TOORAK GARDENS",
                "postCode": "5065",
                "isMetro": true
            },
            {
                "suburbId": 1623,
                "suburbName": "TOOTENILLA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1624,
                "suburbName": "TORRENS ISLAND",
                "postCode": "5960",
                "isMetro": true
            },
            {
                "suburbId": 1625,
                "suburbName": "TORRENS PARK",
                "postCode": "5062",
                "isMetro": true
            },
            {
                "suburbId": 1626,
                "suburbName": "TORRENS VALE",
                "postCode": "5203",
                "isMetro": false
            },
            {
                "suburbId": 1627,
                "suburbName": "TORRENSVILLE",
                "postCode": "5031",
                "isMetro": true
            },
            {
                "suburbId": 1628,
                "suburbName": "TOTHILL BELT",
                "postCode": "5413",
                "isMetro": false
            },
            {
                "suburbId": 1629,
                "suburbName": "TOTHILL CREEK",
                "postCode": "5413",
                "isMetro": false
            },
            {
                "suburbId": 1630,
                "suburbName": "TOTNESS",
                "postCode": "5250",
                "isMetro": true
            },
            {
                "suburbId": 1631,
                "suburbName": "TOWITTA",
                "postCode": "5353",
                "isMetro": false
            },
            {
                "suburbId": 1632,
                "suburbName": "TRANMERE",
                "postCode": "5073",
                "isMetro": true
            },
            {
                "suburbId": 1633,
                "suburbName": "TRINITY GARDENS",
                "postCode": "5068",
                "isMetro": true
            },
            {
                "suburbId": 1634,
                "suburbName": "TROTT PARK",
                "postCode": "5158",
                "isMetro": true
            },
            {
                "suburbId": 1635,
                "suburbName": "TRURO",
                "postCode": "5356",
                "isMetro": false
            },
            {
                "suburbId": 1636,
                "suburbName": "TULKA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1637,
                "suburbName": "TUMBY BAY",
                "postCode": "5605",
                "isMetro": false
            },
            {
                "suburbId": 1638,
                "suburbName": "TUNGKILLO",
                "postCode": "5236",
                "isMetro": false
            },
            {
                "suburbId": 1639,
                "suburbName": "TUNKALILLA",
                "postCode": "5203",
                "isMetro": false
            },
            {
                "suburbId": 1640,
                "suburbName": "TUSMORE",
                "postCode": "5065",
                "isMetro": true
            },
            {
                "suburbId": 1641,
                "suburbName": "TWO WELLS",
                "postCode": "5501",
                "isMetro": true
            },
            {
                "suburbId": 1642,
                "suburbName": "TYRINGA",
                "postCode": "5671",
                "isMetro": false
            },
            {
                "suburbId": 1643,
                "suburbName": "UCOLTA",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 1644,
                "suburbName": "ULEY",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1645,
                "suburbName": "ULEYBURY",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 1646,
                "suburbName": "ULOOLOO",
                "postCode": "5419",
                "isMetro": false
            },
            {
                "suburbId": 1647,
                "suburbName": "ULYERRA",
                "postCode": "5633",
                "isMetro": false
            },
            {
                "suburbId": 1648,
                "suburbName": "UMBERATANA",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1649,
                "suburbName": "UMUWA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 1650,
                "suburbName": "UNDALYA",
                "postCode": "5451",
                "isMetro": false
            },
            {
                "suburbId": 1651,
                "suburbName": "UNDERDALE",
                "postCode": "5032",
                "isMetro": true
            },
            {
                "suburbId": 1652,
                "suburbName": "UNGARRA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1653,
                "suburbName": "UNLEY",
                "postCode": "5061",
                "isMetro": true
            },
            {
                "suburbId": 1654,
                "suburbName": "UNLEY PARK",
                "postCode": "5061",
                "isMetro": true
            },
            {
                "suburbId": 1655,
                "suburbName": "UNO",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 1656,
                "suburbName": "UPALINNA",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 1657,
                "suburbName": "UPPER HERMITAGE",
                "postCode": "5131",
                "isMetro": true
            },
            {
                "suburbId": 1658,
                "suburbName": "UPPER STURT",
                "postCode": "5156",
                "isMetro": true
            },
            {
                "suburbId": 1659,
                "suburbName": "URAIDLA",
                "postCode": "5142",
                "isMetro": true
            },
            {
                "suburbId": 1660,
                "suburbName": "URANIA",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 1661,
                "suburbName": "URRBRAE",
                "postCode": "5064",
                "isMetro": true
            },
            {
                "suburbId": 1662,
                "suburbName": "UWORRA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1663,
                "suburbName": "VALE PARK",
                "postCode": "5081",
                "isMetro": true
            },
            {
                "suburbId": 1664,
                "suburbName": "VALLEY VIEW",
                "postCode": "5093",
                "isMetro": true
            },
            {
                "suburbId": 1665,
                "suburbName": "VEITCH",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 1666,
                "suburbName": "VENUS BAY",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1667,
                "suburbName": "VERDUN",
                "postCode": "5245",
                "isMetro": true
            },
            {
                "suburbId": 1668,
                "suburbName": "VERRAN",
                "postCode": "5603",
                "isMetro": false
            },
            {
                "suburbId": 1669,
                "suburbName": "VICTOR HARBOR",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 1670,
                "suburbName": "VINE VALE",
                "postCode": "5352",
                "isMetro": false
            },
            {
                "suburbId": 1671,
                "suburbName": "VIRGINIA",
                "postCode": "5120",
                "isMetro": true
            },
            {
                "suburbId": 1672,
                "suburbName": "VISTA",
                "postCode": "5091",
                "isMetro": true
            },
            {
                "suburbId": 1673,
                "suburbName": "VIVONNE BAY",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1674,
                "suburbName": "WADDIKEE",
                "postCode": "5640",
                "isMetro": false
            },
            {
                "suburbId": 1675,
                "suburbName": "WADNAMINGA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1676,
                "suburbName": "WAIKERIE",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 1677,
                "suburbName": "WAITPINGA",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 1678,
                "suburbName": "WALKER FLAT",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1679,
                "suburbName": "WALKERVILLE",
                "postCode": "5081",
                "isMetro": true
            },
            {
                "suburbId": 1680,
                "suburbName": "WALKLEY HEIGHTS",
                "postCode": "5098",
                "isMetro": true
            },
            {
                "suburbId": 1681,
                "suburbName": "WALL FLAT",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1682,
                "suburbName": "WALLALA",
                "postCode": "5661",
                "isMetro": false
            },
            {
                "suburbId": 1683,
                "suburbName": "WALLAROO",
                "postCode": "5556",
                "isMetro": false
            },
            {
                "suburbId": 1684,
                "suburbName": "WALLAROO MINES",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 1685,
                "suburbName": "WALLAROO PLAIN",
                "postCode": "5556",
                "isMetro": false
            },
            {
                "suburbId": 1686,
                "suburbName": "WALLERBERDINA",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 1687,
                "suburbName": "WALLOWAY",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 1688,
                "suburbName": "WALTOWA",
                "postCode": "5264",
                "isMetro": false
            },
            {
                "suburbId": 1689,
                "suburbName": "WAMI KATA",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 1690,
                "suburbName": "WANBI",
                "postCode": "5310",
                "isMetro": false
            },
            {
                "suburbId": 1691,
                "suburbName": "WANDANA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1692,
                "suburbName": "WANDEARAH EAST",
                "postCode": "5523",
                "isMetro": false
            },
            {
                "suburbId": 1693,
                "suburbName": "WANDEARAH WEST",
                "postCode": "5523",
                "isMetro": false
            },
            {
                "suburbId": 1694,
                "suburbName": "WANDILO",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1695,
                "suburbName": "WANGARY",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1696,
                "suburbName": "WANGOLINA",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1697,
                "suburbName": "WANILLA",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1698,
                "suburbName": "WAPPILKA",
                "postCode": "5332",
                "isMetro": false
            },
            {
                "suburbId": 1699,
                "suburbName": "WARBURTO",
                "postCode": "5556",
                "isMetro": false
            },
            {
                "suburbId": 1700,
                "suburbName": "WARD BELT",
                "postCode": "5118",
                "isMetro": false
            },
            {
                "suburbId": 1701,
                "suburbName": "WARD HILL",
                "postCode": "5522",
                "isMetro": false
            },
            {
                "suburbId": 1702,
                "suburbName": "WARNERTOWN",
                "postCode": "5540",
                "isMetro": false
            },
            {
                "suburbId": 1703,
                "suburbName": "WARNES",
                "postCode": "5417",
                "isMetro": false
            },
            {
                "suburbId": 1704,
                "suburbName": "WAROOKA",
                "postCode": "5577",
                "isMetro": false
            },
            {
                "suburbId": 1705,
                "suburbName": "WAROONEE",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 1706,
                "suburbName": "WARRADALE",
                "postCode": "5046",
                "isMetro": true
            },
            {
                "suburbId": 1707,
                "suburbName": "WARRAMBOO",
                "postCode": "5650",
                "isMetro": false
            },
            {
                "suburbId": 1708,
                "suburbName": "WARRAWEENA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1709,
                "suburbName": "WARTAKA",
                "postCode": "5715",
                "isMetro": false
            },
            {
                "suburbId": 1710,
                "suburbName": "WASHPOOL",
                "postCode": "5454",
                "isMetro": false
            },
            {
                "suburbId": 1711,
                "suburbName": "WASLEYS",
                "postCode": "5400",
                "isMetro": false
            },
            {
                "suburbId": 1712,
                "suburbName": "WATARRU",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 1713,
                "suburbName": "WATCHMAN",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 1714,
                "suburbName": "WATERFALL GULLY",
                "postCode": "5066",
                "isMetro": true
            },
            {
                "suburbId": 1715,
                "suburbName": "WATERLOO",
                "postCode": "5413",
                "isMetro": false
            },
            {
                "suburbId": 1716,
                "suburbName": "WATERLOO CORNER",
                "postCode": "5110",
                "isMetro": true
            },
            {
                "suburbId": 1717,
                "suburbName": "WATERVALE",
                "postCode": "5452",
                "isMetro": false
            },
            {
                "suburbId": 1718,
                "suburbName": "WATINUMA",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 1719,
                "suburbName": "WATRABA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1720,
                "suburbName": "WATTLE FLAT",
                "postCode": "5203",
                "isMetro": false
            },
            {
                "suburbId": 1721,
                "suburbName": "WATTLE PARK",
                "postCode": "5066",
                "isMetro": true
            },
            {
                "suburbId": 1722,
                "suburbName": "WATTLE RANGE",
                "postCode": "5280",
                "isMetro": false
            },
            {
                "suburbId": 1723,
                "suburbName": "WATTLE RANGE EAST",
                "postCode": "5279",
                "isMetro": false
            },
            {
                "suburbId": 1724,
                "suburbName": "WAUKARINGA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1725,
                "suburbName": "WAURALTEE",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 1726,
                "suburbName": "WAYVILLE",
                "postCode": "5034",
                "isMetro": true
            },
            {
                "suburbId": 1727,
                "suburbName": "WEBB BEACH",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 1728,
                "suburbName": "WEDGE ISLAND",
                "postCode": "5606",
                "isMetro": false
            },
            {
                "suburbId": 1729,
                "suburbName": "WEEKEROO",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1730,
                "suburbName": "WEEROONA ISLAND",
                "postCode": "5495",
                "isMetro": false
            },
            {
                "suburbId": 1731,
                "suburbName": "WEETULTA",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 1732,
                "suburbName": "WELBOURN HILL",
                "postCode": "5724",
                "isMetro": false
            },
            {
                "suburbId": 1733,
                "suburbName": "WELLAND",
                "postCode": "5007",
                "isMetro": true
            },
            {
                "suburbId": 1734,
                "suburbName": "WELLINGTON",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 1735,
                "suburbName": "WELLINGTON EAST",
                "postCode": "5259",
                "isMetro": false
            },
            {
                "suburbId": 1736,
                "suburbName": "WEPAR",
                "postCode": "5278",
                "isMetro": false
            },
            {
                "suburbId": 1737,
                "suburbName": "WERTALOONA",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1738,
                "suburbName": "WEST BEACH",
                "postCode": "5024",
                "isMetro": true
            },
            {
                "suburbId": 1739,
                "suburbName": "WEST BUNDALEER",
                "postCode": "5491",
                "isMetro": false
            },
            {
                "suburbId": 1740,
                "suburbName": "WEST CROYDON",
                "postCode": "5008",
                "isMetro": true
            },
            {
                "suburbId": 1741,
                "suburbName": "WEST HINDMARSH",
                "postCode": "5007",
                "isMetro": true
            },
            {
                "suburbId": 1742,
                "suburbName": "WEST LAKES",
                "postCode": "5021",
                "isMetro": true
            },
            {
                "suburbId": 1743,
                "suburbName": "WEST LAKES SHORE",
                "postCode": "5020",
                "isMetro": true
            },
            {
                "suburbId": 1744,
                "suburbName": "WEST RANGE",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1745,
                "suburbName": "WEST RICHMOND",
                "postCode": "5033",
                "isMetro": true
            },
            {
                "suburbId": 1746,
                "suburbName": "WESTALL",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1747,
                "suburbName": "WESTBOURNE PARK",
                "postCode": "5041",
                "isMetro": true
            },
            {
                "suburbId": 1748,
                "suburbName": "WESTERN FLAT",
                "postCode": "5268",
                "isMetro": false
            },
            {
                "suburbId": 1749,
                "suburbName": "WESTERN RIVER",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1750,
                "suburbName": "WESTONS FLAT",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 1751,
                "suburbName": "WHARMINDA",
                "postCode": "5603",
                "isMetro": false
            },
            {
                "suburbId": 1752,
                "suburbName": "WHITE HILL",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1753,
                "suburbName": "WHITE HUT",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 1754,
                "suburbName": "WHITE SANDS",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1755,
                "suburbName": "WHITE WELL CORNER",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1756,
                "suburbName": "WHITES FLAT",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1757,
                "suburbName": "WHITES RIVER",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1758,
                "suburbName": "WHITES VALLEY",
                "postCode": "5172",
                "isMetro": true
            },
            {
                "suburbId": 1759,
                "suburbName": "WHITWARTA",
                "postCode": "5461",
                "isMetro": false
            },
            {
                "suburbId": 1760,
                "suburbName": "WHYALLA",
                "postCode": "5600",
                "isMetro": false
            },
            {
                "suburbId": 1761,
                "suburbName": "WHYALLA BARSON",
                "postCode": "5601",
                "isMetro": false
            },
            {
                "suburbId": 1762,
                "suburbName": "WHYALLA JENKINS",
                "postCode": "5609",
                "isMetro": false
            },
            {
                "suburbId": 1763,
                "suburbName": "WHYALLA NORRIE",
                "postCode": "5608",
                "isMetro": false
            },
            {
                "suburbId": 1764,
                "suburbName": "WHYALLA PLAYFORD",
                "postCode": "5600",
                "isMetro": false
            },
            {
                "suburbId": 1765,
                "suburbName": "WHYALLA STUART",
                "postCode": "5608",
                "isMetro": false
            },
            {
                "suburbId": 1766,
                "suburbName": "WHYTE YARCOWIE",
                "postCode": "5420",
                "isMetro": false
            },
            {
                "suburbId": 1767,
                "suburbName": "WIAWERA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1768,
                "suburbName": "WIGLEY FLAT",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 1769,
                "suburbName": "WILCHERRY",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 1770,
                "suburbName": "WILCOWIE",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 1771,
                "suburbName": "WILD DOG VALLEY",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1772,
                "suburbName": "WILD HORSE PLAINS",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 1773,
                "suburbName": "WILGENA",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 1774,
                "suburbName": "WILKATANA STATION",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 1775,
                "suburbName": "WILLALO",
                "postCode": "5419",
                "isMetro": false
            },
            {
                "suburbId": 1776,
                "suburbName": "WILLALOOKA",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 1777,
                "suburbName": "WILLAMULKA",
                "postCode": "5554",
                "isMetro": false
            },
            {
                "suburbId": 1778,
                "suburbName": "WILLASTON",
                "postCode": "5118",
                "isMetro": true
            },
            {
                "suburbId": 1779,
                "suburbName": "WILLIAM CREEK",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 1780,
                "suburbName": "WILLIAMSTOWN",
                "postCode": "5351",
                "isMetro": true
            },
            {
                "suburbId": 1781,
                "suburbName": "WILLIPPA",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 1782,
                "suburbName": "WILLOCHRA",
                "postCode": "5433",
                "isMetro": false
            },
            {
                "suburbId": 1783,
                "suburbName": "WILLOUGHBY",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 1784,
                "suburbName": "WILLOW BANKS",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1785,
                "suburbName": "WILLOW CREEK",
                "postCode": "5211",
                "isMetro": false
            },
            {
                "suburbId": 1786,
                "suburbName": "WILLOW SPRINGS",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 1787,
                "suburbName": "WILLOWIE",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 1788,
                "suburbName": "WILLSON RIVER",
                "postCode": "5222",
                "isMetro": false
            },
            {
                "suburbId": 1789,
                "suburbName": "WILLUNGA",
                "postCode": "5172",
                "isMetro": true
            },
            {
                "suburbId": 1790,
                "suburbName": "WILLUNGA HILL",
                "postCode": "5172",
                "isMetro": false
            },
            {
                "suburbId": 1791,
                "suburbName": "WILLUNGA SOUTH",
                "postCode": "5172",
                "isMetro": true
            },
            {
                "suburbId": 1792,
                "suburbName": "WILLYAROO",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1793,
                "suburbName": "WILMINGTON",
                "postCode": "5485",
                "isMetro": false
            },
            {
                "suburbId": 1794,
                "suburbName": "WINDSOR",
                "postCode": "5501",
                "isMetro": false
            },
            {
                "suburbId": 1795,
                "suburbName": "WINDSOR GARDENS",
                "postCode": "5087",
                "isMetro": true
            },
            {
                "suburbId": 1796,
                "suburbName": "WINGFIELD",
                "postCode": "5013",
                "isMetro": true
            },
            {
                "suburbId": 1797,
                "suburbName": "WINKIE",
                "postCode": "5343",
                "isMetro": false
            },
            {
                "suburbId": 1798,
                "suburbName": "WINNININNIE",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1799,
                "suburbName": "WINNINOWIE",
                "postCode": "5700",
                "isMetro": false
            },
            {
                "suburbId": 1800,
                "suburbName": "WINTABATINYANA",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 1801,
                "suburbName": "WINTINNA",
                "postCode": "5723",
                "isMetro": false
            },
            {
                "suburbId": 1802,
                "suburbName": "WINULTA",
                "postCode": "5570",
                "isMetro": false
            },
            {
                "suburbId": 1803,
                "suburbName": "WIRRABARA",
                "postCode": "5481",
                "isMetro": false
            },
            {
                "suburbId": 1804,
                "suburbName": "WIRRAMINNA",
                "postCode": "5719",
                "isMetro": false
            },
            {
                "suburbId": 1805,
                "suburbName": "WIRREALPA",
                "postCode": "5730",
                "isMetro": false
            },
            {
                "suburbId": 1806,
                "suburbName": "WIRREGA",
                "postCode": "5267",
                "isMetro": false
            },
            {
                "suburbId": 1807,
                "suburbName": "WIRRINA COVE",
                "postCode": "5204",
                "isMetro": false
            },
            {
                "suburbId": 1808,
                "suburbName": "WIRRULLA",
                "postCode": "5661",
                "isMetro": false
            },
            {
                "suburbId": 1809,
                "suburbName": "WISANGER",
                "postCode": "5223",
                "isMetro": false
            },
            {
                "suburbId": 1810,
                "suburbName": "WISTOW",
                "postCode": "5251",
                "isMetro": false
            },
            {
                "suburbId": 1811,
                "suburbName": "WITCHELINA",
                "postCode": "5731",
                "isMetro": false
            },
            {
                "suburbId": 1812,
                "suburbName": "WITCHITIE",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 1813,
                "suburbName": "WITERA",
                "postCode": "5671",
                "isMetro": false
            },
            {
                "suburbId": 1814,
                "suburbName": "WITJIRA",
                "postCode": "5734",
                "isMetro": false
            },
            {
                "suburbId": 1815,
                "suburbName": "WOKURNA",
                "postCode": "5520",
                "isMetro": false
            },
            {
                "suburbId": 1816,
                "suburbName": "WOLSELEY",
                "postCode": "5269",
                "isMetro": false
            },
            {
                "suburbId": 1817,
                "suburbName": "WOMBATS REST",
                "postCode": "5320",
                "isMetro": false
            },
            {
                "suburbId": 1818,
                "suburbName": "WOMPINIE",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1819,
                "suburbName": "WONGULLA",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1820,
                "suburbName": "WONGYARRA",
                "postCode": "5481",
                "isMetro": false
            },
            {
                "suburbId": 1821,
                "suburbName": "WONNA",
                "postCode": "5419",
                "isMetro": false
            },
            {
                "suburbId": 1822,
                "suburbName": "WONUARRA",
                "postCode": "5340",
                "isMetro": false
            },
            {
                "suburbId": 1823,
                "suburbName": "WOODCHESTER",
                "postCode": "5255",
                "isMetro": false
            },
            {
                "suburbId": 1824,
                "suburbName": "WOODCROFT",
                "postCode": "5162",
                "isMetro": true
            },
            {
                "suburbId": 1825,
                "suburbName": "WOODFORDE",
                "postCode": "5072",
                "isMetro": true
            },
            {
                "suburbId": 1826,
                "suburbName": "WOODLANE",
                "postCode": "5254",
                "isMetro": false
            },
            {
                "suburbId": 1827,
                "suburbName": "WOODLEIGH",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 1828,
                "suburbName": "WOODS POINT",
                "postCode": "5253",
                "isMetro": false
            },
            {
                "suburbId": 1829,
                "suburbName": "WOODSIDE",
                "postCode": "5244",
                "isMetro": true
            },
            {
                "suburbId": 1830,
                "suburbName": "WOODVILLE",
                "postCode": "5011",
                "isMetro": true
            },
            {
                "suburbId": 1831,
                "suburbName": "WOODVILLE GARDENS",
                "postCode": "5012",
                "isMetro": true
            },
            {
                "suburbId": 1832,
                "suburbName": "WOODVILLE NORTH",
                "postCode": "5012",
                "isMetro": true
            },
            {
                "suburbId": 1833,
                "suburbName": "WOODVILLE PARK",
                "postCode": "5011",
                "isMetro": true
            },
            {
                "suburbId": 1834,
                "suburbName": "WOODVILLE SOUTH",
                "postCode": "5011",
                "isMetro": true
            },
            {
                "suburbId": 1835,
                "suburbName": "WOODVILLE WEST",
                "postCode": "5011",
                "isMetro": true
            },
            {
                "suburbId": 1836,
                "suburbName": "WOOL BAY",
                "postCode": "5575",
                "isMetro": false
            },
            {
                "suburbId": 1837,
                "suburbName": "WOOLPUNDA",
                "postCode": "5330",
                "isMetro": false
            },
            {
                "suburbId": 1838,
                "suburbName": "WOOLSHED FLAT",
                "postCode": "5412",
                "isMetro": false
            },
            {
                "suburbId": 1839,
                "suburbName": "WOOLSHEDS",
                "postCode": "5400",
                "isMetro": false
            },
            {
                "suburbId": 1840,
                "suburbName": "WOOLTANA",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1841,
                "suburbName": "WOOLUMBOOL",
                "postCode": "5272",
                "isMetro": false
            },
            {
                "suburbId": 1842,
                "suburbName": "WOOLUNDUNGA",
                "postCode": "5701",
                "isMetro": false
            },
            {
                "suburbId": 1843,
                "suburbName": "WOOMERA",
                "postCode": "5720",
                "isMetro": false
            },
            {
                "suburbId": 1844,
                "suburbName": "WORLDS END",
                "postCode": "5381",
                "isMetro": false
            },
            {
                "suburbId": 1845,
                "suburbName": "WORROLONG",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1846,
                "suburbName": "WORUMBA",
                "postCode": "5434",
                "isMetro": false
            },
            {
                "suburbId": 1847,
                "suburbName": "WRATTONBULLY",
                "postCode": "5271",
                "isMetro": false
            },
            {
                "suburbId": 1848,
                "suburbName": "WUDINNA",
                "postCode": "5652",
                "isMetro": false
            },
            {
                "suburbId": 1849,
                "suburbName": "WUNKAR",
                "postCode": "5311",
                "isMetro": false
            },
            {
                "suburbId": 1850,
                "suburbName": "WYE",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1851,
                "suburbName": "WYNARKA",
                "postCode": "5306",
                "isMetro": false
            },
            {
                "suburbId": 1852,
                "suburbName": "WYNN VALE",
                "postCode": "5127",
                "isMetro": true
            },
            {
                "suburbId": 1853,
                "suburbName": "WYOMI",
                "postCode": "5275",
                "isMetro": false
            },
            {
                "suburbId": 1854,
                "suburbName": "YACKA",
                "postCode": "5470",
                "isMetro": false
            },
            {
                "suburbId": 1855,
                "suburbName": "YADLAMALKA",
                "postCode": "5713",
                "isMetro": false
            },
            {
                "suburbId": 1856,
                "suburbName": "YAHL",
                "postCode": "5291",
                "isMetro": false
            },
            {
                "suburbId": 1857,
                "suburbName": "YALANDA",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 1858,
                "suburbName": "YALATA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1859,
                "suburbName": "YALLUNDA FLAT",
                "postCode": "5607",
                "isMetro": false
            },
            {
                "suburbId": 1860,
                "suburbName": "YALPARA",
                "postCode": "5431",
                "isMetro": false
            },
            {
                "suburbId": 1861,
                "suburbName": "YALYMBOO",
                "postCode": "5715",
                "isMetro": false
            },
            {
                "suburbId": 1862,
                "suburbName": "YAMBA",
                "postCode": "5340",
                "isMetro": false
            },
            {
                "suburbId": 1863,
                "suburbName": "YANERBIE",
                "postCode": "5680",
                "isMetro": false
            },
            {
                "suburbId": 1864,
                "suburbName": "YANINEE",
                "postCode": "5653",
                "isMetro": false
            },
            {
                "suburbId": 1865,
                "suburbName": "YANKALILLA",
                "postCode": "5203",
                "isMetro": false
            },
            {
                "suburbId": 1866,
                "suburbName": "YANKANINNA",
                "postCode": "5732",
                "isMetro": false
            },
            {
                "suburbId": 1867,
                "suburbName": "YANTANABIE",
                "postCode": "5661",
                "isMetro": false
            },
            {
                "suburbId": 1868,
                "suburbName": "YANYARRIE",
                "postCode": "5432",
                "isMetro": false
            },
            {
                "suburbId": 1869,
                "suburbName": "YARDEA",
                "postCode": "5717",
                "isMetro": false
            },
            {
                "suburbId": 1870,
                "suburbName": "YARRAH",
                "postCode": "5433",
                "isMetro": false
            },
            {
                "suburbId": 1871,
                "suburbName": "YARRAMBA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1872,
                "suburbName": "YATALA VALE",
                "postCode": "5126",
                "isMetro": true
            },
            {
                "suburbId": 1873,
                "suburbName": "YATINA",
                "postCode": "5422",
                "isMetro": false
            },
            {
                "suburbId": 1874,
                "suburbName": "YATTALUNGA",
                "postCode": "5114",
                "isMetro": true
            },
            {
                "suburbId": 1875,
                "suburbName": "YEELANNA",
                "postCode": "5632",
                "isMetro": false
            },
            {
                "suburbId": 1876,
                "suburbName": "YELLABINNA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1877,
                "suburbName": "YELTA",
                "postCode": "5558",
                "isMetro": false
            },
            {
                "suburbId": 1878,
                "suburbName": "YELTANA",
                "postCode": "5641",
                "isMetro": false
            },
            {
                "suburbId": 1879,
                "suburbName": "YINKANIE",
                "postCode": "5332",
                "isMetro": false
            },
            {
                "suburbId": 1880,
                "suburbName": "YONGALA",
                "postCode": "5493",
                "isMetro": false
            },
            {
                "suburbId": 1881,
                "suburbName": "YORKE VALLEY",
                "postCode": "5573",
                "isMetro": false
            },
            {
                "suburbId": 1882,
                "suburbName": "YORKETOWN",
                "postCode": "5576",
                "isMetro": false
            },
            {
                "suburbId": 1883,
                "suburbName": "YOUNGHUSBAND",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1884,
                "suburbName": "YOUNGHUSBAND HOLDINGS",
                "postCode": "5238",
                "isMetro": false
            },
            {
                "suburbId": 1885,
                "suburbName": "YUDNAPINNA",
                "postCode": "5715",
                "isMetro": false
            },
            {
                "suburbId": 1886,
                "suburbName": "YUMALI",
                "postCode": "5261",
                "isMetro": false
            },
            {
                "suburbId": 1887,
                "suburbName": "YUMBARRA",
                "postCode": "5690",
                "isMetro": false
            },
            {
                "suburbId": 1888,
                "suburbName": "YUNDI",
                "postCode": "5172",
                "isMetro": false
            },
            {
                "suburbId": 1889,
                "suburbName": "YUNTA",
                "postCode": "5440",
                "isMetro": false
            },
            {
                "suburbId": 1890,
                "suburbName": "YUNYARINYI",
                "postCode": "0872",
                "isMetro": false
            },
            {
                "suburbId": 1891,
                "suburbName": "ZADOWS LANDING",
                "postCode": "5254",
                "isMetro": false
            }
        ];
    }
