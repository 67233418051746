import $ from 'jquery';

export default function () {

    let $numberApplicantsButton = $('input[name="borrow_number_of_applicants"]');
    let $borrowerButtons = $('.borrower-buttons');
    let $addBorrower = $("#borrower_plus");
    let $delBorrower = $("#borrower_minus");
    const MIN = 2;
    const MAX = 4;
    $numberApplicantsButton.on('change', function (e) {
        if ($(e.currentTarget).val() == "more") {
            initNBorrowers();
        } else {
            destroyNBorrowers();
        }
        updateFoundationAbide();
    });

    function updateFoundationAbide() {
        console.log('updating abide');
        let visible = $('div:visible[class^="text-input borrower-income"]').length;

        //console.log("visible:"+ visible);
        $('div[class^="text-input borrower-income"] input').each(function (k, elem) {
            $(elem).removeProp('required').removeAttr('required');

        });
        console.log('visible: ' + visible);
        for (let i = 1; i <= visible; i++) {
            //console.log('setting required on input#borrow_borrower_' + i + '_income');
            $('input#borrow_borrower_' + i + '_income').prop('required', 'required');
        }

        $(document).foundation();
    }

    function initNBorrowers() {
        // Show borrower 2 & buttons
        $addBorrower.show();
        $delBorrower.hide();
        $borrowerButtons.show();
        let visible = $('div:visible[class^="text-input borrower-income"]').length
        updateBorrowerCount(visible, visible + 1);
        updateFoundationAbide();
    }

    function updateBorrowerCount(current, target) {
        if (target == MIN) {
            $delBorrower.hide();
        }
        if (target == MAX) {
            $addBorrower.hide();
        }

        if (target > current) {
            // show one
            let visible = $('div:visible[class^="text-input borrower-income"]').length
            $('div.borrow_borrower_' + (visible + 1) + '_income').show();
        }
        if (target < current) {
            //hide one
            let visible = $('div:visible[class^="text-input borrower-income"]').length
            $('div.borrow_borrower_' + (visible) + '_income').hide();
        }
        // Reset vaues of hidden borrowers
        $('div:not(:visible)[class^="text-input borrower-income"] input').each(function (k, elem) {
            $(elem).val('');
        });

        updateFoundationAbide();
    }

    $addBorrower.on('click', function () {
        addNBorrower();
    });

    function addNBorrower() {
        // Count visible borrowers
        let visible = $('div:visible[class^="text-input borrower-income"]').length
        $delBorrower.show();
        updateBorrowerCount(visible, visible + 1);
    }

    $delBorrower.on('click', function () {
        delNBorrower();
    });

    function delNBorrower() {
        $addBorrower.show();
        let visible = $('div:visible[class^="text-input borrower-income"]').length
        updateBorrowerCount(visible, visible - 1);
    }

    function destroyNBorrowers() {

        //$('div[class^="text-input borrower-income"] input').removeProp('required')
        $('#borrow_borrower_2_income').removeProp('required').removeAttr('required').val('');
        $('#borrow_borrower_3_income').removeProp('required').removeAttr('required').val('');
        $('#borrow_borrower_4_income').removeProp('required').removeAttr('required').val('');
        //$('#borrow_borrower_5_income').removeProp('required').removeAttr('required').val('');
        console.log('removed required on all borrowers');

        $('div:visible[class^="text-input borrower-income"]').hide();
        $borrowerButtons.hide();
        $('div.borrow_borrower_1_income').show();
        $(document).foundation();
        //updateFoundationAbide();
    }
}

