import $ from 'jquery';

import {
    maybeParseInt,
    maybeParseFloat,
    calculatorLoadStart,
    calculatorLoadError,
    calculateTotalBorrowerIncome,
    getBorrowerCount,
    checkForNoResultsFound,
    calculateMonthlyCommitments

}                                     from "./calculator-utils";
import calculatorDisplayBorrowResults from "./calculator-handle-borrow-results";
import { getSuburbList, getPostCode, isSuburbMetro} from "./calculator-suburb-list";



export default function () {

    console.log("handle borrow request script called");

    var price = parseFloat($("#borrow_property_price").val().replace(/,/g, ''));
    var maxPrice = parseFloat($('#validation-data').attr('data-property-max').replace(/,/g, ''));
    var minPrice = parseFloat($('#validation-data').attr('data-property-min').replace(/,/g, ''));
    $("#borrow_property_price").removeClass("is-invalid-input");
    $("#priceErrorSpan").remove();

    if (price < minPrice || price > maxPrice) {
        console.log("Validation failed, price: " + price + ", too low: " + (price < minPrice) + ", too high: " + (price > maxPrice) + ", maxPrice: " + maxPrice);
        console.log("javascript says " + price + " > " + maxPrice);
        var priceElem = document.getElementById('borrow_property_price');
        priceElem.classList.add("is-invalid-input");

        var errorSpan = document.createElement('span');
        errorSpan.innerText = "Enter a price between " + minPrice + " and " + maxPrice;
        errorSpan.classList.add("form-error");
        errorSpan.id = "priceErrorSpan";
        errorSpan.style.display = "block";
        errorSpan.style.marginTop = "-1rem";
        priceElem.parentNode.parentNode.append(errorSpan);

        return;
    }

    calculatorLoadStart();
    // Reset any potential lingering classes that could affect the results
    $('.no-shared-equity').removeClass('no-shared-equity');
    $('.no-graduate').removeClass('no-graduate');
    $('.no-low-deposit').removeClass('no-low-deposit');
    // reset any potential other results
    window.results = new Array();

    let suburbInput = $('.suburb-selector').val();
    let postCode = getPostCode(suburbInput);

    var numBorrowers = getBorrowerCount();
    let amountArray = [];
    let frequencyArray = [];

    for (let i = 0; i < numBorrowers; i++) {
        let amount = maybeParseFloat($('#borrow_borrower_' + (i+1) + '_income').val());
        amountArray.push(amount);
        frequencyArray.push($('#borrow_borrower_' + (i+1) + '_income_frequency').val());
    }

    let data = {       
        Amount: amountArray,
        Frequency: frequencyArray,
        CommitmentAmount: maybeParseFloat($('#borrow_other_commitments').val()),
        CommitmentFrequency: $('#borrow_other_commitments_frequency').val(),
        GivenPurchasePrice: price,
        IsFirstHomeBuyer: ($('input[name="borrow_first_home_buyer"]:checked').val() == "yes") ? "true" : "false",
        LoanAmount: maybeParseFloat($("#borrow_property_price").val()),
        NumberOfApplicants: getBorrowerCount(),
        NumberOfDependents: maybeParseInt($("#borrow_number_of_dependants").val()),
        Postcode: postCode,
        Purpose: ($('input[name="borrow_build_or_buy"]:checked').val() == "buy") ? "BuyHome" : "BuyLandAndBuild",
        TotalCreditCardLimits: maybeParseInt($("#borrow_total_credit_limit").val())
    };

        
    let url = window.location.origin + '/api/calculator/HMCIB';
    console.log("posting to " + url, data);

    $.ajax({
        url: url,
        type: 'GET',
        data: data,
        traditional: true,
        success: function (result) {
            console.log("success, result: ", result);
            if (result.status != 'Success') {
                calculatorLoadError();
            } else {
                // Valid request
                window.results = result.scenarios 
                calculatorDisplayBorrowResults(result);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log("error: " + errorThrown);
            calculatorLoadError()
        }
    });

    // Event wrapper for handling pub/subs of the requests asyncronously.
    /*const eventName = "homestart.borrow_request";
    $.subscribe(eventName, eventWrapper(eventName))

    function eventWrapper(eventName) {
        //console.log('hit eventWrapper', eventName);

        return function (event, value) {
            calculatorDisplayBorrowResults(value);
        }
    } //*/
}

/* Old code for reference 
console.log("handle borrow request script called");

calculatorLoadStart();
// Reset any potential lingering classes that could affect the results
$('.no-shared-equity').removeClass('no-shared-equity');
$('.no-graduate').removeClass('no-graduate');
$('.no-low-deposit').removeClass('no-low-deposit');
// reset any potential other results
window.results = new Array();

let suburbs = getSuburbList(false); // This needs to be defined outside the loop, otherwise a bunch of API requests can be made erroneously..
// No longer needed, the new API returns all the variants together from one call
// For each of our loan types, create and send off requests 
//$.each(window.calculator.loanTypes, function (key, loanType) {
// Create data structure
/*let data = {
    "NetMonthlyIncome": calculateTotalBorrowerIncome(), // Do we combine all the incomes for all borrowers?
    "NumberOfBorrowers": getBorrowerCount(), // visible borrower elements
    "NumberOfDependants": maybeParseInt($("#borrow_number_of_dependants").val()),
    "StoreCreditCardLimits": maybeParseInt($("#borrow_total_credit_limit").val()),
    "OtherMonthlyCommitments": calculateMonthlyCommitments(),
    "PrimaryProduct": "" + loanType, // This will be dynamically thrown in
    "SecondaryProduct": "Advantage", // Another loan type?
    "Purpose": ($('input[name="borrow_build_or_buy"]:checked').val() == "buy") ? "BuyHome" : "BuyLandAndBuild",
    "PropertyAge": ($('input[name="borrow_build_or_buy"]:checked').val() == "buy") ? "Established" : "OffThePlan",
    "InterestType": "Variable",
    "RepaymentFrequency": "Fortnightly",
    "Locality": ($("#borrow_suburb").val() != "") ? $("#borrow_suburb").val() : "ADELAIDE",
    "IsFirstHomeBuyer": ($('input[name="borrow_first_home_buyer"]:checked').val() == "yes") ? "true" : "false",
    "PurchasePrice": (maybeParseFloat($("#borrow_property_price").val()) == 0) ? "" : maybeParseFloat($("#borrow_property_price").val())
};

let amountArray = [1000, 5];
let frequencyArray = ["Weekly", "Monthly"];
let incomeTypeArray = ["Employment", "Ponzicoin"];
let isGrossNetArray = ["Net", "Net"];

let data = {
    Amount: amountArray,
    Frequency: frequencyArray,
    IncomeType: incomeTypeArray,
    IsGrossNet: isGrossNetArray,
    GivenPurchasePrice: (maybeParseFloat($("#borrow_property_price").val()) == 0) ? "" : maybeParseFloat($("#borrow_property_price").val()),
    IsFirstHomeBuyer: ($('input[name="borrow_first_home_buyer"]:checked').val() == "yes") ? "true" : "false",
    LoanAmount: (maybeParseFloat($("#borrow_property_price").val()) == 0) ? "" : maybeParseFloat($("#borrow_property_price").val()),
    NumberOfApplicants: getBorrowerCount(),
    NumberOfDependents: maybeParseInt($("#borrow_number_of_dependants").val()),
    Postcode: $('.suburb-selector').val(),
    Purpose: ($('input[name="borrow_build_or_buy"]:checked').val() == "buy") ? "BuyHome" : "BuyLandAndBuild",
    TotalCreditCardLimits: maybeParseInt($("#borrow_total_credit_limit").val())
};

/* Deactivate business logic from frontend now that it has been implemented in API
// Sanity checks
// HOMESTART-BL: If the user doesn't have enough income, show error message
if (data.NetMonthlyIncome == 0 || data.NetMonthlyIncome < 100) {
    // Manually triggering this as the API is not
    calculatorLoadError('No result(s) found for the provided parameter(s)');
    return;
}
// HOMESTART-BL: If property price is over 10 million, it's considered invalid
if (data.PurchasePrice >= 10000000) {
    calculatorLoadError('No result(s) found for the provided parameter(s)');
    return;
}
// HOMESTART-BL: If property price too low (and not empty e.g. 0)
if (data.PurchasePrice != "" && data.PurchasePrice <= 1) {
    calculatorLoadError('Please enter a Property purchase price greater than $0, without symbols and spaces.');
    return;
}

// HOMESTART-BL: If suburb is not in the list of Suburbs, show an error.
if (suburbs[data.Locality.toUpperCase()] === undefined) {
    calculatorLoadError("Locality not found")
    return;
}

// HOMESTART-BL: When PurchaseType is "Build" LowDeposit loans are not valid
// Add "no-low-deposits" class to hide these results
if (loanType == "LowDeposit" && data.Purpose == "BuyLandAndBuild") {
    // There's no valid Low Deposit scenario where they can BuyLandAndBuild
    // The API will error on these 2 calls
    console.log('Modifying LowDeposit Purpose');
    // For these low deposit calls we need to modify the request, so that it is a valid request
    data.Purpose = "BuyHome"; // because we always expect 6 results, we just query and hide the results
    // Hide low deposit results
    $('.calculator-results').addClass('no-low-deposit');
}

let url = window.location.origin + '/api/calculator/HMCIB';
console.log("posting to " + url, data);

$.ajax({
    url: url,
    type: 'GET',
    data: data,
    traditional: true,
    success: function (result) {
        console.log("success, result: ", result);
        if (result.ErrorMessage != null && result.ErrorMessage != "") {
            calculatorLoadError(result.ErrorMessage);
            return;
        } /*else if (checkForNoResultsFound(result)) {
                // If the result is empty we set the object to false
                // Prefer to use result.PrimaryProduct but in the case it is empty (aka a failed request) we can use LoanType
                if (result.PrimaryProduct != "") {
                    window.results[data.PrimaryProduct] = false;
                    $.publish(eventName, result);
                } else {
                    window.results[loanType] = false;
                    $.publish(eventName, result);
                }
            } else {
            // Valid request
            console.log('publishing ', eventName, result);
            $.publish(eventName, result);
        }
    },
    error: function (jqXHR, textStatus, errorThrown) {
        // As mentioned above, HomeStart's Business Logic specifies that if low deposit fails in some circumstances we still want to display a result
        /*if (loanType == 'LowDeposit') {
            // If only low deposit fails, it's likely because of the suburb
            if (data.Locality != "ADELAIDE") {
                window.results[data.PrimaryProduct] = false;
            }
        } else {
        console.log("error: " + errorThrown);
        calculatorLoadError()
        //}
    }
});

// Handle secondary product call
// HOMESTART-BL If the Purchase Type is Build
/*if (data.Purpose == "BuyLandAndBuild") {
    // HOMESTART-BL: Advantage loan as a Secondary Product is not valid for Standard, SharedEquity is the secondary one
    data.SecondaryProduct = "SharedEquity";
    if (loanType == "Graduate") {
        // SharedEquity is not valid for Graduate if purpose = build
        data.SecondaryProduct = "0";
    }
    if (loanType != 'LowDeposit') {
        // SharedEquity is not valid for Graduate if purpose = build
        data.SecondaryProduct = "0";

        $('.calculator-results').addClass('no-shared-equity');
    }

} else {
    data.SecondaryProduct = "SharedEquity";
}*/
// Secondary call
/*$.ajax({
    url: url,
    type: "GET",
    data: data,
    contentType: "application/json",
    dataType: "json",
    success: function (result) {
        console.log(result);
        if (result.ErrorMessage != null && result.ErrorMessage != "") {
            calculatorLoadError(result.ErrorMessage);
            return;
        } /*else if (checkForNoResultsFound(result)) {
            // Similar to the first call, if the results are empty or invalid we set the request object to false.
            if (result.PrimaryProduct != "") {
                if ((result.PrimaryProduct + "_" + "Secondary") != "") {
                    //window.results[loanType + "_" + "Secondary"] = false;
                    $.publish(eventName, result);
                } else {
                    //window.results[loanType + "_" + "Secondary"] = false;
                    $.publish(eventName, result);
                }
            } else {
                //window.results[loanType + "_" + "Secondary"] = false;
                $.publish(eventName, result);
            }
        } *//*else {
    $.publish(eventName, result);
}

},
error: function (jqXHR, textStatus, errorThrown) {
if (loanType == 'LowDeposit') {
    // If only low deposit fails, it's likely because of the suburb
    if (data.Locality != "ADELAIDE") {
        window.results[loanType + "_" + "Secondary"] = false;
    }
} else {
    calculatorLoadError()
}
}
});

//});

// Event wrapper for handling pub/subs of the requests asyncronously.
const eventName = "homestart.borrow_request";
$.subscribe(eventName, eventWrapper(eventName))

function eventWrapper(eventName) {
    console.log('hit eventWrapper', eventName);

    return function (event, value) {
        /*if (value.SecondaryProduct != "Advantage") {
            //window.results[value.PrimaryProduct + "_" + value.SecondaryProduct] = value;
            if (value.PrimaryProduct != "") {
                window.results[value.PrimaryProduct + "_" + "Secondary"] = value;
            } else {
                console.log(event, value);
            }

        } else {
            if (value.PrimaryProduct != "") {
                window.results[value.PrimaryProduct] = value;
            } else {
                console.log(event, value);
            }
        }

        //calculatorDisplayBorrowResults(window.results);
        calculatorDisplayBorrowResults(value);
    }
} //
}*/