import $ from 'jquery';

import {
    maybeParseInt,
    maybeParseFloat,
    calculatorLoadStart,
    calculatorLoadError,
    formatCurrency,
    updateCalculatorButtonText,
    //borrowerFillResultsTable,
    updateTabTitle,
    showCallToActions,
    mobileCalculatorAccordionReset,
    calculateDuration,
    checkDuplicateResults,
    calculateTotalBorrowerIncome, checkForNoResultsFound, generateVirtualPageView, mobileCalculatorTabReset,
} from "./calculator-utils";

import { isSuburbMetro } from "./calculator-suburb-list";
import { isMobileOrTablet, objectLength } from "../../utils";

export default function (results) {

    $('.advantage_text.standard').hide();
    $('.advantage_text.graduate').hide();
    $('.advantage_text.lowdeposit').hide();
    $(".calculate-button:visible").removeClass('loading');

    // If the API returns an error message exit early and display that.
    if (results.errors.length > 0) {
        console.log('Encountered API error', results);
        calculatorLoadError(results.errors[0].description);
        return;
    }

    // Calculate highest total amount to display in calculator button
    let highestTotalAmount = 0;

    results.scenarios.forEach(function (item) {
        //console.log(item.scenarioName + ": " + item.maximumBorrowableAmount);
        if (item.maximumBorrowableAmount > highestTotalAmount) {
            highestTotalAmount = item.maximumBorrowableAmount;
        }
    });

    updateCalculatorButtonText("We estimate you could borrow up to:", formatCurrency(highestTotalAmount, true));

    let StandardAdvantage = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "STANDARDADVANTAGE"; });
    let StandardSharedEquity = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "STANDARDSHAREDEQUITY"; });
    let GraduateAdvantage = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "GRADUATEADVANTAGE"; });
    let GraduateSharedEquity = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "GRADUATESHAREDEQUITY"; });
    let LowDepositAdvantage = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "LOWDEPOSITADVANTAGE"; });
    let LowDepositSharedEquity = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "LOWDEPOSITSHAREDEQUITY"; });


    borrowerFillResultsTable("#borrow_homestart_loan_results .primary", StandardAdvantage, 'standard');
    borrowerFillResultsTable("#borrow_homestart_loan_results .secondary", StandardSharedEquity);

    if ((GraduateAdvantage.status == "Fail" || GraduateAdvantage == null) && (GraduateSharedEquity.status == "Fail" || GraduateSharedEquity == null)) {
        $('.calculator-results').addClass('no-graduate');
    } else {
        $('.calculator-results').removeClass('no-graduate');
        borrowerFillResultsTable("#borrow_graduate_loan_results .primary", GraduateAdvantage, 'graduate');
        borrowerFillResultsTable("#borrow_graduate_loan_results .secondary", GraduateSharedEquity);
    }

    if ((LowDepositAdvantage.status == "Fail" || LowDepositAdvantage == null) && (LowDepositSharedEquity.status == "Fail" || LowDepositSharedEquity == null)) {
        $('.calculator-results').addClass('no-low-deposit');
    } else {
        $('.calculator-results').removeClass('no-low-deposit');
        borrowerFillResultsTable("#borrow_lowdeposit_loan_results .primary", LowDepositAdvantage, 'lowdeposit');
        borrowerFillResultsTable("#borrow_lowdeposit_loan_results .secondary", LowDepositSharedEquity);
    }

    updateTabTitle("#borrow_homestart_loan_results-label", `Borrow up to ${formatCurrency(Math.max(StandardAdvantage.maximumBorrowableAmount, StandardSharedEquity.maximumBorrowableAmount), true)}`);
    updateTabTitle("#borrow_graduate_loan_results-label", `Borrow up to ${formatCurrency(Math.max(GraduateAdvantage.maximumBorrowableAmount, GraduateSharedEquity.maximumBorrowableAmount), true)}`);
    updateTabTitle("#borrow_lowdeposit_loan_results-label", `Borrow up to ${formatCurrency(Math.max(LowDepositAdvantage.maximumBorrowableAmount, LowDepositSharedEquity.maximumBorrowableAmount), true)}`);

    $("#borrow_results").show();
    showCallToActions();
    generateVirtualPageView();

    if (!Foundation.MediaQuery.is('medium up')) {
        mobileCalculatorAccordionReset();
    } else {
        $("#borrow_homestart_loan_results").addClass('is-active');
        $("#borrow_homestart_loan_results-label").addClass('is-active')
        $("#borrow_results").find('.is-active').removeClass('is-active');
        $("#borrow_homestart_loan_results-label").parent().addClass('is-active');
        $("#borrow_homestart_loan_results").addClass('is-active');
        $("#borrow_homestart_loan_results").show();
    }
};

function borrowerFillResultsTable(selector, scenario, advantageType = "") {
    let $loanResults = $(selector);
    //console.log($loanResults, scenario);

    if (scenario.status == "Fail") {
        console.log(scenario.scenarioName + ' unavailable');
        $loanResults.hide();
        $loanResults.prev('.subtitle').hide();
        $loanResults.prev('.subtitle').prev('.boost-your-borrowing-power').hide();
    } else {
        $loanResults.show();
        $loanResults.prev('.subtitle').show();
        $loanResults.prev('.subtitle').prev('.boost-your-borrowing-power').show();

        // Update 3 column loan info amounts
        $loanResults.find('.borrow_up_to').find('.amount').text(formatCurrency(scenario.maximumBorrowableAmount, true));
        $loanResults.find('.repayments_of').find('.amount').text(formatCurrency(scenario.repaymentsAmount, true));
        $loanResults.find('.deposit_required').find('.amount').text(formatCurrency(scenario.totalDepositRequired, true));
        var grant = scenario.totalGrants > 0;//$('input[name="borrow_first_home_buyer"]:checked').val() == "yes" && $('input[name="borrow_build_or_buy"]:checked').val() == "build";
        $loanResults.find('.government_grants_showhide').css('display', (grant ? 'inline' : 'none'));
        $loanResults.find('.property_value').text(formatCurrency(scenario.purchasePrice, true));
        $loanResults.find('.startup-help-text').css('display', (scenario.assistanceLoanEligibility ? 'block' : 'none'));

        calculateDuration($loanResults, scenario);

        // console.log(`Secondary Loan Amount: ${parseFloat(scenario.secondaryLoanAmount)}`) 
        if (advantageType.length > 0) {
            if (scenario.secondaryLoanAmount - 0 > 0) {
                $(`.advantage_text.${advantageType}`).show();
            } else {
                $(`.advantage_text.${advantageType}`).hide();
            }
        }

        if (scenario.assistanceLoanEligibility) {
            $loanResults.find('startup-help-text').css('display', 'block');
        } else {
            $loanResults.find('startup-help-text').css('display', 'none');
        }

        var feesTable = document.querySelector(selector).querySelector('table.fees-and-charges');
        feesTable.innerHTML = '';

        // Home Deposit Row
        let row = document.createElement('tr');
        row.classList.add("bold")

        let name = document.createElement('td');
        name.innerText = 'Home Deposit';
        row.append(name);

        let amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("deposit");
        amount.innerText = formatCurrency(scenario.homeDeposit, true);
        row.append(amount);
        feesTable.append(row);

        // Fees & Charges ROW
        row = document.createElement('tr');
        row.classList.add("bold");

        name = document.createElement('td');
        name.innerText = 'Fees & Charges';
        row.append(name);

        amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("subtotal");
        amount.innerText = formatCurrency(scenario.totalFeesAndCharges, true);
        row.append(amount);
        feesTable.append(row);

        if (scenario.feesAndChargesBreakdown) {
            for (let i = 0; i < scenario.feesAndChargesBreakdown.length; i++) {
                row = document.createElement('tr');
                row.classList.add("sub")
                name = document.createElement('td');
                name.innerText = scenario.feesAndChargesBreakdown[i].name;
                row.append(name);
                amount = document.createElement('td');
                amount.innerText = formatCurrency(scenario.feesAndChargesBreakdown[i].value);
                row.append(amount);
                feesTable.append(row);
            }
        }

        //Grants
        if (scenario.totalGrants > 0) {
            row = document.createElement('tr');
            row.classList.add("total")

            name = document.createElement('td');
            name.innerText = 'Government Grants';
            row.append(name);

            amount = document.createElement('td');
            amount.classList.add("fees");
            amount.classList.add("grandtotal");
            amount.innerText = "-" + formatCurrency(scenario.totalGrants, true);
            row.append(amount);

            feesTable.append(row);


            for (let i = 0; i < scenario.grants.length; i++) {
                row = document.createElement('tr');
                row.classList.add("sub");
                name = document.createElement('td');
                name.innerText = scenario.grants[i].name;
                row.append(name);
                amount = document.createElement('td');
                amount.innerText = "-" + formatCurrency(scenario.grants[i].value);
                row.append(amount);
                feesTable.append(row);
            }
        }
        // line / space
        row = document.createElement('tr');
        row.classList.add("spacer")
        row.append(document.createElement('td'));
        row.append(document.createElement('td'));
        feesTable.append(row);



        // Totals
        row = document.createElement('tr');
        row.classList.add("total")

        name = document.createElement('td');
        name.innerText = 'Total contribution required';
        row.append(name);

        amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("grandtotal");
        amount.innerText = formatCurrency(scenario.totalDepositRequired, true);
        row.append(amount);

        feesTable.append(row);
    }
};

/*old stuff kept for reference
{
export default function (results) {
    // sanity check (this gets called a lot, we wanna make sure we've got the entire results)
    // Update calculator button with the top amount
    /*if (Object.keys(results).length < 6) {
        console.log('Skipping rendering of results', results)
        return;
    }

// If the API returns an error message exit early and display that.
if (results.ErrorMessage != "" && results.ErrorMessage != null) {
    console.log('Encountered API error', results);
    calculatorLoadError(results.ErrorMessage);
}
// HOMESTART-BL: If all requests fail and the suburb is NOT a Metro suburb display error
if (results.Standard == false && results.Graduate == false && results.LowDeposit == false) {
    // Check suburb is not metro
    if (!isSuburbMetro($("#borrow_suburb").val().toUpperCase())) {
        calculatorLoadError("Unfortunately HomeStart does not service your locality");
        return;
    } else {
        // HOMESTART-BL: If they all fail and it still is metro, it's likely that their parameters are invalid (e.g. OtherMonthlyCommitments exceed their income level.
        calculatorLoadError('No result(s) found for the provided parameter(s)');
        return;
    }
    startup-help-text
}

$(".calculate-button:visible").removeClass('loading');
console.log('handle borrow results', results);

/*function checkSecondaryLoanAmount(item) {
    console.log(item);
    if (item === false) {
        return true;
    }
    if (item === undefined) {
        return true;
    }
    if (item.SecondaryLoanAmount === undefined) {
        return true;
    }
    if (item.SecondaryLoanAmount > 0) {
        return false;
    }
    return true;
}

const ADVANTAGE_LOAN_MAX_INCOME = 5000; // 5000 per month == 60000 per year
// HOMESTART-BL: If income is > 60,000k /year) advantage loan AND it is affecting the loan results (SecondaryLoanAmount > 0) display advantage text
if (checkSecondaryLoanAmount(results.Standard) && calculateTotalBorrowerIncome() < ADVANTAGE_LOAN_MAX_INCOME) {
    console.log('Standard SecondaryLoanAmount > 0');
    $('.advantage_text.standard').show();
}
// HOMESTART-BL: If income is > 60,000k /year) advantage loan AND it is affecting the loan results (SecondaryLoanAmount > 0) display advantage text
if (checkSecondaryLoanAmount(results.Graduate) && calculateTotalBorrowerIncome() < ADVANTAGE_LOAN_MAX_INCOME) {
    console.log('Graduate SecondaryLoanAmount > 0');
    $('.advantage_text.graduate').show();
}
// HOMESTART-BL: If income is > 60,000k /year) advantage loan AND it is affecting the loan results (SecondaryLoanAmount > 0) display advantage text
if (checkSecondaryLoanAmount(results.LowDeposit) && calculateTotalBorrowerIncome() < ADVANTAGE_LOAN_MAX_INCOME) {
    console.log('LowDeposit SecondaryLoanAmount > 0');
    $('.advantage_text.lowdeposit').show();
}
// HOMESTART-BL: If borrower income exceeds 60,000 hide advantage text
/*if (calculateTotalBorrowerIncome() > ADVANTAGE_LOAN_MAX_INCOME) {
    console.log('HIDING ADVANTAGE TEXT');
    $('.advantage_text.lowdeposit').hide();
    $('.advantage_text.graduate').hide();
    $('.advantage_text.standard').hide();
}

const KEYS = [
    'Standard',
    'Standard_Secondary',
    'Graduate',
    'Graduate_Secondary',
    'LowDeposit',
    'LowDeposit_Secondary',
]


//console.log("BEFORE FORMATTING:", results);
//console.log(typeof (results.Standard), typeof (results.Graduate), typeof (results.LowDeposit))
/*let formatted_results = {
    "Standard": (results.Standard == false || typeof results.Standard === undefined) ? {} : {
        total: results.Standard.TotalLoanAmount,
        repayments: results.Standard.PrimaryLoanRepayment, // Fortnightly or monthly?
        deposit: results.Standard.DepositRequiredBeforeFeesAndCharges,
        property_price: results.Standard.PurchasePrice,
        additionalfundsrequired: results.Standard.AdditionalFundsRequired,
        fees: {
            deposit: results.Standard.DepositRequiredBeforeFeesAndCharges,
            registration: results.Standard.FeesAndCharges["Registration of Transfer"],
            stamp_duty: results.Standard.FeesAndCharges["Stamp Duty on Conveyance"],
            establishment: results.Standard.FeesAndCharges["Establishment Fee"],
            loan_provision: results.Standard.LPC, // TODO this
            mortgage_registration: results.Standard.FeesAndCharges["Mortgage Registration Fee"],
            search: results.Standard.FeesAndCharges["Search Fee"],
            search_admin: results.Standard.FeesAndCharges["Search Administration Fee"],
            settlement: results.Standard.FeesAndCharges["Settlement Fee"],
            valuation: results.Standard.FeesAndCharges["Valuation Fee"],
            conveyancer: results.Standard.FeesAndCharges["Conveyancer"],
            administration: (typeof results.Standard.FeesAndCharges["Disbursement Admin Fee Construction"] !== 'undefined') ? results.Standard.FeesAndCharges["Disbursement Admin Fee Construction"] : results.Standard.FeesAndCharges["Disbursement Admin Fee"], // TODO this
            subtotal: results.Standard.TotalFees,
            grandtotal: results.Standard.AdditionalFundsRequired,
            totalgrants: results.Standard.TotalGrants,
        }
    },
    "Standard_Secondary": results.Standard_Secondary == false ? {} : {
        total: results.Standard_Secondary.TotalLoanAmount,
        repayments: results.Standard_Secondary.PrimaryLoanRepayment, // Fortnightly or monthly?
        deposit: results.Standard_Secondary.DepositRequiredBeforeFeesAndCharges,
        property_price: results.Standard_Secondary.PurchasePrice,
        additionalfundsrequired: results.Standard_Secondary.AdditionalFundsRequired,
        fees: {
            deposit: results.Standard_Secondary.DepositRequiredBeforeFeesAndCharges,
            registration: results.Standard_Secondary.FeesAndCharges["Registration of Transfer"],
            stamp_duty: results.Standard_Secondary.FeesAndCharges["Stamp Duty on Conveyance"],
            establishment: results.Standard_Secondary.FeesAndCharges["Establishment Fee"],
            loan_provision: results.Standard_Secondary.LPC, // TODO this
            mortgage_registration: results.Standard_Secondary.FeesAndCharges["Mortgage Registration Fee"],
            search: results.Standard_Secondary.FeesAndCharges["Search Fee"],
            search_admin: results.Standard_Secondary.FeesAndCharges["Search Administration Fee"],
            settlement: results.Standard_Secondary.FeesAndCharges["Settlement Fee"],
            valuation: results.Standard_Secondary.FeesAndCharges["Valuation Fee"],
            conveyancer: results.Standard_Secondary.FeesAndCharges["Conveyancer"],
            administration: (typeof results.Standard_Secondary.FeesAndCharges["Disbursement Admin Fee Construction"] !== 'undefined') ? results.Standard_Secondary.FeesAndCharges["Disbursement Admin Fee Construction"] : results.Standard_Secondary.FeesAndCharges["Disbursement Admin Fee"],
            subtotal: results.Standard_Secondary.TotalFees,
            grandtotal: results.Standard_Secondary.AdditionalFundsRequired,
            totalgrants: results.Standard_Secondary.TotalGrants,
        }
    },
    "Graduate": (results.Graduate == false || typeof results.Graduate === undefined) ? {} : {
        total: results.Graduate.TotalLoanAmount,
        repayments: results.Graduate.PrimaryLoanRepayment, // Fortnightly or monthly?
        deposit: results.Graduate.DepositRequiredBeforeFeesAndCharges,
        property_price: results.Graduate.PurchasePrice,
        additionalfundsrequired: results.Graduate.AdditionalFundsRequired,
        fees: {
            deposit: results.Graduate.DepositRequiredBeforeFeesAndCharges,
            registration: results.Graduate.FeesAndCharges["Registration of Transfer"],
            stamp_duty: results.Graduate.FeesAndCharges["Stamp Duty on Conveyance"],
            establishment: results.Graduate.FeesAndCharges["Establishment Fee"],
            loan_provision: results.Graduate.LPC,
            mortgage_registration: results.Graduate.FeesAndCharges["Mortgage Registration Fee"],
            search: results.Graduate.FeesAndCharges["Search Fee"],
            search_admin: results.Graduate.FeesAndCharges["Search Administration Fee"],
            settlement: results.Graduate.FeesAndCharges["Settlement Fee"],
            valuation: results.Graduate.FeesAndCharges["Valuation Fee"],
            conveyancer: results.Graduate.FeesAndCharges["Conveyancer"],
            administration: (typeof results.Graduate.FeesAndCharges["Disbursement Admin Fee Construction"] !== 'undefined') ? results.Graduate.FeesAndCharges["Disbursement Admin Fee Construction"] : results.Graduate.FeesAndCharges["Disbursement Admin Fee"],
            subtotal: results.Graduate.TotalFees,
            grandtotal: results.Graduate.AdditionalFundsRequired,
            totalgrants: results.Graduate.TotalGrants,
        }
    },
    "Graduate_Secondary": results.Graduate_Secondary == false ? {} : {
        total: results.Graduate_Secondary.TotalLoanAmount,
        repayments: results.Graduate_Secondary.PrimaryLoanRepayment, // Fortnightly or monthly?
        deposit: results.Graduate_Secondary.DepositRequiredBeforeFeesAndCharges,
        property_price: results.Graduate_Secondary.PurchasePrice,
        additionalfundsrequired: results.Graduate_Secondary.AdditionalFundsRequired,
        fees: {
            deposit: results.Graduate_Secondary.DepositRequiredBeforeFeesAndCharges,
            registration: results.Graduate_Secondary.FeesAndCharges["Registration of Transfer"],
            stamp_duty: results.Graduate_Secondary.FeesAndCharges["Stamp Duty on Conveyance"],
            establishment: results.Graduate_Secondary.FeesAndCharges["Establishment Fee"],
            loan_provision: results.Graduate_Secondary.LPC, // TODO this
            mortgage_registration: results.Graduate_Secondary.FeesAndCharges["Mortgage Registration Fee"],
            search: results.Graduate_Secondary.FeesAndCharges["Search Fee"],
            search_admin: results.Graduate_Secondary.FeesAndCharges["Search Administration Fee"],
            settlement: results.Graduate_Secondary.FeesAndCharges["Settlement Fee"],
            valuation: results.Graduate_Secondary.FeesAndCharges["Valuation Fee"],
            conveyancer: results.Graduate_Secondary.FeesAndCharges["Conveyancer"],
            administration: (typeof results.Graduate_Secondary.FeesAndCharges["Disbursement Admin Fee Construction"] !== 'undefined') ? results.Graduate_Secondary.FeesAndCharges["Disbursement Admin Fee Construction"] : results.Graduate_Secondary.FeesAndCharges["Disbursement Admin Fee"], // TODO this
            subtotal: results.Graduate_Secondary.TotalFees,
            grandtotal: results.Graduate_Secondary.AdditionalFundsRequired,
            totalgrants: results.Graduate_Secondary.TotalGrants,
        }
    },
    "LowDeposit": results.LowDeposit == false ? {} : {
        total: results.LowDeposit.TotalLoanAmount,
        repayments: results.LowDeposit.PrimaryLoanRepayment, // Fortnightly or monthly?
        deposit: results.LowDeposit.DepositRequiredBeforeFeesAndCharges,
        property_price: results.LowDeposit.PurchasePrice,
        additionalfundsrequired: results.LowDeposit.AdditionalFundsRequired,
        fees: {
            deposit: results.LowDeposit.DepositRequiredBeforeFeesAndCharges,
            registration: results.LowDeposit.FeesAndCharges["Registration of Transfer"],
            stamp_duty: results.LowDeposit.FeesAndCharges["Stamp Duty on Conveyance"],
            establishment: results.LowDeposit.FeesAndCharges["Establishment Fee"],
            loan_provision: results.LowDeposit.LPC, // TODO this
            mortgage_registration: results.LowDeposit.FeesAndCharges["Mortgage Registration Fee"],
            search: results.LowDeposit.FeesAndCharges["Search Fee"],
            search_admin: results.LowDeposit.FeesAndCharges["Search Administration Fee"],
            settlement: results.LowDeposit.FeesAndCharges["Settlement Fee"],
            valuation: results.LowDeposit.FeesAndCharges["Valuation Fee"],
            conveyancer: results.LowDeposit.FeesAndCharges["Conveyancer"],
            administration: (typeof results.LowDeposit.FeesAndCharges["Disbursement Admin Fee Construction"] !== 'undefined') ? results.LowDeposit.FeesAndCharges["Disbursement Admin Fee Construction"] : results.LowDeposit.FeesAndCharges["Disbursement Admin Fee"], // TODO this
            subtotal: results.LowDeposit.TotalFees,
            grandtotal: results.LowDeposit.AdditionalFundsRequired,
            totalgrants: results.LowDeposit.TotalGrants,
        }
    },
    "LowDeposit_Secondary": results.LowDeposit_Secondary == false ? {} : {
        total: results.LowDeposit_Secondary.TotalLoanAmount,
        repayments: results.LowDeposit_Secondary.PrimaryLoanRepayment, // Fortnightly or monthly?
        deposit: results.LowDeposit_Secondary.DepositRequiredBeforeFeesAndCharges,
        property_price: results.LowDeposit_Secondary.PurchasePrice,
        additionalfundsrequired: results.LowDeposit_Secondary.AdditionalFundsRequired,
        fees: {
            deposit: results.LowDeposit_Secondary.DepositRequiredBeforeFeesAndCharges,
            registration: results.LowDeposit_Secondary.FeesAndCharges["Registration of Transfer"],
            stamp_duty: results.LowDeposit_Secondary.FeesAndCharges["Stamp Duty on Conveyance"],
            establishment: results.LowDeposit_Secondary.FeesAndCharges["Establishment Fee"],
            loan_provision: results.LowDeposit_Secondary.LPC, // TODO this
            mortgage_registration: results.LowDeposit_Secondary.FeesAndCharges["Mortgage Registration Fee"],
            search: results.LowDeposit_Secondary.FeesAndCharges["Search Fee"],
            search_admin: results.LowDeposit_Secondary.FeesAndCharges["Search Administration Fee"],
            settlement: results.LowDeposit_Secondary.FeesAndCharges["Settlement Fee"],
            valuation: results.LowDeposit_Secondary.FeesAndCharges["Valuation Fee"],
            conveyancer: results.LowDeposit_Secondary.FeesAndCharges["Conveyancer"],
            administration: (typeof results.LowDeposit_Secondary.FeesAndCharges["Disbursement Admin Fee Construction"] !== 'undefined') ? results.LowDeposit_Secondary.FeesAndCharges["Disbursement Admin Fee Construction"] : results.LowDeposit_Secondary.FeesAndCharges["Disbursement Admin Fee"], // TODO this
            subtotal: results.LowDeposit_Secondary.TotalFees,
            grandtotal: results.LowDeposit_Secondary.AdditionalFundsRequired, // TODO Change to Additional Funds Required
            totalgrants: results.LowDeposit_Secondary.TotalGrants,

        }
    },
};*/

// HOMESTART-BL: Sanity check to determine if we should be displaying Low Deposit loans or not.
/*if (objectLength(formatted_results["LowDeposit"]) <= 0 || objectLength(formatted_results["LowDeposit_Secondary"]) <= 0) {
    formatted_results["LowDeposit"] = false;
    formatted_results["LowDeposit_Secondary"] = false;
    $('.calculator-results').addClass('no-low-deposit')
}
// HOMESTART-BL: Sanity check to determine if we should be displaying Graduate loans or not.
if (objectLength(formatted_results["Graduate"]) <= 0 && objectLength(formatted_results["Graduate_Secondary"]) <= 0) {
    formatted_results["Graduate"] = false;
    formatted_results["Graduate_Secondary"] = false;
    //alert(1);
    $('.calculator-results').addClass('no-graduate')
}

// Calculate highest total amount to display in calculator button
let highestTotalAmount = 0;
/*$.each(formatted_results, function (k, r) {
    if ($('.calculator-results').hasClass('no-low-deposit') && (k == 'LowDeposit' || k == 'LowDeposit_Secondary')) {

    } else {
        if (parseInt(r.total) > highestTotalAmount) {
            highestTotalAmount = r.total;
        }
    }
});
results.scenarios.forEach(function (item) {
    console.log(item.scenarioName + ": " + item.maximumBorrowableAmount);
    if (parseInt(item.maximumBorrowableAmount) > highestTotalAmount) {
        highestTotalAmount = item.maximumBorrowableAmount;
    }
});

updateCalculatorButtonText("We estimate you could borrow up to:", formatCurrency(highestTotalAmount));

    function getTotalTypeResult(primary, secondary) {
        const pValue = parseInt(primary);
        const sValue = parseInt(secondary);

        if (isNaN(pValue) && isNaN(sValue)) {
            return null;
        }
        if (isNaN(pValue)) {
            return secondary;
        }
        if (isNaN(sValue)) {
            return primary;
        }
        return pValue > sValue ? primary : secondary;
    }

function getTotalTypeResult(primary, secondary) {
    const pValue = parseInt(primary);
    const sValue = parseInt(secondary);

    if (isNaN(pValue) && isNaN(sValue)) {
        return null;
    }
    if (isNaN(pValue)) {
        return secondary;
    }
    if (isNaN(sValue)) {
        return primary;
    }
    return pValue > sValue ? primary : secondary;
}


/*const standardBorrowTotal = formatCurrency(getTotalTypeResult(formatted_results.Standard.total, formatted_results.Standard_Secondary.total));
updateTabTitle("#borrow_homestart_loan_results-label", standardBorrowTotal != null && standardBorrowTotal.length > 0 ? ('Borrow up to ' + standardBorrowTotal) : "");

if (!checkForNoResultsFound(results.Graduate)) {
    const graduateBorrowTotal = formatCurrency(getTotalTypeResult(formatted_results.Graduate.total, formatted_results.Graduate_Secondary.total));
    updateTabTitle("#borrow_graduate_loan_results-label", graduateBorrowTotal != null && graduateBorrowTotal.length > 0 ? ('Borrow up to ' + graduateBorrowTotal) : "");
}

// Check result for assistance loan and display appropriate text
if (!checkForNoResultsFound(results.Standard)) {
    var startupText = document.getElementsByClassName('startup-help-text');
    for (var i = 0; i < startupText.length; i++) {

        if (results.Standard.AssistanceLoanEligibility) {
            startupText[i].style.display = 'block';
        }
        else {
            startupText[i].style.display = 'none';
        }
    }
}



// When property price is entered and build is selected, loans are in reverse order (advantage first). we need to flip these.
if ($("#borrow_build_or_buy_build").is(':checked')) {
    borrowerFillResultsTable("#borrow_homestart_loan_results .primary", formatted_results.Standard_Secondary);
    borrowerFillResultsTable("#borrow_homestart_loan_results .secondary", formatted_results.Standard);
    $("#borrow_homestart_loan_results .subtitle:not(.last) .advantage_text").hide();
    $("#borrow_homestart_loan_results .subtitle.last .secondary_product a").attr('href', '/home-loans/borrowing-boost-loans/advantage-loan');
    $("#borrow_homestart_loan_results .subtitle.last .secondary_product a").text('Advantage Loan*');
    $("#borrow_homestart_loan_results .subtitle.last span").css('display', 'inline-block');

    if (!checkForNoResultsFound(results.Graduate)) {
        borrowerFillResultsTable("#borrow_graduate_loan_results .primary", formatted_results.Graduate_Secondary);
        borrowerFillResultsTable("#borrow_graduate_loan_results .secondary", formatted_results.Graduate);

        $("#borrow_graduate_loan_results .subtitle:not(.last) .advantage_text").hide();
        $("#borrow_graduate_loan_results .subtitle.last .secondary_product a").text('Advantage Loan*');
        $("#borrow_graduate_loan_results .subtitle.last span").css('display', 'inline-block');
    }
} else {
    borrowerFillResultsTable("#borrow_homestart_loan_results .primary", formatted_results.Standard);
    borrowerFillResultsTable("#borrow_homestart_loan_results .secondary", formatted_results.Standard_Secondary);

    if (!checkForNoResultsFound(results.Graduate)) {
        borrowerFillResultsTable("#borrow_graduate_loan_results .primary", formatted_results.Graduate);
        borrowerFillResultsTable("#borrow_graduate_loan_results .secondary", formatted_results.Graduate_Secondary);
    }
}

// Showing low deposit (if it is available)
if (formatted_results["LowDeposit"] != false) {

    const lowDepositBorrowTotal = formatCurrency(getTotalTypeResult(formatted_results.LowDeposit.total, formatted_results.LowDeposit_Secondary.total));
    updateTabTitle("#borrow_lowdeposit_loan_results-label", lowDepositBorrowTotal != null && lowDepositBorrowTotal.length > 0 ? ('Borrow up to ' + lowDepositBorrowTotal) : "");

    borrowerFillResultsTable("#borrow_lowdeposit_loan_results .primary", formatted_results.LowDeposit);
    borrowerFillResultsTable("#borrow_lowdeposit_loan_results .secondary", formatted_results.LowDeposit_Secondary);
}


// HOMESTART-BL: If results are exactly duplicate, hide the secondary one
if (checkDuplicateResults(formatted_results.Standard, formatted_results.Standard_Secondary)) {
    //alert('STANDARD second result its literally the same');
    // Hide standard
    $("#borrow_homestart_loan_results .boost-your-borrowing-power").hide();
    $("#borrow_homestart_loan_results .subtitle.last").hide();
    $("#borrow_homestart_loan_results .secondary").hide();

}
// HOMESTART-BL: If results are exactly duplicate, hide the secondary one
if (checkDuplicateResults(formatted_results.Graduate, formatted_results.Graduate_Secondary)) {
    //alert('GRADUATAE second result its literally the same');
    $("#borrow_graduate_loan_results .boost-your-borrowing-power").hide();
    $("#borrow_graduate_loan_results .subtitle.last").hide();
    $("#borrow_graduate_loan_results .secondary").hide();
}
// HOMESTART-BL: If results are exactly duplicate, hide the secondary one
if (checkDuplicateResults(formatted_results.LowDeposit, formatted_results.LowDeposit_Secondary)) {
    //alert('LOW DEPOSIT second result its literally the same');
    $("#borrow_lowdeposit_loan_results .boost-your-borrowing-power").hide();
    $("#borrow_lowdeposit_loan_results .subtitle.last").hide();
    $("#borrow_lowdeposit_loan_results .secondary").hide();
}

if (!Foundation.MediaQuery.is('medium up')) {
    mobileCalculatorAccordionReset();
} else {
    $("#borrow_homestart_loan_results").addClass('is-active').show();
    $("#borrow_homestart_loan_results-label").addClass('is-active')
    // reset results table to show homestart
    $("#borrow_results").find('.is-active').removeClass('is-active');
    $("#borrow_homestart_loan_results-label").parent().addClass('is-active');
    $("#borrow_homestart_loan_results").addClass('is-active'); // first one remains hidden until shown
}

$("#borrow_results").show();
showCallToActions();
generateVirtualPageView();
};
}*/