import $ from "jquery";

export default (window) => {

    $('#Pause-Payments-Button').on('click', function () {
        lockBackground();
        document.getElementById('Pause-Payments').style.display = 'block'
        console.log("Pause Payments clicked");
        $('#Pause-Payments-Already-Paused').css("display", "none");
        $('#Pause-Payments-Can-Pause').css("display", "none");
        $('#Pause-Payments-Error').css("display", "none");
        $('#Pause-Payments-Loading').css("display", "block");

        $.ajax({
            url: '/api/calmsdashboard/getpausepayment',
        }).done(function (result) {
            console.log(result);

            //$('#Pause-Payments-Resume-Date-Span').html(new Date().toISOString());
            $('#Pause-Payments-Loading').css("display", "none");

            if (result.isPaymentPaused) {
                $('#Pause-Payments').find('.modal-header').html("<h2>Paused Payments</h2>");
                $('#Pause-Payments-Already-Paused').css("display", "block");
                $('#Pause-Payments-Can-Pause').css("display", "none");
                $('#Pause-Payments-Error').css("display", "none");
                $('#Pause-End-Date').html(result.pausedPaymentEndDate);
                $('.Pause-Payments-Resume-Date').html(result.resumePaymentDate);

                return;
            }

            if (result.canPausePayment) {
                $('#Pause-Payments').find('.modal-header').html("<h2>Pause Payments</h2>");
                $('#Pause-Payments-Already-Paused').css("display", "none");
                $('#Pause-Payments-Can-Pause').css("display", "block");
                $('#Pause-Payments-Error').css("display", "none");
                $('#Pause-Payments-Request-Button').html("Confirm");
                $('#Max-Pause-Duration').html(result.maxPausePeriod);
                $('.Pause-Frequency-Selector-Span').html(result.frequency + "s");
                $('.Pause-Payments-Resume-Date').html(result.resumePaymentDate);

                var frequency = result.frequency;
                if (result.maxPausePeriod > 1) { frequency += "s";}

                $('.Pause-Frequency-Span').html(frequency);

                var selector = document.getElementById('Pause-Payments-Duration-Selector');
                selector.innerHTML = '';

                for (var i = 0; i < result.maxPausePeriod; i++) {
                    var n = i + 1;
                    var option = document.createElement("option");
                    option.innerHTML = n;
                    option.value = n;
                    selector.appendChild(option);
                }

                selector.value = 1;
                $('#Pause-Payments-Selected-Quantity').html(1);
            }
            else {
                $('#Pause-Payments').find('.modal-header').html("<h2>Pause Payments</h2>");
                $('#Pause-Payments-Already-Paused').css("display", "none");
                $('#Pause-Payments-Can-Pause').css("display", "none");
                $('#Pause-Payments-Error').css("display", "block");
                $('#Pause-Payments-Error-h').html("An error has occurred");
                $('#Pause-Payments-Error-p').html(result.errors[0].description);
            }
        });
    });

    $('#Pause-Payments-Duration-Selector').on('change', function () {
        var n = $('#Pause-Payments-Duration-Selector').val();
        $('#Pause-Payments-Selected-Quantity').html(n);
    });

    $('#Pause-Payments-Request-Button').on('click', function () {

        $('#Pause-Payments-Request-Button').html("<img src='/Content/img/oval.svg' width='18px'>");

        //(string HULID, int Period, string Frequency, bool Resume)
        var payload = {
            HULID: $('#primary-loan-div').attr('HULID'),
            Period: $('#Pause-Payments-Duration-Selector').val(),
            Frequency: "",
            Resume: false
        }
        console.log(payload);

        $.ajax({
            url: '/api/calmsdashboard/PausePayment',
            data: payload,
            success: function (result) {
                console.log(result);
                if (result.errors.length > 0) {
                    $('.modal').hide();
                    document.getElementById('ErrorModal').style.display = 'block';
                    document.getElementById('ErrorModal-Description').innerText = result.errors[0].description;
                }
                else {
                    $('.modal').hide();
                    document.getElementById('RequestSubmittedModal').style.display = 'block';
                    $('#Pause-Payments-Request-Button').html("Request");
                    lockBackground();
                }
            },
            error: function () {
                $('.modal').hide();
                document.getElementById('FailModal').style.display = 'block';
                $('#UCD-Submit-Button').html("Update");
                $('#UCD-Submit-Button').attr("disabled", false);

            }
        });
    });

    $('#Pause-Payments-Resume-Button').on('click', function () {
        document.getElementById('Pause-Payments').style.display = 'none';
        document.getElementById('Pause-Payments-Resume-Modal').style.display = 'block';
        $('#Pause-Payments-Resume-Confirm-Button').html("Confirm");
    });

    $('#Pause-Payments-Resume-Confirm-Button').on('click', function () {
        $('#Pause-Payments-Resume-Confirm-Button').html("<img src='/Content/img/oval.svg' width='18px'>");

        //(string HULID, int Period, string Frequency, bool Resume)
        var payload = {
            HULID: $('#primary-loan-div').attr('HULID'),
            Period: 0,
            Frequency: "",
            Resume: true
        }
        console.log(payload);

        $.ajax({
            url: '/api/calmsdashboard/PausePayment',
            data: payload,
            success: function (result) {
                console.log(result);
                if (result.errors.length > 0) {
                    $('.modal').hide();
                    document.getElementById('ErrorModal').style.display = 'block';
                    document.getElementById('ErrorModal-Description').innerText = result.errors[0].description;
                    
                }
                else {
                    document.getElementById('RequestSubmittedModal').style.display = 'block';
                    document.getElementById('Pause-Payments-Resume-Modal').style.display = 'none';
                    $('#Pause-Payments-Resume-Confirm-Button').html("Confirm");
                    unlockBackground();
                }
            },
            error: function () {
                $('.modal').hide();
                document.getElementById('FailModal').style.display = 'block';
                $('#UCD-Submit-Button').html("Update");
                $('#UCD-Submit-Button').attr("disabled", false);
            }

        });
    });
}
