import $ from 'jquery';

import {getFullSuburbList, getSuburbListAutoComplete} from "../../components/calculators/calculator-suburb-list";
import {getPostCodeMap, isBrokerSearchInputValid}     from "./location-valuers-helpers";
import {formatCurrency, mRound}                       from "../../components/calculators/calculator-utils";

export default function (window) {
    var $postcodeField = $("#valuer_search_suburb");

    // do request
    var searchTerm = $postcodeField.val().trim();

    if (searchTerm == '') {
        return false;
    }
    $('#valuer_search_submit').addClass('loading');
    var API_URL = window.location.origin + '/api/valuersearchservice/searchapi/postcode/';
    var postcode = isBrokerSearchInputValid(searchTerm);
    var $resultsContainer = $("#valuer_search_results");
   if (postcode) {
        $.get(API_URL + searchTerm, function (data) {
            console.log(data);

            $resultsContainer.html('');
            if (data.length == 0) {
                    //console.log(data.errorMessage);
                    $resultsContainer.html('');
                    $resultsContainer.prepend(`<p class="count">Your valuer search for ${searchTerm} has returned <strong>0</strong> results.</p>`)

            } else {
                // reset results container
                $resultsContainer.html('')
                var valuersOrValuer = (data.length >=1) ? "Valuers" : "Valuer"

                $resultsContainer.prepend(`<p class="count">Your valuer search for ${searchTerm} has returned <strong>${data.length} </strong> ${valuersOrValuer.toLowerCase()}.<br/><br/><strong>Valuers that service this location:</strong></p>`)

                var template = responseTemplate(data);
                $resultsContainer.append(template);

            }
            $('#valuer_search_submit').removeClass('loading');
        });

    } else {

        $resultsContainer.html('');
        $resultsContainer.prepend(`<p class="count">Your valuer search for ${searchTerm} has returned <strong>0</strong> results.</p>`)
        $('#valuer_search_submit').removeClass('loading');

    }

}

function responseTemplate(data) {

    var template = `
        <div class="location-search-response column tiny-12" data-locality-id="${data.localityID}" data-category-id="${data.categoryID}">
       <table>
        <thead>
        <tr>
            <th>Valuer</th>
            <th>Contact Number</th>
            <th>Standard Valuation</th>
            <th>Progressive Valuation</th>
        </tr>
        </thead>
        <tbody>
        `
    // Add each category products
    // Each result goes in as a <tr><td>
    $.each(data, function (k, datum) {

        template += `
<tr>
            <td>${datum.valuer}</td>
            <td>${datum.contactNo}</td>
            <td>$${valuerFormatCurrency(datum.standard)}</td>
            
            <td>$${valuerFormatCurrency(datum.progress)}</td>
        </tr>`
    });

    template += `
        </tbody>

        </table> 
        </div>`
    return template;

}
function valuerFormatCurrency(number) {
    return parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


