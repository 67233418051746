import $ from 'jquery';
import YouTubePlayer from 'youtube-player';
import {getYoutubeVideoID} from "../utils";

export default () => {

    $('#testimonialsSelectedList').change(function () {
        $('#testimonialsWidgetContainer .testimonial-option').each(function () {
            var option = $(this).attr('data-node-guid');
            var element = $(this);
            element.show();
            $('#testimonialsWidgetContainer .testimonial-sort-option').each(function () {
                var selOption = $(this).parent().attr('data-node-guid');
                if (option === selOption) {
                    element.hide();
                }
            });
        });
    });

    var slider = $('.testimonial-slider').slick({
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 10000,
    });

    var currentPlayer = null;
    $(document).on('open.zf.reveal', function () {
        var $modal = $($(this)[0].activeElement);

        if( $modal.hasClass('testimonial-reveal') ){
            slider.slick('slickPause');

            var $embed = $modal.find('.youtube-embed').first();
            var videoID = getYoutubeVideoID($embed.attr('data-videoid'));

            if (currentPlayer == null || currentPlayer.videoID != videoID) {
                currentPlayer = YouTubePlayer($embed.get(0), {
                    playerVars: {
                        rel: 0,
                    }
                });
                currentPlayer.videoID = videoID;
                currentPlayer.loadVideoById(videoID).then(function(){
                    currentPlayer.playVideo();
                });
            }
            else{
                currentPlayer.playVideo();
            }
        }
    });

    $(document).on('closed.zf.reveal', function () {
        if( currentPlayer != null ){
            currentPlayer.stopVideo();
        }
        slider.slick('slickPlay');
    });
};
