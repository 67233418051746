import $ from 'jquery'
import {maskElement, scrollTo} from '../utils'

export default () => {
    function applyMasks($form){
        $form.find('input[mask]:not(.masked)').each(function(){
            $(this).addClass('has-prefix');
            maskElement($(this));
        });
    }

    $('#form_container').each(function(){
        const $container = $(this);
        $container.on('submit', 'form[data-ajax=true]', function () {
            setTimeout(function () {
                if ($container.find('.formwidget-submit-text').length) {
                    scrollTo($container, 500);
                }
            }, 750);
        });
    });

    $('body').on('submit', 'form[data-ajax=true]', function () {
        setTimeout(function () {
            applyMasks($('form[data-ajax=true]'));
        }, 750);
    });

    $('form[data-ajax=true]').each(function(){
        applyMasks($(this));
    });
}