/*******************************************************
 * Copyright (C) 1996-2019 The Digital Embassy Pty Ltd admin@thedigitalembassy.com.au
 * These source files are proprietary, confidential and the Intellectual Property of The Digital Embassy Pty Ltd
 * Unauthorized copying, modifying and/or distributing of source files, via any medium is strictly prohibited
 *******************************************************/
import $ from 'jquery';

import initIEDetect from './components/ie-detect'
import initDetectBrowser from './components/detect-browser'
import initPDFLinks from './components/pdf-links'
import initTestimonialsWidget from './components/testimonials-widget';
import initLandingPage from './components/landing-page';
import initHeroSlider from './components/hero-slider';
import initBlogPage from './components/blog-page';
import initAccordions from './components/accordions';
import initResponsiveAccordion from './components/responsive-accordion';
import initMenu from './components/menu';
import initSlideInMenu from './components/slide-in-menu';
import initForms from './components/forms';
import initIframes from './components/iframe';
import initCTAShowMore from './components/cta-showmore';
//import initQNAAccordion        from './components/qna-accordion';
import initTopicsDropdown from './components/mystart';
import initCompareHomeLoans from './components/compare-home-loans';
import initSmoothScroll from './components/smooth-anchor';
import initCalculator from './components/calculator';

import initFlexVideo from './components/flex-video';
import initTables from './components/table';
import initArticleBox from './components/article-box';
import initTagManager from './components/tag-manager';
import initKenticoForms from './components/kentico-forms';
import initKeyFacts from './components/key-facts';
import initSeminarItemPage from './components/seminar-item-page'
import initLightcase from './components/lightcase'
import {hasPDFViewer} from "./utils";
import initBroker from './broker/broker_main';
import initCheckLogin from './components/check-login';
import initCustomer from './customer/customer';
import initTransactingCustomer from './customer/transacting-customers/transacting-customer';

const main = () => {
    window.jQuery = $;
    window.$ = $;


    Foundation.Abide.defaults.patterns['number_commas'] = /^[-+]?\d*(?:[\.\,]\d+)*?$/;

    $(document).foundation()
    window.canRenderPDF = hasPDFViewer(window, navigator);

    // capture mask objects
    window.inputMasks = {};

    initIEDetect();
    initDetectBrowser(window);
    initPDFLinks(window, navigator)
    initHeroSlider();
    initLandingPage();
    initBlogPage();
    initResponsiveAccordion();
    initAccordions();
    initMenu(window);
    initSlideInMenu(window);
    initForms();
    initIframes(window);
    initArticleBox();
    initTestimonialsWidget();
    initCTAShowMore();
    initSmoothScroll(window);
    initTopicsDropdown();
    initCompareHomeLoans();
    initFlexVideo();
    initTables();
    initTagManager(window);
    initKenticoForms(window);
    initKeyFacts(window);
    initCalculator(window);
    initSeminarItemPage();
    initBroker(window);
    initCustomer(window);
    initLightcase();
    initTransactingCustomer(window);

    function iOSversion() {
        console.log('Is this IOS?')
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            console.log('it is ios')
            let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
    }

    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        let ver = iOSversion();

        console.log('Is ios');
        if (ver[0] >= 13) {
            console.log('Adding ios13 class to body');
            $('body').addClass('ios13');
        }
    }
    initCheckLogin(window);

};

// Side Effects
$(document).ready(() => main());
