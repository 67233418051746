import $ from 'jquery';

function hideOnClickOutside(element) {
  const outsideClickListener = event => {
    if (!element.contains(event.target)) {
      // $('.menu-button').removeClass('is-active');
      $('html').removeClass('menu-open');
      removeClickListener()
    }
  }

  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener)
  }

  removeClickListener();
  document.addEventListener('click', outsideClickListener)
}

const init = function ($menu) {
  const menu = $menu.get(0);

  // toggle menu
  $('body').on('click', '.menu-button', function (e) {
    // $(this).toggleClass('is-active');
    $('html').toggleClass('menu-open');
    // Scroll menu to top (breaks when using #goto brwser links
    if(window.location.hash) {
      $('body,html,.slide-in-menu,.slide-in-menu-inner').scrollTop(0);
    }


    if( $('html').hasClass('menu-open') ) {
      // click outside of menu
      setTimeout(function () {
        hideOnClickOutside(menu);
      }, 1);
    }
  });

  $menu.on('click', ' .menu-item.has-child > a', function(e){
    if ($menu.hasClass('broker-slide-in-menu')) {
        console.log('has class,exiting early');
       return;
    }
    e.preventDefault();

    const item = $(this).parent();
    $(item).toggleClass('open');
    $('> .sub-menu', item).slideToggle();

    $(item).siblings().removeClass('open').find('> .sub-menu').slideUp();
  })

  $('body').on('click', '[data-search]', function(e){
    if( !Foundation.MediaQuery.is('large up') ){
      e.stopPropagation();

      $('.menu-button').click();

      if( $('html').hasClass('menu-open') ) {
        setTimeout(function () {
          $('[name="searchtext"]', menu).focus();
        }, 300);
      }
    }
  });

  // $('body').on('click', function() {
  //   console.log('off menu');
  //   $('html.menu').removeClass('menu');
  // });

  // if ($('body').width() < 769) {
  //   $('body').append('<span class="menu-overlay"></span>');
  //
  //   $('body').on('click touchstart', '.menu-overlay', function () {
  //     $('html').toggleClass('menu');
  //   });
  // }

  //accordion
  // if ($('body').width() < 769) {
  //   $('.slide-in-menu div').slideUp();
  //   $('.slide-in-menu > ul > li').removeClass('highlighted-mobile');
  //
  //   $(document).on('click', '.slide-in-menu > ul > li > a', function (event) {
  //     event.preventDefault();
  //     if ($(this).parent().hasClass('highlighted-mobile')) {
  //       $('.slide-in-menu > ul > li').removeClass('highlighted-mobile');
  //       $('.slide-in-menu li div').slideUp();
  //     } else {
  //       $('.slide-in-menu > ul > li').removeClass('highlighted-mobile');
  //       $('.slide-in-menu li div').slideUp();
  //
  //       $(this).parent().toggleClass('highlighted-mobile');
  //       $(this).next().slideToggle();
  //     }
  //     $('.slide-in-menu').animate({scrollTop: 0}, '500', 'swing', function () {
  //       //alert("Finished animating");
  //     });
  //   });
  //
  // }
  /*
              window.addEventListener("orientationchange", function() {
                  // Announce the new orientation number
                  //alert(window.orientation);
                  if ($('body').width() > 768 ) {
                      $('.slide-in-menu div').slideDown();
                      $('.slide-in-menu > ul > li').removeClass('highlighted-mobile');
                  }
              }, false);
  */
  // $(window).on('resize', function () {
  //   if ($('body').width() > 768) {
  //     $('.slide-in-menu div').slideDown();
  //     $('.slide-in-menu > ul > li').removeClass('highlighted-mobile');
  //   } else {
  //     $('.slide-in-menu div').hide();
  //     $('.slide-in-menu > ul > li').removeClass('highlighted-mobile');
  //     $('.manage .link').attr('href', 'tel:1300636878');
  //   }
  // });
}

export default (window) => {
  $('.slide-in-menu').each(function(){
    init($(this));
  })
}