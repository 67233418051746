import $ from 'jquery';
import {hexToRgbA} from "./../utils"

export default function (window) {
  var formContainer = $("#form_container");
  if (formContainer.length) {

    // Form Container Background/Opacity
    var formBgAlpha = (formContainer.data('background-opacity') < 100) ? formContainer.data('background-opacity') * .01 : 1;
    var formBackgroundColor = hexToRgbA(formContainer.data('background-color'), formBgAlpha);
    formContainer.css({background: formBackgroundColor});

    // Body Background Color
    var body = $('body');
    if (body.data('background-color') != '') {
      body.css({background: body.data('background-color')});
    }

    // Form Header text
    var formHeader = $('h3.form-header');
    formHeader.css({color: formContainer.data('form-header-color')})

    // Form Label Colours
    var formLabels = formContainer.find('label');
    var formLabelColor = formContainer.data('form-label-color')
    formLabels.each((k, elem) => {
      $(elem).css({color: formLabelColor});
    });

    // Form Submit Button
    var formSubmitButton = formContainer.find('input[type="submit"]');
    formSubmitButton.css({
      color: formContainer.data('form-submit-text-color'),
      background: formContainer.data('form-submit-background-color'),
    });
  }
}