import $ from 'jquery'
import download from 'downloadjs'

export default (window) => {
  const canRenderPDF = window.canRenderPDF;

  $('a[href*=".pdf"]').each(function () {
    if( canRenderPDF ) {
      // remove download attr
      $(this).removeAttr('download');
    }
    // else{
    //   // add download attribute
    //   let label = $(this).attr('aria-label');
    //   label = label ? label : $(this).attr('title');
    //   label = label ? label : $(this).attr('href');
    //   label = label ? label : document.title;
    //   $(this).attr('download',label);
    // }

    // open in a new tab
    $(this).attr('target', '_blank');
  });
  $('a[href*=".PDF"]').each(function () {
    if( canRenderPDF ) {
      // remove download attr
      $(this).removeAttr('download');
    }
    // else{
    //   // add download attribute
    //   let label = $(this).attr('aria-label');
    //   label = label ? label : $(this).attr('title');
    //   label = label ? label : $(this).attr('href');
    //   label = label ? label : document.title;
    //   $(this).attr('download',label);
    // }

    // open in a new tab
    $(this).attr('target', '_blank');
  });

  // use JS helper to trigger the download (used mainly for IE which can't render PDF's)
  $('html.ie body').on('click', 'a[href*=".pdf"]', function (e) {
    e.preventDefault();
    e.stopPropagation();

    download($(this).attr('href'));
  });
}