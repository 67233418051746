import $ from 'jquery'

//
// Provides a wrapper around the Kentico form submissions that provides
// events that can be hooked into to trigger custom functionality.
//
// Events:
//  These events are now thrown in updateableFormHelper.js
//   kenticoForm_BeforeSubmit: (e, formId, formName)
//     Triggered before form submission has been sent to the server.
//   kenticoForm_AfterAjaxSuccess: (e, formId, formName, data, defaultHandler)
//     Triggered after a successful ajax submission.
//
export default function (window) {
    // Defines default AfterAjaxSuccess handler.
    //
    //  This handler
    // Can be cancelled by calling e.preventDefault(). If you do this make
    // sure that you check the formName before cancelling because you will
    // cancel every form otherwise.
    $('body').on('kenticoForm_AfterAjaxSuccess', 'form', (e, formId, formName, data, handler) => setTimeout(() => {
        if (e.isDefaultPrevented()) {
            console.log('prevented');
            return;
        }
        const fn = new Function('data', handler)
        fn(data)
    }, 1))
}