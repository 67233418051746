import $ from 'jquery';

export default () => {
  /**
   * Expand the topics drop-down
   */
  $("#topics_dropdown_button").on('click', function () {
    if ($("#topics_dropdown").is(':visible')) {
      $("#topics_dropdown").slideUp();
    } else {
      $("#topics_dropdown").slideDown();
    }
  });

  $('.mystart-subscribe .form-field').each(function(k,elem) { 
    if($(elem).height() ==0) { 
      $(elem).attr('style','margin-bottom:0px');
    }
  })
  /**
   * Remove the ".hideme" class from 8 articles
   * Mimics fake pagination
   */
  $("#mystart_loadmore").on('click', function () {
    hideViewMore($(this));

    let isMystart = $('.mystart-blog').length >= 1;
    let isNotifications = $('.notifications-listing').length >= 1;
    let $mystartHidden = $('.mystart-blog .card-tiles .hideme');
    var $notificationsHidden = $('.notifications-listing .hideme');

    // take a snapshot of the current scroll position
    const scrollSnapshot = document.documentElement.scrollTop;

    if (isMystart) {
      $mystartHidden.each(function (k, elem) {
        $(elem).removeClass('hideme');
        if (k >= 8) {
          return false;
        }
      });
    }

    if (isNotifications) {
      $notificationsHidden.each(function (k, elem) {
        $(elem).removeClass('hideme');
        if (k >= 8) {
          return false;
        }
      });
    }

    // restore the scroll snapshot
    document.documentElement.scrollTop = scrollSnapshot;

    hideViewMore($(this));
  })
};

/**
 * If no more items exist in the hidden ".hideme" container, hide the load more button.
 *
 * @param $this
 */
function hideViewMore($this) {
  let isMystart = ($('.mystart-blog').length >= 1 ? true : false);
  let isNotifications = ($('.notifications-listing').length >= 1 ? true : false);
  let $refresh = $('.mystart-blog .card-tiles .hideme');

  if ($refresh.length === 0 && isMystart && !isNotifications) {
    $("#mystart_loadmore").hide();
  }
  else if ($('.notifications-listing .hideme').length === 0 && isNotifications && !isMystart) {
    $("#mystart_loadmore").hide();
  }
}
