/*
 * Data Sync
 *  - Links values for duplicate fields across multiple calculators together,
 *  - e.g. typing in Property Price in one pushes it across to the other.
 */
import $                from 'jquery';
import {formatCurrency} from "./calculator-utils";

export default function () {
    // Little pub/sub for event delegating
    (function ($) {
        let o = $({});

        $.subscribe = function () {
            //console.log('subsceibe');
            o.on.apply(o, arguments);
        };

        $.unsubscribe = function () {
            o.off.apply(o, arguments);
        };

        $.publish = function () {
            o.trigger.apply(o, arguments);
        };

    }(jQuery))
    let syncItems = [];

    // Populate list of items to sync
    $("[data-sync]").each(function (k, elem) {
        var syncItemName = $(elem).data('sync');
        syncItems.push(syncItemName);
        if (!syncItems.indexOf(syncItemName) > -1) {
            syncItems.push(syncItemName);
        }
    });
    // unique fields
    syncItems = syncItems.filter((v, i, a) => a.indexOf(v) === i);

    $.each(syncItems, function (k, syncItem) {
        var $items = $('[data-sync="' + syncItem + '"]');
        if ($items.length) {
            $.subscribe(syncItem, updateSyncedItems(syncItem));
            // Create an on listener event (which selects all other data-sync and updates them w/ the new value) for each input item
            // Set this to update every time the value changes

            // Loop through each input with the matching data-sync attr,
            // create listener event on element, to publish the sync event with the new value
            $.each($items, function (k, elem) {
                let $elem = $(elem);
                let syncEvent = $(elem).data('sync');
                $elem.on('change', function (e) {
                    //console.log(e.C);
                    let $this = $(e.currentTarget);
                    if ($this.hasClass('button-group')) {
                        let name = $this.attr('id');
                        let newValue = $('input[name="' + name + '"]:checked').val()
                        // Handle button group
                        //console.log("clicked " + newValue);
                        //console.log(syncEvent + ' triggered with value of ' + newValue);
                        $.publish(syncEvent, newValue);
                        // find checked button, find other groups, check those buttons
                    } else {
                        // Handle text input sync
                        //console.log('Syncing text fields')
                        let $this = $(e.currentTarget);
                        let newValue = $this.val();
                        //console.log('Updating sync group: ' + syncEvent + '  with value:' + newValue);

                        $.publish(syncEvent, newValue);

                    }

                });
                $elem.on('input', function (e) {
                    //console.log(e.C);
                    let $this = $(e.currentTarget);
                    if ($this.hasClass('button-group')) {
                        let name = $this.attr('id');
                        let newValue = $('input[name="' + name + '"]:checked').val()
                        // Handle button group
                        //console.log("clicked " + newValue);
                        //console.log(syncEvent + ' triggered with value of ' + newValue);
                        $.publish(syncEvent, newValue);
                        // find checked button, find other groups, check those buttons
                    } else {
                        // Handle text input sync
                        //console.log('Syncing text fields')
                        //let $this = $(e.currentTarget);
                        let newValue = $this.val();
                        //console.log('Updating sync group: ' + syncEvent + '  with value:' + newValue);

                        $.publish(syncEvent, newValue);

                    }

                });

            })
        } else {
            //console.log('no elements found for ' + syncItem);
        }
    });
}

export const updateSyncedItems = (syncEvent, excludedID) => {
    return function (event, value) {
        // If they aren't set to the current newValue
        // set them to the newValue
        //console.log('syncEvent:' + syncEvent);
        //console.log('value:' + value);
        //console.log('event:', event);
        // Skip the first argument (event object) but log the name and other args.
        // Find all other items with data-sync=syncEvent
        let $itemsToSync = $('[data-sync="' + syncEvent + '"]');

        if (excludedID != null) {
            $itemsToSync = $itemsToSync.filter('not(#' + excludedID + ')');
        }

        if ($itemsToSync.hasClass('button-group')) {
            // console.log('Syncing button group');
            // Loop through each matching group and set its value to NewValue
            $itemsToSync.find('input').each(function (k, elem) {
                let $input = $(elem)
                if ($input.is(':checked')) {
                    $input.removeProp('checked');
                }
                if ($input.attr('value') == value) {
                    $input.prop('checked', 'checked');
                }

            })
        } else {
            // Handle updating text inputs
            let $itemsToSync = $('[data-sync="' + syncEvent + '"]');

            $itemsToSync.each(function (k, elem) {
                let $input = $(elem);
                //console.log($input);
                if ($('html').hasClass('ie-11')) {

                    if ($input.hasClass('masked')) {
                        // IE has issues syncing across commas, so we just sync it, then reformat it
                        value = value.toString().replace(/,/g, '');
                        $input.val(formatCurrency(value).replace('$', ''));
                    }
                } else if ($input.attr('id') =='lmilpc_property_price' ) {
                    //alert('gottem')
                    let newValue = value.replace(/,/g,'');
                    $input.val(newValue);
                } else {
                    $input.val(value);
                }
            })
        }
    };
}

