import $ from 'jquery';

export default function (window) {
    $('.news-slideshow').slick(
        {
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        centerMode: true
                    }
                },
            ]
        });

    adjustNewsSlideHeight();

    $('.news-slideshow').on('breakpoint', function () {
        adjustNewsSlideHeight();
    })

    if ($('ul.accordion.document-list-group').length >= 2) {
        $('ul.accordion.document-list-group:first-child .accordion-title').trigger('click')
    }

    function adjustNewsSlideHeight() {
        let slickHeight = $('.news-slideshow .slick-list').height();
        $('.news-slideshow .slick-list').height(slickHeight + 10);
    }

};
