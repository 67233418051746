import $                      from 'jquery';
import {calculateButtonReset} from "./calculator-utils";
import IMask from 'imask';

export default function (window) {
    const lmilpcDeposit = document.getElementById("lmilpc_deposit")
    if( lmilpcDeposit )
    {
        IMask(lmilpcDeposit, {
            mask: 'num',
            blocks: {
                num: {
                    // nested masks are available!
                    mask: Number
                }
            }
        });
    }

    const lmilpcPropertyPrice = document.getElementById("lmilpc_property_price")
    if( lmilpcPropertyPrice ) {
        IMask(lmilpcPropertyPrice, {
            mask: 'num',
            blocks: {
                num: {
                    // nested masks are available!
                    mask: Number
                }
            }
        });
    }
    $('.slider').on('changed.zf.slider', function (event) {
        // These fire once on load when the slider is initialized, we want to ignore that
        if ($('.calculate-button:visible').hasClass('loading')) {
            console.log('Skipping double request');
            return;
        }
        let $elem = $(event.target);
        let inputTarget = $elem.find('.slider-handle').attr('aria-controls');
        let $inputTarget = $("#" + inputTarget);

        if (($inputTarget.val() == 1000 && $inputTarget.attr('id') == 'lmilpc_deposit') && $("#lmilpc_property_price").val() == 75000) {
            console.log($inputTarget.val(), $inputTarget.attr('id'), $("#lmilpc_property_price").val() );
            console.log('Ignoring event, default value');
            return;
        }

        if ($inputTarget.val() == 75000 && $inputTarget.attr('id') == 'lmilpc_property_price' && $("#lmilpc_deposit").val() == 1000) {
            console.log($inputTarget.val(), $inputTarget.attr('id'), $("#lmilpc_deposit").val() );
            console.log('Ignoring event, default value');
            return;
        }
        //if ($('#lmilpc_property_price').val() != 75000 && $('#lmilpc_deposit').val() != 1000) {

            //if ($('.calculate-button:visible').hasClass('loading')) {
            //console.log('Skipping double request');
            //return;
            //}
            $('.calculate-button:visible .button').trigger('click');
        //}

    });
}

