import $ from 'jquery';
export default function() {
    window.getUrlParams =
        function () {
            var tmp = window.location.href.split('?');
            var result = {};
            if (tmp.length > 1) {
                tmp = tmp[1].split("&");
                for (var i = 0; i < tmp.length; i++) {
                    var param = tmp[i].split('=');
                    if (param.length > 1) {
                        result[param[0]] = param[1];
                    }
                }
            }
            return result;
        };
    if (window.getUrlParams().topic) {
        $('#topicsDropdown').val(window.getUrlParams().topic);
    }

    $('#topicsDropdown').change(function () {
        var selected = $(this).val() !== '' ? '?topic=' + $(this).val() : '';

        location.href = window.location.href.split('?')[0] + selected;
    });
};