import $ from 'jquery';

import {getFullSuburbList, getSuburbListAutoComplete} from "../../components/calculators/calculator-suburb-list";

export const isSearchInputValid = (val) => {
    if (val === "" || val === undefined) {
        console.log('Search: no value passed');
        return false;
    }

    var postCodeSuburbMap = getPostCodeMap();
    if (parseInt(val) !== NaN && val.length <= 4) {
        val = parseInt(val);
        // Could be valid, check it's a valid post code
        console.log(val, 'is a number');

        if (!(val in postCodeSuburbMap)) {
            //console.warn("it's a valid postcode!", val, postCodeSuburbMap[val]);
            //alert("Invalid post code");
            //console.warn("it's NOT a valid postcode!", val, postCodeSuburbMap[val]);
            return true;
        }
    } else {
        // Possibly a suburb name
        //console.log(val.toLowerCase());
        if (!(postCodeSuburbMap.includes(val.toLowerCase()))) {
            // We aren't explicitely checking suburb names, the API will return an error for us here fi the suburb name is incorect
        }
    }
    // If we got to here it's valid
    return true;
};

export const isBrokerSearchInputValid = (val) => {
    if (val === "" || val === undefined) {
        console.log('Search: no value passed');
        return false;
    }


    var postCodeSuburbMap = getBrokerPostCodeMap();

    if (!isNaN(val) && val.length <= 4) {
        val = parseInt(val);
        // Could be valid, check it's a valid post code

        for (let key in postCodeSuburbMap) {
            if (parseInt(postCodeSuburbMap[key]) === val) {
                return val;
            }
        }

        return false;

    } else {
        for (let key in postCodeSuburbMap) {
            if (key === val.toLowerCase()) {
                return postCodeSuburbMap[key];
            }
        }

        return false;
    }
};
export const getAutoCompletePostCodes = () => {
    let suburbs = getFullSuburbList();
    let postCodeSuburbMap = [];
    $.each(suburbs, function (k, obj) {
        postCodeSuburbMap.push({label: obj["postCode"], value: obj["suburbName"]});
        postCodeSuburbMap.push({label: obj["suburbName"].toLowerCase(), value: obj["suburbName"]});
    });
    //window.postcodemap = postCodeSuburbMap;

    console.log(postCodeSuburbMap);
    return postCodeSuburbMap;
}
export const getPostCodeMap = () => {
    let suburbs = getFullSuburbList();
    let postCodeSuburbMap = [];
    $.each(suburbs, function (k, obj) {
        postCodeSuburbMap[obj['postCode']] = obj["suburbName"].toLowerCase();
    });
    //window.postcodemap = postCodeSuburbMap;
    return postCodeSuburbMap;
};

export const getBrokerPostCodeMap = () => {
    let suburbs = getFullSuburbList();
    let postCodeSuburbMap = [];
    $.each(suburbs, function (k, obj) {
        postCodeSuburbMap[obj['suburbName'].toLowerCase()] = obj["postCode"];
    });

    // window.postcodemap = postCodeSuburbMap;
    return postCodeSuburbMap;
};

