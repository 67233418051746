import $ from 'jquery';

export default function () {
    //if ($("div:not(.hero-slider) .hero-feature").length >= 1) {
    //var parent = $("section:not(.hero-slider) .hero-feature").parent();
    //parent.removeClass('tiny-12');
    //parent.find('.hero-feature').wrapAll('<div class="hero-slider banner-item">');
    //}
    // Hero sliders inside a slider section
    let $slider = $('.hero-slider:not(".edit-mode")');

    $slider.slick({
        dots: true,
        infinite: true,
        speed: 500,
        // fade: true,
        // cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
    });

};