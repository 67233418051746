import initCalculatorDataSync        from './calculators/calculator-data-sync';
import initCalculatorNBorrowers      from './calculators/calculator-n-borrowers';
import initCalculatorSuburbSuggestor from './calculators/calculator-suburb-suggestor';
import initCalculatorValidation      from './calculators/calculator-validation';
import initShowFees                  from './calculators/calculator-show-fees';
import initResetOnTabChange          from './calculators/calculator-reset-on-tab-change';
import initCTAButtons          from './calculators/calculator-cta-buttons';
import initSliderHandler from './calculators/calculator-slider-handler';
import initNumberInputHandler from './calculators/calculator-number-input-handler';
import initSubmitOnEnter       from './calculators/calculator-submit-on-enter';
import initDynamicBreadCrumbs  from './calculators/calculator-dynamic-bread-crumbs';
import initCalculatorInputMask from './calculators/calculator-input-masking';
import initResetOnFormChange   from './calculators/calculator-reset-on-form-change';
import $                       from "jquery";


export default function (window) {
    // Global settings under calculator object on window
    window.calculator = {
        loanTypes: {
            25: "Standard",
            47: "Graduate",
            54: "LowDeposit"
        },
    };

    initSliderHandler(window);
    initNumberInputHandler(window);
    initResetOnFormChange(window);
    initCalculatorInputMask(window);
    initCalculatorDataSync();
    initDynamicBreadCrumbs();
    //initSubmitOnEnter();
    initCalculatorNBorrowers();
    initCalculatorSuburbSuggestor();
    initCalculatorValidation(window);
    initShowFees();
    initResetOnTabChange();
    initCTAButtons(window);
    if ($('.calculator-page').length >= 1) {
        var paramKey = window.location.search.toString();
        paramKey = paramKey.replace('?', '');
        var $target = $(".row.calculator-tabs");
        var doScroll = true;
        var selectedTab = "";
        if (paramKey.includes('borrow')) {
            selectedTab = "borrow"
        } else if (paramKey.includes('deposit')) {
            selectedTab = "deposit";
        } else if (paramKey.includes('repayments')) {
            // regular scroll
            selectedTab = "repayments";
            //alert('scroll for homeloan');
        } else if (paramKey.includes('lmilpc')) {
            selectedTab = "lmilpc";
        } else {
            doScroll = false;
        }
        /*if (doScroll) {
        $(document).foundation();
            $("#calculator-tabs").foundation('selectTab',selectedTab)
                $("#calculator-tabs").foundation('selectTab',selectedTab)
            const menuHeight = $('header').filter(':visible').outerHeight();
            $('html, body').animate(
                {
                    scrollTop: $target.offset().top - menuHeight // offset menu height so it's not blocking content
                }, 500);
        }*/
    }
}