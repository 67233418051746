import $ from 'jquery';

const HIDDEN_FIELD_KEY = "Seminar.Value";

export default () => {
    const $page = $('.page-seminar');

    if( $page.length ){
        const $input = $page.find('input[type="hidden"][name*="'+HIDDEN_FIELD_KEY+'"]');

        if( $input.length ){
            $input.val( $page.data('seminar-title') );
        }
    }
}