/*
 | Dynamic bread crumbs that change depending on
 | which Calculator tab is currently being viewed
 */
import $ from 'jquery';


export default function () {
    if (!$('body').hasClass('calculator-page')) {
        //alert('no page class');
        return;
    }

    // Set initilly

    updateBreadCrumbs(window.location.search);

    $("#calculator-tabs").on('change.zf.tabs', function (e) {
        updateBreadCrumbs(window.location.search);
    });

    function updateBreadCrumbs(hash) {
        let hashMap = {
            "": "How much can I borrow", // Just incase there's no hash
            "?borrow": "How much can I borrow",
            "?deposit": "How much deposit do I need",
            "?repayments": "What are my repayments",
            "?lmilpc": "LMI vs LPC",
        }
        let $breadcrumbs = $('ul.breadcrumbs');
        if ($breadcrumbs.find('.calculator-breadcrumb-element').length == 0) {
            $breadcrumbs.append("<li class='calculator-breadcrumb-element'><span>" + hashMap[hash] + "</span></li>");
        } else {
            // Already inserted element, update it
            $('.calculator-breadcrumb-element span').text(hashMap[hash]);
        }
        document.title = 'HomeStart Finance - ' + hashMap[hash];
        $('title').text('HomeStart Finance - ' + hashMap[hash]);
    }
};
