import initSSRSReportEmbed from './components/ssrs-report-embed';
import initAlerts from './components/alerts';
import initValuerSearch from './components/valuer-search';
import initLocationSearch from './components/location-search';
import initBrokerSearch from './components/broker-search';
import {initBreadCrumbFix, initNoSubmitOnEnter} from "./components/broker-utils";
import initNewsSlider from "./components/broker-news-slider";
import {initCalculatorScrolling} from "./components/homeloan/calculator-tab-scrolling";
import {initFaqAutoOpen} from "./components/faq-widget-autoopen";
import initAggregatorReport from "./components/ssrs-aggregator-embed";
import initDisplayDocuments from './components/broker-cm-document';
import initDisplayBoardDocuments from './components/board-cm-document';
import { initYTD, initYTDPrint } from './components/year-to-date';
import { initSeniorsEquity } from "./components/seniors-equity";

export default function (window) {
    // Global settings under calculator object on window
    initSSRSReportEmbed();
    initAlerts();
    initBrokerSearch(window);
    initNewsSlider(window)
    initNoSubmitOnEnter(window);
    initFaqAutoOpen(window);
    initCalculatorScrolling(window);
    initAggregatorReport(window);
    initDisplayDocuments();
    initDisplayBoardDocuments();
    initYTD(window);
    initYTDPrint(window);
    initSeniorsEquity(window);
    //console.log('Deployed correctly');

    $("form[id*=LodgeSpotAndReferLead] .ktc-default-section")
        .append(`<div class="form-field"> <a href="https://homestart.com.au/privacy">Privacy Collection Statement Consent</a>`)
    // Force advance.homestart tracker to open in new tab
    $('a[href="https://advance.homestart.com.au/tracker/broker/login"]').each(function (k, elem) {
        $(elem).attr('target', '_blank');
    });
    // Slider double check to ensure it's in the right DOM position
    if ($('body').hasClass('broker-page')) {
        let $slider = $('.hero-slider:not(".edit-mode")');

        let $parent = $slider.parent()

        $("#main").prepend($slider)

        window.setTimeout(function () {
            //console.log('executed timer')
            let $slider = $('.hero-slider:not(".edit-mode")');
            $parent = $slider.parent();
            if ($parent.is("div") && $parent.attr('id') != 'main') {
                console.log('slider is out of position')
                $("#main").prepend($slider)
            }
        }, 1000)
    }

}
