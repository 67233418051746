import $ from "jquery";

export default (window) => {
    $('#Update-Payment-Details-Button').on('click', function () {

        console.log("Update Payment Details");

        $('.error').remove();
        $('#Update-Payment-Details-Form').hide();
        $('#UPD-Error-Div').hide();
        $('#UPD-Loading-Div').show();
        document.getElementById('UPD-Specific-Amount-Input').classList.remove('is-invalid-input');
        document.getElementById('UPD-DirectDebit-Date').classList.remove('is-invalid-input');
        document.getElementById('UPD-Monthly-Day1-Button').classList.remove('is-invalid-input');
        document.getElementById('UPD-Monthly-Day2-Button').classList.remove('is-invalid-input');
        document.getElementById('UPD-BSB').classList.remove('is-invalid-input');
        document.getElementById('UPD-AccountNumber').classList.remove('is-invalid-input');
        document.getElementById('UPD-DirectDebit-Date-Div').style.display = 'none';
        document.getElementById('UPD-Monthly-Day1-Div').style.display = 'none';
        document.getElementById('UPD-Monthly-Day2-Div').style.display = 'none';
        lockBackground();
        document.getElementById('Update-Payment-Details').style.display = 'block';
        $('.day-picker-button').css('background', 'white');
        $('.day-picker-button').css('color', 'black');
        $('.day-picker-button').removeClass('selected-day');

        $('#UPD-AccountName').val("");
        $("#UPD-AccountName").attr("original", "");
        $('#UPD-BSB').val("");
        $("#UPD-BSB").attr("original", "");
        $('#UPD-AccountNumber').val("");
        $("#UPD-AccountNumber").attr("original", "");
        $('#UPD-Frequency').val("");

        $.ajax({
            url: "/api/calmsdashboard/GetPaymentDetails",
            success: function (result) {
                console.log(result);

                if (result.errors.length == 0) {
                    $('#Update-Payment-Details-Form').show();
                    $('#UPD-Loading-Div').hide();
                    $('#UPD-AccountName').val(result.bankAccount.accountName);
                    $("#UPD-AccountName").attr("original", result.bankAccount.accountName);
                    $('#UPD-BSB').val(result.bankAccount.bsb);
                    $("#UPD-BSB").attr("original", result.bankAccount.bsb);
                    $('#UPD-AccountNumber').val(result.bankAccount.maskedAccountNumber);
                    $("#UPD-AccountNumber").attr("original", result.bankAccount.maskedAccountNumber);

                    var frequencyMinimumPayments = result.paymentTypeDetail.paymentByPaymentFrequencies;
                    var specificPayment = result.paymentTypeDetail.directDebitPaymentTypeDetail.specificAmount;

                    if (result.paymentTypeDetail.directDebitPaymentTypeDetail.minimumPaymentAmount == specificPayment)
                    { $('#UPD-Minimum-Payments').click(); }
                    else
                    { $('#UPD-Specific-Amount').click(); }

                    $('#UPD-Minimum-Payment-Value').html("$" + result.paymentTypeDetail.directDebitPaymentTypeDetail.minimumPaymentAmount);
                    $('#UPD-Minimum-Payment-Value').attr("Weekly", frequencyMinimumPayments.find(function (element) { return element.key == "W" }).value);
                    $('#UPD-Minimum-Payment-Value').attr("Fortnightly", frequencyMinimumPayments.find(function (element) { return element.key == "F" }).value);
                    $('#UPD-Minimum-Payment-Value').attr("Bi-Monthly", frequencyMinimumPayments.find(function (element) { return element.key == "B" }).value);
                    $('#UPD-Minimum-Payment-Value').attr("Monthly", frequencyMinimumPayments.find(function (element) { return element.key == "M" }).value);
                    $('#UPD-Specific-Amount-Input').val("$" + specificPayment);
                    $('#UPD-Specific-Amount-Input').blur();
                    $("#UPD-Specific-Amount-Input").attr("original", specificPayment);

                    switch (result.paymentTypeDetail.directDebitPaymentTypeDetail.frequency.key) {
                        case "W":
                            document.getElementById('UPD-Frequency').value = "Weekly";
                            $("#UPD-Frequency").attr("original", "Weekly");
                            $('#UPD-Minimum-Payment-Value').attr("minimum", $('#UPD-Minimum-Payment-Value').attr("Weekly"));
                            $('#UPD-Minimum-Payment-Value').html("$" + $('#UPD-Minimum-Payment-Value').attr("Weekly"));
                            document.getElementById('UPD-DirectDebit-Date-Div').style.display = 'block';
                            break;
                        case "F":
                            document.getElementById('UPD-Frequency').value = "Fortnightly";
                            $("#UPD-Frequency").attr("original", "Fortnightly");
                            $('#UPD-Minimum-Payment-Value').attr("minimum", $('#UPD-Minimum-Payment-Value').attr("Fortnightly"));
                            $('#UPD-Minimum-Payment-Value').html("$" + $('#UPD-Minimum-Payment-Value').attr("Fortnightly"));
                            document.getElementById('UPD-DirectDebit-Date-Div').style.display = 'block';
                            break;
                        case "B":
                            document.getElementById('UPD-Frequency').value = "Bi-Monthly";
                            $("#UPD-Frequency").attr("original", "Bi-Monthly");
                            $('#UPD-Minimum-Payment-Value').attr("minimum", $('#UPD-Minimum-Payment-Value').attr("Bi-Monthly"));
                            $('#UPD-Minimum-Payment-Value').html("$" + $('#UPD-Minimum-Payment-Value').attr("Bi-Monthly"));
                            document.getElementById('UPD-Monthly-Day1-Div').style.display = 'block';
                            document.getElementById('UPD-Monthly-Day2-Div').style.display = 'block';
                            break;
                        case "M":
                            document.getElementById('UPD-Frequency').value = "Monthly";
                            $("#UPD-Frequency").attr("original", "Monthly");
                            $('#UPD-Minimum-Payment-Value').attr("minimum", $('#UPD-Minimum-Payment-Value').attr("Monthly"));
                            $('#UPD-Minimum-Payment-Value').html("$" + $('#UPD-Minimum-Payment-Value').attr("Monthly"));
                            document.getElementById('UPD-Monthly-Day1-Div').style.display = 'block';
                            break;
                    }

                    var date = new Date(result.paymentTypeDetail.directDebitPaymentTypeDetail.nextPaymentDate);
                    $('#UPD-DirectDebit-Date').val(formatDateDMY(date));
                    $('#UPD-DirectDebit-Date').attr('original', formatDateDMY(date));
                    $('#UPD-Monthly-Day1-Button').attr('original', result.paymentTypeDetail.directDebitPaymentTypeDetail.dayOfMonth1);
                    $('#UPD-Monthly-Day2-Button').attr('original', result.paymentTypeDetail.directDebitPaymentTypeDetail.dayOfMonth2);
                    $('#UPD-Day1-' + result.paymentTypeDetail.directDebitPaymentTypeDetail.dayOfMonth1).click();
                    $('#UPD-Day2-' + result.paymentTypeDetail.directDebitPaymentTypeDetail.dayOfMonth2).click();

                    console.log("date: " + $('#UPD-DirectDebit-Date').val());
                    //$("#UPD-Frequency").trigger('change');

                    if (result.paymentTypeDetail.bPayPaymentTypeDetail.billerCode != null)
                    { $('#UPD-BPay-Biller-Code').html(" " + result.paymentTypeDetail.bPayPaymentTypeDetail.billerCode); }
                    else
                    { console.log("biller code null"); }

                    $('#UPD-BPay-Reference').html(" " + result.paymentTypeDetail.bPayPaymentTypeDetail.referenceNumber);
                    $('#UPD-BPay-Weekly-Minimum').html("$" + result.paymentTypeDetail.paymentByPaymentFrequencies[0].value);
                    $('#UPD-BPay-Fortnightly-Minimum').html("$" + result.paymentTypeDetail.paymentByPaymentFrequencies[1].value);
                    $('#UPD-BPay-Monthly-Minimum').html("$" + result.paymentTypeDetail.paymentByPaymentFrequencies[2].value);
                    $('#UPD-BPay-Date').html("Next payment by " + result.paymentTypeDetail.bPayPaymentTypeDetail.nextPaymentDate);
                    $('#UPD-BPay-Amount').html("$" + result.paymentTypeDetail.bPayPaymentTypeDetail.nextPaymentAmount);

                    if (result.paymentTypeDetail.paymentFacilityType.value == 'DIRECT_DEBIT') {
                        $('#UPD-BPAY').css('display', 'none');
                        $('#UPD-Direct-Debit').css('display', 'block');
                        $('#UPD-SwitchToBPay-Button').css("display", "block");
                        $('#UPD-DirectDebit-Button').attr('originalPaymentType', "DIRECT_DEBIT");
                    }
                    else {
                        $('#UPD-BPAY').css('display', 'block');
                        $('#UPD-Direct-Debit').css('display', 'none');
                        $('#UPD-SwitchToBPay-Button').css("display", "none");
                        $('#UPD-DirectDebit-Button').attr('originalPaymentType', "BPAY");
                    }
                }
                else {
                    //Handle the error message returned by API
                    $('#UPD-Error-Div').show();
                    $('#UPD-Loading-Div').hide();
                    $('#UPD-Error-Div-h').html("An error has occurred");
                    $('#UPD-Error-Div-p').html(result.errors[0].description);
                }
            },
            error: function () { //For when the API doesn't return anything at all
                $('.modal').hide();
                document.getElementById('FailModal').style.display = 'block';
            }
        });
    });

    //Validate input for bank details and send to confirm screen
    $('#UPD-UpsertBankAccountButton').on('click', function () {

        $('.error').remove();
        document.getElementById('UPD-UpsertBankAccountButton-ready').style.display = 'none';
        document.getElementById('UPD-UpsertBankAccountButton-loading').style.display = 'block';
        document.getElementById('UPD-UpsertBankAccountButton').enabled = false;

        setTimeout(validateBankDetails, 50); //needs to be a timeout to ensure the loading button activates
    });

    $('#UPD-Bank-Confirm-Checkbox').on('click', function () {
        document.getElementById('UPD-Bank-Confirm-Button').disabled = !document.getElementById('UPD-Bank-Confirm-Checkbox').checked;
    });

    $('#UPD-Bank-Confirm-Button').on('click', function () {
        document.getElementById('UPD-Bank-Confirm-Button').style.display = "none";
        document.getElementById('UPD-Bank-Confirm-Loading').style.display = "inline-block";
        var payload = {
            AccountName: $('#UPD-AccountName').val(),
            BSB: $('#UPD-BSB').val(),
            AccountNumber: $('#UPD-AccountNumber').val()
        }
        console.log("Upsert Bank Details payload");
        console.log(payload);

        $.ajax({
            url: "/api/calmsdashboard/UpsertBankDetails",
            data: payload,
            success: function (result) {
                console.log("Upsert Bank Details success");
                console.log(result);

                document.getElementById('UPD-Bank-Confirm-Button').style.display = "inline-block";
                document.getElementById('UPD-Bank-Confirm-Loading').style.display = "none";
                document.getElementById('UPD-Bank-Confirm').style.display = 'none';

                if (result.errors.length > 0) {
                    $('.modal').hide();
                    document.getElementById('ErrorModal').style.display = 'block';
                    document.getElementById('ErrorModal-Description').innerText = result.errors[0].description;
                }
                else {
                    document.getElementById('RequestSubmittedModal').style.display = 'block';
                    document.getElementById('UPD-Bank-Confirm-Button').disabled = !document.getElementById('UPD-Bank-Confirm-Checkbox').checked;
                    setTimeout(function () { document.body.style.overflow = 'hidden' }, 100);
                }
            },
            error: function () {
                document.getElementById('UPD-Bank-Confirm').style.display = 'none';
                document.getElementById('FailModal').style.display = 'block';
                document.getElementById('UPD-Bank-Confirm-Button').style.display = "inline-block";
                document.getElementById('UPD-Bank-Confirm-Loading').style.display = "none";

            }
        });
    });

    //--------------------- BPAY --------------------       

    $('#UPD-BPay-Confirm-Button').on('click', function () {
        document.getElementById('UPD-BPay-Confirm-Loading').style.display = "inline-block";
        document.getElementById('UPD-BPay-Confirm-Button').style.display = "none";
        console.log("Switch to BPay");

        $.ajax({
            url: "/api/calmsdashboard/SwitchToBPay",
            success: function (result) {

                if (result.errors.length > 0) {
                    $('.modal').hide();
                    document.getElementById('ErrorModal').style.display = 'block';
                    document.getElementById('ErrorModal-Description').innerText = result.errors[0].description;
                }
                else {
                    console.log(result);
                    document.getElementById('UPD-BPay-Confirm-Button').style.display = "inline-block";
                    document.getElementById('UPD-BPay-Confirm-Loading').style.display = "none";
                    document.getElementById('UPD-BPay-Confirm').style.display = 'none';
                    document.getElementById('RequestSubmittedModal').style.display = 'block';
                }
            },
            error: function (errorThrown) {
                console.log("Error:");
                console.log(errorThrown);
                document.getElementById('UPD-BPay-Confirm-Button').style.display = "inline-block";
                document.getElementById('UPD-BPay-Confirm-Loading').style.display = "none";
                document.getElementById('UPD-BPay-Confirm').style.display = 'none';
                document.getElementById('FailModal').style.display = 'block';
            }
        });
    });

    // ------------------------ DIRECT DEBIT -----------------

    $('#UPD-Minimum-Payments').on('click', function () {
        $('#UPD-Specific-Amount-Input').val($('#UPD-Minimum-Payment-Value').html());
        $('#UPD-Specific-Amount-Input').css('display', 'none');
    });

    $("#UPD_minimum_payments").on('click', function () { ToggleSpecificAmountInputBox() });
    $("#UPD_specific_amount").on('click', function () { ToggleSpecificAmountInputBox() });
    //$('#UPD_specific_amount_input').hide();

    $('#UPD-Specific-Amount').on('click', function () {
        $('#UPD-Specific-Amount-Input').css('display', 'block');
    });

    $('#UPD-DirectDebit-Button').on('click', function () {
        document.getElementById('UPD-Specific-Amount-Input').classList.remove('is-invalid-input');
        document.getElementById('UPD-DirectDebit-Date').classList.remove('is-invalid-input');
        document.getElementById('UPD-Monthly-Day1-Button').classList.remove('is-invalid-input');
        document.getElementById('UPD-Monthly-Day2-Button').classList.remove('is-invalid-input');
        $('.error').remove();
        var valid = true;

        if (document.getElementById('UPD-Specific-Amount').checked) {
            if (!validPaymentAmount()) {valid = false;}
        }

        var frequency = $('#UPD-Frequency').val();
        var originalFrequency = $('#UPD-Frequency').attr('original');

        switch (frequency) {
            case "Bi-Monthly":
                if ($('#UPD-Monthly-Day1-Button').val() == "") {
                    $('#UPD-Monthly-Day1-Button').addClass("is-invalid-input");
                    var error = document.createElement("span");
                    error.innerHTML = "Please select a payment day.";
                    error.className = "error";
                    error.style.marginTop = "0px";
                    error.style.display = "block";
                    $('#UPD-Monthly-Day1-Button').after(error);
                    valid = false;
                }
                if ($('#UPD-Monthly-Day2-Button').val() == "") {
                    $('#UPD-Monthly-Day2-Button').addClass("is-invalid-input");
                    var error = document.createElement("span");
                    error.innerHTML = "Please select a payment day.";
                    error.className = "error";
                    error.style.marginTop = "0px";
                    error.style.display = "block";
                    $('#UPD-Monthly-Day2-Button').after(error);
                    valid = false;
                }
                break;
            case "Monthly":
                if ($('#UPD-Monthly-Day1-Button').val() == "") {
                    $('#UPD-Monthly-Day1-Button').addClass("is-invalid-input");
                    var error = document.createElement("span");
                    error.innerHTML = "Please select a payment day.";
                    error.className = "error";
                    error.style.marginTop = "0px";
                    error.style.display = "block";
                    $('#UPD-Monthly-Day1-Button').after(error);
                    valid = false;
                }
                break;
            default:
                if ($('#UPD-DirectDebit-Date').val() == "") {
                    $('#UPD-DirectDebit-Date').addClass("is-invalid-input");
                    var error = document.createElement("span");
                    error.innerHTML = "Please select a payment date.";
                    error.className = "error";
                    error.style.marginTop = "0px";
                    error.style.display = "block";
                    $('#UPD-DirectDebit-Date').after(error);
                    valid = false;
                }
                break;
        }

        if (!valid) { return;}

        document.getElementById('UPD-DirectDebit-Date-Div').style.display = 'none';
        document.getElementById('Update-Payment-Details').style.display = 'none';
        document.getElementById('UPD-DirectDebit-Confirm').style.display = 'block';

        var payment = $('#UPD-Specific-Amount-Input').val();
        payment = payment.replace('$', "");
        payment = payment.replace(/,/g, "");
        var originalPayment = $('#UPD-Specific-Amount-Input').attr('original');
        var originalPaymentType = $('#UPD-DirectDebit-Button').attr('originalPaymentType');


        document.getElementById('UPD-DirectDebit-Confirm-Span').innerHTML = "";
        if (originalPaymentType == "BPAY") { document.getElementById('UPD-DirectDebit-Confirm-Span').innerHTML += "You will switch to direct debit.<br>"; }
        if (payment != originalPayment) { document.getElementById('UPD-DirectDebit-Confirm-Span').innerHTML += "Your payment amount will be changed to $" + payment + "<br>"; }
        if (frequency != originalFrequency && frequency != null) { document.getElementById('UPD-DirectDebit-Confirm-Span').innerHTML += "Your payment frequency will be " + frequency + "<br>"; }

        if (frequency == "Monthly") {
            var day1 = $('#UPD-Monthly-Day1-Button').val();
            var originalDay1 = $('#UPD-Monthly-Day1-Button').attr('original');
            if (day1 != originalDay1 && day1 != "") { document.getElementById('UPD-DirectDebit-Confirm-Span').innerHTML += "Your payments will occur on day " + day1 + " each month.<br>"; }
        }
        else if (frequency == "Bi-Monthly") {
            var day1 = $('#UPD-Monthly-Day1-Button').val();
            var originalDay1 = $('#UPD-Monthly-Day1-Button').attr('original');
            var day2 = $('#UPD-Monthly-Day2-Button').val();
            var originalDay2 = $('#UPD-Monthly-Day2-Button').attr('original');

            if ((day1 != originalDay1 || day2 != originalDay2) && day1 != "" && day2 != "")
            { document.getElementById('UPD-DirectDebit-Confirm-Span').innerHTML += "Your payments will occur on days " + day1 + " and " + day2 + " each month.<br>"; }
        }
        else {
            var nextPayment = $('#UPD-DirectDebit-Date').val();
            var originalNextPayment = $('#UPD-DirectDebit-Date').attr('original');
            if (nextPayment != originalNextPayment && nextPayment != "") { document.getElementById('UPD-DirectDebit-Confirm-Span').innerHTML += "Your next payment will be on " + nextPayment + "<br>"; }
        }
    });

    $('#UPD-DirectDebit-Confirm-Checkbox').on('click', function () {
        document.getElementById('UPD-DirectDebit-Confirm-Button').disabled = !document.getElementById('UPD-DirectDebit-Confirm-Checkbox').checked;
    });

    $('#UPD-DirectDebit-Confirm-Button').on('click', function () {
        document.getElementById('UPD-DirectDebit-Date-Div').style.display = 'none';
        document.getElementById('UPD-DirectDebit-Confirm-Button').style.display = "none";
        document.getElementById('UPD-DirectDebit-Confirm-Loading').style.display = "inline-block";

        var frequency = $('#UPD-Frequency').val();
        var nextPaymentDate = $('#UPD-DirectDebit-Date').val();
        var day1 = $('.selected-day1').val();
        var day2 = $('.selected-day2').val();
        var amount = document.getElementById('UPD-Specific-Amount').checked ? $('#UPD-Specific-Amount-Input').val().slice(1) : 0.0;

        var payload = {
            Frequency: frequency,
            NextPaymentDate: nextPaymentDate,
            Day1: day1,
            Day2: day2,
            Amount: amount
        }

        console.log(payload);

        $.ajax({
            url: "/api/calmsdashboard/UpsertDirectDebit",
            data: payload,
            success: function (result) {
                if (result.errors.length > 0) {
                    $('.modal').hide();
                    document.getElementById('ErrorModal').style.display = 'block';
                    document.getElementById('ErrorModal-Description').innerText = result.errors[0].description;
                }
                else {
                    console.log(result);
                    document.getElementById('UPD-DirectDebit-Confirm').style.display = 'none';
                    document.getElementById('Update-Payment-Details').style.display = 'none';
                    document.getElementById('RequestSubmittedModal').style.display = 'block';
                    document.getElementById('UPD-DirectDebit-Confirm-Button').style.display = "inline-block";
                    document.getElementById('UPD-DirectDebit-Confirm-Loading').style.display = "none";
                }
            },
            error: function (errorThrown) {
                console.log("Error:");
                console.log(errorThrown);
                document.getElementById('UPD-DirectDebit-Confirm').style.display = 'none';
                document.getElementById('FailModal').style.display = 'block';
                document.getElementById('UPD-DirectDebit-Confirm-Button').style.display = "inline-block";
                document.getElementById('UPD-DirectDebit-Confirm-Loading').style.display = "none";

            }
        });
    });

    $('#UPD-DirectDebit-Confirm-Back').click(function () {
        document.getElementById('UPD-DirectDebit-Confirm').style.display = 'none';
        document.getElementById('Update-Payment-Details').style.display = 'block';
        document.getElementById('UPD-Specific-Amount-Input').classList.remove('is-invalid-input');
        $('.error').remove();

        var frequency = $('#UPD-Frequency').val();

        switch (frequency) {
            case "Weekly":
                document.getElementById('UPD-DirectDebit-Date-Div').style.display = 'block';
                break;
            case "Fortnightly":
                document.getElementById('UPD-DirectDebit-Date-Div').style.display = 'block';
                break;
            case "Bi-Monthly":
                document.getElementById('UPD-Monthly-Day1-Div').style.display = 'block';
                document.getElementById('UPD-Monthly-Day2-Div').style.display = 'block';
                break;
            case "Monthly":
                document.getElementById('UPD-Monthly-Day1-Div').style.display = 'block';
                break;
        }
    });

    $('#UPD-Frequency').change(function () {

        $('#UPD-DirectDebit-Date-Div').css('display', 'none');
        $('#UPD-Monthly-Day1-Div').css('display', 'none');
        $('#UPD-Monthly-Day2-Div').css('display', 'none');
        $('#UPD-calendar-div').hide();
        $('#UPD-DirectDebit-Date').val("");

        $('#UPD-Monthly-Day1-Button').val("");
        $('.date1-button').css('background', 'white');
        $('.date1-button').css('color', 'black');
        $('.date1-button').removeClass('selected-day');

        $('#UPD-Monthly-Day2-Button').val("");
        $('.date2-button').css('background', 'white');
        $('.date2-button').css('color', 'black');
        $('.date2-button').removeClass('selected-day');

        var freq = $('#UPD-Frequency option:selected').val();
        var payment = 0;

        switch (freq) {
            case "Weekly":
                $('#UPD-DirectDebit-Date-Div').css('display', 'block');
                var payment = $('#UPD-Minimum-Payment-Value').attr("Weekly");
                $('#UPD-Minimum-Payment-Value').attr("minimum", payment);
                $('#UPD-Minimum-Payment-Value').html("$" + payment);
                break;
            case "Fortnightly":
                $('#UPD-DirectDebit-Date-Div').css('display', 'block');
                var payment = $('#UPD-Minimum-Payment-Value').attr("Fortnightly");
                $('#UPD-Minimum-Payment-Value').attr("minimum", payment);
                $('#UPD-Minimum-Payment-Value').html("$" + payment);

                break;
            case "Monthly":
                $('#UPD-Monthly-Day1-Div').css('display', 'block');
                var payment = $('#UPD-Minimum-Payment-Value').attr("Monthly");
                $('#UPD-Minimum-Payment-Value').attr("minimum", payment);
                $('#UPD-Minimum-Payment-Value').html("$" + payment);
                break;
            case "Bi-Monthly":
                $('#UPD-Monthly-Day1-Div').css('display', 'block');
                $('#UPD-Monthly-Day2-Div').css('display', 'block');
                var payment = $('#UPD-Minimum-Payment-Value').attr("Bi-Monthly");
                $('#UPD-Minimum-Payment-Value').attr("minimum", payment);
                $('#UPD-Minimum-Payment-Value').html("$" + payment);
                break;
        }

        if (document.getElementById('UPD-Minimum-Payments').checked) {
            $('#UPD-Specific-Amount-Input').val(payment);
            $('#UPD-Specific-Amount-Input').blur();
        }
    });

    $('.date1-button').click(function () {
        $('#UPD-Monthly-Day1-Button').val($(this).html());
        $('#UPD-Monthly-Day1-Picker').hide();
        $('.date1-button').css('background', 'white');
        $('.date1-button').css('color', 'black');
        $('.date1-button').removeClass('selected-day');
        $(this).css('background', '#0093D6');
        $(this).css('color', 'white');
        $(this).addClass('selected-day1');
    });

    $('#UPD-Monthly-Day1-Button').focus(function () {
        $('#UPD-Monthly-Day1-Picker').show();
        $('#UPD-Monthly-Day1-Button').blur();
    });

    $('#UPD-Monthly-Day2-Button').focus(function () {
        $('#UPD-Monthly-Day2-Picker').show();
        $('#UPD-Monthly-Day2-Button').blur();
    });

    $('.date2-button').click(function () {
        $('#UPD-Monthly-Day2-Button').val($(this).html());
        $('#UPD-Monthly-Day2-Picker').hide();
        $('.date2-button').css('background', 'white');
        $('.date2-button').css('color', 'black');
        $('.date2-button').removeClass('selected-day');
        $(this).css('background', '#0093D6');
        $(this).css('color', 'white');
        $(this).addClass('selected-day2');
    });

    $('#UPD-DirectDebit-Date').click(function () {

        var freq = $('#UPD-Frequency option:selected').val();
        $('#UPD-calendar-div').show();
        $('#UPD-DirectDebit-Date').blur();

        switch (freq) {
            case "Weekly":
                var targetDate = new Date();
                var lastValid = new Date();;
                lastValid = new Date(lastValid.setDate(lastValid.getDate() + 7));
                document.getElementById("html-calendar").setAttribute("firstValid", targetDate);
                document.getElementById("html-calendar").setAttribute("lastValid", lastValid);
                setupCalendar(targetDate.getFullYear(), targetDate.getMonth() + 1);
                $('#UPD-DirectDebit-Date-Div').css('display', 'block');
                break;
            case "Fortnightly":
                var targetDate = new Date();
                var lastValid = new Date();
                lastValid = new Date(lastValid.setDate(lastValid.getDate() + 14));
                document.getElementById("html-calendar").setAttribute("firstValid", targetDate);
                document.getElementById("html-calendar").setAttribute("lastValid", lastValid);
                setupCalendar(targetDate.getFullYear(), targetDate.getMonth() + 1);
                $('#UPD-DirectDebit-Date-Div').css('display', 'block');
                break;
            case "Monthly":
                $('#UPD-Monthly-Day1-Div').css('display', 'block');
                break;
            case "Bi-Monthly":
                $('#UPD-Monthly-Day1-Div').css('display', 'block');
                $('#UPD-Monthly-Day2-Div').css('display', 'block');
                break;
        }
    });

    $('#UPD-DirectDebit-Date').focus(function () {
        $('#UPD-DirectDebit-Date').blur();
    });

    $('#html-calendar-prev').click(function () {
        var calendarMonth = new Date(document.getElementById("html-calendar").getAttribute("current"));
        calendarMonth.setMonth(calendarMonth.getMonth() - 1);
        var year = calendarMonth.getFullYear();
        var month = calendarMonth.getMonth();
        if (month == 0) {
            year--;
            month = 12;
        }
        setupCalendar(year, month);
    });

    $('#html-calendar-next').click(function () {
        var calendarMonth = new Date(document.getElementById("html-calendar").getAttribute("current"));
        calendarMonth.setMonth(calendarMonth.getMonth() + 1);
        var year = calendarMonth.getFullYear();
        var month = calendarMonth.getMonth();
        if (month == 0) {
            year--;
            month = 12;
        }
        setupCalendar(year, month);
    });
}


function formatDateYMD(inputDate) {
    var year = inputDate.toLocaleString("default", { year: "numeric" });
    var month = inputDate.toLocaleString("default", { month: "2-digit" });
    var day = inputDate.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    return year + "-" + month + "-" + day;
}

function formatDateDMY(inputDate) {
    var year = inputDate.toLocaleString("default", { year: "numeric" });
    var month = inputDate.toLocaleString("default", { month: "long" });
    var day = inputDate.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    return day + " " + month + " " + year;
}

function setupCalendar(year, month) {

    var calendar = document.getElementById("html-calendar");
    calendar.setAttribute("current", new Date(year, month));
    var firstValid = new Date(calendar.getAttribute('firstValid'));
    var lastValid = new Date(calendar.getAttribute('lastValid'));

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    document.getElementById("html-calendar-month").innerText = monthNames[month - 1];
    //document.getElementById("html-calendar-year").innerText = year;
    calendar.replaceChildren();

    var buttonDate = new Date(year, month - 1, 1);
    buttonDate.setDate(buttonDate.getDate() - (buttonDate.getDay() + 6) % 7);

    for (var d = 1; d < 43; d++) {
        var button = document.createElement("button");
        button.classList = "calendar-day";
        button.type = 'button';
        button.setAttribute("buttonDate", formatDateDMY(buttonDate));
        button.innerText = buttonDate.getDate();

        if (buttonDate.getMonth() == month - 1) {
            button.classList.add("calendar-this-month");
        }
        else {
            button.classList.add("calendar-other-month");
        }

        if (buttonDate >= firstValid && buttonDate <= lastValid && buttonDate.getDay() != 0 && buttonDate.getDay() != 6) {
            button.classList.add("calendar-valid");
            button.classList.remove("calendar-other-month");
            button.classList.remove("calendar-this-month");
            button.onclick = function () {
                setInputDate(this);
                $('#UPD-calendar-div').hide();
            };

            if (formatDateDMY(buttonDate) == $('#UPD-DirectDebit-Date').val()) { button.classList.add('calendar-selected'); }
        }

        calendar.appendChild(button);
        buttonDate.setDate(buttonDate.getDate() + 1);
    }
}

function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
}

function setInputDate(elem) {
    $('#UPD-DirectDebit-Date').val(elem.getAttribute("buttonDate"));
    $('.calendar-day').removeClass('calendar-selected');
    elem.classList.add('calendar-selected');
}

function validPaymentAmount() {
    var amountInput = $('#UPD-Specific-Amount-Input');
    var amount = amountInput.val();
    var minimum = $('#UPD-Minimum-Payment-Value').attr("minimum");
    console.log(amount);
    amount = amount.replace(/\D/g, '');
    amount = parseInt(amount);

    if (isNaN(amount)) {
        amountInput.addClass("is-invalid-input");
        var error = document.createElement("span");
        error.innerHTML = "Please enter a payment amount.";
        error.className = "error";
        error.style.display = "block";
        amountInput.after(error);
        return false;
    }

    if (amount < minimum * 100) { //2 extra zeros because this is looking at cents, not dollars
        console.log("Amount: " + amount + " minimum: " + minimum);
        amountInput.addClass("is-invalid-input");
        var error = document.createElement("span");
        error.innerHTML = "The minimum payment is $" + minimum + ".";
        error.className = "error";
        error.style.display = "block";
        amountInput.after(error);
        return false;
    }

    return true;
}

function validateBankDetails() {

    var accountName = $('#UPD-AccountName').val();
    var originalAccountName = $("#UPD-AccountName").attr("original");
    var BSB = $('#UPD-BSB').val();
    var originalBSB = $("#UPD-BSB").attr("original");
    var validBSB = false;
    var accountNumber = $('#UPD-AccountNumber').val();
    var originalAccountNumber = $("#UPD-AccountNumber").attr("original");
    var validAccountNumber = false;

    if (BSB == originalBSB) {
        validBSB = true;
        console.log("BSB not changed, clearing from upsert...");
        BSB = "";
    }
    else {
        $.ajax({
            url: "/api/calmsdashboard/ValidateBSB?BSB=" + BSB,
            success: function (result) { validBSB = (result == "True"); console.log("validBSB = " + validBSB); },
            error: function () { validBSB = false; console.log("BSB validation faled");},
            async: false
        });
    }

    if (accountNumber == originalAccountNumber) {
        validAccountNumber = true;
        console.log("Account Number has not changed, clearing from upsert...");
        accountNumber = "";
    }
    else {
        console.log("Account Number has been changed, validating...");
        $.ajax({
            url: "/api/calmsdashboard/ValidateAccountNumber?AccountNumber=" + accountNumber,
            success: function (result) { validAccountNumber = (result == "true"); },
            async: false
        });
    }

    document.getElementById('UPD-UpsertBankAccountButton-ready').style.display = 'block';
    document.getElementById('UPD-UpsertBankAccountButton-loading').style.display = 'none';
    document.getElementById('UPD-UpsertBankAccountButton').enabled = true;

    if (validBSB && validAccountNumber && accountName != "") {
        console.log("validBSB = " + validBSB);
        document.getElementById('Update-Payment-Details').style.display = 'none';
        document.getElementById('UPD-Bank-Confirm').style.display = 'block';

        document.getElementById('UPD-Bank-Confirm-Span').innerHTML = "";
        if (accountName != originalAccountName) { document.getElementById('UPD-Bank-Confirm-Span').innerHTML += "Your account name will be updated to " + accountName + "<br>"; }
        if (BSB != "") { document.getElementById('UPD-Bank-Confirm-Span').innerHTML += "Your BSB will be updated to " + BSB + "<br>"; }
        if (accountNumber != "") { document.getElementById('UPD-Bank-Confirm-Span').innerHTML += "Your account number will be updated to " + accountNumber + "<br>"; }

        return;
    }

    if (!validBSB) {
        $('#UPD-BSB').addClass('is-invalid-input');

        var error = document.createElement("span");
        error.innerHTML = "Please enter a valid BSB";
        error.className = "error";
        error.style.display = "flex";
        $('#UPD-BSB').after(error);
    }

    if (!validAccountNumber) {
        $('#UPD-AccountNumber').addClass('is-invalid-input');

        var error = document.createElement("span");
        error.innerHTML = "Please enter a valid Account Number";
        error.className = "error";
        error.style.display = "flex";
        $('#UPD-AccountNumber').after(error);
    }

    if (accountName == "") {
        $('#UPD-AccountName').addClass('is-invalid-input');

        var error = document.createElement("span");
        error.innerHTML = "Please enter the account name";
        error.className = "error";
        error.style.display = "flex";
        $('#UPD-AccountName').after(error);
    }
}

function ToggleSpecificAmountInputBox() {
    if ($('#UPD_specific_amount').is(':checked')) { $('#UPD_specific_amount_input').show(); }
    else { $('#UPD_specific_amount_input').hide(); }

}
