import $                                              from 'jquery';
import {getFullSuburbList, getSuburbListAutoComplete} from "./calculator-suburb-list";
import {getAutoCompletePostCodes, getPostCodeMap}     from "../../broker/components/location-valuers-helpers";

export default function () {

    let elemList = $('input.suburb-selector');
    var suburbList = JSON.parse(localStorage.getItem("suburbs"));
    let suburbs = getFullSuburbList();
    let postCodeFinder = [];
    $.each(suburbs, function (k, obj) {
        postCodeFinder[obj['suburbName'].toLowerCase()] = obj["postCode"].toLowerCase();
    });

    if (suburbList == null || suburbList == "undefined") {

        var suburbList = getAutoCompletePostCodes();

        /*
         var suburbUrl = window.location.origin + '/api/calculatorservice/getsuburbs?data=';
         $.ajax({
         url: suburbUrl,
         data: {"data": ''},
         type: "GET",
         contentType: "application/json",
         dataType: "text",
         }).then(function success(data) {
         var suburbs = JSON.stringify(JSON.parse(data).Suburbs);

         if (suburbs.length == 0 || suburbs == "") {
         // Use backup
         suburbs = getSuburbListAutoComplete();
         }
         console.log("setting", suburbs);

         })
         */

        localStorage.setItem("suburbs", JSON.stringify(suburbList));
        applyAutocomplete()
    } else {
        applyAutocomplete();
    }

    function applyAutocomplete() {
        //console.log('Applying autocomplete!');
        var suburbList = JSON.parse(localStorage.getItem("suburbs"));
        elemList.each(function (k, elem) {
            elem = $(elem);
            console.log('Applying autocomplete!');
            let autoCompleteDiv = elem.parent().find('.autocomplete-results');
            if (suburbList.length == 0 || suburbList == "") {
                //console.log(elem.length)
                //console.log('auto completing to ' + autoCompleteDiv.attr('id'), autoCompleteDiv)
                //console.log("Autocompleting with list", suburbList);
                suburbList = getAutoCompletePostCodes();
                localStorage.setItem("suburbs", JSON.stringify(suburbList));
            }
            elem.autocomplete(
                {
                    minLength: 3,
                    appendTo: "#" + autoCompleteDiv.attr('id'),
                    source: suburbList,
                    onSelect: function (suggestion) {
                        // set each suburb selector to the one that's been picked
                        elemList.each(function (k, elem) {
                            $(elem).val(suggestion);
                        })

                    }
                }
            ).autocomplete("instance")._renderItem = function (ul, item) {
                //console.log(item);
                var html = ""

                if (item.label == "alma") {
                }
                if (item.label.toLowerCase() === item.value.toLowerCase()) {
                    var string = item.label.toString().toLowerCase();
                    let postcode = postCodeFinder[string];
                    html = `<li>${postcode} ${item.value} </li>`

                    return $(html).appendTo(ul);
                }
                if (parseInt(item.label) !== NaN && item.label.length <= 4) {
                    // is string - fetch the postcode

                    html = `<li>${item.label} ${item.value} </li>`

                    return $(html).appendTo(ul);
                }

                //console.log(item);
                var string = item.label.toString().toLowerCase();
                let postcode = postCodeFinder[string];
                html = `<li>${postcode} ${item.value} </li>`

                return $(html).appendTo(ul);
            }

        });


    }


    function getPostCodeForSuburb(suburb = "") {
        let result = undefined;
        $.each(suburbList, function (k, obj) {
            //console.log(obj);
            if (obj["value"].toLowerCase() == suburb.toLowerCase() && obj['label'].length == 4) {
                return obj["label"];
            }
        });
    }

}


