import $ from "jquery";

export default (window) => {
    if ($('#Authorise-a-Third-Party').length == 0) {
        console.log("Authorise third party div not found");
        return;
    }

    const API_URL = '/api/calmsdashboard/PrintauthorisethirdpartyPDF';


    //Code to add autocomplete options after an input form, uncomment to bring back    
    /*$("#atp_address").autocomplete({
        source: function (request, response) {

            $("#atp_address_loading").css("display", "block");

            $.ajax({
                url: "/api/searchaddress",
                dataType: "json",
                data: { address: $("#atp_address").val() },
                success: function (data) {
                    $('.autocompleteSuggestion').remove();
                    (data).forEach(element => {
                        var suggestion = document.createElement("p");
                        suggestion.innerHTML = element;
                        suggestion.className = "autocompleteSuggestion";
                        suggestion.onclick = function () {
                            $("#atp_address").val(element);
                            $('.autocompleteSuggestion').remove();
                        }
                        suggestion.style.color = "black";
                        $("#atp_address").after(suggestion);
                        $("#atp_address_loading").css("display", "none");
                    });

                }
            });
        }
    });    

    $("#atp_address").on({
        blur: function () { $('.autocompleteSuggestion').remove();}
    });*/

    $("#authorise-third-party-button").on("click", function () {
        lockBackground();
        document.getElementById('Authorise-a-Third-Party').style.display = 'block';
        //$("#atp_button_loading").css("display", "none");
        //$("#atp_button_ready").css("display", "inline");
    });

    $('#Authorise-a-Third-Party-Form').on('submit', function (e) {

        e.preventDefault();
        $('.error').remove();

        var invalid = $(this).find('.is-invalid-label');
        console.log(invalid.length, 'invalids');

        if (invalid.length != 0) {
            for (var i = 0; i < invalid.length; i++)
            {
                var error = document.createElement("span");
                error.innerHTML = invalid[i].getAttribute("errorText");
                error.className = "error";
                invalid[i].after(error);
            }

            validDOB();

            return;
        }

        if (!validDOB()) { return; }

        $("#atp_submit_button").prop('disabled', 'disabled');
        $("#atp_button_loading").css("display", "inline");
        $("#atp_button_ready").css("display", "none");

        // Handle the submission of the formvar
        var $container = $('#Authorise-a-Third-Party');
        var firstName = $container.find("input[name='atp_firstname']").val();
        var lastName = $container.find("input[name='atp_lastname']").val();
        var address = $container.find("input[name='atp_address']").val();
        var dob = $container.find("input[name='atp_dob']").val();
        var contactnumber = $container.find("input[name='atp_contact_number']").val();
        var email = $container.find("input[name='atp_email']").val();
        var relationship = $container.find("input[name='atp_relationship']").val();

        var payload = {
            'first_name': firstName,
            'last_name': lastName,
            'address': address,
            'dob': dob,
            'contactnumber': contactnumber,
            'email': email,
            'relationship': relationship
        }

        console.log(payload);

        $.ajax({
            url: API_URL,
            data: payload,
            success: function (result) {
                console.log(result);

                $('#Authorise-a-Third-Party').hide();
                $("#atp_submit_button").removeAttr('disabled');

                if (result == "Success") {
                    $('#RequestSubmittedModal').show();
                    $('#Authorise-a-Third-Party-Form')[0].reset();
                    $("#atp_button_loading").css("display", "none");
                    $("#atp_button_ready").css("display", "inline");
                } else if (result == "Logout") {
                    document.location = "/api/Account/Login";
                } else {                  
                        $('.modal').hide();
                        document.getElementById('FailModal').style.display = 'block';
                        $("#rfa_button_loading").css("display", "none");
                        $("#rfa_button_ready").css("display", "inline");
                }
            },
            error: function (error) {
                if (error.responseText.includes("User cannot perform action on this api")) {
                    document.location = "/api/Account/Login";
                } else {
                    $('.modal').hide();
                    document.getElementById('FailModal').style.display = 'block';
                    $("#rfa_button_loading").css("display", "none");
                    $("#rfa_button_ready").css("display", "inline");
                }
            }
        });

    });

    console.log("Authorise third party form ready");
}

function validDOB() {
    console.log("validating DOB");
    var dobInput = $('#atp_dob');
    var dob = new Date(Date.parse(dobInput.val()));
    //console.log("value parsed from form: " + dob);
    /*var day = 60 * 60 * 24 * 1000;
    dob = new Date(dob.getTime() - day);
    console.log("one day older: " + dob);*/

    /*if (isNaN(dob)) {
        dobInput.addClass("is-invalid-input");
        var error = document.createElement("span");
        error.innerHTML = "Please enter their date of birth.";
        error.className = "error";
        error.style.display = "block";
        dobInput.after(error);
        return false;
    }*/

    var month_diff = Date.now() - dob;
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);

    if (age < 18) {
        dobInput.addClass("is-invalid-input");
        var error = document.createElement("span");
        error.innerHTML = "You may only nominate someone over 18 years of age.";
        error.className = "error";
        error.style.display = "block";
        dobInput.after(error);
        return false;
    }

    return true;
}