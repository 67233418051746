import $ from "jquery";
export default (window) => {

    if (window.location.pathname == "/") {
        //alert('on home page');
        $.post(window.location.origin + '/api/account/isloggedin', {}, function (data) {
            // If they're not logged in
            if (data.isLoggedIn != true) {
                // And the menu isn't set to Login
                if ($('.login-menu .login-link').text().trim() != "Login") {
                    // Fix it up
                    $('.login-menu').html(`
                        <a href="#" class="login-link">
                            <span class="siteicon siteicon-user-circle-o"> </span>
                            Login 
                        </a>
                        <ul class="login-menu-dropdown">
                            <li>
                                <a href="https://secure.homestart.com.au/eBusiness/welcome.asp"> Customer <i class="siteicon fal fa-chevron-right"> </i> </a>
                            </li>
                            <li>
                                <a href="/api/Account/Login"> Broker <i class="siteicon fal fa-chevron-right"> </i> </a>
                            </li>
                            <li>
                                <a href="/api/Account/Login"> Corporate <i class="siteicon fal fa-chevron-right"> </i> </a>
                            </li>
                            <li>
                                <a href="/api/Account/Login"> Online Application <i class="siteicon fal fa-chevron-right"> </i> </a>
                            </li>
                        </ul>
                    `)
                }
            }

        })
    }
}